import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import App from './App';
import './index.scss';
import alarmeReducer from './services/reducteurs/alarme';
import alertesReducer from './services/reducteurs/alertes';
import attenteRequeteReducer from './services/reducteurs/attenteRequete';
import authReducer from './services/reducteurs/auth';
import capteurReducer from './services/reducteurs/capteur';
import exportImpressionEcranReducer from './services/reducteurs/exportImpressionEcran';
import grapheReducer from './services/reducteurs/graphe';
import interfaceClientReducer from './services/reducteurs/interfaceClient';
import maintenanceReducer from './services/reducteurs/maintenance';
import popupReducer from './services/reducteurs/popup';
import privilegeReducer from './services/reducteurs/privilege';
import rapportReducer from './services/reducteurs/rapport';
import utilisateurReducer from './services/reducteurs/utilisateur';
import vueReducer from './services/reducteurs/vue';
import { setUrlPathFromDomain } from './services/utils/utils';
import * as serviceWorker from './serviceWorker';

// const composeEnhancers = compose;
const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;

let cheminURLAPI = setUrlPathFromDomain(
  'http://localhost/argos_frontend/api_argos/api.php',
  './index.php',
  './index.php'
);

axios.defaults.baseURL = cheminURLAPI;
console.error('Chemin api', axios.defaults.baseURL);

const rootReducer = combineReducers({
  authentifie: authReducer,
  alrt: alertesReducer,
  utilisateur: utilisateurReducer,
  capteur: capteurReducer,
  graphe: grapheReducer,
  vue: vueReducer,
  alarme: alarmeReducer,
  rapport: rapportReducer,
  privilegeUtilisateur: privilegeReducer,
  attenteRequete: attenteRequeteReducer,
  popup: popupReducer,
  interfaceClient: interfaceClientReducer,
  exportImpressionEcran: exportImpressionEcranReducer,
  maintenance: maintenanceReducer,
});

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3C3935',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

ReactDOM.render(
  <BrowserRouter
    getUserConfirmation={(message, callback) => {
      // this is the default behavior
      const allowTransition = window.confirm(message);
      callback(allowTransition);
    }}
  >
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tableau from '../../../../UI/tableau/Tableau';
import * as privilege from '../../../../../ressources/glossaires/glossaire_privileges';

import Bouton from '../../../../UI/Bouton/Bouton';
import * as actions from '../../../../../services/actions';
import AttenteRequete from '../../../../../hoc/AttenteRequete/AttenteRequete';
import * as requestType from '../../../../../ressources/glossaires/glossaire_requetes';
import { checkMot } from '../../../../../ressources/functionJS/checkMot';
import { VscNewFile, VscRefresh } from 'react-icons/vsc';
import * as type_btn from '../../../../../ressources/glossaires/glossaire_type_de_bouton';
import { glossaire_alarm_level } from '../../../../../ressources/glossaires/glossaire_niveau_alarme';
import * as glossaire_type_d_alarme from '../../../../../ressources/glossaires/glossaire_type_d_alarme';
import { jsDictionaryEquals } from '../../../../../ressources/functionJS/jsDictionaryEquals';
import { textFilter } from 'react-bootstrap-table2-filter';
import { VscSearch, VscCloudDownload } from 'react-icons/vsc';
import {
  BiLineChart,
  BiLineChartDown,
  ImEye,
  ImEyeBlocked,
} from 'react-icons/all';
import * as S from './TableauAlarmes.module.scss';
import exportFromJSON from 'export-from-json';
export const iconSize = 30;
export const convertiUtsToDateHour = (uts) => {
  let unixToconvert = Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(uts * 1000);
  unixToconvert = unixToconvert.replaceAll('/', '-').replace(',', '');
  const time = unixToconvert.split(' ')[1];
  let splitDate = unixToconvert.split(' ')[0].split('-');
  let date = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0];
  return date + ' ' + time;
};
export const convertiUtsToDate = (uts) => {
  let unixToconvert = Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(uts * 1000);
  unixToconvert = unixToconvert.replaceAll('/', '-').replace(',', '');
  let splitDate = unixToconvert.split(' ')[0].split('-');
  return splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0];
};

class TableauAlarmesClient extends Component {
  componentDidMount() {
    //récupère la liste des Alarmes (utiliser la requête api) en fonction de la liste de projets sélectionné dans le filtre
    if (!isNaN(this.props.project_id_url) && this.props.project_id_url !== '') {
      // cas pour l'interface client utilisation de l'id projet de l'url
      this.props.recupererListeAlarmes([this.props.project_id_url]);
    } else if (this.props.filtre_projects_list_id.length > 0) {
      // envoie la requête de recupération de alarmes si la liste de projets n'est pas vide
      this.props.recupererListeAlarmes(this.props.filtre_projects_list_id);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //récupère la liste des Alarmes en fonction de la liste de projets sélectionné dans le filtre, si re lance la requête si la liste de projets change
    if (
      !isNaN(this.props.project_id_url) &&
      prevProps.project_id_url !== this.props.project_id_url &&
      this.props.project_id_url !== ''
    ) {
      // cas pour l'interface client utilisation de l'id projet de l'url
      this.props.recupererListeAlarmes([this.props.project_id_url]);
    } else if (
      JSON.stringify(prevProps.listeAlarmes) !==
      JSON.stringify(this.props.listeAlarmes)
    ) {
      // envoie la requête de recupération de Alarmes si la liste de projets change entre temps
      this.setState({ ...this.state, listeAlarmes: this.props.listeAlarmes });
    } else if (
      !jsDictionaryEquals(
        prevProps.filtre_projects_list_id,
        this.props.filtre_projects_list_id
      )
    ) {
      this.props.recupererListeAlarmes(this.props.filtre_projects_list_id);
    }
    if (
      this.state.selectedRowAlarmId !== null &&
      this.props.attenteReq_recuperer_la_liste_des_alarmes === true
    ) {
      //renvoie l'information de la composante pour actualiser le conteneur info
      this.props.vueSelectione(
        this.props.listeAlarmes.find(
          (alarme) => alarme.alarm_id === this.state.selectedRowAlarmId
        )
      );
    }
  }

  afficherFiltre = (dataField, placeholder) => {
    const newCol = this.state.columns.map((col) => {
      if (col.dataField === dataField) {
        return {
          ...col,
          filter: textFilter({
            placeholder: placeholder,
          }),
        };
      } else {
        return col;
      }
    });
    this.setState({
      ...this.state,
      columns: [...newCol],
    });
  };
  state = {
    listExport: [],
    selectedRowAlarmId: null,
    columns: [
      // liste des clés pour l'entête du tableau de Alarmes
      {
        dataField: 'alarm_id',
        text: [
          checkMot('id'),
          <Bouton
            key={'alarm_id_header_search'}
            type={type_btn.lupeSearch}
            cliquer={() => this.afficherFiltre('alarm_id', checkMot('id'))}
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        headerAlign: 'left',
        align: 'left',
        hidden: true,
      },
      {
        dataField: 'acknowledgement_status',
        text: [
          checkMot('status'),
          <Bouton
            key={'acknowledgement_status_header_search'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('acknowledgement_status', checkMot('status'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        formatter: (cell) => {
          return parseInt(cell) === 0 ? (
            <ImEyeBlocked size={iconSize} />
          ) : (
            <ImEye size={iconSize} />
          );
        },
      },
      {
        dataField: 'alarm_level',
        text: [
          checkMot('niveau'),
          <Bouton
            key={'alarm_level_header_search'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('alarm_level', checkMot('niveau'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        formatter: (cell) => {
          const alarme = glossaire_alarm_level[parseInt(cell)];
          if (alarme.split(' ').length === 2) {
            const extremum =
              alarme.split(' ')[0] === 'High' ? (
                <BiLineChart />
              ) : (
                <BiLineChartDown />
              );
            const couleur = alarme.split(' ')[1];
            let text;
            let classCouleurAlarme;
            switch (couleur) {
              case 'black':
                classCouleurAlarme = S.CouleurAlarme_black;
                text = checkMot('arret');
                break;
              case 'red':
                classCouleurAlarme = S.CouleurAlarme_red;
                text = checkMot('alarme');
                break;
              case 'amber':
                classCouleurAlarme = S.CouleurAlarme_orange;
                text = checkMot('alerte');
                break;
              case 'blue':
                classCouleurAlarme = S.CouleurAlarme_blue;
                text = checkMot('vigilance');
                break;
              default:
                classCouleurAlarme = S.CouleurAlarme_green;
                text = '';
                break;
            }
            return (
              <div className={classCouleurAlarme}>
                {extremum} {text}
              </div>
            );
          } else if (alarme === glossaire_alarm_level[9]) {
            return (
              <div className={S.CouleurAlarme_grey}>
                {checkMot('pas_de_donnees')}
              </div>
            );
          }
        },
      },
      {
        dataField: 'alarm_type',
        text: [
          checkMot('alarm_type'),
          <Bouton
            key={'alarm_type_header_search'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('alarm_type', checkMot('status'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        headerAlign: 'left',
        align: 'left',
        hidden: true,
        formatter: (cell) =>
          checkMot(
            Object.keys(glossaire_type_d_alarme).find(
              (key) => glossaire_type_d_alarme[key] === parseInt(cell)
            )
          ),
      },
      {
        dataField: 'sensor_name',
        text: [
          checkMot('nom_capteur') + ' ',
          // <Bouton key={"sensor_name_header_search"} type={type_btn.lupeSearch}
          //         cliquer={() => this.afficherFiltre("sensor_name", checkMot("nom_capteur"))}><VscSearch size={10}/>
          // </Bouton>
        ],
        sort: true,
        headerAlign: 'left',
        align: 'left',
        filter: textFilter(),
      },
      {
        dataField: 'sensor_id',
        text: [
          checkMot('id_capteur'),
          <Bouton
            key={'sensor_id_header_search'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('sensor_id', checkMot('id_capteur'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        headerAlign: 'left',
        align: 'left',
        hidden: true,
      },
      {
        dataField: 'prefix',
        text: [
          checkMot('composante'),
          <Bouton
            key={'prefix_header_search'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('prefix', checkMot('composante'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        headerAlign: 'left',
        align: 'left',
      },
      {
        dataField: 'project_id',
        text: [
          checkMot('projet'),
          <Bouton
            key={'project_id_header_search'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('project_id', checkMot('projet'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        headerAlign: 'left',
        align: 'left',
        hidden: true,
        formatter: (cell) => {
          for (let i = 0; i < this.props.projects_list.length; i++) {
            if (this.props.projects_list[i].project_id === cell) {
              return this.props.projects_list[i].project_name;
            }
          }
          return cell;
        },
      },
      {
        dataField: 'alarm_start',
        text: [
          checkMot('debut'),
          <Bouton
            key={'alarm_start_header_search'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('alarm_start', checkMot('debut'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        headerAlign: 'left',
        align: 'left',
        formatter: (cell) => {
          return convertiUtsToDateHour(cell);
        },
      },
      {
        dataField: 'alarm_end',
        text: [
          checkMot('fin'),
          <Bouton
            key={'alarm_end_header_search'}
            type={type_btn.lupeSearch}
            cliquer={() => this.afficherFiltre('alarm_end', checkMot('fin'))}
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        headerAlign: 'left',
        align: 'left',
        hidden: true,
        formatter: (cell) => {
          return convertiUtsToDateHour(cell);
        },
      },
      {
        dataField: 'alarm_value',
        text: [
          checkMot('valeur'),
          <Bouton
            key={'alarm_value_header_search'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('alarm_value', checkMot('valeur'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        headerAlign: 'left',
        align: 'left',
      },
      {
        dataField: 'engineering_unit',
        text: [
          checkMot('unite'),
          <Bouton
            key={'engineering_unit_header_search'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('alarm_value', checkMot('unite'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        headerAlign: 'left',
        align: 'left',
      },
      {
        dataField: 'remark',
        text: [
          checkMot('remarque'),
          <Bouton
            key={'remark_header_search'}
            type={type_btn.lupeSearch}
            cliquer={() => this.afficherFiltre('remark', checkMot('remarque'))}
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        headerAlign: 'left',
        align: 'left',
      },
    ],
    hiden: false,
    selectRow: {
      // paramètre d'affichage des lignes du tableau
      mode: 'radio',
      clickToSelect: true,
      hideSelectAll: true,
      hideSelectColumn: true,
      headerAlign: 'left',
      align: 'left',
      style: () => {
        //style après sélection
        return {
          background: '#eeb214',
          color: 'black',
        };
      },
      onSelect: (row) => {
        //fonction de récupération des données de la ligne après sélection de la ligne
        this.setState({ selectedRowAlarmId: row.alarm_id });
        return this.props.vueSelectione(row);
      },
    },

    iconeCreer: <VscNewFile size={15} />,
    iconeEffacer: (
      <svg
        width="1.5em"
        height="1.5em"
        viewBox="0 0 16 16"
        className="bi bi-folder-minus"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M9.828 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91H9v1H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181L15.546 8H14.54l.265-2.91A1 1 0 0 0 13.81 4H9.828zm-2.95-1.707L7.587 3H2.19c-.24 0-.47.042-.684.12L1.5 2.98a1 1 0 0 1 1-.98h3.672a1 1 0 0 1 .707.293z"
        />
        <path
          fillRule="evenodd"
          d="M11 11.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z"
        />
      </svg>
    ),
    iconeMultiSelection: false,
  };
  exporterConfiguration = () => {
    const data = this.props.listeAlarmes;
    const fileName = 'ArgosListAlarm';
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
  };
  render() {
    let listeCleACache = [];
    if (this.props.listeAlarmes !== undefined) {
      if (this.props.interfaceAdmin) {
        for (
          let indLigne = 0;
          indLigne < this.props.listeAlarmes.length;
          indLigne++
        ) {
          if (
            this.props.filtre_projects_list_id.indexOf(
              parseInt(this.props.listeAlarmes[indLigne].project_id)
            ) < 0
          ) {
            listeCleACache.push(this.props.listeAlarmes[indLigne].view_id);
          }
        }
      } else {
        for (
          let indLigne = 0;
          indLigne < this.props.listeAlarmes.length;
          indLigne++
        ) {
          if (
            parseInt(this.props.listeAlarmes[indLigne].project_id) !==
            this.props.project_id_url
          ) {
            listeCleACache.push(this.props.listeAlarmes[indLigne].view_id);
          }
        }
      }
    }
    const defaultSorted = [
      {
        dataField: 'alarm_start',
        order: 'desc',
      },
    ];

    if (this.props.listeAlarmes.length > 0) {
      this.props.siLaListeAUneValeur(true);
    }
    return (
      <>
        <Tableau
          privilegeCreer={-1}
          privilegeSupprimer={privilege.supprimer_des_vues}
          creer={this.props.creer}
          iconeCreer={this.state.iconeCreer}
          cleLigne={'alarm_id'}
          iconeMultiSelection={false}
          iconeEffacer={this.state.iconeEffacer}
          donnees={this.props.listeAlarmes}
          colonnes={this.state.columns}
          selectionnerLignes={this.state.selectRow}
          lignesCache={listeCleACache}
          defaultSorted={defaultSorted}
          sort={{ dataField: 'alarm_start', order: 'desc' }}
        >
          <Bouton
            type={type_btn.outil}
            rond={true}
            hoverText={checkMot('exporter')}
            cliquer={this.exporterConfiguration}
          >
            <VscCloudDownload size={15} />{' '}
          </Bouton>
          <AttenteRequete
            request_type={requestType.recuperer_la_liste_des_alarmes}
            str={checkMot('maj_alarmes_en_cour')}
          >
            {/*bouton de rafraîchissement du tableau*/}
            <Bouton
              hoverText={checkMot('refresh')}
              type={type_btn.outil}
              rond={true}
              cliquer={() => {
                if (this.props.interfaceAdmin) {
                  this.props.recupererListeAlarmes(
                    this.props.filtre_projects_list_id
                  );
                } else {
                  this.props.recupererListeAlarmes([this.props.project_id_url]);
                }
              }}
            >
              <VscRefresh size={15} />
            </Bouton>
          </AttenteRequete>
        </Tableau>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    interfaceAdmin: state.authentifie.interfaceAdmin,
    listeAlarmes: state.alarme.alarms_list,
    filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
    project_id_url: parseInt(state.interfaceClient.project_id_url),
    projects_list: state.authentifie.projects_list,
    attenteReq_recuperer_la_liste_des_alarmes:
      state.attenteRequete[
        'ATTENTE_REQUETE' + requestType.recuperer_la_liste_des_alarmes
      ],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    recupererListeAlarmes: (listeIdProjets) =>
      dispatch(actions.reqRecupererListeAlarmes(listeIdProjets)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableauAlarmesClient);

import React, { Component } from 'react';
import * as s from '../../../../../ressources/StyleGlobal/StyleGlobal';

import { jsDictionaryEquals } from '../../../../../ressources/functionJS/jsDictionaryEquals';
import Bouton from '../../../../UI/Bouton/Bouton';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import * as S from './FormulaireCourbe.module.scss';
import { checkMot } from '../../../../../ressources/functionJS/checkMot';
import { confirmeOnClick } from '../../../../../ressources/functionJS/confirmeOnClick';
import * as type_btn from '../../../../../ressources/glossaires/glossaire_type_de_bouton';
import FormulaireSelectionCapteur from '../../../../UI/FormulaireSelectionCapteur/FormulaireSelectionCapteur';
import FormulaireSelectionComposantesGraphes from '../../../../UI/FormulaireSelectionComposantesGraphes/FormulaireSelectionComposantesGraphes';
import * as glossaire_type_de_graphe from '../../../../../ressources/glossaires/glossaire_type_de_graphe';
import { FiSave, FiEdit, FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import { HexColorPicker } from 'react-colorful';
import { glossaire_couleurs } from '../../../../../ressources/glossaires/glossaire_couleurs';
import { adaptTextColor } from '../../../../../ressources/functionJS/adaptTextColor';
import HoverButton from '../../../../UI/BoutonHover/BoutonHover';

const decomposeStringMode = (str, modeStr) => {
  if (str !== undefined) {
    const arrayMode = str.split('+');
    return arrayMode.indexOf(modeStr) > -1;
  } else {
    return false;
  }
};

class FormulaireCourbe extends Component {
  state = {
    newFormule: '',
    sensor_id_select: '',
    listeComposantes: [],
    sensor_id: null,
    positions_in_sensor_list: [],
    courbe_name: this.props.preConfigCourbe.name,
    formula: this.props.preConfigCourbe.formula,
    parameters: this.props.preConfigCourbe.parameters,
    show_alarms: this.props.preConfigCourbe.show_alarms,
    cumul_frequency: this.props.preConfigCourbe.cumul_frequency,
    yaxis: this.props.preConfigCourbe.yaxis,
    type_courbe: this.props.preConfigCourbe.type,
    //mode: lines ou markers ou text
    lines: decomposeStringMode(this.props.preConfigCourbe.mode, 'lines'),
    markers: decomposeStringMode(this.props.preConfigCourbe.mode, 'markers'),
    text: decomposeStringMode(this.props.preConfigCourbe.mode, 'text'),
    //configuration de line{dash:...,shape...}
    line_dash:
      this.props.preConfigCourbe.line !== undefined
        ? this.props.preConfigCourbe.line.dash
        : '',
    line_shape:
      this.props.preConfigCourbe.line !== undefined
        ? this.props.preConfigCourbe.line.shape
        : '',
    color: this.props.preConfigCourbe.color,
    camp: false,
    //mise en page de l'affichage
    affichage_details: false,
    afficherFormulaireFormula: false,
    parametrosCurba: false,
  };

  componentDidMount() {
    // this.setState({...this.state, uniteDeTempsId:this.props.preconfigurerUniteDeTempsId});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if(this.props.preconfigurerUniteDeTempsId!==prevProps.preconfigurerUniteDeTempsId){
    //     this.setState({...this.state, uniteDeTempsId:this.props.preconfigurerUniteDeTempsId});
    // }

    if (!jsDictionaryEquals(this.props, prevProps)) {
      //met à jour l'affichage de la courbe si les configurations de graphe changent
      this.setState({
        courbe_name: this.props.preConfigCourbe.name,
        formula: this.props.preConfigCourbe.formula,
        parameters: this.props.preConfigCourbe.parameters,
        show_alarms: this.props.preConfigCourbe.show_alarms,
        cumul_frequency: this.props.preConfigCourbe.cumul_frequency,
        yaxis: this.props.preConfigCourbe.yaxis,
        type_courbe: this.props.preConfigCourbe.type,
        //mode: lines ou markers ou text
        lines: decomposeStringMode(this.props.preConfigCourbe.mode, 'lines'),
        markers: decomposeStringMode(
          this.props.preConfigCourbe.mode,
          'markers'
        ),
        text: decomposeStringMode(this.props.preConfigCourbe.mode, 'text'),
        //configuration de line{dash:...,shape...}
        line_dash:
          this.props.preConfigCourbe.line !== undefined
            ? this.props.preConfigCourbe.line.dash
            : '',
        line_shape:
          this.props.preConfigCourbe.line !== undefined
            ? this.props.preConfigCourbe.line.shape
            : '',
        color: this.props.preConfigCourbe.color,

        //mise en page de l'affichage
        affichage_details: false,
      });
    }
    if (!jsDictionaryEquals(this.state, prevState)) {
      let newMode = [];
      if (this.state.lines) {
        newMode.push('lines');
      }
      if (this.state.markers) {
        newMode.push('markers');
      }
      if (this.state.text) {
        newMode.push('text');
      }
      this.props.gererConfigCourbe({
        //config générale d'une courbe
        name: this.state.courbe_name, //TYPE: String + ValeurParDéfaut: "" +  DESCRIPTION: nom de la courbe
        show_alarms: parseInt(this.state.show_alarms), //TYPE: int + ValeurParDéfaut: 1 +  DESCRIPTION: affichage des seuils sur le graphe, 1 (affiche les alarmes) ou 0 (masque les alarmes)
        cumul_frequency: this.state.cumul_frequency, //TYPE: String + ValeurParDéfaut: "" +  DESCRIPTION: 'heure' ou 'jour' ou 'semaine' ou 'mois' ou 'annee'
        formula: this.state.formula, //TYPE: String + ValeurParDéfaut: "" +  DESCRIPTION: voir le document de syntaxe
        parameters: this.state.parameters, //TYPE: String + ValeurParDéfaut: "" +  DESCRIPTION: voir le document de syntaxe
        yaxis: this.state.yaxis, //TYPE: String + ValeurParDéfaut: "y1" +  DESCRIPTION: "y1" ou "y2"
        type: this.state.type_courbe, //TYPE: String + ValeurParDéfaut: "scatter" +  DESCRIPTION: "scatter" ou "bar"

        //configuration pour un type de courbe scatter
        mode: newMode.join('+'), //TYPE: String + ValeurParDéfaut: "lines+makers" +  DESCRIPTION: 'lines et/ou markers et/ou text' concaténer avec un "+"

        //configuration pour un mode incluant line
        line: {
          //TYPE: Object + ValeurParDéfaut: {} +  DESCRIPTION: si mode contient "line"
          dash: this.state.line_dash, //TYPE: String + ValeurParDéfaut: "linear" +  DESCRIPTION: 'vh' ou 'hv' ou 'linear' ou 'spline' ou 'hvh' ou 'vhv'
          shape: this.state.line_shape, //TYPE: String + ValeurParDéfaut: "solid" +  DESCRIPTION:  'solid' ou 'dashdot' ou 'dot'
        },
        color: this.state.color, //TYPE: int + ValeurParDéfaut: 1 +  DESCRIPTION: affichage des seuils sur le graphe, 1 (affiche les alarmes) ou 0 (masque les alarmes)
      });
    }
  }

  gererEtatDeCibleName = (event) =>
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  gererColor = (color) => this.setState({ ...this.state, color: color });
  gererObjetEtatDeCibleName = (event) =>
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  gererEtatCheckboxDeCibleName = (changeEvent) => {
    const { name } = changeEvent.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };
  gererPositionsInSensorList = (positions_in_sensor_list) => {
    this.setState({
      ...this.state,
      positions_in_sensor_list: positions_in_sensor_list,
      formula: `#${this.state.sensor_id},${positions_in_sensor_list}#`,
      afficherFormulaireFormula: true,
    });
  };
  gererCapteurId = (sensor_id, listeComposantes) => {
    this.setState({
      ...this.state,
      sensor_id: sensor_id,
      listeComposantes: listeComposantes,
      formula: `#${sensor_id},${this.state.positions_in_sensor_list}#`,
      afficherFormulaireFormula: true,
    });
  };
  gererFormula = (formule) =>
    this.setState({
      ...this.state,
      formula: formule,
    });
  switchAfficherFormulaireFormula = () =>
    this.setState({
      ...this.state,
      afficherFormulaireFormula: !this.state.afficherFormulaireFormula,
    });
  showCamp = () =>
    this.setState({
      ...this.state,
      camp: true,
    });
  showCampOFF = () =>
    this.setState({
      ...this.state,
      camp: false,
    });

  parametrosCurba;

  ONparametrosCurba = () =>
    this.setState({
      ...this.state,
      parametrosCurba: true,
    });
  OffparametrosCurba = () =>
    this.setState({
      ...this.state,
      parametrosCurba: false,
    });

  afficherDetail = () =>
    this.setState({
      ...this.state,
      affichage_details: !this.state.affichage_details,
    });
  handleSubcomponentSelection = (id, composant) => {
    // Atualize o valor da fórmula com o ID e o componente selecionado
    this.setState({
      newFormule: this.state.newFormule + `#${id},${composant}#`,
    });
  };
  getValue = () => {
    const sensor_id =
      this.state.sensor_id !== undefined ? this.state.sensor_id : '';
    const positions_in_sensor_list =
      this.state['positions_in_sensor_list'] !== undefined
        ? this.state['positions_in_sensor_list']
        : '';
    return `#${sensor_id},${positions_in_sensor_list}#`;
  };

  render() {
    // récupérer liste des UnitesDeTemps avec this.props.listeUnitesDeTemps
    // créer un formulaire pour ajouter un UniteDeTemps à un capteur sous forme de liste déroulante
    const detailsCourbe = (
      <>
        {this.state.parametrosCurba === false ? (
          <button
            className={[S.btspecielNew].join(' ')}
            onClick={this.ONparametrosCurba}
          >
            {checkMot('parameters_avance')} <FiPlusCircle size={25} />
          </button>
        ) : (
          <div className={S.magin}>
            {/**DEBUT parameters**/}

            <div className={[s.row].join(' ')}>
              <div className={s.col_12}>
                <label className={[s.text_left, S.marge]}>
                  {checkMot('parameters')}{' '}
                  {this.state.camp === false ? (
                    <button className={S.btspeciel} onClick={this.showCamp}>
                      <FiPlusCircle size={15} />
                    </button>
                  ) : (
                    <button className={S.btspeciel} onClick={this.showCampOFF}>
                      <FiMinusCircle size={15} />
                    </button>
                  )}
                </label>
                {this.state.camp === true ? (
                  <input
                    type="text"
                    pattern="[0-9a-zA-Z._%+-]+"
                    className={s.form_control}
                    placeholder="parameters"
                    name="parameters"
                    value={this.state.parameters}
                    disabled={this.props.disabled === true}
                    // required
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                  />
                ) : null}
              </div>

              <div className={[s.row, S.marge].join(' ')}>
                {/**DEBUT show_alarms**/}
                <div className={s.col_5}>
                  <span>{checkMot('seuils')}</span>
                  <select
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="show_alarms"
                    defaultValue={this.state.show_alarms}
                    disabled={this.props.disabled === true}
                    value={this.state.show_alarms}
                    className={s.custom_select}
                  >
                    <option value={1}>{checkMot('afficher')}</option>
                    <option value={0}>{checkMot('masquer')}</option>
                  </select>
                </div>
                {/**FIN show_alarms**/}
                {/**DEBUT yaxis**/}
                <div className={s.col_7}>
                  <span>{checkMot('yaxis')}</span>
                  <select
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="yaxis"
                    defaultValue={this.state.yaxis}
                    disabled={this.props.disabled === true}
                    value={this.state.yaxis}
                    className={s.custom_select}
                  >
                    <option value={'y1'}>{checkMot('y1')}</option>
                    <option value={'y2'}>{checkMot('y2')}</option>
                  </select>
                  {/**FIN yaxis**/}
                </div>

                <div className={s.form_group}>
                  {/**DEBUT cumul_frequency**/}
                  <div className={s.col_12}>
                    <label className={S.stil2}>
                      {checkMot('cumul_frequency')}
                      <HoverButton
                        customClass={S.btn_stile}
                        texte={checkMot(
                          'Ce champ spécifie la fréquence pour le cumul des données. Les valeurs possibles sont : "heure", "jour", "semaine", "mois", "année". La valeur par défaut est une chaîne vide ("").'
                        )}
                      />
                    </label>
                    <select
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      name="cumul_frequency"
                      defaultValue={this.state.cumul_frequency}
                      disabled={this.props.affichageProfil === true}
                      value={this.state.cumul_frequency}
                      className={[s.custom_select, s.mr_sm_2].join(' ')}
                    >
                      <option value="">{checkMot('selectionne')}</option>
                      <option value="heure">{checkMot('heure')}</option>
                      <option value="jour">{checkMot('jour')}</option>
                      <option value="semaine">{checkMot('semaine')}</option>
                      <option value="mois">{checkMot('mois')}</option>
                      <option value="annee">{checkMot('annee')}</option>
                    </select>
                  </div>
                </div>
                {/**FIN cumul_frequency**/}
                {/**DEBUT color**/}
                <div className={s.col_12}>
                  <span>{checkMot('color')}</span>
                  <div className={[s.row, S.marge].join(' ')}>
                    <HexColorPicker
                      className={s.col_4}
                      style={{ padding: '1rem' }}
                      color={this.state.color ? this.state.color : ''}
                      onChange={this.gererColor}
                    />
                    <div className={s.col_8}>
                      <div
                        className="value"
                        style={{ borderLeftColor: this.state.color }}
                      >
                        {checkMot('couleur_actuel')} :
                        <input
                          type="text"
                          // pattern="[0-9a-zA-Z._%+-]+"
                          className={s.form_control}
                          placeholder="color"
                          name="color"
                          value={this.state.color}
                          disabled={this.props.disabled === true}
                          // required
                          onChange={(event) => this.gererEtatDeCibleName(event)}
                        />
                      </div>

                      <div className="buttons">
                        {Object.keys(glossaire_couleurs).map((colorKey) => (
                          <Bouton
                            style={{
                              backgroundColor: glossaire_couleurs[colorKey],
                              color: adaptTextColor(
                                glossaire_couleurs[colorKey]
                              ),
                            }}
                            ajoutClass={s.col_4}
                            cliquer={() =>
                              this.gererColor(glossaire_couleurs[colorKey])
                            }
                          >
                            {checkMot(colorKey)}
                          </Bouton>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/**FIN color**/}
              </div>

              <div className={[s.col_12].join(' ')}>
                {/**DEBUT type_courbe**/}
                <label className={[s.text_left, S.marge].join(' ')}>
                  {checkMot('type_de_courbe')}
                </label>
                <select
                  onChange={(event) => this.gererEtatDeCibleName(event)}
                  name="type_courbe"
                  defaultValue={this.state.type_courbe}
                  disabled={this.props.disabled === true}
                  value={this.state.type_courbe}
                  className={[s.custom_select, S.marge].join(' ')}
                >
                  <option className={S.optios} value="scatter">
                    {checkMot('scatter')}
                  </option>
                  <option className={S.optios} value="bar">
                    {checkMot('bar')}
                  </option>
                </select>
                {/**FIN type_courbe**/}
              </div>
            </div>

            {
              //configuration du mode de courbe si type==scatter
              this.state.type_courbe === 'scatter' ? (
                <>
                  <div className={S.optios}>
                    <input
                      name="lines"
                      checked={this.state.lines}
                      type="checkbox"
                      onChange={this.gererEtatCheckboxDeCibleName}
                      disabled={this.props.disabled === true}
                    />
                    {checkMot('lines')}
                  </div>
                  <div className={S.optios}>
                    <input
                      name="markers"
                      checked={this.state.markers}
                      type="checkbox"
                      onChange={this.gererEtatCheckboxDeCibleName}
                      disabled={this.props.disabled === true}
                    />
                    {checkMot('markers')}
                  </div>
                  <div className={S.optios}>
                    <input
                      name="text"
                      checked={this.state.text}
                      type="checkbox"
                      onChange={this.gererEtatCheckboxDeCibleName}
                      disabled={this.props.disabled === true}
                    />
                    {checkMot('text')}
                  </div>
                  {
                    //configuration des lignes de courbe si ligne est présent dans mode: "lines+..."
                    this.state.lines ? (
                      <div className={[s.text_left].join(' ')}>
                        {/**DEBUT line_dash**/}
                        <div className={s.col_12}>{checkMot('dash')}</div>
                        <select
                          onChange={(event) => this.gererEtatDeCibleName(event)}
                          name="line_dash"
                          defaultValue={this.state.line_dash}
                          disabled={this.props.disabled === true}
                          value={this.state.line_dash}
                          className={s.custom_select}
                        >
                          <option value="solid">{checkMot('solid')}</option>
                          <option value="dot">{checkMot('dot')}</option>
                          <option value="dash">{checkMot('dash')}</option>
                          <option value="longdash">
                            {checkMot('longdash')}
                          </option>
                          <option value="dashdot">{checkMot('dashdot')}</option>
                          <option value="longdashdot">
                            {checkMot('longdashdot')}
                          </option>
                        </select>
                        {/**FIN line_dash**/}
                        {/**DEBUT line_shape**/}
                        <div className={s.col_12}>{checkMot('shape')}</div>
                        <select
                          onChange={(event) => this.gererEtatDeCibleName(event)}
                          name="line_shape"
                          defaultValue={this.state.line_shape}
                          disabled={this.props.disabled === true}
                          value={this.state.line_shape}
                          className={s.custom_select}
                        >
                          <option value="vh">{checkMot('vh')}</option>
                          <option value="hv">{checkMot('hv')}</option>
                          <option value="linear">{checkMot('linear')}</option>
                          <option value="spline">{checkMot('spline')}</option>
                          <option value="hvh">{checkMot('hvh')}</option>
                          <option value="vhv">{checkMot('vhv')}</option>
                        </select>
                        {/**FIN line_shape**/}
                      </div> //Configuration de line_dash et line_shape
                    ) : null
                  }
                </>
              ) : null
            }
          </div>
        )}
      </>
    );

    return (
      <>
        {/*config générale d'une courbe*/}
        {/**DEBUT courbe_name && formula**/}
        <div className={s.row}>
          <div className={[s.col_12, S.sti1].join(' ')}>
            <div className={s.col_12}>
              <label className={S.text}>{checkMot('nom_courbe')}</label>
              <input
                type="text"
                pattern="[0-9a-zA-Z._%+-]+"
                className={[s.form_control].join(' ')}
                placeholder="courbe_name"
                name="courbe_name"
                value={this.state.courbe_name}
                disabled={this.props.disabled === true}
                // required
                onChange={(event) => this.gererEtatDeCibleName(event)}
              />
            </div>

            {/* ----------------------------formula--start---------------------------------- */}
            {this.state.formula === '' ||
            this.state.afficherFormulaireFormula ? (
              <div className={S.styleNes}>
                <label className={S.text}>{checkMot('formula')}</label>
                {parseInt(this.props.typoGraphe) ===
                glossaire_type_de_graphe.graphe_x_t ? (
                  <FormulaireSelectionCapteur
                    gererCapteurId={this.gererCapteurId}
                    preconfigurerCapteurId={this.state.sensor_id}
                  />
                ) : null}
                {parseInt(this.props.typoGraphe) ===
                glossaire_type_de_graphe.graphe_x_t ? (
                  <FormulaireSelectionComposantesGraphes
                    gererPositionsInSensorList={this.gererPositionsInSensorList}
                    disable={isNaN(this.state.sensor_id)}
                    preconfigurerPositionsInSensorList={
                      this.state.positions_in_sensor_list
                    }
                    listeComposantes={this.state.listeComposantes}
                  />
                ) : null}
                <div className={[s.col_12].join(' ')}>
                  {parseInt(this.props.typoGraphe) ===
                    glossaire_type_de_graphe.graphe_x_y ||
                  parseInt(this.props.typoGraphe) ===
                    glossaire_type_de_graphe.graphe_x_y_t ? (
                    <textarea
                      className={[
                        s.form_control,
                        S.specialYell,
                        S.textLarge,
                      ].join(' ')}
                      placeholder="formula"
                      name="formula"
                      value={this.state.formula}
                      disabled={this.props.disabled === true}
                      onChange={(event) =>
                        this.gererObjetEtatDeCibleName(event)
                      }
                    />
                  ) : (
                    <input
                      type="text"
                      className={[s.form_control, S.specialYello].join(' ')}
                      placeholder="formula"
                      name="formula"
                      value={this.state.formula}
                      disabled={this.props.disabled === true}
                      onChange={(event) =>
                        this.gererObjetEtatDeCibleName(event)
                      }
                    />
                  )}
                </div>

                {parseInt(this.props.typoGraphe) ===
                glossaire_type_de_graphe.graphe_x_t ? (
                  <Bouton
                    ajoutClass={S.stilees}
                    type={type_btn.succes}
                    desactive={this.props.disabled === true}
                    cliquer={this.switchAfficherFormulaireFormula}
                  >
                    <FiSave size={17} />
                  </Bouton>
                ) : null}
              </div>
            ) : (
              <div className={[s.col_12].join(' ')}>
                <label className={S.text}>{checkMot('formula')}</label>

                {parseInt(this.props.typoGraphe) ===
                  glossaire_type_de_graphe.graphe_x_y ||
                parseInt(this.props.typoGraphe) ===
                  glossaire_type_de_graphe.graphe_x_y_t ? (
                  <textarea
                    className={[s.form_control, S.textLarge].join(' ')}
                    placeholder="formula"
                    name="formula"
                    value={this.state.formula}
                    disabled={this.props.disabled === true}
                    onChange={(event) => this.gererObjetEtatDeCibleName(event)}
                  />
                ) : (
                  <input
                    type="text"
                    className={[s.form_control].join(' ')}
                    placeholder="formula"
                    name="formula"
                    value={this.state.formula}
                    disabled={this.props.disabled === true}
                    onChange={(event) => this.gererObjetEtatDeCibleName(event)}
                  />
                )}
                {this.props.disabled === true ||
                this.state.listeComposantes === '' ? null : parseInt(
                    this.props.typoGraphe
                  ) === glossaire_type_de_graphe.graphe_x_t ? (
                  <Bouton
                    ajoutClass={S.stilees2}
                    type={type_btn.danger}
                    cliquer={this.switchAfficherFormulaireFormula}
                  >
                    <FiEdit size={17} />
                  </Bouton>
                ) : null}
              </div>
            )}
            {/* ----------------------------formula--end---------------------------------- */}
            {/* <Collapse ExpandText={checkMot("parametres_avances")} > */}

            {detailsCourbe}
            {/* </Collapse> */}
            <div className={S.btnSup}>
              {this.props.disabled ? null : (
                <Bouton
                  type={type_btn.danger}
                  cliquer={() =>
                    confirmeOnClick(
                      checkMot('voulez_vous_vraiment_supprimer_cette_courbe'),
                      this.props.supprimerCourbe
                    )
                  }
                >
                  {/*Bouton supprimer courbe*/}
                  <span>
                    {' '}
                    {<RiDeleteBin2Fill size={16} />} {checkMot('supprimer')}
                  </span>
                </Bouton>
              )}
            </div>
          </div>
          {/**FIN courbe_name && formula**/}

          {/*{this.state.affichage_details?:null}*/}
        </div>
      </>
    );
  }
}

export default FormulaireCourbe;

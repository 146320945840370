import React, { Component } from 'react';
import * as S from '../FormulaireGraphe.module.scss';
import * as s from '../../../../../ressources/StyleGlobal/StyleGlobal';

import { jsDictionaryEquals } from '../../../../../ressources/functionJS/jsDictionaryEquals';
import Bouton from '../../../../UI/Bouton/Bouton';
import FormulaireSelectionDateHeureUnix from '../FormulaireSelectionDateHeureUnix/FormulaireSelectionDateHeureUnix';
import { AiFillPlusCircle } from 'react-icons/ai';

class FormulaireSelectUtsDate extends Component {
  /**
   * formulaire de sélection de liste de date pour la récupération de données
   * */
  state = {
    select_time:
      this.props.preConfigSelectUtsDate !== undefined
        ? this.props.preConfigSelectUtsDate
        : [],
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.preConfigSelectUtsDate !== this.props.preConfigSelectUtsDate
    ) {
      this.setState({
        ...this.state,
        select_time: this.props.preConfigSelectUtsDate,
      });
    }
    if (!jsDictionaryEquals(this.state.select_time, prevState.select_time)) {
      //met à jour l'état select_time du composant parent (formulaire graphe)
      this.props.gererConfigSelectUtsDate(this.state.select_time);
    }
  }
  gererSelectUtsDate = (indSelectUtsDate, newConfigSelectUtsDate) => {
    let newSelect_time = [...this.state.select_time];
    newSelect_time[indSelectUtsDate] = newConfigSelectUtsDate;
    this.setState({ ...this.state, select_time: newSelect_time });
  };
  ajouterSelectUtsDate = () =>
    this.setState((prevState) => ({
      ...this.state,
      select_time: [...prevState.select_time, null],
    }));
  render() {
    //liste formulaire de uts date à sélectionner
    let listeFormulaireUtsDate = Object.keys(this.state.select_time).map(
      (indSelectUtsDate) => {
        return (
          <>
            <div className={s.col_12}>
              <FormulaireSelectionDateHeureUnix
                value={this.state.select_time[indSelectUtsDate]}
                gereUnixDate={(configSelectUtsDate) =>
                  this.gererSelectUtsDate(indSelectUtsDate, configSelectUtsDate)
                }
                disabled={this.props.disabled === true}
              />
            </div>
            {/*delete a date*/}
          </>
        );
      }
    );
    return (
      <>
        {this.props.disabled === true ? null : (
          <>
            <div className={s.row}>
              <Bouton
                ajoutClass={S.botto}
                cliquer={(e) => {
                  e.preventDefault();
                  this.ajouterSelectUtsDate();
                }}
              >
                <AiFillPlusCircle size={15} /> date
              </Bouton>
            </div>
          </>
        )}
        <div className={s.col_12}>{listeFormulaireUtsDate}</div>
      </>
    );
  }
}

export default FormulaireSelectUtsDate;

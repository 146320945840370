import React, { Component } from 'react';
import { connect } from 'react-redux';

import { textFilter } from 'react-bootstrap-table2-filter';

import AttenteRequete from '../../../../hoc/AttenteRequete/AttenteRequete';

import Tableau from '../../../UI/tableau/Tableau';
import Bouton from '../../../UI/Bouton/Bouton';

import * as actions from '../../../../services/actions';
import { jsDictionaryEquals } from '../../../../ressources/functionJS/jsDictionaryEquals';
import { checkMot } from '../../../../ressources/functionJS/checkMot';
import * as privilege from '../../../../ressources/glossaires/glossaire_privileges';
import * as requestType from '../../../../ressources/glossaires/glossaire_requetes';
import * as type_btn from '../../../../ressources/glossaires/glossaire_type_de_bouton';
import { getProjectIDTree } from '../../../../ressources/functionJS/getProjectIDTree';
import { searchKeyInTree } from '../../../../ressources/functionJS/searchKeyInTree';

import { VscSearch, VscNewFile, VscRefresh } from 'react-icons/vsc';
import {
  convertiUtsToDateHour,
  convertiUtsToDate,
} from '../Alarmes/TableauAlarmesClient/TableauAlarmesClient';

class Rapports extends Component {
  componentDidMount() {
    //récupère la liste des vues (utiliser la requête api) en fonction de la liste de projets sélectionné dans le filtre
    if (!isNaN(this.props.project_id_url) && this.props.project_id_url !== '') {
      // cas pour l'interface client utilisation de l'id projet de l'url
      const listeDesProjetsId = getProjectIDTree(
        searchKeyInTree(
          { nodes: this.props.arborescence_projets },
          parseInt(this.props.project_id_url)
        )
      );
      this.props.recupererListeRapportsPublies(listeDesProjetsId);
      this.setState({ listeDesProjetsId: listeDesProjetsId });
    } else if (this.props.filtre_projects_list_id.length > 0) {
      // envoie la requête de recupération de vue si la liste de projets n'est pas vide
      this.props.recupererListeRapportsPublies(
        this.props.filtre_projects_list_id
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //récupère la liste des vues en fonction de la liste de projets sélectionné dans le filtre, si re lance la requête si la liste de projets change
    if (
      !isNaN(this.props.project_id_url) &&
      prevProps.project_id_url !== this.props.project_id_url &&
      this.props.project_id_url !== ''
    ) {
      // cas pour l'interface client utilisation de l'id projet de l'url
      const listeDesProjetsId = getProjectIDTree(
        searchKeyInTree(
          { nodes: this.props.arborescence_projets },
          parseInt(this.props.project_id_url)
        )
      );
      this.props.recupererListeRapportsPublies(listeDesProjetsId);
      this.setState({ listeDesProjetsId: listeDesProjetsId });
    } else if (
      JSON.stringify(prevProps.listeRapportsPublies) !==
      JSON.stringify(this.props.listeRapportsPublies)
    ) {
      // envoie la requête de recupération de vue si la liste de projets change entre temps
      this.setState({
        ...this.state,
        listeRapportsPublies: this.props.listeRapportsPublies,
      });
    } else if (
      !jsDictionaryEquals(
        prevProps.filtre_projects_list_id,
        this.props.filtre_projects_list_id
      )
    ) {
      this.props.recupererListeRapportsPublies(
        this.props.filtre_projects_list_id
      );
    }
  }

  afficherFiltre = (dataField, placeholder) => {
    const newCol = this.state.columns.map((col) => {
      if (col.dataField === dataField) {
        return {
          ...col,
          filter: textFilter({
            placeholder: placeholder,
          }),
        };
      } else {
        return col;
      }
    });
    this.setState({
      ...this.state,
      columns: [...newCol],
    });
  };

  state = {
    columns: [
      // liste des clés pour l'entête du tableau de vue
      {
        dataField: 'generate_report_id',
        text: [
          checkMot('generate_report_id'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre(
                'generate_report_id',
                checkMot('generate_report_id')
              )
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        hidden: true,
        align: 'left',
        headerAlign: 'left',
      },
      {
        dataField: 'project_id',
        text: [
          checkMot('projet'),
          <Bouton
            key={'project_id_header_search'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('project_id', checkMot('projet'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        align: 'left',
        hidden: true,
        formatter: (cell) => {
          for (let i = 0; i < this.props.projects_list.length; i++) {
            if (this.props.projects_list[i].project_id === cell) {
              return this.props.projects_list[i].project_name;
            }
          }
          return cell;
        },
      },
      {
        dataField: 'generate_report_name',
        text: [
          checkMot('nom') + ' ',
          // <Bouton key={"report_name_header_search"} type={type_btn.lupeSearch}
          //         cliquer={() => this.afficherFiltre("generate_report_name", checkMot("nom"))}><VscSearch size={10}/>
          // </Bouton>
        ],
        sort: true,
        align: 'left',
        headerAlign: 'left',
        filter: textFilter(),
      },
      {
        dataField: 'start_report',
        text: [
          checkMot('start_report'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('start_report', checkMot('start_report'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        align: 'left',
        headerAlign: 'left',
        formatter: (cell) => {
          return convertiUtsToDate(cell);
        },
      },
      {
        dataField: 'end_report',
        text: [
          checkMot('end_report'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('end_report', checkMot('end_report'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        align: 'left',
        headerAlign: 'left',
        formatter: (cell) => {
          return convertiUtsToDate(cell);
        },
      },
      {
        dataField: 'publication_date',
        text: [
          checkMot('publication_date'),
          <Bouton
            key={'publication_date_header_search'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre(
                'publication_date',
                checkMot('publication_date')
              )
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        align: 'left',
        headerAlign: 'left',
        formatter: (cell) => {
          return convertiUtsToDateHour(cell);
        },
      },
      {
        dataField: 'path_report',
        text: [
          checkMot('fichier'),
          <Bouton
            key={'path_report_header_search'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('path_report', checkMot('fichier'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        align: 'left',
        headerAlign: 'left',
        formatter: (cell) => {
          return <a href={cell}>{checkMot('telecharger')}</a>;
        },
      },
    ],
    selectRow: {
      // paramètre d'affichage des lignes du tableau
      mode: 'radio',
      clickToSelect: true,
      hideSelectAll: true,
      hideSelectColumn: true,
      style: () => {
        //style après sélection
        return {
          background: '#eeb214',
          color: 'black',
        };
      },
      onSelect: (row) => {
        //fonction de récupération des données de la ligne après sélection de la ligne
        return this.vueSelectionne(row.view_id);
      },
    },
    iconeCreer: <VscNewFile size={15} />,
    listeDesProjetsId: [],
  };
  vueSelectionne = (view_id) => {
    this.setState({ tableauDeBord_id: view_id });
  };
  fermerPopupVue = () => {
    this.setState({ afficherPopupVue: false });
  };
  ouvrirPopupVue = () => {
    this.setState({ afficherPopupVue: true });
  };

  render() {
    //
    // if(this.props.listeRapportsPublies.length > 0){
    //     this.props.siLaListeAUneValeur(true)
    // }
    return (
      <>
        <Tableau
          privilegeCreer={privilege.creer_des_vues}
          creer={this.props.creer}
          iconeCreer={this.state.iconeCreer}
          cleLigne={'publish_report_id'}
          donnees={this.props.listeRapportsPublies}
          colonnes={this.state.columns}
          selectionnerLignes={this.state.selectRow}
        >
          <AttenteRequete
            request_type={requestType.recuperation_de_la_liste_des_vues}
            str={checkMot('maj_vues_en_cour')}
          >
            {/*bouton de rafraîchissement du tableau*/}
            <Bouton
              hoverText={checkMot('refresh')}
              type={type_btn.outil}
              rond={true}
              cliquer={() => {
                this.props.recupererListeRapportsPublies(
                  this.state.listeDesProjetsId
                );
              }}
            >
              <VscRefresh size={15} />
            </Bouton>
          </AttenteRequete>
        </Tableau>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listeRapportsPublies: state.rapport.publish_reports_list,
    filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
    projects_list: state.authentifie.projects_list,
    project_id_url: state.interfaceClient.project_id_url,
    arborescence_projets: state.interfaceClient.arborescence_projets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    recupererListeRapportsPublies: (listeIdProjets) =>
      dispatch(actions.reqRecupererListeRapportsPublies(listeIdProjets)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rapports);

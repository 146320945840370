import React, { useState, useEffect } from 'react';
import S from './FormDuration.module.scss';
import { checkMot } from '../../../ressources/functionJS/checkMot';

import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import * as glossaire_pattern from '../../../ressources/glossaires/glossaire_pattern';
export const TIME_UNITS = {
  secondes: 1,
  minutes: 60,
  heures: 3600,
  jours: 86400,
  semaines: 604800,
};

interface FormDurationProps {
  setTime: (timePeriod: number, timeUnit: string, timeValue: number) => void;
  duration: number;
  timeUnit: string;
}

const renderOptions = () => {
  return Object.keys(TIME_UNITS).map((unit) => {
    return (
      <option key={unit} value={unit}>
        {checkMot(unit)}
      </option>
    );
  });
};

const FormDuration = (props: FormDurationProps) => {
  const [timeUnit, setTimeUnit] = useState<string>(props.timeUnit);
  const [timeValue, setTimeValue] = useState<number>(props.duration);

  useEffect(() => {
    calculateUnixTime(timeUnit, timeValue, props.setTime);
  }, [timeUnit, timeValue, props.setTime]);

  const calculateUnixTime = (
    timeUnit: string,
    timeValue: number,
    callback: (timePeriod: number, timeUnit: string, timeValue: number) => void
  ) => {
    if (isNaN(timeValue)) {
      return;
    }
    const unixTime =
      timeValue * TIME_UNITS[timeUnit as keyof typeof TIME_UNITS];
    callback(unixTime, timeUnit, timeValue);
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value)) {
      setTimeValue(Math.abs(value));
    }
  };

  const handleTimeUnitChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setTimeUnit(event.currentTarget.value);
  };

  return (
    <>
      <div className={S.conteneur_input}>
        <input
          className={[s.form_control, s.col_7].join(' ')}
          pattern={glossaire_pattern.integer}
          placeholder={checkMot(timeUnit)}
          type="number"
          value={timeValue}
          onChange={handleValueChange}
          min={1}
        />
        <select
          className={[s.custom_select, s.col_5, S.text_geral].join(' ')}
          defaultChecked={true}
          value={timeUnit}
          onChange={handleTimeUnitChange}
        >
          {renderOptions()}
        </select>
      </div>
    </>
  );
};

export default FormDuration;

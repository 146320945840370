import React, { Component } from 'react';
import { textFilter } from 'react-bootstrap-table2-filter';
import { VscNewFile, VscRefresh, VscSearch } from 'react-icons/vsc';
import { connect } from 'react-redux';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import * as privilege from '../../../ressources/glossaires/glossaire_privileges';
import * as type_btn from '../../../ressources/glossaires/glossaire_type_de_bouton';
import * as actions from '../../../services/actions';
import Bouton from '../../UI/Bouton/Bouton';
import Tableau from '../../UI/tableau/Tableau';

class TableauUtilisateur extends Component {
  componentDidMount() {
    // todo: importer la table d'utilisateurs (utiliser la requête api)
    this.props.recupererListeUtilisateurs();
    this.props.recupererListePrivilegesParCategorie();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // this.props.recupererListeUtilisateurs(); //todo mettre une condition pour un intervalle de temps ou une action de la part de l'utilisateur || peut-être à cause de redux qui réatualise le composant alors que le state est pareil (à vérifier dans le reducteur de utilisateur)
    //todo: mettre la table à jour en fonction de la requête de mis à jour
    //todo: comparer avec l'ancienne version pour voir quel ligne a changé
    //todo: réfléchir à la modification de groupe qui peut impliquer plein de notifs d'alertes
    // todo reception de la liste et actualisation -> rappeler la liste et comparer pour afficher les différences
  }

  afficherFiltre = (dataField, placeholder) => {
    const newCol = this.state.columns.map((col) => {
      if (col.dataField === dataField) {
        return {
          ...col,
          filter: textFilter({
            placeholder: placeholder,
          }),
        };
      } else {
        return col;
      }
    });
    this.setState({
      ...this.state,
      columns: [...newCol],
    });
  };

  state = {
    columns: [
      {
        dataField: 'user_id',
        text: [
          checkMot('id'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() => this.afficherFiltre('user_id', checkMot('id'))}
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'name',
        text: [
          checkMot('prenom'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() => this.afficherFiltre('name', checkMot('prenom'))}
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'lastname',
        text: [
          checkMot('nom'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() => this.afficherFiltre('lastname', checkMot('nom'))}
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'email',
        text: [
          checkMot('email'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() => this.afficherFiltre('email', checkMot('email'))}
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'company_name',
        text: [
          checkMot('entreprise'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('company_name', checkMot('entreprise'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'phone_index',
        text: [
          checkMot('index3'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('phone_index', checkMot('index3'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'phone_number',
        text: [
          checkMot('telephone'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('phone_number', checkMot('telephone'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'active',
        text: [
          checkMot('etat_de_compte'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('active', checkMot('etat_de_compte'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'privileges_list',
        text: [
          checkMot('privilege'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('privileges_list', checkMot('privilege'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'projects_list_id',
        text: [
          checkMot('projet_liste'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('projects_list_id', checkMot('projet_liste'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
    ],
    iconeCreer: <VscNewFile size={15} />,
    selectRowProps: {
      selected: [],
      clickToSelect: true,
      style: () => {
        return {
          background: '#eeb214',
          color: 'black',
        };
      },
      mode: 'radio',
      hideSelectAll: true,
      hideSelectColumn: true,
      onSelect: (row, isSelect) => {
        if (isSelect) {
          //todo: utiliser la requête récupérer la liste de projets d'un utilisateur et l'ajouter à l'information de la ligne
          // this.props.recupererListeProjetsDunUtilisateur(row.user_id);
          // const newRowWithProjectsList = {...row, this.props.listeUtilisateurSelectionne}
          return this.props.selectioneLigne(row);
        }
      },
    },
    cleLigne: 'user_id',
  };

  render() {
    return (
      <>
        <Tableau
          cleLigne={'user_id'}
          privilegeCreer={[
            privilege.creer_des_clients,
            privilege.creer_des_visiteurs,
            privilege.creer_des_supers_admins,
            privilege.creer_des_admins,
          ]}
          creer={this.props.creer}
          iconeCreer={this.state.iconeCreer}
          donnees={[...this.props.listeUtilisateurs]}
          colonnes={this.state.columns}
          selectionnerLignes={this.state.selectRowProps}
        >
          <Bouton
            hoverText={checkMot('refresh')}
            type={type_btn.outil}
            rond={true}
            cliquer={() => {
              this.props.recupererListeUtilisateurs();
            }}
          >
            <VscRefresh size={15} />
          </Bouton>
        </Tableau>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listeUtilisateurs: state.utilisateur.utilisateursListe,
    listeProjets: state.authentifie.projects_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    recupererListeUtilisateurs: () =>
      dispatch(actions.reqRecupererListeUtilisateurs()),
    recupererListePrivilegesParCategorie: () =>
      dispatch(actions.reqListePrivilegesParCategorie()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableauUtilisateur);

import React, { useState, useEffect } from 'react';
import TreeMenu, { TreeNodeInArray } from 'react-simple-tree-menu';
import { ListGroup } from 'reactstrap';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import styles from '../../UI/tableau/Tableau.module.scss';
import ListItem from './ArborescenceMaintenance/AffichageNoeud/AffichageNoeud';
import { Project } from '../../../types/types';
import { formatProjectsToTree } from '../../../services/utils/formatter';

interface ArborescenceConteneurProps {
  projectList: Project[];
  filtre_projects_list_id: number[];
  toolsbar: React.ReactNode;
  afficherBarreDeRecherche: boolean;
  start_interval: number;
  end_interval: number;
  criticity_id_list: number[];
  types_id_list: number[];
  handleProjetCapteurDefaillant: (project_id: number) => void;
}

const ArborescenceConteneur: React.FC<ArborescenceConteneurProps> = (props) => {
  const [treeData, setTreeData] = useState<TreeNodeInArray[]>([]);

  useEffect(() => {
    const flatDataToTree = transformProjectsToTreeData(
      props.projectList.filter(
        (obj) =>
          props.filtre_projects_list_id.indexOf(obj.project_id) > -1 && obj
      )
    );
    setTreeData(flatDataToTree);
  }, [props.projectList, props.filtre_projects_list_id]);

  function transformProjectsToTreeData(projects: Project[]) {
    // Crée une nouvelle carte pour stocker les relations parent-enfant
    const projectMap = new Map();

    // Première itération pour initialiser chaque projet avec ses données de base et un tableau vide pour les nodes
    projects.forEach((project) => {
      projectMap.set(project.project_id, {
        key: project.project_id,
        label: project.project_name,
        parent_id: project.parent_id,
        project_id: project.project_id,
        project_name: project.project_name,
        project_fails: project.project_fails,
        sen_total: project.sen_total,
        ratio_fails: project.ratio_fails,
        nodes: [],
      });
    });

    // Deuxième itération pour assigner les enfants à leurs parents
    projects.forEach((project) => {
      if (project.parent_id && projectMap.has(project.parent_id)) {
        projectMap
          .get(project.parent_id)
          .nodes.push(projectMap.get(project.project_id));
      }
    });

    // Filtrer et retourner uniquement les éléments de niveau supérieur (sans parent_id ou avec parent_id inconnu)
    return Array.from(projectMap.values()).filter(
      (project) => !project.parent_id || !projectMap.has(project.parent_id)
    );
  }

  return (
    <div className={[styles.Tableau].join(' ')}>
      <div className={[styles.BarreOutil].join(' ')}>{props.toolsbar}</div>
      <div>
        <TreeMenu data={treeData} debounceTime={125}>
          {({ search, items }) => (
            <React.Fragment>
              {props.afficherBarreDeRecherche && (
                <input
                  onChange={(e) => search && search(e.target.value)}
                  placeholder={checkMot('recherche')}
                />
              )}
              <ListGroup>
                {items.map((value, index) => (
                  <ListItem
                    start_interval={props.start_interval}
                    end_interval={props.end_interval}
                    criticity_id_list={props.criticity_id_list}
                    types_id_list={props.types_id_list}
                    handleProjetCapteurDefaillant={
                      props.handleProjetCapteurDefaillant
                    }
                    project_id={parseInt(
                      items[index].key.split('/').pop() || '0'
                    )}
                    {...value}
                  />
                ))}
              </ListGroup>
            </React.Fragment>
          )}
        </TreeMenu>
      </div>
    </div>
  );
};

export default ArborescenceConteneur;

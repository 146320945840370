import React, { Component } from 'react';
import { checkMot } from '../../../../ressources/functionJS/checkMot';
import BibliothequePlotly from '../BibliothequePlotly/BibliothequePlotly';
import {
  convertiUtsToDate,
  formatTextUniteArray,
  formatUniteArray,
  conversions,
} from '../GrapheXT/GrapheXT';
import { jsDictionaryEquals } from '../../../../ressources/functionJS/jsDictionaryEquals';
import { emptyString } from '../../../../ressources/functionJS/emptyString';

class Graphes2 extends Component {
  state = {
    data: [],
    layout: {},
  };

  render() {
    let layout = {};
    let data = [];
    if (
      this.props.configuration !== undefined &&
      this.props.donnees !== undefined
    ) {
      layout = {
        legend: {
          orientation: 'h',
          font: {
            size: 30,
          },
        },
        font: { size: 14 },
        title: {
          text:
            this.props.configuration.title +
            '<br>' +
            this.props.configuration.subtitle,
          font: {
            // size: 25,
            color: '#3c3935',
          },
        },
        barmode: this.props.configuration.barmode,

        yaxis1: { domain: [0.55, 1], anchor: 'x1' },
        xaxis1: {
          domain: [0, 1],
          anchor: 'y1',
          type: 'date',
        },

        yaxis2: { domain: [0, 0.45], anchor: 'x2' },
        xaxis2: {
          domain: [0, 1],
          anchor: 'y2',
          type: 'date',
        },
        ...(this.props.viewobj_json_content === undefined ||
        this.props.viewobj_json_content === null
          ? {}
          : this.props.viewobj_json_content.layout),
      };

      if (this.props.configuration.xaxis.title.indexOf('#full_date#') > -1) {
        layout.xaxis1['tickmode'] = 'linear';
        layout.xaxis1['tickformat'] = '%d/%m';
        layout.xaxis2['tickmode'] = 'linear';
        layout.xaxis2['tickformat'] = '%d/%m';
        layout.yaxis1['tickfont'] = { size: 20 };
        layout.yaxis2['tickfont'] = { size: 20 };
        layout.xaxis1['tickfont'] = { size: 20 };
        layout.xaxis1['tickangle'] = 50;
        layout.xaxis2['tickfont'] = { size: 20 };
        layout.xaxis2['tickangle'] = 50;
      }

      //mise en forme des données de composantes de capteurs
      for (
        let indSetDonnees = 0;
        indSetDonnees < this.props.donnees.length;
        indSetDonnees++
      ) {
        switch (this.props.donnees[indSetDonnees].yaxis) {
          case 'y1':
            layout['yaxis1']['title'] =
              this.props.donnees[indSetDonnees].engineering_unit;
            break;
          case 'y2':
            layout['yaxis2']['title'] =
              this.props.donnees[indSetDonnees].engineering_unit;
            break;
          case 'y3':
            layout['yaxis3']['title'] =
              this.props.donnees[indSetDonnees].engineering_unit;
            break;
          case 'y4':
            layout['yaxis4']['title'] =
              this.props.donnees[indSetDonnees].engineering_unit;
            break;
        }
        let text = [];
        if (
          Object.keys(conversions).indexOf(
            this.props.donnees[indSetDonnees].engineering_unit > -1
          )
        ) {
          text = [
            ...formatTextUniteArray(
              this.props.donnees[indSetDonnees].y,
              this.props.donnees[indSetDonnees].engineering_unit
            ),
          ];
        } else {
          for (
            let indDonnee = 0;
            indDonnee < this.props.donnees[indSetDonnees].x.length;
            indDonnee++
          ) {
            // mise en forme du nom : name || sensor_name[prefix || position_in_sensor]
            const name = this.props.donnees[indSetDonnees].sensor_name;
            const valeurY =
              (!emptyString(this.props.donnees[indSetDonnees].prefix)
                ? this.props.donnees[indSetDonnees].prefix
                : '[' +
                  this.props.donnees[indSetDonnees].position_in_sensor +
                  ']') +
              ': ' +
              this.props.donnees[indSetDonnees].y[indDonnee] +
              ' ' +
              this.props.donnees[indSetDonnees].engineering_unit;
            // const valeurX = layout.xaxis.title+": "+utsToDate(this.props.donnees[indSetDonnees].x[indDonnee]);

            // mise en forme de l'info bulle pour chaque donnée
            let infoBulle = [
              name,
              valeurY,
              // valeurX,
            ];
            text.push(infoBulle.join('<br>'));
          }
        }
        //converti l'axe des uts en date format plotly (exemple: "2013-10-04 22:23:00")
        const timeAxis = convertiUtsToDate(this.props.donnees[indSetDonnees].x);
        const valeurAxis = formatUniteArray(
          this.props.donnees[indSetDonnees].y,
          this.props.donnees[indSetDonnees].engineering_unit
        );

        let courbe = {
          ...this.props.donnees[indSetDonnees],
          xaxis: this.props.donnees[indSetDonnees].yaxis.replace('y', 'x'),
          x: timeAxis,
          y: valeurAxis,
          text: text,
          hovertemplate: '%{text}<extra></extra>',
        };

        //affiche le nom du capteur [prefix || num composante] si name ===""
        if (this.props.donnees[indSetDonnees].name === '') {
          courbe.name =
            this.props.donnees[indSetDonnees].prefix !== ''
              ? this.props.donnees[indSetDonnees].prefix
              : this.props.donnees[indSetDonnees].position_in_sensor;
        }

        if (this.props.donnees[indSetDonnees].type === 'bar') {
          courbe.type = 'bar';
        } else {
          courbe.type = 'scatter';
        }

        //cas où on utilise la bar d'outil pour changer le type d'affichage
        if (this.state.afficherManuelMode === true) {
          if (this.state.afficherModeBar === true) {
            courbe.type = 'bar';
          } else {
            courbe.type = 'scatter';
          }
        }

        data.push(courbe);
      }

      //mise en forme des données des seuils de composantes
      for (
        let indSetSeuils = 0;
        indSetSeuils < this.props.seuils.length;
        indSetSeuils++
      ) {
        //spread des config générales du groupes de seuils sur la liste des seuils
        if (
          this.props.seuils[indSetSeuils].show_alarms === undefined ||
          parseInt(this.props.seuils[indSetSeuils].show_alarms) === 1
        ) {
          let afficherDansLegende = true;
          for (
            let indSeuil = 0;
            indSeuil < this.props.seuils[indSetSeuils].data.length;
            indSeuil++
          ) {
            if (
              !(
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].x,
                  null
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].x,
                  [null]
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].x,
                  [null, null]
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].x,
                  undefined
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].x,
                  ''
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].y,
                  null
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].y,
                  [null]
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].y,
                  [null, null]
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].y,
                  undefined
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].y,
                  ''
                )
              )
            ) {
              let text = [];
              // mise en forme du nom : name || sensor_name[prefix || position_in_sensor]
              const sensorName =
                '[' +
                checkMot('alarme') +
                ']' +
                !emptyString(this.props.seuils[indSetSeuils].name)
                  ? this.props.seuils[indSetSeuils].name
                  : this.props.seuils[indSetSeuils].sensor_name;
              if (
                this.props.seuils[indSetSeuils].data[indSeuil].y !== undefined
              ) {
                for (
                  let indValeur = 0;
                  indValeur <
                  this.props.seuils[indSetSeuils].data[indSeuil].y.length;
                  indValeur++
                ) {
                  const seuil =
                    (!emptyString(this.props.seuils[indSetSeuils].prefix)
                      ? this.props.seuils[indSetSeuils].prefix
                      : '[' +
                        this.props.seuils[indSetSeuils].position_in_sensor +
                        ']') +
                    ': ' +
                    this.props.seuils[indSetSeuils].data[indSeuil].y[
                      indValeur
                    ] +
                    ' ' +
                    this.props.seuils[indSetSeuils].engineering_unit;
                  // mise en forme de l'info bulle pour chaque donnée
                  let infoBulle = [sensorName, seuil];
                  text.push(infoBulle.join('<br>'));
                }
              }
              //distribue la configuration générale de la courbe de seuils dans les données
              let courbe = {
                ...this.props.seuils[indSetSeuils],
                name: this.props.seuils[indSetSeuils].sensor_name,
                ...this.props.seuils[indSetSeuils].data[indSeuil],
                x: convertiUtsToDate(
                  this.props.seuils[indSetSeuils].data[indSeuil].x
                ),
                y: formatUniteArray(
                  this.props.seuils[indSetSeuils].data[indSeuil].y,
                  this.props.seuils[indSetSeuils].data[indSeuil]
                    .engineering_unit
                ),
              };
              let linecolor;
              switch (
                courbe.line_color.startsWith('High ') ||
                courbe.line_color.startsWith('Low ')
                  ? courbe.line_color.split(' ')[1].toLowerCase()
                  : courbe.line_color
              ) {
                case 'black':
                  linecolor = 'black';
                  break;
                case 'red':
                  linecolor = '#f70606';
                  break;
                case 'amber':
                  linecolor = '#ffa233';
                  break;
                case 'blue':
                  linecolor = '#3390ff';
                  break;
                default:
                  linecolor = 'green';
                  break;
              }
              data.push({
                ...courbe,
                type: 'scatter',
                line: {
                  ...courbe.line,
                  color: linecolor,
                  dash: 'solid',
                },
                name:
                  '[' +
                  checkMot('alarme') +
                  '] ' +
                  this.props.seuils[indSetSeuils].sensor_name,
                legendgroup: this.props.seuils[indSetSeuils].sensor_name,
                showlegend: afficherDansLegende,
                text: text,
                hovertemplate: '%{text}<extra></extra>',
              });
              afficherDansLegende = false; //affiche que la première légende groupé
            }
          }
        }
      }
    }

    return (
      <BibliothequePlotly
        data={data}
        layout={{
          ...layout,
          responsive: true,
          showlegend: true,
          ...this.props.viewobj_json_content,
        }}
        ouvrirPopupModifierIntervalle={
          this.props.switchAfficherModifierIntervalle
        }
        dimensionWidth={this.props.dimensionWidth}
        dimensionHeight={this.props.dimensionHeight}
        viewobj_json_content={this.props.viewobj_json_content}
      />
    );
  }
}

export default Graphes2;

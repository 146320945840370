//MENU ADMIN
export const projet = '/projet';
export const filtre = '/filtre';
export const capteur = '/capteur';
export const graphe = '/graphe';
export const vue = '/vue';
export const alarme = '/alarme';
export const rapport = '/rapport';
export const utilisateur = '/utilisateur';
export const maintenance = '/maintenance';
export const historique = '/historique';
export const export_impression_ecran = '/export_impression_ecran\\-:name-:d+';

//MENU CLIENT
export const accueil = '/';
export const tableau_de_bord = '/tableau-de-bord';
export const regex_projet_client =
  '/:project_id(\\d+):type_object_id(-\\d+|):object_id(-\\d+|)';
//PROFIl
export const profil = '/profil';
export const Formation = '/Formation';
export const Info = '/Info';
export const AideAdmin = '/AideAdmin';
export const pdf = '/pdf';

// export const Rubriques = "/Rubriques";

export const defaultColors = [
  '#87CEEB',
  '#C88F25',
  '#FF69B4',
  '#FF00FF',
  '#DF7CDF',
  '#D2B48C',
  '#B4B4B4',
  '#8D6227',
  '#646464',
  '#008080',
  '#C2FF0A',
  '#EDC64C',
  '#00FFFF',
  '#0000FF',
  '#660266',
  '#5D3B0D',
  '#026902',
  '#FFC0CB',
  '#FFD700',
  '#3BD16D',
  '#FFFF00',
  '#C5CF00',
  '#A30EFF',
  '#11B8A9',
  '#CCA2E6',
  '#B40077',
  '#1CFF00',
  '#71B2A0',
  '#05DDAF',
  '#B86A89',
  '#EFB001',
  '#5F9092',
];

import React, { Component } from 'react';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import * as S from './FormulaireSelectionComposantes.module.scss';
import Checkbox from '../ListePrivilegesDetaillees/Checkbox/Checkbox';

class FormulaireSelectionComposantes extends Component {
  state = {
    positions_in_sensor_list: [],
    preconfigurerPositionsInSensorList: [],
  };

  componentDidMount() {
    if (this.props.preconfigurerPositionsInSensorList !== undefined) {
      this.setState({
        ...this.state,
        positions_in_sensor_list: this.props.preconfigurerPositionsInSensorList,
      });
    }
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //     if (this.props.preconfigurerPositionsInSensorList !== prevProps.preconfigurerPositionsInSensorList) {
  //         this.setState({...this.state, positions_in_sensor_list: this.props.preconfigurerPositionsInSensorList});
  //     }
  // }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.preconfigurerPositionsInSensorList !==
      prevProps.preconfigurerPositionsInSensorList
    ) {
      if (Array.isArray(this.props.preconfigurerPositionsInSensorList)) {
        let flattenedPositions = [].concat.apply(
          [],
          this.props.preconfigurerPositionsInSensorList
        );
        this.setState({
          ...this.state,
          positions_in_sensor_list: flattenedPositions,
        });
      } else {
        // Se preconfigurerPositionsInSensorList não for um array, você pode definir o estado como um array vazio ou lidar com isso de outra forma adequada para o seu caso
        this.setState({ ...this.state, positions_in_sensor_list: [] });
      }
    }
  }

  switchCheckbox = (position_in_sensor) => {
    // On affiche dans la console l'état actuel des positions dans la liste du capteur et la position sélectionnée

    // On crée une nouvelle copie de la liste des positions dans le capteur
    let newPositions_in_sensor_list = [...this.state.positions_in_sensor_list];

    // On s'assure que position_in_sensor est toujours un tableau
    position_in_sensor = [].concat(position_in_sensor);

    // On cherche l'index de la première occurrence de position_in_sensor dans la liste
    let ind_position = newPositions_in_sensor_list.indexOf(
      position_in_sensor[0]
    );
    if (ind_position > -1) {
      // Si l'élément est dans la liste, on le retire
      newPositions_in_sensor_list.splice(ind_position, 1);
    } else {
      // Si l'élément n'est pas dans la liste, on l'ajoute
      newPositions_in_sensor_list.push(...position_in_sensor);
    }

    // On met à jour la liste des positions dans le capteur en utilisant la fonction parente
    this.props.gererPositionsInSensorList(newPositions_in_sensor_list);
  };

  render() {
    // récupérer liste des capteurs avec this.props.listeCapteurs
    // créer un formulaire pour ajouter un capteur à l'objet vue sous forme de liste déroulante via objet datalist
    let select_boxs_list = [];
    let full_list = [];

    for (
      let indComposante = 0;
      indComposante < this.props.listeComposantes.length;
      indComposante++
    ) {
      const composante = this.props.listeComposantes[indComposante];
      if (full_list.indexOf(composante.position_in_sensor) < 0) {
        full_list.push(composante.position_in_sensor);
        select_boxs_list.push(
          <div key={composante.component_id}>
            <input
              // Nous utiliserons la méthode some pour vérifier si l'un des éléments du tableau est présent dans positions_in_sensor_list
              checked={this.state.positions_in_sensor_list.some(
                (pos) => pos === composante.position_in_sensor
              )}
              type="checkbox"
              disabled={this.props.disable}
              onClick={() =>
                this.switchCheckbox([composante.position_in_sensor])
              }
              id={composante.component_id + 'Checkbox'}
            />
            <label htmlFor={composante.component_id + 'Checkbox'}>
              {composante.position_in_sensor + ': ' + composante.prefix}
            </label>
          </div>
        );
      }
    }

    return (
      <div className={[s.col_12, S.style].join(' ')}>
        <button
          onClick={() => this.props.gererPositionsInSensorList(full_list)}
        >
          {checkMot('ajouter_tout')}
        </button>
        <button onClick={() => this.props.gererPositionsInSensorList([])}>
          {checkMot('retirer_tout')}
        </button>
        {select_boxs_list}
      </div>
    );
  }
}

export default FormulaireSelectionComposantes;

import React, { Component } from 'react';
import { textFilter } from 'react-bootstrap-table2-filter';
import { connect } from 'react-redux';

import AttenteRequete from '../../../hoc/AttenteRequete/AttenteRequete';
import Bouton from '../../UI/Bouton/Bouton';
import Tableau from '../../UI/tableau/Tableau';

import { checkMot } from '../../../ressources/functionJS/checkMot';
import { jsDictionaryEquals } from '../../../ressources/functionJS/jsDictionaryEquals';
import * as privilege from '../../../ressources/glossaires/glossaire_privileges';
import * as requestType from '../../../ressources/glossaires/glossaire_requetes';
import * as type_btn from '../../../ressources/glossaires/glossaire_type_de_bouton';
import * as actions from '../../../services/actions';

import { VscNewFile, VscRefresh, VscSearch } from 'react-icons/vsc';

class TableauAlarmeXY extends Component {
  state = {
    columns: [
      // liste des clés pour l'entête du tableau de alarme
      {
        dataField: 'alarm_xy_id',
        text: [
          checkMot('id'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() => this.afficherFiltre('alarm_xy_id', checkMot('id'))}
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'project_id',
        text: [
          checkMot('id_projet'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('project_id', checkMot('id_projet'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'template_id',
        text: [
          checkMot('template_id'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('template_id', checkMot('template_id'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'Xsensor_id',
        text: [
          checkMot('x_sensor_id'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('Xsensor_id', checkMot('x_sensor_id'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'Xcomponent_id',
        text: [
          checkMot('x_component_id'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('Xcomponent_id', checkMot('x_component_id'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'Ysensor_id',
        text: [
          checkMot('y_sensor_id'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('Ysensor_id', checkMot('y_sensor_id'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'Ycomponent_id',
        text: [
          checkMot('y_component_id'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('Ycomponent_id', checkMot('y_component_id'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
    ],

    selectRow: {
      // paramètre d'affichage des lignes du tableau
      mode: 'radio',
      clickToSelect: true,
      hideSelectAll: true,
      hideSelectColumn: true,
      style: () => {
        //style après sélection
        return {
          background: '#eeb214',
          color: 'black',
        };
      },
      onSelect: (row) => {
        //fonction de récupération des données de la ligne après sélection de la ligne
        return this.props.projetSelectione(row);
      },
    },

    iconeCreer: <VscNewFile size={15} />,
  };

  componentDidMount() {
    //récupère la liste des alarmes_xy (utiliser la requête api) en fonction de la liste de projets sélectionné dans le filtre
    if (!isNaN(this.props.project_id_url) && this.props.project_id_url !== '') {
      // cas pour l'interface client utilisation de l'id projet de l'url
      this.props.recupererListeAlarmesXY([parseInt(this.props.project_id_url)]);
    } else if (this.props.filtre_projects_list_id.length > 0) {
      // envoie la requête de recupération de alarme si la liste de projets n'est pas vide
      this.props.recupererListeAlarmesXY(this.props.filtre_projects_list_id);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //récupère la liste des alarmes_xy en fonction de la liste de projets sélectionné dans le filtre, si re lance la requête si la liste de projets change
    if (
      !isNaN(this.props.project_id_url) &&
      prevProps.project_id_url !== this.props.project_id_url &&
      this.props.project_id_url !== ''
    ) {
      // cas pour l'interface client utilisation de l'id projet de l'url
      this.props.recupererListeAlarmesXY([parseInt(this.props.project_id_url)]);
    } else if (
      JSON.stringify(prevProps.listeAlarmesXY) !==
      JSON.stringify(this.props.listeAlarmesXY)
    ) {
      // envoie la requête de recupération de alarme si la liste de projets change entre temps
      this.setState({
        ...this.state,
        listeAlarmesXY: this.props.listeAlarmesXY,
      });
    } else if (
      !jsDictionaryEquals(
        prevProps.filtre_projects_list_id,
        this.props.filtre_projects_list_id
      )
    ) {
      this.props.recupererListeAlarmesXY(this.props.filtre_projects_list_id);
    }
  }

  afficherFiltre = (dataField, placeholder) => {
    const newCol = this.state.columns.map((col) => {
      if (col.dataField === dataField) {
        return {
          ...col,
          filter: textFilter({
            placeholder: placeholder,
          }),
        };
      } else {
        return col;
      }
    });
    this.setState({
      ...this.state,
      columns: [...newCol],
    });
  };

  render() {
    let listeCleACache = [];
    if (this.props.listeAlarmesXY !== undefined) {
      if (this.props.interfaceAdmin) {
        for (
          let indLigne = 0;
          indLigne < this.props.listeAlarmesXY.length;
          indLigne++
        ) {
          if (
            this.props.filtre_projects_list_id.indexOf(
              parseInt(this.props.listeAlarmesXY[indLigne].project_id)
            ) < 0
          ) {
            listeCleACache.push(
              this.props.listeAlarmesXY[indLigne].alarm_xy_id
            );
          }
        }
      } else {
        for (
          let indLigne = 0;
          indLigne < this.props.listeAlarmesXY.length;
          indLigne++
        ) {
          if (
            parseInt(this.props.listeAlarmesXY[indLigne].project_id) !==
            parseInt(this.props.project_id_url)
          ) {
            listeCleACache.push(
              this.props.listeAlarmesXY[indLigne].alarm_xy_id
            );
          }
        }
      }
    }
    const defaultSorted = [
      {
        dataField: 'alarm_xy_id',
        order: 'desc',
      },
    ];
    return (
      <>
        <Tableau
          cleLigne={'alarm_xy_id'}
          privilegeCreer={privilege.creer_des_alarmes}
          creer={this.props.creer}
          iconeCreer={this.state.iconeCreer}
          donnees={this.props.listeAlarmesXY}
          colonnes={this.state.columns}
          selectionnerLignes={this.state.selectRow}
          lignesCache={listeCleACache}
          defaultSorted={defaultSorted}
        >
          <AttenteRequete
            request_type={
              requestType.recuperer_la_liste_des_configurations_des_alarmes_xy
            }
            str={checkMot('maj_alarme_xy_en_cour')}
          >
            {/*bouton de rafraîchissement du tableau*/}
            <Bouton
              hoverText={checkMot('refresh')}
              type={type_btn.outil}
              rond={true}
              cliquer={() => {
                this.props.recupererListeAlarmesXY(
                  this.props.filtre_projects_list_id
                );
              }}
            >
              <VscRefresh size={15} />
            </Bouton>
            {this.props.children}
          </AttenteRequete>
        </Tableau>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    interfaceAdmin: state.authentifie.interfaceAdmin,
    listeAlarmesXY: state.alarme.list_alarms_xy,
    filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
    project_id_url: state.interfaceClient.project_id_url,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    recupererListeAlarmesXY: (listeIdProjets) =>
      dispatch(actions.reqListeAlarmesXY(listeIdProjets)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableauAlarmeXY);

export const succes = 'success';
export const danger = 'danger';
export const actualiser = 'refresh';
export const fermer = 'fermer';
export const outil = 'outil';
export const fermeCi = 'fermeCi';
export const Wait = 'Wait';
export const popupferme = 'popupferme';
export const Max = 'Max';
export const Min = 'Min';
export const succeSimple = 'succeSimple';
export const lupeSearch = 'lupeSearch';
export const lupeSearchClose = 'lupeSearchClose';
export const btnDisable = 'btnDisable';

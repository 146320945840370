import React, { Component } from 'react';
import { connect } from 'react-redux';

import ImporterImage from '../../../../UI/ImporterImage/ImporterImage';
import Bouton from '../../../../UI/Bouton/Bouton';

import AttenteRequete from '../../../../../hoc/AttenteRequete/AttenteRequete';

import { checkMot } from '../../../../../ressources/functionJS/checkMot';
import * as glossaire_pattern from '../../../../../ressources/glossaires/glossaire_pattern';
import * as glossaire_type_de_couche from '../../../../../ressources/glossaires/glossaire_type_de_couche';
import { IsArrayString } from '../../../../../ressources/functionJS/IsArrayString';
import * as requestType from '../../../../../ressources/glossaires/glossaire_requetes';

import * as actions from '../../../../../services/actions';
import * as s from '../../../../../ressources/StyleGlobal/StyleGlobal';
import * as S from '../../FormulaireVue/FormulaireVue.module.scss';

class AjouterCouche extends Component {
  state = {
    layer_type: glossaire_type_de_couche.Image,
    projection_id: 0,
    position: 0,
    layer_name: '',
    image_base64: '',
    bounds_image: '[[0,0],[0,0]]',
    geojson_content: '',
    tiles_url: '',
  };
  gererEtatDeCibleName = (event) =>
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  gererEtatDeCibleNameQgisWms = (event) =>
    this.setState({
      ...this.state,
      qgis_wms: {
        ...this.state.qgis_wms,
        [event.target.name]: event.target.value,
      },
    });
  gererEtatDeCibleNameQgisWmsParametre = (event) =>
    this.setState({
      ...this.state,
      qgis_wms: {
        ...this.state.qgis_wms,
        parametre: {
          ...this.state.qgis_wms.parametre,
          [event.target.name]: event.target.value,
        },
      },
    });
  gererEnvoi = () => {
    //mise en forme de l'objet pour l'envoi de la requête API
    let formulaireCouche = {
      layer_type: parseInt(this.state.layer_type),
      projection_id: parseInt(this.state.projection_id),
      position: parseInt(this.state.position),
      layer_name: this.state.layer_name,
    };
    switch (parseInt(this.state.layer_type)) {
      case glossaire_type_de_couche.Image:
        formulaireCouche = {
          ...formulaireCouche,

          image_base64: this.state.image_base64,
          bounds_image: IsArrayString(this.state.bounds_image, [
            [0, 0],
            [0, 0],
          ]),

          geojson_content: '',

          tiles_url: '',
        };
        break;
      case glossaire_type_de_couche.Tuile:
        formulaireCouche = {
          ...formulaireCouche,

          tiles_url: this.state.tiles_url,

          image_base64: '',
          bounds_image: '',

          geojson_content: '',
        };
        break;
      case glossaire_type_de_couche.GeoJSON:
        formulaireCouche = {
          ...formulaireCouche,

          geojson_content: this.state.geojson_content,

          image_base64: '',
          bounds_image: '',

          tiles_url: '',
        };
        break;
      case glossaire_type_de_couche.QgisWms:
        formulaireCouche = {
          ...formulaireCouche,

          geojson_content: '',

          image_base64: '',
          bounds_image: '',

          tiles_url: '',
          qgis_wms: {
            ...this.state.qgis_wms,
          },
        };
        break;
      default:
        formulaireCouche = null;
        break;
    }
    if (formulaireCouche !== null) {
      //Envoi la requête de création d'une couche de vue
      this.props.reqAjouterCoucheVue(formulaireCouche);
    } else {
      console.warn('Type de couche inconnu');
    }
  };
  affichageFormulaire = (style, label_state, label_name, pattern) => {
    /**
     * permet d'afficher un composant input de gestion de la modification
     */

    switch (pattern) {
      case glossaire_pattern.array:
        return (
          <div className={[s.col_11, S.styleNexw].join(' ')}>
            <span className={[S.stil2].join(' ')}>{label_name}</span>
            <input
              type="text"
              pattern={pattern}
              className={[s.form_control].join(' ')}
              placeholder={label_state}
              name={label_state}
              value={
                this.props.preconfigurerVue !== undefined
                  ? JSON.stringify(this.state[label_state])
                  : null
              }
              disabled={this.props.affichageProfil === true}
              onChange={(event) => this.gererEtatDeCibleName(event)}
            />
          </div>
        );
      default:
        return (
          <div className={[s.col_11, S.styleNexw].join(' ')}>
            <span className={[S.stil2].join(' ')}>{label_name}</span>
            <input
              type="text"
              pattern={pattern}
              className={[s.form_control].join(' ')}
              placeholder={label_state}
              name={label_state}
              value={
                this.props.preconfigurerVue !== undefined
                  ? this.state[label_state]
                  : null
              }
              disabled={this.props.affichageProfil === true}
              onChange={(event) => this.gererEtatDeCibleName(event)}
            />
          </div>
        );
    }
  };
  affichageFormulaireObjet = (
    methodeGerer,
    style,
    label_state,
    label_name,
    pattern
  ) => {
    /**
     * permet d'afficher un composant input de gestion de la modification
     */

    switch (pattern) {
      case glossaire_pattern.array:
        return (
          <div className={[s.col_11, S.styleNexw].join(' ')}>
            <span className={[S.stil2].join(' ')}>{label_name}</span>
            <input
              type="text"
              pattern={pattern}
              className={[s.form_control].join(' ')}
              placeholder={label_state}
              name={label_state}
              value={
                this.props.preconfigurerVue !== undefined
                  ? JSON.stringify(this.state[label_state])
                  : null
              }
              disabled={this.props.affichageProfil === true}
              onChange={(event) => methodeGerer(event)}
            />
          </div>
        );
      default:
        return (
          <div className={[s.col_11, S.styleNexw].join(' ')}>
            <span className={[S.stil2].join(' ')}>{label_name}</span>
            <input
              type="text"
              pattern={pattern}
              className={[s.form_control].join(' ')}
              placeholder={label_state}
              name={label_state}
              value={
                this.props.preconfigurerVue !== undefined
                  ? this.state[label_state]
                  : null
              }
              disabled={this.props.affichageProfil === true}
              onChange={(event) => methodeGerer(event)}
            />
          </div>
        );
    }
  };
  gererImageImporter = (imageImporter) => {
    this.setState({ image_base64: imageImporter.base64 });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  render() {
    let formulaireCouche;
    switch (parseInt(this.state.layer_type)) {
      case glossaire_type_de_couche.Image:
        formulaireCouche = (
          <>
            <ImporterImage
              extensionAccepter={'image/png, image/jpeg, image/jpg, .svg'}
              gererImageImporter={this.gererImageImporter}
            />

            {/**DEBUT bounds_image**/}
            <div className={[S.stillers, s.card].join(' ')}>
              <div className={[S.stillersT, s.card_header].join(' ')}>
                {checkMot('bounds_image')}
              </div>
              <div className={s.card_body}>
                <div>
                  <input
                    defaultChecked
                    type="radio"
                    value={this.state.bounds_image}
                    name={this.state.bounds_image}
                    onChange={this.handleChange}
                  />
                  {checkMot("Limites de l'image personalise ")}

                  <div
                    style={{
                      display:
                        this.state.bounds_image !== '[[0,0],[1080,1920]]'
                          ? 'block'
                          : 'none',
                    }}
                  >
                    {' '}
                    {this.affichageFormulaire(
                      s.col_12,
                      'bounds_image',
                      checkMot('bounds_image'),
                      glossaire_pattern.array
                    )}
                  </div>
                </div>

                <div>
                  <input
                    type="radio"
                    value={this.state.bounds_image}
                    name={this.state.bounds_image}
                    onClick={() =>
                      this.setState({ bounds_image: '[[0,0],[1080,1920]]' })
                    }
                  />
                  {checkMot('1920 x 1080')}
                </div>
              </div>
            </div>

            {/**FIN bounds_image**/}
          </>
        );
        break;
      case glossaire_type_de_couche.Tuile:
        formulaireCouche = (
          <>
            {/**DEBUT tiles_url**/}
            {this.affichageFormulaire(
              s.col_12,
              'tiles_url',
              checkMot('tiles_url'),
              glossaire_pattern.text
            )}
            {/**FIN tiles_url**/}
          </>
        );
        break;
      case glossaire_type_de_couche.GeoJSON:
        formulaireCouche = (
          <>
            {/**DEBUT geojson_content**/}
            {this.affichageFormulaire(
              s.col_12,
              'geojson_content',
              checkMot('geojson_content'),
              glossaire_pattern.text
            )}
            {/**FIN geojson_content**/}
          </>
        );
        break;
      case glossaire_type_de_couche.QgisWms:
        formulaireCouche = (
          <>
            {/**DEBUT url**/}
            {this.affichageFormulaireObjet(
              this.gererEtatDeCibleNameQgisWms,
              s.col_12,
              'url',
              checkMot('url'),
              glossaire_pattern.text
            )}
            {/**FIN url**/}
            {/**DEBUT map**/}
            {this.affichageFormulaireObjet(
              this.gererEtatDeCibleNameQgisWmsParametre,
              s.col_12,
              'map',
              checkMot('map'),
              glossaire_pattern.text
            )}
            {/**FIN map**/}
            {/**DEBUT layers**/}
            {this.affichageFormulaireObjet(
              this.gererEtatDeCibleNameQgisWmsParametre,
              s.col_12,
              'layers',
              checkMot('layers'),
              glossaire_pattern.text
            )}
            {/**FIN layers**/}
            {/**DEBUT format**/}
            {this.affichageFormulaireObjet(
              this.gererEtatDeCibleNameQgisWmsParametre,
              s.col_12,
              'format',
              checkMot('format'),
              glossaire_pattern.text
            )}
            {/**FIN format**/}
            {/**DEBUT transparent**/}
            {this.affichageFormulaireObjet(
              this.gererEtatDeCibleNameQgisWmsParametre,
              s.col_12,
              'transparent',
              checkMot('transparent'),
              glossaire_pattern.text
            )}
            {/**FIN transparent**/}
          </>
        );
        break;
      default:
        formulaireCouche = 'type de couche invalide';
        break;
    }
    return (
      <>
        <div className={[s.row, S.marge].join(' ')}>
          {/**DEBUT layer_type**/}
          <div className={[s.col_11, S.styleNexw].join(' ')}>
            <label className={[S.stil2].join(' ')}>
              {checkMot('layer_type')}
            </label>
            <select
              value={this.state.layer_type}
              onChange={(event) => this.gererEtatDeCibleName(event)}
              name="layer_type"
              className={[s.form_control, s.mb_2].join(' ')}
            >
              <option
                selected={glossaire_type_de_couche.Image}
                value={glossaire_type_de_couche.Image}
              >
                {checkMot('image')}
              </option>
              <option
                selected={glossaire_type_de_couche.Tuile}
                value={glossaire_type_de_couche.Tuile}
              >
                {checkMot('tuile')}
              </option>
              <option
                selected={glossaire_type_de_couche.GeoJSON}
                value={glossaire_type_de_couche.GeoJSON}
              >
                {checkMot('geojson')}
              </option>
              <option
                selected={glossaire_type_de_couche.QgisWms}
                value={glossaire_type_de_couche.QgisWms}
              >
                {checkMot('qgis_wms')}
              </option>
            </select>
          </div>
          {/**FIN layer_type**/}
          {/**DEBUT projection_id**/}
          {this.affichageFormulaire(
            s.col_12,
            'projection_id',
            checkMot('projection_id'),
            glossaire_pattern.integer
          )}
          {/**FIN projection_id**/}
          {/**DEBUT position**/}
          {this.affichageFormulaire(
            s.col_12,
            'position',
            checkMot('position'),
            glossaire_pattern.integer
          )}
          {/**FIN position**/}
          {/**DEBUT layer_name**/}
          {this.affichageFormulaire(
            s.col_12,
            'layer_name',
            checkMot('layer_name'),
            glossaire_pattern.text
          )}
          {/**FIN layer_name**/}
          {formulaireCouche}
        </div>
        <div className={[s.text_left, S.styleNexw].join(' ')}>
          <AttenteRequete
            request_type={requestType.ajouter_une_couche_de_vue}
            str={checkMot('creation_en_cour')}
          >
            <Bouton cliquer={this.gererEnvoi}>{checkMot('enregistrer')}</Bouton>
          </AttenteRequete>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    reqAjouterCoucheVue: (layer) =>
      dispatch(actions.reqAjouterCoucheVue(ownProps.view_id, layer)),
  };
};

export default connect(null, mapDispatchToProps)(AjouterCouche);

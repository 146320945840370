import React, { Component } from 'react';

import FormulairePersonalisationPrivileges from '../Ajouter/FormulairePersonalisationPrivileges/FormulairePersonalisationPrivileges';

import Bouton from '../../UI/Bouton/Bouton';

// import FormulaireAjoutProjetsAUtilisateur from "../../UI/FormulaireSelectionProjets/FormulaireSelectionProjets";
import FormulaireSelectionProjetsArborecenseUtilisateur from '../../UI/FormulaireSelectionProjetsArborecenseUtilisateur/FormulaireSelectionProjetsArborecenseUtilisateur';
import AttenteRequete from '../../../hoc/AttenteRequete/AttenteRequete';
import Collapse from '../../../hoc/Collapse/Collapse';

import { checkMot } from '../../../ressources/functionJS/checkMot';
import * as requestType from '../../../ressources/glossaires/glossaire_requetes';
import { confirmeOnClick } from '../../../ressources/functionJS/confirmeOnClick';

import * as type_btn from '../../../ressources/glossaires/glossaire_type_de_bouton';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import { MdDone, MdClose, MdModeEdit, MdDeleteSweep } from 'react-icons/md';
import {
  style,
  hedes,
  text_general_b,
  text_general_c,
  text_general,
  ConteneurInfo,
  bts,
} from './ProfilUtilisateur.module.scss';

function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}
class ProfilUtilisateur extends Component {
  state = {
    info_text_idUtilisateur: this.props.infoUser.user_id,
    info_text_nom: this.props.infoUser.lastname,
    info_text_Prenom: this.props.infoUser.name,
    info_text_Email: this.props.infoUser.email,
    info_text_Enterprise: this.props.infoUser.company_name,
    info_text_indexTelephonique: this.props.infoUser.phone_index,
    info_text_Telephone: this.props.infoUser.phone_number,
    info_text_activeUtilisateur: this.props.infoUser.active,
    info_text_Privilege: this.props.infoUser.privileges_list,
    info_text_Projects_list_id: this.props.infoUser.projects_list_id,
    //todo: si c'est en modification ne pas changer de profil
    afficherModifier: false,
  };
  afficherModifierProfil = () => {
    this.setState({ afficherModifier: true });
  };
  annulerModifierProfil = () => {
    this.setState({ afficherModifier: false });
  };
  enregistrerModifierProfil = () => {
    /*todo: envoyer la requête que si il y a eu une modification*/
    const formulaireDeModificationUtilisateur = {
      user_id: this.props.infoUser.user_id,
      login_name: this.state.info_text_Email,
      lastname: this.state.info_text_nom,
      name: this.state.info_text_Prenom,
      email: this.state.info_text_Email,
      phone_number: this.state.info_text_Telephone,
      phone_index: this.state.info_text_indexTelephonique,
      company_name: this.state.info_text_Enterprise,
      active: this.state.info_text_activeUtilisateur,
      privileges_list: this.state.info_text_Privilege,
      projects_list_id: this.state.info_text_Projects_list_id,
    };
    this.props.reqModifier(formulaireDeModificationUtilisateur);
  };
  componentDidUpdate(prevProps) {
    if (this.props.infoUser !== prevProps.infoUser) {
      this.setState({
        info_text_idUtilisateur: this.props.infoUser.user_id,
        info_text_Prenom: this.props.infoUser.name,
        info_text_nom: this.props.infoUser.lastname,
        info_text_Email: this.props.infoUser.email,
        info_text_Enterprise: this.props.infoUser.company_name,
        info_text_indexTelephonique: this.props.infoUser.phone_index,
        info_text_Telephone: this.props.infoUser.phone_number,
        info_text_activeUtilisateur: this.props.infoUser.active,
        info_text_Privilege: this.props.infoUser.privileges_list,
        info_text_Mes_projets: this.props.infoUser.projects_list,
        info_text_Projects_list_id: this.props.infoUser.projects_list_id,
        afficherModifier: false,
      });
    }
  }

  gererEtatDeCibleName = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };
  gererPrivilegesList = (privileges_list) => {
    this.setState({ ...this.state, info_text_Privilege: privileges_list });
  };
  gererProjetsList = (projects_list_id) => {
    this.setState({
      ...this.state,
      info_text_Projects_list_id: projects_list_id,
    });
  };
  render() {
    const PrivilegesCatUtilisateur =
      this.props.listePrivilegesParCategorie !== null &&
      this.props.listePrivilegesParCategorie !== undefined
        ? Object.keys(this.props.listePrivilegesParCategorie).map((cleCat) => {
            /**
                cleCat: catégorie de privilège (visiteur, client, admin)
                */
            return arrayEquals(
              this.props.listePrivilegesParCategorie[cleCat],
              this.props.infoUser.privileges_list
            ) ? (
              <span key={'affichage' + cleCat}> {checkMot(cleCat)}</span>
            ) : null;
          })
        : checkMot('liste_de_categorie_des_privileges_non_recupere');

    const formulaireModifier = (
      <>
        <div className={s.row}>
          <div className={[s.col_12, s.text_left].join(' ')}>
            <Collapse ExpandText={checkMot('modifier_info_general')}>
              <div className={s.row}>
                <div className={s.col_6}>
                  <label className={[style].join(' ')}>{checkMot('nom')}</label>
                  <input
                    className={text_general}
                    placeholder={this.state.info_text_nom}
                    name="info_text_nom"
                    value={this.state.info_text_nom}
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[style].join(' ')}>
                    {checkMot('prenom')}
                  </label>
                  <input
                    className={text_general}
                    placeholder={this.state.info_text_Prenom}
                    name="info_text_Prenom"
                    value={this.state.info_text_Prenom}
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[style].join(' ')}>
                    {checkMot('email')}
                  </label>
                  <input
                    className={text_general}
                    placeholder={this.state.info_text_Email}
                    name="info_text_Email"
                    value={this.state.info_text_Email}
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[style].join(' ')}>
                    {checkMot('entreprise')}
                  </label>
                  <input
                    className={text_general}
                    placeholder={this.state.info_text_Enterprise}
                    name="info_text_Enterprise"
                    value={this.state.info_text_Enterprise}
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                  />
                </div>
              </div>
            </Collapse>
            <Collapse ExpandText={checkMot('modifier_info_avance')}>
              <div className={s.row}>
                <div className={s.col_4}>
                  <label className={[style].join(' ')}>
                    {checkMot('index')}
                  </label>
                  <input
                    className={text_general}
                    placeholder={this.state.info_text_indexTelephonique}
                    size="4"
                    name="info_text_indexTelephonique"
                    value={this.state.info_text_indexTelephonique}
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                  />
                </div>
                <div className={s.col_8}>
                  <label className={[style].join(' ')}>
                    {checkMot('telephone')}
                  </label>
                  <input
                    className={text_general}
                    placeholder={this.state.info_text_Telephone}
                    name="info_text_Telephone"
                    value={this.state.info_text_Telephone}
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                  />
                </div>
                <div className={s.col_12}>
                  <FormulairePersonalisationPrivileges
                    gererPrivilegesList={this.gererPrivilegesList}
                    preconfigurerPrivilege={this.props.infoUser.privileges_list}
                  />
                </div>
                <div className={s.col_12}>
                  {/* <FormulaireAjoutProjetsAUtilisateur gererProjetsList={this.gererProjetsList} preconfigurerProjectsListId={this.state.info_text_Projects_list_id}/> */}
                  <FormulaireSelectionProjetsArborecenseUtilisateur
                    gererProjetsList={this.gererProjetsList}
                    preconfigurerProjectsListId={
                      this.state.info_text_Projects_list_id
                    }
                  />
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className={s.row}>
          <div className={s.col_md_6}>
            <Bouton
              cliquer={this.annulerModifierProfil}
              ajoutClass={bts}
              type={type_btn.danger}
            >
              <MdClose /> {checkMot('annuler')}
            </Bouton>
          </div>
          <div className={[s.col_md_6, s.text_left].join('')}>
            <AttenteRequete
              request_type={requestType.modification_d_un_utilisateur}
              str={checkMot('enregistrement_en_cour')}
            >
              <Bouton
                cliquer={this.enregistrerModifierProfil}
                ajoutClass={bts}
                type={type_btn.succes}
              >
                <MdDone />
                {checkMot('enregistre')}
              </Bouton>
            </AttenteRequete>
          </div>
        </div>
      </>
    );

    let noms_projet_liste = [];
    if (
      this.props.listeProjets !== undefined &&
      this.props.infoUser.projects_list_id !== undefined
    ) {
      for (let i = 0; i < this.props.listeProjets.length; i++) {
        // this.props.listeProjets[i].project_id
        for (let j = 0; j < this.props.infoUser.projects_list_id.length; j++) {
          if (
            this.props.infoUser.projects_list_id[j] ===
            this.props.listeProjets[i].project_id
          ) {
            noms_projet_liste.push(this.props.listeProjets[i].project_name);
          }
        }
      }
    }
    const profil = (
      <>
        <div className={s.row}>
          <div className={[s.col_md_12, s.text_left].join(' ')}>
            <Collapse ExpandText={checkMot('info_general')}>
              <div className={s.row}>
                <div className={s.col_8}>
                  <label className={[style].join(' ')}>{checkMot('nom')}</label>
                  <div className={text_general_b}>
                    {this.props.infoUser.name}
                  </div>
                </div>
                <div className={s.col_4}>
                  <label className={[style].join(' ')}>
                    {checkMot('prenom')}
                  </label>
                  <div className={text_general_b}>
                    {this.props.infoUser.lastname}
                  </div>
                </div>
                <div className={s.col_8}>
                  <label className={[style].join(' ')}>
                    {checkMot('email')}
                  </label>
                  <div className={text_general_b}>
                    {this.props.infoUser.email}
                  </div>
                </div>
                <div className={s.col_4}>
                  <label className={[style].join(' ')}>
                    {checkMot('entreprise')}
                  </label>
                  <div className={text_general_b}>
                    {this.props.infoUser.company_name}
                  </div>
                </div>
              </div>
            </Collapse>
            <Collapse ExpandText={checkMot('info_avance')}>
              <div className={s.row}>
                <div className={s.col_5}>
                  <label className={[style].join(' ')}>
                    {' '}
                    {checkMot('index')}
                  </label>
                  <div className={text_general_c}>
                    {this.props.infoUser.phone_index}
                  </div>
                </div>
                <div className={s.col_7}>
                  <label className={[style].join(' ')}>
                    {checkMot('telephone')}
                  </label>
                  <div className={text_general_c}>
                    {this.props.infoUser.phone_number}
                  </div>
                </div>
                <div className={s.col_12}>
                  <label className={[style].join(' ')}>
                    {checkMot('mes_projets')}
                  </label>
                  <div className={text_general_c}>
                    {noms_projet_liste.join(', ')}
                  </div>
                </div>
                <div className={s.col_12}>
                  <label className={[style].join(' ')}>
                    {checkMot('privileges')}
                  </label>
                  <div className={text_general_c}>
                    {PrivilegesCatUtilisateur.join('') === ''
                      ? checkMot('privileges_personnalise')
                      : PrivilegesCatUtilisateur}
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className={s.row}>
          <div className={[s.col].join(' ')}>
            <Bouton cliquer={this.afficherModifierProfil} ajoutClass={bts}>
              <MdModeEdit /> {checkMot('modifier')}
            </Bouton>
          </div>
          <div className={[s.col].join(' ')}>
            <AttenteRequete
              request_type={requestType.supprimer_un_utilisateur}
              str={checkMot('suppression_en_cour')}
            >
              <Bouton
                cliquer={() =>
                  confirmeOnClick(
                    checkMot('voulez_vous_vraiment_supprimer_cet_utilisateur'),
                    () => this.props.reqSupprimer(this.props.infoUser.user_id)
                  )
                }
                ajoutClass={bts}
              >
                <MdDeleteSweep /> {checkMot('supprimer')}
              </Bouton>
            </AttenteRequete>
          </div>
        </div>
      </>
    );

    return (
      <div className={ConteneurInfo}>
        <div className={[s.card_header, hedes].join(' ')}>
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            className="bi bi-info-circle-fill"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
            />
          </svg>{' '}
          {checkMot('utilisateur_n')}
          {this.props.infoUser.user_id}
        </div>
        <div className={s.container}>
          {this.state.afficherModifier ? formulaireModifier : profil}
        </div>
        {/*    todo dans un composant un nouveau composant afficher la liste de projet de l'utilisateur sélectionné */}
      </div>
    );
  }
}

export default ProfilUtilisateur;

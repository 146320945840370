import React, { Component } from 'react';
import { connect } from 'react-redux';

import { jsDictionaryEquals } from '../../../../../../../../ressources/functionJS/jsDictionaryEquals';
import * as S from './GrapheRapport.module.scss';
import * as actions from '../../../../../../../../services/actions';
import Collapse from '../../../../../../../../hoc/Collapse/Collapse';
import FormulaireSelectionGraphe from '../../../../../../../UI/FormulaireSelectionGraphe/FormulaireSelectionGraphe';

class GrapheRapport extends Component {
  state = {
    ...this.props.preconfigureObjet,
  };

  gererEtatDeCibleName = (event) =>
    this.setState({ ...this.state, [event.target.name]: event.target.value });

  componentDidMount() {
    if (
      this.props !== undefined &&
      this.props.preconfigureObjet === undefined
    ) {
      this.setState({
        //paramètre par défaut du rapport gabarit(voir si dessus)
        ...this.state,
      });
    } else if (
      this.props !== undefined &&
      this.props.preconfigureObjet !== undefined
    ) {
      this.setState({
        //pré-configuration du formulaire
        ...this.state,
        ...this.props.preconfigureObjet,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props !== undefined &&
      prevProps.preconfigureObjet !== this.props.preconfigureObjet &&
      this.props.preconfigureObjet === undefined
    ) {
      this.setState({
        //todo: paramètre par défaut
        ...this.state,
      });
    } else if (
      this.props !== undefined &&
      this.props.preconfigureObjet !== undefined &&
      !jsDictionaryEquals(
        prevProps.preconfigureObjet,
        this.props.preconfigureObjet
      )
    ) {
      this.setState({
        //todo: pré-configuration du formulaire
        ...this.state,
        ...this.props.preconfigureObjet,
      });
    }
    if (!jsDictionaryEquals(prevState, this.state)) {
      this.props.gererJson(this.state);
    }
  }

  gererGrapheId = (graph_id) =>
    this.setState({ ...this.state, graph_id: parseInt(graph_id) });

  render() {
    return (
      <Collapse
        ExpandText={
          <div className={S.contenuEnTete}>
            {this.props.contenuEnTete}
            <span className={S.titre}>{this.state.text}</span>
            <span className={S.bold}>
              {' '}
              ID du graphe : {this.props.preconfigureObjet.graph_id}{' '}
            </span>
          </div>
        }
      >
        <FormulaireSelectionGraphe
          gererGrapheId={this.gererGrapheId}
          preconfigurerGrapheId={this.props.preconfigureObjet.graph_id}
        />
        {this.props.children}
      </Collapse>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reqCreerRapportGabarit: (formulaireRapportGabarit) =>
      dispatch(actions.reqCreerRapportGabarit(formulaireRapportGabarit)),
    reqModifierRapportGabarit: (formulaireRapportGabarit) =>
      dispatch(actions.reqModifierRapportGabarit(formulaireRapportGabarit)),
  };
};

export default connect(null, mapDispatchToProps)(GrapheRapport);

import axios from 'axios';

export const _post = async (body: any): Promise<any> => {
  const response = await axios.post('', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from 'reactstrap/es/Spinner';
class AttenteRequete extends Component {
  render() {
    return this.props.attenteReq === undefined || !this.props.attenteReq ? (
      this.props.children
    ) : (
      <>
        <Spinner />
        {this.props.str}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    attenteReq: state.attenteRequete['ATTENTE_REQUETE' + ownProps.request_type],
  };
};

export default connect(mapStateToProps)(AttenteRequete);

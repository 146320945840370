import React, { Component } from 'react';
import { connect } from 'react-redux';

import { checkMot } from '../../ressources/functionJS/checkMot';
import S from './InterfaceClient.module.scss';
import OngletsClient from './Tabs/OngletsClient';

class InterfaceClient extends Component {
  state = {
    flagProjectIdInProjectsList: false,
    projet: {},
  };

  componentDidMount() {
    //Recherche si le projet est dans la liste des projets de l'utilisateur
    for (let project of this.props.projects_list) {
      if (parseInt(this.props.project_id) === project.project_id) {
        this.setState({ projet: project, flagProjectIdInProjectsList: true });
        break;
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.project_id !== this.props.project_id) {
      //Recherche si le projet est dans la liste des projets de l'utilisateur
      for (let project of this.props.projects_list) {
        if (parseInt(this.props.project_id) === project.project_id) {
          this.setState({
            projet: { ...project },
            flagProjectIdInProjectsList: true,
          });
          break;
        }
      }
    }
  }

  render() {
    let affiche;
    if (this.state.flagProjectIdInProjectsList) {
      affiche = (
        <div className={[S.ConteneurProjet].join(' ')}>
          <OngletsClient montrerMenu={this.props.montrerMenu} />
        </div>
      );
    } else {
      affiche = <p>{checkMot('vous_n_avez_pas_acces_projet')}</p>;
    }
    return affiche;
  }
}

const mapStateToProps = (state) => {
  return {
    projects_list: state.authentifie.projects_list,
  };
};

export default connect(mapStateToProps)(InterfaceClient);

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { textFilter } from 'react-bootstrap-table2-filter';
import AffichageVue from '../../../Vue/AffichageVue/AffichageVue';

import AttenteRequete from '../../../../hoc/AttenteRequete/AttenteRequete';

import Tableau from '../../../UI/tableau/Tableau';
import Bouton from '../../../UI/Bouton/Bouton';
import Popup from '../../../UI/PopupVue/Popup';

import * as actions from '../../../../services/actions';
import { jsDictionaryEquals } from '../../../../ressources/functionJS/jsDictionaryEquals';
import { checkMot } from '../../../../ressources/functionJS/checkMot';
import * as privilege from '../../../../ressources/glossaires/glossaire_privileges';
import * as requestType from '../../../../ressources/glossaires/glossaire_requetes';
import * as type_btn from '../../../../ressources/glossaires/glossaire_type_de_bouton';
import { glossaire_type_de_vue } from '../../../../ressources/glossaires/glossaire_type_de_vue';
import { getProjectIDTree } from '../../../../ressources/functionJS/getProjectIDTree';
import { searchKeyInTree } from '../../../../ressources/functionJS/searchKeyInTree';
import off from '../../../../ressources/Logo/off-f.png';
import on from '../../../../ressources/Logo/on-f.png';
import { VscSearch, VscNewFile, VscRefresh } from 'react-icons/vsc';

class Vues extends Component {
  componentDidMount() {
    //récupère la liste des vues (utiliser la requête api) en fonction de la liste de projets sélectionné dans le filtre
    if (!isNaN(this.props.project_id_url) && this.props.project_id_url !== '') {
      // cas pour l'interface client utilisation de l'id projet de l'url
      const listeDesProjetsId = getProjectIDTree(
        searchKeyInTree(
          { nodes: this.props.arborescence_projets },
          parseInt(this.props.project_id_url)
        )
      );
      this.props.recupererListeVues(listeDesProjetsId);
      this.setState({ listeDesProjetsId: listeDesProjetsId });
    } else if (this.props.filtre_projects_list_id.length > 0) {
      // envoie la requête de recupération de vue si la liste de projets n'est pas vide
      this.props.recupererListeVues(this.props.filtre_projects_list_id);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //récupère la liste des vues en fonction de la liste de projets sélectionné dans le filtre, si re lance la requête si la liste de projets change
    if (
      !isNaN(this.props.project_id_url) &&
      prevProps.project_id_url !== this.props.project_id_url &&
      this.props.project_id_url !== ''
    ) {
      // cas pour l'interface client utilisation de l'id projet de l'url
      const listeDesProjetsId = getProjectIDTree(
        searchKeyInTree(
          { nodes: this.props.arborescence_projets },
          parseInt(this.props.project_id_url)
        )
      );
      this.props.recupererListeVues(listeDesProjetsId);
      this.setState({ listeDesProjetsId: listeDesProjetsId });
    } else if (
      JSON.stringify(prevProps.listeVues) !==
      JSON.stringify(this.props.listeVues)
    ) {
      // envoie la requête de recupération de vue si la liste de projets change entre temps
      this.setState({ ...this.state, listeVues: this.props.listeVues });
    } else if (
      !jsDictionaryEquals(
        prevProps.filtre_projects_list_id,
        this.props.filtre_projects_list_id
      )
    ) {
      this.props.recupererListeVues(this.props.filtre_projects_list_id);
    }
  }

  afficherFiltre = (dataField, placeholder) => {
    const newCol = this.state.columns.map((col) => {
      if (col.dataField === dataField) {
        return {
          ...col,
          filter: textFilter({
            placeholder: placeholder,
          }),
        };
      } else {
        return col;
      }
    });
    this.setState({
      ...this.state,
      columns: [...newCol],
    });
  };

  state = {
    columns: [
      // liste des clés pour l'entête du tableau de vue
      {
        dataField: 'favoris',
        text: [
          checkMot('favoris') + ' ',
          // <Bouton key={"favoris_header_search"} type={type_btn.lupeSearch}
          //         cliquer={() => this.afficherFiltre("favoris", checkMot("favoris"))}><VscSearch size={10}/>
          // </Bouton>
        ],
        sort: true,
        align: 'center',
        headerAlign: 'center',
        style: {
          width: '150px',
        },

        formatter: (cell) => {
          switch (parseInt(cell)) {
            case 0:
              return (
                <img alt="off" title="Non favori" src={off} className="" />
              );
            case 1:
              return <img alt="off" title="favori" src={on} className="" />;
            default:
              return checkMot(cell);
          }
        },
      },
      {
        dataField: 'view_id',
        text: [
          checkMot('id'),
          <Bouton
            key={'view_id_header_search'}
            type={type_btn.lupeSearch}
            cliquer={() => this.afficherFiltre('view_id', checkMot('id'))}
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        hidden: true,
        headerAlign: 'left',
        align: 'left',
      },
      {
        dataField: 'project_id',
        text: [
          checkMot('projet'),
          <Bouton
            key={'project_id_header_search'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('project_id', checkMot('projet'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        align: 'left',
        headerAlign: 'left',
        hidden: true,
        formatter: (cell) => {
          for (let i = 0; i < this.props.projects_list.length; i++) {
            if (this.props.projects_list[i].project_id === cell) {
              return this.props.projects_list[i].project_name;
            }
          }
          return cell;
        },
      },
      {
        dataField: 'view_name',
        text: [
          checkMot('nom') + ' ',
          // <Bouton key={"view_name_header_search"} type={type_btn.lupeSearch}
          //         cliquer={() => this.afficherFiltre("view_name", checkMot("nom"))}><VscSearch size={10}/>
          // </Bouton>
        ],
        sort: true,
        align: 'left',
        headerAlign: 'left',
        filter: textFilter(),
        formatter: (cell, row) => {
          return <span title={row.view_title}>{cell}</span>;
        },
      },
      {
        dataField: 'view_title',
        text: [
          checkMot('descriptif') + ' ',
          // <Bouton key={"view_title_header_search"} type={type_btn.lupeSearch}
          //         cliquer={() => this.afficherFiltre("view_title", checkMot("descriptif"))}><VscSearch size={10}/>
          // </Bouton>
        ],
        sort: true,
        align: 'left',
        headerAlign: 'left',
        hidden: true,
      },

      // {dataField: 'update_time', text: checkMot("update_time"), sort: true},
      // {dataField: 'view_projection', text: checkMot("view_projection"), sort: true},
      // {dataField: 'view_bound_coordinates', text: checkMot("view_bound_coordinates"), sort: true},
      // {dataField: 'view_zoom_levels', text: checkMot("view_zoom_levels"), sort: true},
      // {dataField: 'view_copyright', text: checkMot("view_copyright"), sort: true},
      // {dataField: 'view_flag_fix_pos', text: checkMot("view_flag_fix_pos"), sort: true},
    ],
    selectRow: {
      // paramètre d'affichage des lignes du tableau
      mode: 'radio',
      clickToSelect: true,
      hideSelectAll: true,
      hideSelectColumn: true,
      style: () => {
        //style après sélection
        return {
          background: '#eeb214',
          color: 'black',
        };
      },
      onSelect: (row) => {
        //fonction de récupération des données de la ligne après sélection de la ligne
        this.ouvrirPopupVue();
        return this.vueSelectionne(row.view_id);
      },
    },
    iconeCreer: <VscNewFile size={15} />,
    tableauDeBord_id: null,
    afficherPopupVue: false,
    listeDesProjetsId: [],
  };
  vueSelectionne = (view_id) => {
    this.setState({ tableauDeBord_id: view_id });
  };
  fermerPopupVue = () => {
    this.setState({ afficherPopupVue: false });
  };
  ouvrirPopupVue = () => {
    this.setState({ afficherPopupVue: true });
  };

  render() {
    let donnees_avec_favoris = [];
    let liste_tableaux_de_bord_favoris = [];
    if (this.props.favorite_views_list !== undefined) {
      liste_tableaux_de_bord_favoris = [
        ...this.props.favorite_views_list.map((value) => value[0]),
      ];
    }
    for (let i = 0; i < this.props.listeVues.length; i++) {
      if (
        parseInt(this.props.listeVues[i].view_type) ===
          glossaire_type_de_vue.plan_et_carte &&
        this.state.listeDesProjetsId.indexOf(
          parseInt(this.props.listeVues[i].project_id)
        ) > -1
      ) {
        if (
          liste_tableaux_de_bord_favoris.indexOf(
            this.props.listeVues[i].view_id
          ) > -1
        ) {
          donnees_avec_favoris.push({ ...this.props.listeVues[i], favoris: 1 });
        } else {
          donnees_avec_favoris.push({ ...this.props.listeVues[i], favoris: 0 });
        }
      }
    }
    if (donnees_avec_favoris.length > 0) {
      this.props.siLaListeAUneValeur(true);
    }
    return (
      <>
        <Tableau
          privilegeCreer={privilege.creer_des_vues}
          creer={this.props.creer}
          iconeCreer={this.state.iconeCreer}
          cleLigne={'view_id'}
          donnees={donnees_avec_favoris}
          colonnes={this.state.columns}
          selectionnerLignes={this.state.selectRow}
        >
          <AttenteRequete
            request_type={requestType.recuperation_de_la_liste_des_vues}
            str={checkMot('maj_vues_en_cour')}
          >
            {/*bouton de rafraîchissement du tableau*/}
            <Bouton
              hoverText={checkMot('refresh')}
              type={type_btn.outil}
              rond={true}
              cliquer={() => {
                this.props.recupererListeVues(this.state.listeDesProjetsId);
              }}
            >
              <VscRefresh size={15} />
            </Bouton>
          </AttenteRequete>
        </Tableau>
        <Popup
          sansBouton={true}
          eventAuClique={this.ouvrirPopupVue}
          eventALaFermeture={this.fermerPopupVue}
          showModal={this.state.afficherPopupVue}
        >
          <AffichageVue view_id={this.state.tableauDeBord_id} />
        </Popup>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listeVues: state.vue.views_list,
    filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
    projects_list: state.authentifie.projects_list,
    project_id_url: state.interfaceClient.project_id_url,
    arborescence_projets: state.interfaceClient.arborescence_projets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    recupererListeVues: (listeIdProjets) =>
      dispatch(actions.reqRecupererListeVues(listeIdProjets)),
    // recupererListeCapteursReduites:(listeIdProjets) => dispatch(actions.reqRecupererListeCapteursReduites(listeIdProjets)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Vues);

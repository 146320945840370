import React, { Component } from 'react';
import * as S from './ObjectExport.module.scss';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';

class ObjectExport extends Component {
  state = {
    JsonShow: false,
    copied: false,
  };

  toggleJsonShow = () => {
    this.setState((prevState) => ({ JsonShow: !prevState.JsonShow }));
  };

  copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.setState({ copied: true }, () => {
          setTimeout(() => this.setState({ copied: false }), 3000);
        });
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        this.setState({ copied: false });
      });
  };

  render() {
    const { JsonShow, copied } = this.state;
    const { TitleObj, ImgObj, DescriptionObj, JsonCodeObj } = this.props;

    return (
      <div className={[S.containerObject, s.card, s.text_center].join(' ')}>
        <div className={[S.TitleObj, s.card_header].join(' ')}>{TitleObj}</div>
        <div className={[S.ImgObj, s.card_text].join(' ')}>{ImgObj}</div>
        <div className={[S.DescriptionObj, s.card_text].join(' ')}>
          {DescriptionObj}
        </div>

        <div className={[S.jss, s.text_center].join(' ')}>
          <button
            className={[s.btn, s.btn_primary, S.BTN].join(' ')}
            onClick={this.toggleJsonShow}
          >
            Code {JsonShow ? <IoIosEyeOff size={15} /> : <IoIosEye size={15} />}
          </button>
        </div>
        {JsonShow && (
          <div
            className={[S.JsonCodeObj, s.card_footer, s.text_muted].join(' ')}
          >
            <div className={S.st}>{JsonCodeObj}</div>
            <button
              className={[S.nes, s.btn, s.btn_primary, S.BTN].join(' ')}
              onClick={() => this.copyToClipboard(JsonCodeObj)}
            >
              Copier code
            </button>
            {copied && (
              <div
                className="copy-notification"
                style={{
                  position: 'fixed',
                  top: '0px',
                  left: '25.2%',
                  backgroundColor: 'green',
                  color: 'white',
                  padding: '10px',
                  borderRadius: '5px',
                  zIndex: 1000,
                }}
              >
                "Copié avec succès !"
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ObjectExport;

export const glossaire_alarm_level = {
  1: 'High black',
  2: 'High red',
  3: 'High amber',
  4: 'High blue',
  8: 'Low black',
  7: 'Low red',
  6: 'Low amber',
  5: 'Low blue',
  9: 'Watchdog',
};

export const couleurAlarme = (niveauAlarme) => {
  let linecolor;
  switch (
    glossaire_alarm_level[parseInt(niveauAlarme)].split(' ')[1].toLowerCase()
  ) {
    case 'black':
      linecolor = 'black';
      break;
    case 'red':
      linecolor = '#f70606';
      break;
    case 'amber':
      linecolor = '#ffa233';
      break;
    case 'blue':
      linecolor = '#3390ff';
      break;
    default:
      linecolor = 'green';
      break;
  }
  return linecolor;
};

import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Bouton from '../Bouton/Bouton';
import S from './Popup.module.scss';
import * as type_btn from '../../../ressources/glossaires/glossaire_type_de_bouton';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';

const affichagePetit = '5.9% 0.1% 0% 15.2%';
const affichageGrand = '0% 0% 0% 0%';

class Popup extends Component {
  state = {
    showModal:
      (this.props.showModal !== undefined ? this.props.showModal : false) ||
      (this.props.afficherVueConnexion === true && this.props.identifier),
    overflowX: 'auto',
    overflowY: 'hidden',

    inset: this.props.afficherExpand ? affichageGrand : affichagePetit,
    afficherExpand: this.props.afficherExpand,
  };
  componentDidMount() {
    if (this.props.width === '100%') {
      this.setState({
        inset: '5.9% 0.1% 0% 0%',
      });
    } else {
      this.setState({
        inset: '5.9% 0.1% 0% 15.2%',
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.width !== prevProps.width) {
      if (this.props.width === '100%') {
        this.setState({
          inset: '5.9% 0.1% 0% 0%',
        });
      } else {
        this.setState({
          inset: '5.9% 0.1% 0% 15.2%',
        });
      }
    }
  }
  setShowModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  setShowModalFalse = () => {
    this.setState({
      showModal: false,
    });
    if (
      this.props.eventALaFermeture !== undefined &&
      this.props.eventALaFermeture instanceof Function
    ) {
      this.props.eventALaFermeture();
    }
  };
  setExpand = () => {
    this.setState({
      inset: '0% 0% 0% 0%',
      afficherExpand: true,
      overflowX: 'hidden',
    });
    if (document.getElementById(this.props.popup_id) !== null) {
      document.getElementById(this.props.popup_id).requestFullscreen();
    }
  };
  setPetit = () => {
    this.setState({
      inset: affichagePetit,
      afficherExpand: false,
      overflowX: 'auto',
      overflowY: 'auto',
    });
    if (document.fullscreenElement !== null) {
      document.exitFullscreen();
    }
  };
  render() {
    let classpopup = [S.boutonOutilPopup, s.row, s.text_right]; //line new
    let barreOutils; //line new
    let boutonExpand; //line new
    if (this.state.afficherExpand) {
      boutonExpand = (
        <Bouton
          ajoutClass={[S.PositionBoutonFermeture, S.BoutonOutil].join(' ')}
          type={type_btn.Min}
          rond={true}
          cliquer={() => {
            this.setPetit();
            if (document.fullscreenElement !== null) {
              document.exitFullscreen();
            }
          }}
        />
      );
    } else {
      boutonExpand = (
        <Bouton
          ajoutClass={[S.PositionBoutonFermeture, S.BoutonOutil].join(' ')}
          type={type_btn.Max}
          rond={true}
          cliquer={() => {
            this.setExpand();
            if (document.getElementById(this.props.popup_id) !== null) {
              document.getElementById(this.props.popup_id).requestFullscreen();
            }
          }}
        />
      );
    }

    barreOutils = (
      <div className={classpopup.join(' ')}>
        <Bouton
          ajoutClass={[S.PositionBoutonFermeture, S.BoutonOutil].join(' ')}
          type={type_btn.fermer}
          rond={true}
          cliquer={this.setShowModalFalse}
        />
        {boutonExpand}
        {this.props.barre_outils !== undefined ? this.props.barre_outils : null}
      </div>
    );

    let classBouton = S.format;
    if (this.props.textAlignLeftBouton === true) {
      classBouton = S.formatLeft;
    }
    return (
      <div className={S.divMaster3}>
        {this.props.sansBouton === true ? null : this.props.afficherLien ===
          true ? ( //transforme le bouton d'ouverture de popup en liens d'ouverture
          <a
            href={''}
            onClick={(event) => {
              event.preventDefault();
              this.setShowModal();
              if (
                this.props.eventAuClique !== undefined &&
                this.props.eventAuClique instanceof Function
              ) {
                this.props.eventAuClique();
              }
            }}
          >
            {this.props.icone}
            {this.props.texte_ouverture}
          </a>
        ) : (
          <Bouton
            hoverText={this.props.hoverText}
            ajoutClass={classBouton}
            type={this.props.typeBoutonAffichage}
            desactive={this.props.desactive === true}
            cliquer={() => {
              this.setShowModal();
              if (
                this.props.eventAuClique !== undefined &&
                this.props.eventAuClique instanceof Function
              ) {
                this.props.eventAuClique();
              }
            }}
          >
            {this.props.icone}
            {this.props.texte_ouverture}
          </Bouton>
        )}
        <Modal
          id={this.props.popup_id}
          isOpen={this.state.showModal || this.props.showModal === true}
          onRequestClose={this.setShowModalFalse}
          style={{
            overlay: {
              position: 'absolute',
              zIndex: '1000',

              //    padding: "1px",
              // paddingTop: "50px",
              //    backgroundColor: "white",
              border: 'black 1px solid',
              //    borderRadius: "10px",
              backgroundColor: 'transparent',

              transition: 'width 2s, height 4s',
            },
            content: {
              overflowX: this.state.overflowX,
              overflowY: this.state.overflowY,

              inset: this.state.inset,
              padding: '0px',
              boxShadow: '-5px 0px 5px rgba(0, 0, 0, 0.226)',
            },
          }}
        >
          {barreOutils}
          {/* <a href="https://www.itmsol.fr" target="_blank" rel="noopener noreferrer">
                    <img className={S.glogo} src={glogo} alt="itmsol"/>
                    </a> */}
          {this.props.children}
        </Modal>
      </div>
    );
  }
}

const mapStateToprops = (state) => {
  return {
    left: state.popup.left,
    width: state.popup.width,
    top: state.popup.top,
    height: state.popup.height,

    identifier: state.authentifie.identifier,
  };
};

export default connect(mapStateToprops)(Popup);

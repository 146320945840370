export const glossaire_couleurs = {
  rougeITM: '#A6163F',
  jauneSB: '#f0af00',
  gris2SB: '#646464',
  gris1SB: '#b4b4b4',
  blancSB: '#ffffff',
  noirSB: '#000000',
  bleu3SB: '#074c7c',
  bleu2SB: '#4b96cd',
  bleu1SB: '#33b8c9',
  vertSB: '#49B857',
  orangeSB: '#ea690a',
  violetSB: '#7570ad',
};

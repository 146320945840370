import React, { Component } from 'react';

import TableauGabarit from './TableauGabarit/TableauGabarit';
import FormulaireGabarit from './ProfilGabarit/FormulaireGabarit/FormulaireGabarit';
import ProfilGabarit from './ProfilGabarit/ProfilGabarit';

import AccesRestreins from '../../../hoc/AccesRestreins/AccesRestreins';

import * as privilege from '../../../ressources/glossaires/glossaire_privileges';
import * as indexConteneurInfo from '../../../ressources/glossaires/glossaire_conteneur_info';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import * as S from './Gabarit.module.scss';
import { RiAddCircleLine } from 'react-icons/ri';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';

class Gabarit extends Component {
  state = {
    InfoSelect: null,
    indexConteneurInfo: 0,
  };
  selectioneLigne = (ligne) => {
    this.setState({
      ...this.state,
      InfoSelect: ligne,
      indexConteneurInfo: indexConteneurInfo.profil,
    });
  };
  //fonction pour ouverture du conteneur information avec le formulaire de création
  creerGabarit = () => {
    this.setState({
      ...this.state,
      indexConteneurInfo: indexConteneurInfo.ajouterForm,
    });
  };

  render() {
    let affichageFormulaireGabarit;
    switch (this.state.indexConteneurInfo) {
      case indexConteneurInfo.ajouterForm:
        affichageFormulaireGabarit = (
          <span style={{ display: 'block' }}>
            <FormulaireGabarit
              annuler={() => this.setState({ ...this.state, showCreer: false })}
            />
          </span>
        );
        break;
      case indexConteneurInfo.profil:
        affichageFormulaireGabarit = (
          <ProfilGabarit
            InfoSelect={this.state.InfoSelect}
            reqModifier={this.props.reqModifier}
            reqSupprimer={this.props.reqSupprimer}
          />
        );
        break;
      default:
        affichageFormulaireGabarit = (
          <div className={S.fontSize}>{checkMot('selectionne_un_gabarit')}</div>
        );
    }
    return (
      <div className={[s.row, S.comtnet].join(' ')}>
        <div className={s.col_sm_6}>
          <div className={S.fontSize}>{checkMot('liste_des_gabarits')}</div>
          <AccesRestreins
            listNumPrivilege={[
              privilege.creer_des_gabarits,
              privilege.modifier_des_gabarits,
              privilege.supprimer_des_gabarits,
            ]}
          >
            <TableauGabarit selectioneLigne={this.selectioneLigne} />
          </AccesRestreins>
          <AccesRestreins numPrivilege={privilege.creer_des_gabarits}>
            <button onClick={this.creerGabarit} className={S.btadd}>
              <RiAddCircleLine size={34} />
              {checkMot('creer_un_gabarit')}
            </button>
          </AccesRestreins>
        </div>
        <div className={s.col_sm_6}>{affichageFormulaireGabarit}</div>
      </div>
    );
  }
}

export default Gabarit;

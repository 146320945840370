import React, { Component } from 'react';

import AttenteRequete from '../../../../../hoc/AttenteRequete/AttenteRequete';

import * as s from '../../../../../ressources/StyleGlobal/StyleGlobal';
import {
  ConteneurInfo,
  Formulaire,
  label,
  btF,
  col,
  btnn,
  margin,
} from './FormulaireGroupeEmail.module.scss';
import { AiOutlineClose } from 'react-icons/ai';
import { RiMailSendFill } from 'react-icons/all';

import * as requestType from '../../../../../ressources/glossaires/glossaire_requetes';
import { glossaire_alarm_level } from '../../../../../ressources/glossaires/glossaire_niveau_alarme';

import Bouton from '../../../Bouton/Bouton';
import { checkMot } from '../../../../../ressources/functionJS/checkMot';

import FormulaireSelectionProjet from '../../../../Capteur/Ajouter/FormulaireSelectionProjet/FormulaireSelectionProjet';
import * as actions from '../../../../../services/actions';
import { connect } from 'react-redux';

class FormulaireGroupeEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project_id: null,
      email_group_name: '',
      list_emails: [],
    };
  }

  componentDidMount() {
    if (this.props.preconfigurerGroupeEmail !== undefined) {
      this.setState({
        ...this.props.preconfigurerGroupeEmail,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.preconfigurerGroupeEmail !== undefined &&
      this.props.preconfigurerGroupeEmail.group_email_id !==
        prevProps.preconfigurerGroupeEmail.group_email_id
    ) {
      this.setState({
        ...this.props.preconfigurerGroupeEmail,
      });
    }
  }

  gererEtatEmailGroupName = (event) => {
    this.setState({ ...this.state, email_group_name: event.target.value });
  };
  gererEtatEmail = (event, indEmail) => {
    /**
     * modifie l'email de l'index en entrée
     * @param event : [object] récupère l'email tapé dans l'input
     * @param indEmail : [string] index du groupe d'email dans la liste
     */
    let newList_email = [...this.state.list_emails];
    newList_email[indEmail] = {
      ...newList_email[indEmail],
      email: event.target.value,
    };
    this.setState({ ...this.state, list_emails: newList_email });
  };
  gererSupprimerEmail = (indEmail) => {
    /**
     * supprime l'email de la liste via son index
     * @param indEmail : [int] index du groupe d'email dans la liste
     *
     */
    let newList_email = [...this.state.list_emails];
    newList_email.splice(indEmail, 1);
    this.setState({ ...this.state, list_emails: newList_email });
  };
  gererEtatCheckboxSeuil = (indEmail, checkboxValue, cleSeuil) => {
    /**
     * ajoute ou supprime id du type d'alarme des favoris d'un email
     * @param indEmail : [int] index du groupe d'email dans la liste
     * @param checkboxValue : [boolean] indique si l'id du type d'alarme est dans la liste de list_alarms_levels_favorites
     * @param cleSeuil : [int] id du type d'alarme
     */
    let newList_email = [...this.state.list_emails]; // nouvelle liste d'email après dupliqué
    if (checkboxValue) {
      //supprime l'id du type d'alarme poue le mail de la liste sélectionné (avec un filtre)
      newList_email[indEmail] = {
        ...newList_email[indEmail],
        list_alarms_levels_favorites: newList_email[
          indEmail
        ].list_alarms_levels_favorites.filter((v) => v !== cleSeuil),
      };
    } else {
      //ajoute l'id du type d'alarme poue le mail de la liste sélectionné
      newList_email[indEmail].list_alarms_levels_favorites.push(cleSeuil);
    }
    this.setState({ ...this.state, list_emails: newList_email }); //remplace l'ancienne liste d'emails par la nouvelle
  };
  gererProjetId = (projetId) => {
    this.setState({ ...this.state, project_id: projetId });
  };
  reqEnvoi = () => {
    /**
     * envoie la requête de création ou modification de groupe d'email
     * @type {{email_group_name: (string|*), list_emails: ([]|*), project_id: number}}
     */
    let formulaireGroupeEmail = {
      //création de l'objet à envoyer
      project_id: parseInt(this.state.project_id),
      email_group_name: this.state.email_group_name,
      list_emails: this.state.list_emails,
    };
    if (this.state.group_email_id === undefined) {
      //envoi la requête de création de groupe d'email si group_email_id n'est pas défini
      this.props.reqAjouter(formulaireGroupeEmail);
    } else {
      //ajoute le group_email_id et envoie la requête de modification
      formulaireGroupeEmail['group_email_id'] = this.state.group_email_id;
      this.props.reqModifier(formulaireGroupeEmail);
    }
  };

  render() {
    let affichageBouton;
    if (!this.props.affichageProfil) {
      //cache les boutons d'envoie de req si c'est le mode profil
      if (this.props.preconfigurerGroupeEmail !== undefined) {
        //formulaire de modification
        affichageBouton = (
          <>
            <AttenteRequete
              request_type={requestType.modifier_un_groupe_d_email}
              str={checkMot('modification_en_cours')}
            >
              {/*bouton d'envoi de la requête de modification de groupe de mail*/}
              <button className={btF} onClick={this.reqEnvoi}>
                <RiMailSendFill size={24} /> {checkMot('envoi_modification')}
              </button>
            </AttenteRequete>
          </>
        );
      } else {
        //formulaire de création
        affichageBouton = (
          <>
            <AttenteRequete
              request_type={requestType.creer_un_groupe_d_email}
              str={checkMot('creation_en_cours')}
            >
              {/*bouton d'envoi de la requête de modification de groupe de mail*/}
              <button className={btF} onClick={this.reqEnvoi}>
                <RiMailSendFill size={24} /> {checkMot('envoi_creation')}
              </button>
            </AttenteRequete>
          </>
        );
      }
    }
    return (
      <>
        <div className={[ConteneurInfo].join(' ')}>
          <div
            className={[s.container, s.border, s.text_left, Formulaire].join(
              ' '
            )}
          >
            <FormulaireSelectionProjet
              affichageProfil={this.props.affichageProfil}
              gererProjetId={this.gererProjetId}
              preconfigurerProjetId={this.state.project_id}
            />
            {/**FIN project_id**/}
            <div className={s.form_group}>
              <label
                className={[label].join(' ')}
                htmlFor="inlineFormCustomSelect"
              >
                {checkMot('groupe_email_name')}
              </label>
              <div className={[s.form_row, s.align_items_center].join(' ')}>
                <div className={s.col_12}>
                  <input
                    type="text"
                    pattern="[0-9a-zA-Z._%+-\\s ]+"
                    className={[s.form_control, s.mb_2].join(' ')}
                    placeholder="email_group_name"
                    value={this.state.email_group_name}
                    disabled={this.props.affichageProfil}
                    onChange={(event) => this.gererEtatEmailGroupName(event)}
                  />
                </div>
              </div>
            </div>
            {/**email_group_name**/}
            <div className={[s.form_row, s.align_items_center].join(' ')}>
              <div className={s.col_sm_12}>
                {this.props.affichageProfil ? null : (
                  <Bouton
                    cliquer={() =>
                      this.setState({
                        ...this.state,
                        list_emails: [
                          ...this.state.list_emails,
                          {
                            email: '',
                            list_alarms_levels_favorites: [],
                          },
                        ],
                      })
                    }
                  >
                    {checkMot('ajouter_un_email')}
                  </Bouton>
                )}
              </div>
              <div className={[s.row].join(' ')}>
                {this.state.list_emails.map((objEmail, indEmail) => {
                  /**
                   * @return [array] liste des emails avec la liste des types d'alarmes favoris lié à l'email
                   */
                  return (
                    <div className={col}>
                      <input
                        type="text"
                        placeholder="email_group_name"
                        value={objEmail.email}
                        disabled={this.props.affichageProfil}
                        onChange={(event) =>
                          this.gererEtatEmail(event, indEmail)
                        }
                      />
                      {this.props.affichageProfil ? null : (
                        <div className={btnn}>
                          <Bouton
                            cliquer={() => this.gererSupprimerEmail(indEmail)}
                          >
                            <AiOutlineClose />
                          </Bouton>
                        </div>
                      )}
                      {Object.keys(glossaire_alarm_level).map((cleAlarme) => {
                        /**
                         *
                         * @return [array] liste des types d'alarmes favoris lié à un email
                         */
                        const checkboxValue =
                          this.state.list_emails[
                            indEmail
                          ].list_alarms_levels_favorites.indexOf(
                            parseInt(cleAlarme)
                          ) > -1;
                        return (
                          <div
                            className={margin}
                            onChange={() =>
                              this.gererEtatCheckboxSeuil(
                                indEmail,
                                checkboxValue,
                                parseInt(cleAlarme)
                              )
                            }
                          >
                            <input
                              className={margin}
                              disabled={this.props.affichageProfil}
                              type="checkbox"
                              checked={checkboxValue}
                            />

                            {glossaire_alarm_level[cleAlarme]}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            {/**list_emails**/}
            {affichageBouton}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reqAjouter: (idGroupeEmail) =>
      dispatch(actions.reqCreerGroupeEmail(idGroupeEmail)),
    reqModifier: (idGroupeEmail) =>
      dispatch(actions.reqModifierGroupeEmail(idGroupeEmail)),
  };
};

export default connect(null, mapDispatchToProps)(FormulaireGroupeEmail);

import React, { Component } from 'react';

import { AiOutlineMinusSquare, AiOutlinePlusSquare } from 'react-icons/ai';
import { connect } from 'react-redux';
import * as actions from '../../../../services/actions';
import * as S from './FormulaireAjoutUtilisateursAProjet.module.scss';
class FormulaireAjoutUtilisateursAProjet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonVisible: false,
      listeIdUtilisateur: [],
      effaceSelect: false,
      newstateUser: this.props.listeUtilisateur,
      inputValue: this.props.listeUtilisateur,
      filterText: '',
    };
  }
  onChangeListeUtilisateur = (newValue) => {
    // Atualize o estado do componente pai com o novo valor do input
    this.setState({ listeUtilisateur: newValue });
  };
  componentDidMount() {
    // importe la table d'utilisateurs dans le réducteur d'utilisateur (utiliser la requête api)
    this.props.recupererListeUtilisateurs();

    //Préconfigure le formulaire de modification avec les utilisateurs du projet
    if (this.props.preconfigurerUtilisateursListId !== undefined) {
      this.setState({
        ...this.state,
        listeIdUtilisateur: this.props.preconfigurerUtilisateursListId,
        buttonVisible: true,
      });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state !== prevState) {
      this.props.gererUtilisateursList(this.state.listeIdUtilisateur);
    }
    if (this.props.listeUtilisateurs) {
      console.error(
        'réregarde ça',
        this.props.listeUtilisateurs,
        'newuserInput value :',
        this.state.inputValue,
        'newuser',
        this.state.newstateUser
      );
    }
  }

  gererListeIdUtilisateur = (event) => {
    const data = this.props.listeUtilisateurs;

    const userIds = data.map((user) => user.user_id);

    console.error('ids: ', userIds);

    if (!event || !event.target) {
      this.setState({ inputValue: event.target.value });
      return;
    }

    const newValue = event.target.value;

    if (!newValue) {
      this.setState({ inputValue: event.target.value });
      return;
    }

    const parsedValue = parseInt(newValue, 10);

    if (isNaN(parsedValue)) {
      this.setState({ inputValue: event.target.value });
      return;
    }

    if (
      newValue !== 'unselectable' &&
      this.state.listeIdUtilisateur.indexOf(parsedValue) < 0
    ) {
      let newListeIdUtilisateur = [...this.state.listeIdUtilisateur];
      newListeIdUtilisateur.push(parsedValue);
      this.setState({
        ...this.state,
        listeIdUtilisateur: newListeIdUtilisateur,
        inputValue: newValue,
        buttonVisible: true,
      });
    }
  };

  enleverUtilisateur = (userId) => {
    // Filtrer la liste pour exclure l'utilisateur avec l'ID spécifié
    const newListeIdUtilisateur = this.state.listeIdUtilisateur.filter(
      (id) => id !== userId
    );

    // Mettre à jour l'état
    this.setState({
      ...this.state,
      listeIdUtilisateur: newListeIdUtilisateur,
      buttonVisible: newListeIdUtilisateur.length > 0,
    });
  };

  limparSelecao() {
    this.setState({ inputValue: '' });
  }

  toggleUserSelection = (userId) => {
    const isSelected = this.state.listeIdUtilisateur.includes(userId);
    let newListeIdUtilisateur;

    if (isSelected) {
      newListeIdUtilisateur = this.state.listeIdUtilisateur.filter(
        (id) => id !== userId
      );
    } else {
      newListeIdUtilisateur = [...this.state.listeIdUtilisateur, userId];
    }

    this.setState({
      listeIdUtilisateur: newListeIdUtilisateur,
      buttonVisible: newListeIdUtilisateur.length > 0,
    });
  };

  handleInputChange = (event) => {
    this.setState({ filterText: event.target.value });
  };

  render() {
    const filteredUsers = Object.keys(this.props.listeUtilisateurs).filter(
      (indListeUtilisateurs) => {
        const user = this.props.listeUtilisateurs[indListeUtilisateurs];
        const fullName = `${user.lastname} ${user.name}`.toLowerCase();
        return fullName.includes(this.state.filterText.toLowerCase());
      }
    );
    const iconeEnlever = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-file-x-fill"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM6.854 6.146a.5.5 0 1 0-.708.708L7.293 8 6.146 9.146a.5.5 0 1 0 .708.708L8 8.707l1.146 1.147a.5.5 0 0 0 .708-.708L8.707 8l1.147-1.146a.5.5 0 0 0-.708-.708L8 7.293 6.854 6.146z"
        />
      </svg>
    );

    const listeDeroulanteUtilisateur = (
      <>
        <div className={S.customDropdownContainer}>
          <input
            type="text"
            placeholder="Filtrer les utilisateurs par nom..."
            value={this.state.filterText}
            onChange={this.handleInputChange}
            className="form-control"
          />
          <ul className={S.customDropdown}>
            {filteredUsers.map((indListeUtilisateurs) => {
              const user = this.props.listeUtilisateurs[indListeUtilisateurs];
              const isSelected = this.state.listeIdUtilisateur.includes(
                user.user_id
              );
              return (
                <li key={user.user_id} className={S.dropdownItem}>
                  {`${user.lastname} ${user.name}`}
                  <button
                    type="button"
                    className={S.btnn}
                    onClick={() => this.toggleUserSelection(user.user_id)}
                  >
                    {isSelected ? (
                      <AiOutlineMinusSquare size={20} />
                    ) : (
                      <AiOutlinePlusSquare size={20} />
                    )}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
    // récupération des informations des utilisateurs sélectionnés
    let information_utilisateur_liste = [];
    if (
      this.props.listeUtilisateurs !== undefined &&
      this.state.listeIdUtilisateur.length > 0
    ) {
      for (let i = 0; i < this.props.listeUtilisateurs.length; i++) {
        for (let j = 0; j < this.state.listeIdUtilisateur.length; j++) {
          if (
            this.state.listeIdUtilisateur[j] ===
            this.props.listeUtilisateurs[i].user_id
          ) {
            information_utilisateur_liste.push(this.props.listeUtilisateurs[i]);
          }
        }
      }
    }

    return (
      <>
        {listeDeroulanteUtilisateur}
        {/* affiche les utilisateurs sélectionnés avec la possibilité de les retirer individuellement avec une croix*/}
        {Object.keys(information_utilisateur_liste).map(
          (indListeUtilisateurs) => {
            return (
              <div key={indListeUtilisateurs}>
                <span
                  onClick={() =>
                    this.enleverUtilisateur(
                      information_utilisateur_liste[indListeUtilisateurs]
                        .user_id
                    )
                  }
                >
                  {iconeEnlever}
                </span>{' '}
                {information_utilisateur_liste[indListeUtilisateurs].lastname +
                  ', ' +
                  information_utilisateur_liste[indListeUtilisateurs].name +
                  ', mail : ' +
                  (information_utilisateur_liste[indListeUtilisateurs].email
                    ? information_utilisateur_liste[indListeUtilisateurs].email
                    : '')}
              </div>
            );
          }
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    listeUtilisateurs: state.utilisateur.utilisateursListe,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    recupererListeUtilisateurs: () =>
      dispatch(actions.reqRecupererListeUtilisateurs()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormulaireAjoutUtilisateursAProjet);

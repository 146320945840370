import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import axios from 'axios';
import * as actions from './index';
import * as actionTypes from './actionTypes';
import { store } from '../../index';
const affichageRetourErreurs = (dispatch, typeAlerte, titre, res) => {
  if (res.data.errors !== undefined) {
    for (let erreurKey = 0; erreurKey < res.data.errors.length; erreurKey++) {
      dispatch(
        actions.ajouterAlerte(typeAlerte, titre, [
          'ApiEchecErreursEnBoucle',
          { numAvertissement: erreurKey, idErreur: res.data.errors[erreurKey] },
        ])
      );
    }
  } else {
    dispatch(
      actions.ajouterAlerte(
        typeAlerte,
        'Erreur retour api',
        'errors est undefine, pour une requête de connexion'
      )
    );
  }
};
const requetePost = (dispatch, requeteAPI) => {
  /**
   * gère l'envoi du type de requête et du retour de l'api
   * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
   * @param requeteAPI - permet de rendre la requête à l'API
   * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
   */

  console.error(
    requeteAPI.request_type ===
      requestType.recuperation_des_donnees_d_un_graphe &&
      requeteAPI.graph_id === 1921,
    requeteAPI.requestType,
    requeteAPI.graph_id
  );
  if (
    requeteAPI.request_type ===
      requestType.recuperation_des_donnees_d_un_graphe &&
    parseInt(requeteAPI.graph_id) === 1921
  ) {
    fetch(
      "https://argos-v19.itmsol.fr/images/api/10505_Tassement%20des%20points%20situ%C3%A9s%20%C3%A0%20proximit%C3%A9%20de%20l'axe%20du%20tunnel.json"
    )
      .then((response) => response.json())
      .then((data) => {
        // Utilisez les données de votre fichier JSON ici
        dispatch(enregistrerDonneesGraphe(data));
      })
      .catch((error) => {
        console.error(
          'Erreur lors de la récupération du fichier JSON :',
          error
        );
      });
    // throw "Arrêt de la fonction";
  } else {
    const state = store.getState();
    dispatch(actions.debutAttente(requeteAPI.request_type));
    axios
      .post('', requeteAPI, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let titreAlerteSucces = 'Requête api succès';
        let corpsAlerteSucces = 'Requête api succès';
        let titreAlerteEchec = 'Requête api échec';
        let corpsAlerteEchec = 'Requête api échec';
        let succesRequete = {
          alerte() {},
          actionReducteur() {},
          fonctionSupplementaire() {},
        };
        let succesRequeteAvecErreur = {
          alerte() {},
          actionReducteur() {},
          fonctionSupplementaire() {},
        };
        let echecRequete = {
          alerte() {},
          actionReducteur() {},
          fonctionSupplementaire() {},
        };
        switch (requeteAPI.request_type) {
          case requestType.recuperation_de_la_liste_des_graphes:
            titreAlerteSucces = 'ApiSuccesTitre_recuperer_la_liste_des_graphes';
            corpsAlerteSucces = 'ApiSuccesCorps_recuperer_la_liste_des_graphes';
            titreAlerteEchec = 'ApiEchecTitre_recuperer_la_liste_des_graphes';
            corpsAlerteEchec = 'ApiEchecCorps_recuperer_la_liste_des_graphes';

            succesRequete.alerte = () =>
              dispatch(
                actions.ajouterAlerte(
                  'success',
                  titreAlerteSucces,
                  corpsAlerteSucces
                )
              );
            succesRequete.actionReducteur = () =>
              dispatch(enregistrerListeGraphes(res.data));
            // if(res.data.errors.length > 0){
            //     succesRequeteAvecErreur.actionReducteur = () => dispatch(enregistrerListeGraphes(res.data));
            // }
            // if(res.data.state === false){
            //     echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
            //     // echecRequete.actionReducteur = () => dispatch(enregistrerListeGraphes(res.data));
            //     echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
            // }
            break;
          case requestType.recuperer_un_graphe:
            titreAlerteSucces = 'ApiSuccesTitre_recuperation_de_un_graphe';
            corpsAlerteSucces = 'ApiSuccesCorps_recuperation_de_un_graphe';
            titreAlerteEchec = 'ApiEchecTitre_recuperation_de_un_graphe';
            corpsAlerteEchec = 'ApiEchecCorps_recuperation_de_un_graphe';

            // succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces,corpsAlerteSucces));
            succesRequete.actionReducteur = () =>
              dispatch(enregistrerGraphe(res.data));
            // if(res.data.errors.length > 0){
            //     succesRequeteAvecErreur.actionReducteur = () => dispatch(enregistrerListeGraphes(res.data));
            // }
            // if(res.data.state === false){
            //     echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
            //     // echecRequete.actionReducteur = () => dispatch(enregistrerListeGraphes(res.data));
            //     echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
            // }
            break;
          case requestType.recuperation_des_donnees_d_un_graphe:
            titreAlerteSucces =
              'ApiSuccesTitre_recuperation_des_donnees_d_un_graphe';
            corpsAlerteSucces =
              'ApiSuccesCorps_recuperation_des_donnees_d_un_graphe';
            titreAlerteEchec =
              'ApiEchecTitre_recuperation_des_donnees_d_un_graphe';
            corpsAlerteEchec =
              'ApiEchecCorps_recuperation_des_donnees_d_un_graphe';

            // succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces,corpsAlerteSucces));
            succesRequete.actionReducteur = () =>
              dispatch(enregistrerDonneesGraphe(res.data));
            // if(res.data.errors.length > 0){
            //     succesRequeteAvecErreur.actionReducteur = () => dispatch(enregistrerListeGraphes(res.data));
            // }
            // if(res.data.state === false){
            //     echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
            //     // echecRequete.actionReducteur = () => dispatch(enregistrerListeGraphes(res.data));
            //     echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
            // }
            break;
          case requestType.recuperation_des_donnees_d_un_graphe_automatique_xt:
            titreAlerteSucces =
              'ApiSuccesTitre_recuperation_des_donnees_d_un_graphe_automatique_xt';
            corpsAlerteSucces =
              'ApiSuccesCorps_recuperation_des_donnees_d_un_graphe_automatique_xt';
            titreAlerteEchec =
              'ApiEchecTitre_recuperation_des_donnees_d_un_graphe_automatique_xt';
            corpsAlerteEchec =
              'ApiEchecCorps_recuperation_des_donnees_d_un_graphe_automatique_xt';

            succesRequete.actionReducteur = () =>
              dispatch(
                enregistrerDonneesGrapheAutomatiqueXT(
                  res.data,
                  requeteAPI.sensor_id,
                  requeteAPI.position_in_sensor
                )
              );
            break;
          case requestType.supprimer_un_graphe:
            titreAlerteSucces = 'ApiSuccesTitre_supprimer_un_graphe';
            corpsAlerteSucces = 'ApiSuccesCorps_supprimer_un_graphe';
            titreAlerteEchec = 'ApiEchecTitre_supprimer_un_graphe';
            corpsAlerteEchec = 'ApiEchecCorps_supprimer_un_graphe';

            succesRequete.alerte = () =>
              dispatch(
                actions.ajouterAlerte('success', titreAlerteSucces, [
                  corpsAlerteSucces,
                  requeteAPI.graph_id,
                ])
              );

            if (res.data.errors.length > 0) {
              succesRequeteAvecErreur.alerte = () =>
                dispatch(
                  actions.ajouterAlerte('warning', titreAlerteSucces, [
                    corpsAlerteSucces,
                    requeteAPI.graph_id,
                  ])
                );
              succesRequeteAvecErreur.fonctionSupplementaire = () =>
                affichageRetourErreurs(
                  dispatch,
                  'warning',
                  titreAlerteSucces,
                  res
                );
            }
            if (res.data.state === false) {
              echecRequete.alerte = () =>
                dispatch(
                  actions.ajouterAlerte('danger', titreAlerteEchec, [
                    corpsAlerteEchec,
                    res.data.errors.length,
                  ])
                );
              echecRequete.fonctionSupplementaire = () =>
                affichageRetourErreurs(
                  dispatch,
                  'danger',
                  titreAlerteEchec,
                  res
                );
            }
            break;
          case requestType.creer_un_graphe:
            titreAlerteSucces = 'ApiSuccesTitre_creer_un_graphe';
            corpsAlerteSucces = 'ApiSuccesCorps_creer_un_graphe';
            titreAlerteEchec = 'ApiEchecTitre_creer_un_graphe';
            corpsAlerteEchec = 'ApiEchecCorps_creer_un_graphe';

            succesRequete.alerte = () =>
              dispatch(
                actions.ajouterAlerte('success', titreAlerteSucces, [
                  corpsAlerteSucces,
                  res.data.graph_id,
                ])
              );

            if (res.data.errors.length > 0) {
              succesRequeteAvecErreur.alerte = () =>
                dispatch(
                  actions.ajouterAlerte('warning', titreAlerteSucces, [
                    corpsAlerteSucces,
                    res.data.graph_id,
                  ])
                );
              succesRequeteAvecErreur.fonctionSupplementaire = () =>
                affichageRetourErreurs(
                  dispatch,
                  'warning',
                  titreAlerteSucces,
                  res
                );
            }
            if (res.data.state === false) {
              echecRequete.alerte = () =>
                dispatch(
                  actions.ajouterAlerte('danger', titreAlerteEchec, [
                    corpsAlerteEchec,
                    res.data.errors.length,
                  ])
                );
              echecRequete.fonctionSupplementaire = () =>
                affichageRetourErreurs(
                  dispatch,
                  'danger',
                  titreAlerteEchec,
                  res
                );
            }
            break;
          case requestType.modifier_un_graphe:
            titreAlerteSucces = 'ApiSuccesTitre_modifier_un_graphe';
            corpsAlerteSucces = 'ApiSuccesCorps_modifier_un_graphe';
            titreAlerteEchec = 'ApiEchecTitre_modifier_un_graphe';
            corpsAlerteEchec = 'ApiEchecCorps_modifier_un_graphe';

            succesRequete.alerte = () =>
              dispatch(
                actions.ajouterAlerte('success', titreAlerteSucces, [
                  corpsAlerteSucces,
                  requeteAPI.config.graph_id,
                ])
              );
            succesRequete.fonctionSupplementaire = () =>
              dispatch(actions.reqRecupererGraphe(requeteAPI.config.graph_id));

            if (res.data.errors.length > 0) {
              succesRequeteAvecErreur.alerte = () =>
                dispatch(
                  actions.ajouterAlerte('warning', titreAlerteSucces, [
                    corpsAlerteSucces,
                    requeteAPI.graph_id,
                  ])
                );
              succesRequeteAvecErreur.fonctionSupplementaire = () =>
                affichageRetourErreurs(
                  dispatch,
                  'warning',
                  titreAlerteSucces,
                  res
                );
            }
            if (res.data.state === false) {
              echecRequete.alerte = () =>
                dispatch(
                  actions.ajouterAlerte('danger', titreAlerteEchec, [
                    corpsAlerteEchec,
                    res.data.errors.length,
                  ])
                );
              echecRequete.fonctionSupplementaire = () =>
                affichageRetourErreurs(
                  dispatch,
                  'danger',
                  titreAlerteEchec,
                  res
                );
            }
            break;
          case requestType.recuperation_de_la_liste_des_formats_de_dates:
            titreAlerteSucces =
              'ApiSuccesTitre_recuperation_de_la_liste_des_formats_de_dates';
            corpsAlerteSucces =
              'ApiSuccesCorps_recuperation_de_la_liste_des_formats_de_dates';
            titreAlerteEchec =
              'ApiEchecTitre_recuperation_de_la_liste_des_formats_de_dates';
            corpsAlerteEchec =
              'ApiEchecCorps_recuperation_de_la_liste_des_formats_de_dates';

            succesRequete.actionReducteur(
              dispatch(
                enregistrerListeFormatsDeDates(res.data.dates_formats_list)
              )
            );
            succesRequete.alerte = () =>
              dispatch(
                actions.ajouterAlerte(
                  'success',
                  titreAlerteSucces,
                  corpsAlerteSucces
                )
              );

            if (res.data.errors.length > 0) {
              succesRequeteAvecErreur.actionReducteur(
                dispatch(
                  enregistrerListeFormatsDeDates(res.data.dates_formats_list)
                )
              );
              succesRequeteAvecErreur.alerte = () =>
                dispatch(
                  actions.ajouterAlerte('warning', titreAlerteSucces, [
                    corpsAlerteSucces,
                    res.data.errors.length,
                  ])
                );
              succesRequeteAvecErreur.fonctionSupplementaire = () =>
                affichageRetourErreurs(
                  dispatch,
                  'warning',
                  titreAlerteSucces,
                  res
                );
            }
            if (res.data.state === false) {
              echecRequete.alerte = () =>
                dispatch(
                  actions.ajouterAlerte('danger', titreAlerteEchec, [
                    corpsAlerteEchec,
                    res.data.errors.length,
                  ])
                );
              echecRequete.fonctionSupplementaire = () =>
                affichageRetourErreurs(
                  dispatch,
                  'danger',
                  titreAlerteEchec,
                  res
                );
            }
            break;
          default:
            break;
        }
        if (res.data.state) {
          if (res.data.errors.length === 0) {
            succesRequete.actionReducteur();
            succesRequete.alerte();
            succesRequete.fonctionSupplementaire();
          } else {
            succesRequeteAvecErreur.actionReducteur();
            succesRequeteAvecErreur.alerte();
            succesRequeteAvecErreur.fonctionSupplementaire();
          }
        } else {
          echecRequete.actionReducteur();
          echecRequete.alerte();
          echecRequete.fonctionSupplementaire();
        }
        if (
          requeteAPI.request_type ===
          requestType.recuperation_de_la_liste_des_graphes
        ) {
          //teste de rafraîchissement automatique après chaque interval de temps
          // setTimeout(()=>dispatch(actions.reqRecupererListeGraphes()),7000)
        } else {
          //rafraîchie la liste des graphes après chaque requête
        }
        if (
          res.data.state === 1 &&
          (requeteAPI.request_type === requestType.supprimer_un_graphe ||
            requeteAPI.request_type === requestType.modifier_un_graphe ||
            requeteAPI.request_type === requestType.creer_un_graphe)
        ) {
          dispatch(
            actions.reqRecupererListeGraphes(
              state.authentifie.filtre_projects_list_id
            )
          );
        }
      })
      .catch((err) => {
        console.error('Error API', requeteAPI.request_type, ':', err);
        // dispatch(actions.ajouterAlerte("danger", "ApiErreurRequeteTitre", ["ApiErreurRequeteCorps", {request_type: requeteAPI.request_type, messageErreur: err.toString()}]));
      })
      .finally(() => dispatch(actions.finAttente(requeteAPI.request_type)));
  }
};

export const reqRecupererListeGraphes = (listeIdProjets) => {
  return (dispatch) => {
    requetePost(dispatch, {
      request_type: requestType.recuperation_de_la_liste_des_graphes,
      projects_id: listeIdProjets,
    });
  };
};
export const reqSupprimerGraphe = (idGraphe) => {
  return (dispatch) => {
    requetePost(dispatch, {
      request_type: requestType.supprimer_un_graphe,
      graph_id: idGraphe,
    });
  };
};
export const reqRecupererGraphe = (graph_id) => {
  return (dispatch) => {
    requetePost(dispatch, {
      request_type: requestType.recuperer_un_graphe,
      graph_id: graph_id,
    });
  };
};
export const reqRecupererDonneesGraphe = (
  graph_id,
  time_period,
  measures_num,
  select_time,
  time_interval
) => {
  return (dispatch) => {
    requetePost(dispatch, {
      request_type: requestType.recuperation_des_donnees_d_un_graphe,
      graph_id: graph_id,
      time_period: time_period === undefined ? null : time_period,
      measures_num: measures_num === undefined ? null : measures_num,
      select_time: select_time === undefined ? [] : select_time,
      time_interval: time_interval === undefined ? [null, null] : time_interval,
    });
  };
};
export const reqRecupererDonneesGrapheAutomatiqueXT = (
  sensor_id,
  position_in_sensor,
  time_period,
  measures_num,
  select_time,
  time_interval
) => {
  return (dispatch) => {
    requetePost(dispatch, {
      request_type:
        requestType.recuperation_des_donnees_d_un_graphe_automatique_xt,
      sensor_id: sensor_id,
      position_in_sensor: position_in_sensor,
      time_period: time_period === undefined ? null : time_period,
      measures_num: measures_num === undefined ? null : measures_num,
      select_time: select_time === undefined ? [] : select_time,
      time_interval: time_interval === undefined ? [null, null] : time_interval,
    });
  };
};
export const reqCreerGraphe = (formulaireGrapheT_X) => {
  return (dispatch) => {
    requetePost(dispatch, {
      request_type: requestType.creer_un_graphe,
      config: {
        ...formulaireGrapheT_X,
      },
    });
  };
};
export const reqModifierGraphe = (formulaireGrapheT_X) => {
  return (dispatch) => {
    requetePost(dispatch, {
      request_type: requestType.modifier_un_graphe,
      config: {
        ...formulaireGrapheT_X,
      },
    });
  };
};
export const reqRecupererListeFormatsDeDates = () => {
  return (dispatch) => {
    requetePost(dispatch, {
      request_type: requestType.recuperation_de_la_liste_des_formats_de_dates,
    });
  };
};
const enregistrerListeGraphes = (data) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_GRAPHES,
    graphs_list: data.graphs_list,
  };
};
const enregistrerGraphe = (data) => {
  return {
    type: actionTypes.ENREGISTRER_GRAPHE,
    configuration_graphe: data.config,
  };
};
const enregistrerDonneesGraphe = (data) => {
  return { type: actionTypes.ENREGISTRER_DONNEES_GRAPHE, donnees_graphe: data };
};
const enregistrerDonneesGrapheAutomatiqueXT = (
  data,
  sensor_id,
  position_in_sensor
) => {
  return {
    type: actionTypes.ENREGISTRER_DONNEES_GRAPHE_AUTOMATIQUE_XT,
    donnees_graphe: data,
    sensor_id: sensor_id,
    position_in_sensor: position_in_sensor,
  };
};
const enregistrerListeFormatsDeDates = (dates_formats_list) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_FORMATS_DE_DATES,
    dates_formats_list: dates_formats_list,
  };
};

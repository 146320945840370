import { getLanguage, getTranslation } from './translationService';

// Ajoutez une référence pour stocker les fonctions de mise à jour
let updateListeners = [];

/**
 * S'abonner aux changements de langue
 * @param {Function} callback - Fonction à appeler lors d'un changement de langue
 * @returns {Function} - Fonction pour se désabonner
 */
export const subscribeToLanguageChanges = (callback) => {
  updateListeners.push(callback);
  return () => {
    updateListeners = updateListeners.filter(
      (listener) => listener !== callback
    );
  };
};

// Écoutez l'événement de changement de langue global
if (typeof window !== 'undefined') {
  window.addEventListener('languageChange', () => {
    // Notifiez tous les abonnés du changement
    updateListeners.forEach((listener) => listener());
  });
}

/**
 * Fonction qui retourne la traduction d'une phrase selon la langue actuelle
 * @param {string} cle_traduction - Clé de traduction à rechercher
 * @param {string} str - Paramètres optionnels pour les phrases dynamiques
 * @returns {string} - Traduction dans la langue actuelle ou la clé si non trouvée
 */
export const checkPhrase = (cle_traduction, str) => {
  // Récupérer la langue actuelle depuis translationService
  const currentLanguage = getLanguage();

  try {
    // Utiliser getTranslation pour obtenir la traduction
    // Incluons le paramètre str pour les traductions qui nécessitent des variables
    const translation = getTranslation(cle_traduction, currentLanguage);

    // Si la traduction est une fonction, lui passer le paramètre str
    if (typeof translation === 'function') {
      return translation(str);
    }

    // Sinon, retourner directement la traduction (chaîne de caractères)
    return translation;
  } catch (error) {
    console.warn(
      '/!\\',
      cle_traduction,
      " n'existe pas dans le dictionnaire de langues. /!\\"
    );
    return cle_traduction;
  }
};

import axios from 'axios';
import * as actions from './index';
import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import * as actionTypes from './actionTypes';
import { store } from '../../index';
export const reqRecupererListeProjetsMaintenance = (
  start_interval,
  end_interval,
  types_id_list,
  criticity_id_list,
  projects_id
) => {
  return (dispatch) => {
    requetePost(dispatch, {
      request_type: requestType.recuperer_objet_projets_taux_de_pannes,
      start_interval: start_interval,
      end_interval: end_interval,
      types_id_list: types_id_list.map((value) => parseInt(value)),
      criticity_id_list: criticity_id_list.map((value) => parseInt(value)),
      projects_id: projects_id,
    });
  };
};
export const reqRecupererLeTauxDePannesParJour = (
  start_interval,
  end_interval,
  types_id_list,
  criticity_id_list,
  projects_id
) => {
  return (dispatch) => {
    requetePost(dispatch, {
      request_type: requestType.graphe_taux_de_pannes,
      start_interval: start_interval,
      end_interval: end_interval,
      types_id_list: types_id_list.map((value) => parseInt(value)),
      criticity_id_list: criticity_id_list.map((value) => parseInt(value)),
      projects_id: projects_id,
    });
  };
};
export const reqRecupererLaListeDesCapteursEnPanne = (
  start_interval,
  end_interval,
  types_id_list,
  criticity_id_list,
  projects_id
) => {
  return (dispatch) => {
    requetePost(dispatch, {
      request_type: requestType.liste_des_capteurs_defaillants_par_projet,
      start_interval: start_interval,
      end_interval: end_interval,
      types_id_list: types_id_list.map((value) => parseInt(value)),
      criticity_id_list: criticity_id_list.map((value) => parseInt(value)),
      projects_id: projects_id,
    });
  };
};

const requetePost = (dispatch, requeteAPI) => {
  /**
   * gère l'envoi du type de requête et du retour de l'api
   * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
   * @param requeteAPI - permet de rendre la requête à l'API
   * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
   */

  const state = store.getState();
  dispatch(actions.debutAttente(requeteAPI.request_type));
  axios
    .post('', requeteAPI, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      let titreAlerteSucces = 'Requête api succès';
      let corpsAlerteSucces = 'Requête api succès';
      let titreAlerteEchec = 'Requête api échec';
      let corpsAlerteEchec = 'Requête api échec';
      let succesRequete = {
        alerte() {},
        actionReducteur() {},
        fonctionSupplementaire() {},
      };
      let succesRequeteAvecErreur = {
        alerte() {},
        actionReducteur() {},
        fonctionSupplementaire() {},
      };
      let echecRequete = {
        alerte() {},
        actionReducteur() {},
        fonctionSupplementaire() {},
      };

      switch (requeteAPI.request_type) {
        case requestType.recuperer_objet_projets_taux_de_pannes:
          titreAlerteSucces =
            'ApiSuccesTitre_recuperer_objet_projets_taux_de_pannes';
          corpsAlerteSucces =
            'ApiSuccesCorps_recuperer_objet_projets_taux_de_pannes';
          titreAlerteEchec =
            'ApiEchecTitre_recuperer_objet_projets_taux_de_pannes';
          corpsAlerteEchec =
            'ApiEchecCorps_recuperer_objet_projets_taux_de_pannes';
          succesRequete.actionReducteur = () =>
            dispatch(
              enregistrerListeProjetsMaintenance(res.data.projects_list)
            );
          // succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));
          if (res.data.errors.length > 0) {
            succesRequeteAvecErreur.alerte = () =>
              dispatch(
                actions.ajouterAlerte('warning', titreAlerteSucces, [
                  corpsAlerteSucces,
                  res.data.errors.length,
                ])
              );
            succesRequeteAvecErreur.fonctionSupplementaire = () =>
              affichageRetourErreurs(
                dispatch,
                'warning',
                titreAlerteSucces,
                res
              );
          }
          if (res.data.state === false) {
            echecRequete.alerte = () =>
              dispatch(
                actions.ajouterAlerte('danger', titreAlerteEchec, [
                  corpsAlerteEchec,
                  res.data.errors.length,
                ])
              );
            echecRequete.fonctionSupplementaire = () =>
              affichageRetourErreurs(dispatch, 'danger', titreAlerteEchec, res);
          }
          break;
        case requestType.graphe_taux_de_pannes:
          titreAlerteSucces = 'ApiSuccesTitre_graphe_taux_de_pannes';
          corpsAlerteSucces = 'ApiSuccesCorps_graphe_taux_de_pannes';
          titreAlerteEchec = 'ApiEchecTitre_graphe_taux_de_pannes';
          corpsAlerteEchec = 'ApiEchecCorps_graphe_taux_de_pannes';
          succesRequete.actionReducteur = () =>
            dispatch(
              enregistrerListeTauxDePannesParJour(res.data.projects_list)
            );
          succesRequete.alerte = () =>
            dispatch(
              actions.ajouterAlerte(
                'success',
                titreAlerteSucces,
                corpsAlerteSucces
              )
            );
          if (res.data.errors.length > 0) {
            succesRequeteAvecErreur.alerte = () =>
              dispatch(
                actions.ajouterAlerte('warning', titreAlerteSucces, [
                  corpsAlerteSucces,
                  res.data.errors.length,
                ])
              );
            succesRequeteAvecErreur.fonctionSupplementaire = () =>
              affichageRetourErreurs(
                dispatch,
                'warning',
                titreAlerteSucces,
                res
              );
          }
          if (res.data.state === false) {
            echecRequete.alerte = () =>
              dispatch(
                actions.ajouterAlerte('danger', titreAlerteEchec, [
                  corpsAlerteEchec,
                  res.data.errors.length,
                ])
              );
            echecRequete.fonctionSupplementaire = () =>
              affichageRetourErreurs(dispatch, 'danger', titreAlerteEchec, res);
          }
          break;
        case requestType.liste_des_capteurs_defaillants_par_projet:
          titreAlerteSucces =
            'ApiSuccesTitre_liste_des_capteurs_defaillants_par_projet';
          corpsAlerteSucces =
            'ApiSuccesCorps_liste_des_capteurs_defaillants_par_projet';
          titreAlerteEchec =
            'ApiEchecTitre_liste_des_capteurs_defaillants_par_projet';
          corpsAlerteEchec =
            'ApiEchecCorps_liste_des_capteurs_defaillants_par_projet';
          succesRequete.actionReducteur = () =>
            dispatch(enregistrerListeCapteursEnPanne(res.data.sensors_list));
          succesRequete.alerte = () =>
            dispatch(
              actions.ajouterAlerte(
                'success',
                titreAlerteSucces,
                corpsAlerteSucces
              )
            );
          if (res.data.errors.length > 0) {
            succesRequeteAvecErreur.alerte = () =>
              dispatch(
                actions.ajouterAlerte('warning', titreAlerteSucces, [
                  corpsAlerteSucces,
                  res.data.errors.length,
                ])
              );
            succesRequeteAvecErreur.fonctionSupplementaire = () =>
              affichageRetourErreurs(
                dispatch,
                'warning',
                titreAlerteSucces,
                res
              );
          }
          if (res.data.state === false) {
            echecRequete.alerte = () =>
              dispatch(
                actions.ajouterAlerte('danger', titreAlerteEchec, [
                  corpsAlerteEchec,
                  res.data.errors.length,
                ])
              );
            echecRequete.fonctionSupplementaire = () =>
              affichageRetourErreurs(dispatch, 'danger', titreAlerteEchec, res);
          }
          break;
        default:
          break;
      }
      if (res.data.state) {
        if (res.data.errors.length === 0) {
          succesRequete.actionReducteur();
          succesRequete.alerte();
          succesRequete.fonctionSupplementaire();
        } else {
          succesRequeteAvecErreur.actionReducteur();
          succesRequeteAvecErreur.alerte();
          succesRequeteAvecErreur.fonctionSupplementaire();
        }
      } else {
        echecRequete.actionReducteur();
        echecRequete.alerte();
        echecRequete.fonctionSupplementaire();
      }
      if (
        requeteAPI.request_type === requestType.creation_d_un_sensor_type ||
        requeteAPI.request_type === requestType.suppression_d_un_sensor_type ||
        requeteAPI.request_type === requestType.modification_d_un_sensor_type
      ) {
        dispatch(actions.reqRecupererListeSensorType());
      } else if (
        requeteAPI.request_type === requestType.creation_d_un_capteur ||
        requeteAPI.request_type === requestType.supprimer_un_capteur ||
        requeteAPI.request_type === requestType.modification_d_un_capteur
      ) {
        dispatch(
          actions.reqRecupererListeCapteurs(
            state.authentifie.filtre_projects_list_id
          )
        );
      }
      dispatch(actions.finAttente(requeteAPI.request_type));
    })
    .catch((err) => {
      console.error('Error API', requeteAPI.request_type, ':', err);
      // dispatch(actions.ajouterAlerte("danger", "ApiErreurRequeteTitre", ["ApiErreurRequeteCorps", {request_type: requeteAPI.request_type, messageErreur: err.toString()}]));
      // dispatch(actions.finAttente(requeteAPI.request_type));
    })
    .finally(() => dispatch(actions.finAttente(requeteAPI.request_type)));
};
const affichageRetourErreurs = (dispatch, typeAlerte, titre, res) => {
  if (res.data.errors !== undefined) {
    for (let erreurKey = 0; erreurKey < res.data.errors.length; erreurKey++) {
      dispatch(
        actions.ajouterAlerte(typeAlerte, titre, [
          'ApiEchecErreursEnBoucle',
          {
            numAvertissement: erreurKey,
            idErreur: res.data.errors[erreurKey],
          },
        ])
      );
    }
  } else {
    dispatch(
      actions.ajouterAlerte(
        typeAlerte,
        'Erreur retour api',
        'errors est undefine, pour une requête de connexion'
      )
    );
  }
};

const enregistrerListeProjetsMaintenance = (projects_list) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_PROJETS_MAINTENANCE,
    projects_list: projects_list,
  };
};
const enregistrerListeTauxDePannesParJour = (projects_list) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_TAUX_DE_PANNES_PAR_JOUR,
    donnees_pannes: projects_list,
  };
};
const enregistrerListeCapteursEnPanne = (sensors_list) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_CAPTEURS_EN_PANNES,
    sensors_list: sensors_list,
  };
};

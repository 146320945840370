import React, { Component } from 'react';
import * as localeDictionary from 'plotly.js/lib/locales/fr';
import Plot from 'react-plotly.js';
import BibliothequePlotly from '../BibliothequePlotly/BibliothequePlotly';

class GrapheCirculaire extends Component {
  /**
   *
   * @return {JSX.Element}
   */
  state = {
    afficherRangeSlider: true,
    afficherModeBar: false,
  };

  render() {
    let values = [];
    let labels = [];
    let colors = [];

    let showlegend = true;
    if (
      this.props.configuration !== undefined &&
      this.props.configuration.showlegend !== undefined
    ) {
      showlegend = this.props.configuration.showlegend === 1;
    }
    for (let courbe of this.props.donnees) {
      if (courbe.y.length > 0) {
        values.push(courbe.y[courbe.y.length - 1]);
        labels.push(
          courbe.name !== '' ? courbe.name : courbe.sensor_name + courbe.prefix
        );
        colors.push(courbe.color);
      }
    }
    let jsonLayout = {};
    let styleData = {};
    if (this.props.viewobj_json_content !== undefined) {
      if (this.props.viewobj_json_content.layout !== undefined) {
        jsonLayout = this.props.viewobj_json_content.layout;
      }
      if (this.props.viewobj_json_content.styleData !== undefined) {
        styleData = this.props.viewobj_json_content.styleData;
      }
    }

    let data = [
      {
        type: 'pie',
        values: values,
        labels: labels.map((v) => v + '      '),

        marker: {
          colors: colors,
        },
        textinfo: 'label+percent',
        insidetextorientation: 'radial',
        hole: 0.4,
        ...styleData,
      },
    ];
    return (
      <BibliothequePlotly
        data={data}
        layout={{
          title: {
            text:
              this.props.configuration.title +
              '<br>' +
              this.props.configuration.subtitle,
          },
          responsive: true,
          width:
            this.props.dimensionWidth !== undefined
              ? this.props.dimensionWidth
              : window.innerWidth * 0.75, //150
          height:
            this.props.dimensionHeight !== undefined
              ? this.props.dimensionHeight
              : window.innerHeight * 0.85, //165

          showlegend: showlegend,
          legend: {
            x: 1.05, //todo rendre la légende responsive
            y: 1,
            borderwidth: 2,
            entrywidthmode: 'pixels',
            entrywidth: 20,
          },
          modebar: {
            // vertical modebar button layout
            orientation: 'h',
            // for demonstration purposes
            bgcolor: 'rgba(0, 0, 0, 0)',
            color: 'black',
            activecolor: '#f0af00',
          },
          ...jsonLayout,
        }}
        donneesGraphe={this.props.donneesGraphe}
        ouvrirPopupModifierIntervalle={
          this.props.switchAfficherModifierIntervalle
        }
        dimensionWidth={this.props.dimensionWidth}
        dimensionHeight={this.props.dimensionHeight}
        viewobj_json_content={this.props.viewobj_json_content}
        config={{
          locales: { fr: localeDictionary },
          locale: 'fr',
          displayModeBar:
            this.props.viewobj_json_content === undefined ||
            this.props.viewobj_json_content === null
              ? true
              : this.props.viewobj_json_content.length === 0 ||
                  this.props.viewobj_json_content.displayModeBar === true
                ? true
                : false,
          displaylogo: false,
          modeBarButtonsToRemove: [''],
        }}
      />
    );
  }
}

export default GrapheCirculaire;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormulaireSelectionProjetParent from '../Ajouter/FormulaireSelectionProjetParent/FormulaireSelectionProjetParent';
import FormulaireAjoutUtilisateursAProjet from '../Ajouter/FormulaireAjoutUtilisateursAProjet/FormulaireAjoutUtilisateursAProjet';
import AjouterImageProjet from './AjouterImageProjet/AjouterImageProjet';

import AffichageImage from '../../UI/AffichageImage/AffichageImage';
import Bouton from '../../UI/Bouton/Bouton';

import AccesRestreins from '../../../hoc/AccesRestreins/AccesRestreins';
import AttenteRequete from '../../../hoc/AttenteRequete/AttenteRequete';
import Collapse from '../../../hoc/Collapse/Collapse';

import { stringOrEmptyString } from '../../../ressources/functionJS/stringOrEmptyString';
import * as privilege from '../../../ressources/glossaires/glossaire_privileges';
import { IsArrayString } from '../../../ressources/functionJS/IsArrayString';
import * as requestType from '../../../ressources/glossaires/glossaire_requetes';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import * as type_btn from '../../../ressources/glossaires/glossaire_type_de_bouton';
import { confirmeOnClick } from '../../../ressources/functionJS/confirmeOnClick';

import * as actions from '../../../services/actions';

import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import {
  AiFillPicture,
  AiFillEdit,
  AiFillDelete,
  AiOutlineClose,
  AiOutlineCheck,
} from 'react-icons/ai';
import {
  Overlay,
  styImg,
  imgProject,
  hedes,
  text_master,
  text_master_b,
  ConteneurInfo,
  text_master_d,
  text_master_e,
  afficheImage,
  bts,
  taglabel,
} from './ProfilProjet.module.scss';
import PopupSmall from '../../UI/PopupSmall/PopupSmall';

class ProfilProjet extends Component {
  state = {
    info_text_project_id: this.props.infoProjet.project_id,
    info_text_project_name: this.props.infoProjet.project_name,
    info_text_parent_id: this.props.infoProjet.parent_id,
    info_text_geo_barr:
      this.props.infoProjet.geo_barr === null
        ? '[]'
        : this.props.infoProjet.geo_barr.toString(),
    info_text_groupe: this.props.infoProjet.groupe,
    info_text_users_list_id: this.props.infoProjet.users_list_id,
    info_text_description: this.props.infoProjet.description,
    info_text_active: this.props.infoProjet.active,

    afficherModifier: false,
  };

  gererEtatDeCibleName = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };
  gererProjetParentId = (parentId) => {
    this.setState({ ...this.state, info_text_parent_id: parentId });
  };
  gererUtilisateursList = (utilisateurIdList) => {
    this.setState({
      ...this.state,
      info_text_users_list_id: utilisateurIdList,
    });
  };
  afficherModifierProfil = () => {
    this.setState({ afficherModifier: true });
  };
  envoyerModifierProfil = () => {
    const infoModifierProjet = {
      project_id: parseInt(this.props.infoProjet.project_id),
      project_name: stringOrEmptyString(this.state.info_text_project_name),
      parent_id: parseInt(this.state.info_text_parent_id),
      geo_barr: IsArrayString(this.state.info_text_geo_barr, []),
      groupe: stringOrEmptyString(this.state.info_text_groupe),
      users_list_id: this.state.info_text_users_list_id,
      description: stringOrEmptyString(this.state.info_text_description),
      active: parseInt(this.state.info_text_active),
    };
    this.props.reqModifier(infoModifierProjet);
  };
  annulerModificationProfil = () => {
    this.setState({ afficherModifier: false });
  };

  componentDidMount() {
    // todo: importer la table d'utilisateurs (utiliser la requête api)
    this.props.recupererListeUtilisateurs();
  }

  componentDidUpdate(prevProps) {
    if (this.props.infoProjet !== prevProps.infoProjet) {
      this.setState({
        info_text_project_id: this.props.infoProjet.project_id,
        info_text_project_name: this.props.infoProjet.project_name,
        info_text_groupe: this.props.infoProjet.groupe,
        info_text_description: this.props.infoProjet.description,
        info_text_parent_id: this.props.infoProjet.parent_id,
        info_text_project_image: this.props.infoProjet.project_image,
        info_text_active: this.props.infoProjet.active,
        info_text_geo_barr: this.props.infoProjet.geo_barr,

        afficherModifier: false,
      });
    }
  }

  render() {
    /*  récupère la listes des emails du projet sélectionné*/
    let emails_users_liste = [];
    if (
      this.props.infoProjet.users_list_id !== undefined &&
      this.props.listeUtilisateurs !== undefined
    ) {
      // si les deux listes exitent, la boucle s'execute
      for (let i = 0; i < this.props.infoProjet.users_list_id.length; i++) {
        // i : parcours les index de la liste des ids d'utilisateurs associés au projet
        for (let j = 0; j < this.props.listeUtilisateurs.length; j++) {
          // j : parcours les index de la liste des utilisateurs
          if (
            this.props.listeUtilisateurs[j].user_id ===
            this.props.infoProjet.users_list_id[i]
          ) {
            // comparaison des ids sur les deux listes
            emails_users_liste.push(this.props.listeUtilisateurs[j].email); //recupere la valaur de email
          }
        }
      }
    }

    const formulaireModifier = (
      <>
        <div className={[ConteneurInfo].join(' ')}>
          <div className={s.row}>
            <div className={[s.col_md_12, s.text_left].join(' ')}>
              <Collapse ExpandText={checkMot('modifier_info_general')}>
                <div className={s.row}>
                  <div className={s.col_6}>
                    <label className={[taglabel].join(' ')}>
                      {checkMot('nom')}{' '}
                    </label>
                    <input
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      name="info_text_project_name"
                      className={text_master_b}
                      placeholder={this.state.info_text_project_name}
                      value={this.state.info_text_project_name}
                    />
                  </div>
                  <div className={s.col_6}>
                    <label className={[taglabel].join(' ')}>
                      {checkMot('groupe')}
                    </label>
                    <input
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      name="info_text_groupe"
                      className={text_master_b}
                      placeholder={this.state.info_text_groupe}
                      value={this.state.info_text_groupe}
                    />
                  </div>
                  <div className={s.col_12}>
                    <label className={[taglabel].join(' ')}>
                      {checkMot('description')}
                    </label>
                    <input
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      name="info_text_description"
                      className={text_master_e}
                      placeholder={this.state.info_text_description}
                      value={this.state.info_text_description}
                    />
                    {/*todo: afficher la valeur préconfigurer et la liste déroulante*/}
                    {/*<span className={taglabel}>Id du projet parent</span><input onChange={event=>this.gererEtatDeCibleName(event)} name="info_text_parent_id" className={text_parent_id} placeholder={this.state.info_text_parent_id} value={this.state.info_text_parent_id}/>*/}
                  </div>
                  <div className={s.col_12}>
                    <FormulaireSelectionProjetParent
                      gererProjetParentId={this.gererProjetParentId}
                      preconfigurerParentId={this.props.infoProjet.parent_id}
                      projet_id={this.props.infoProjet.project_id}
                    />
                    {/*la liste de sélections d'utilisateurs*/}
                  </div>
                  <div className={s.col_12}>
                    <label className={[taglabel].join(' ')}>
                      {checkMot('liste_utilisateurs')}{' '}
                    </label>
                    <div className={text_master_b}>
                      {emails_users_liste.map((email, index) => (
                        <p key={index}>{email}</p>
                      ))}
                    </div>
                  </div>
                </div>
              </Collapse>
              <Collapse ExpandText={checkMot('modifier_info_avance')}>
                <div className={s.row}>
                  <div className={s.col_12}>
                    <FormulaireAjoutUtilisateursAProjet
                      gererUtilisateursList={this.gererUtilisateursList}
                      preconfigurerUtilisateursListId={
                        this.props.infoProjet.users_list_id
                      }
                    />
                  </div>
                  <div className={s.col_12}>
                    <label className={[taglabel].join(' ')}>
                      {checkMot('image_de_projet_url')}
                    </label>
                    <input
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      name="info_text_project_image"
                      className={text_master_e}
                      placeholder={this.state.info_text_project_image}
                      value={this.state.info_text_project_image}
                    />
                  </div>
                  <div className={s.col_12}>
                    <label className={[taglabel].join(' ')}>
                      {checkMot('etat')}
                    </label>
                    <select
                      value={this.state.info_text_active}
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      name="info_text_active"
                      className={[s.custom_select, s.mr_sm_2].join(' ')}
                    >
                      <option
                        selected={parseInt(this.state.info_text_active)}
                        value={1}
                      >
                        {' '}
                        {checkMot('active')}
                      </option>
                      <option
                        selected={!parseInt(this.state.info_text_active)}
                        value={0}
                      >
                        {checkMot('desactive')}
                      </option>
                    </select>
                  </div>
                  <div className={s.col_12}>
                    <label className={[taglabel].join(' ')}>
                      {checkMot('geo_barriere')}
                    </label>
                    <input
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      name="info_text_geo_barr"
                      className={text_master_e}
                      placeholder={this.state.info_text_geo_barr}
                      value={this.state.info_text_geo_barr}
                    />
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.col_6}>
              <Bouton
                cliquer={this.annulerModificationProfil}
                ajoutClass={bts}
                type={type_btn.danger}
              >
                <AiOutlineClose /> {checkMot('annuler')}
              </Bouton>
            </div>
            <div className={[s.col_6, s.text_left].join('')}>
              {/*todo: mettre dans la fonction pour la requête les informations à envoyer pour la modifiaction*/}
              <AttenteRequete
                request_type={requestType.modification_d_un_projet}
              >
                <Bouton
                  cliquer={this.envoyerModifierProfil}
                  ajoutClass={bts}
                  type={type_btn.succes}
                >
                  <AiOutlineCheck /> {checkMot('enregistre')}
                </Bouton>
              </AttenteRequete>
            </div>
          </div>
        </div>
      </>
    );

    // const customStyles ={
    //     content : {
    //         width:'50%',
    //         height:'40%',
    //         top: '5%',
    //         left: '30%',
    //         right: '20%',
    //         bottom : '20%',
    //         zIndex: "1000",
    //         // marginRight           : '-50%',
    //         // transform             : 'translate(-50%, -50%)'
    //     }
    // }
    const profil = (
      <>
        <div className={s.row}>
          <div className={[s.col_md_12, s.text_left].join(' ')}>
            <div className={[imgProject, s.text_center].join(' ')}>
              {' '}
              {this.props.infoProjet.project_image !== null ? (
                <AffichageImage
                  ajoutClass={afficheImage}
                  src={this.props.infoProjet.project_image}
                  alt={'project_image'}
                />
              ) : (
                <div>aucune image de projet</div>
              )}
            </div>
            <Collapse ExpandText={checkMot('info_general')}>
              <div className={s.row}>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('nom')}
                  </label>
                  <div className={text_master}>
                    {this.props.infoProjet.project_name}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('groupe')}
                  </label>
                  <div className={text_master}>
                    {this.props.infoProjet.groupe}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('description')}
                  </label>
                  <div className={text_master_d}>
                    {this.props.infoProjet.description}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('id_du_projet_parent')}
                  </label>
                  <div className={text_master}>
                    {this.props.infoProjet.parent_id !== null
                      ? this.props.infoProjet.parent_id
                      : 'Projet Principal'}
                  </div>
                </div>
              </div>
            </Collapse>
            <Collapse ExpandText={checkMot('info_avance')}>
              <div className={s.row}>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('image_de_projet_url')}
                  </label>
                  <div className={text_master}>
                    {this.props.infoProjet.project_image}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('etat')}
                  </label>
                  <div className={text_master}>
                    {this.props.infoProjet.active ? 'Active' : 'Desactive'}
                  </div>
                </div>
                <div className={s.col_12}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('geo_barriere')}
                  </label>
                  <div className={text_master_d}>
                    {this.props.infoProjet.geo_barr === null
                      ? '[]'
                      : this.props.infoProjet.geo_barr.toString()}
                  </div>
                </div>
                <div className={s.col_12}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('liste_utilisateurs')}
                  </label>
                  <div className={text_master_d}>
                    {emails_users_liste.map((email, index) => (
                      <p key={index}>{email}</p>
                    ))}
                  </div>
                </div>
              </div>
            </Collapse>
            <div className={s.row}>
              <div className={[s.text_left, styImg].join(' ')}>
                <AccesRestreins numPrivilege={privilege.modifier_des_projets}>
                  {/*<Bouton cliquer={this.handleOpenModal}> <AiFillPicture/> {checkMot("ajouter_une_image")}</Bouton>*/}

                  <PopupSmall
                    texte_ouverture={checkMot('ajouter_une_image')}
                    icone={<AiFillPicture />}
                    ajoutOverlay={Overlay}
                  >
                    <AjouterImageProjet
                      project_id={this.props.infoProjet.project_id}
                      ajouterImageProjet={this.props.ajouterImageProjet}
                    />
                  </PopupSmall>
                  {/*<Modal isOpen={this.state.showModal}  className={Modale} overlayClassName={Overlay} onRequestClose={this.handleCloseModal}>*/}
                  {/*</Modal>*/}
                </AccesRestreins>
              </div>

              <div className={s.text_left}>
                <AccesRestreins numPrivilege={privilege.modifier_des_projets}>
                  <Bouton
                    cliquer={this.afficherModifierProfil}
                    ajoutClass={bts}
                  >
                    <AiFillEdit /> {checkMot('modifier')}
                  </Bouton>
                </AccesRestreins>
              </div>
              <div className={s.text_left}>
                <AccesRestreins numPrivilege={privilege.supprimer_des_projets}>
                  <AttenteRequete
                    request_type={requestType.supprimer_un_projet}
                  >
                    <Bouton
                      cliquer={() =>
                        confirmeOnClick(
                          checkMot(
                            'voulez_vous_vraiment_supprimer_ce_utilisateur'
                          ),
                          () =>
                            this.props.reqSupprimer(
                              this.state.info_text_project_id
                            )
                        )
                      }
                      ajoutClass={bts}
                    >
                      <AiFillDelete /> {checkMot('supprimer')}
                    </Bouton>
                  </AttenteRequete>
                </AccesRestreins>
              </div>
            </div>
          </div>
        </div>
      </>
    );
    return (
      <div className={ConteneurInfo}>
        <div className={[s.card_header, hedes].join(' ')}>
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            className="bi bi-info-circle-fill"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
            />
          </svg>
          {checkMot('projet_id')}: {this.props.infoProjet.project_id}
        </div>
        <div className={s.container}>
          {this.state.afficherModifier ? formulaireModifier : profil}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listeUtilisateurs: state.utilisateur.utilisateursListe,
    listeProjets: state.authentifie.projects_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    recupererListeUtilisateurs: () =>
      dispatch(actions.reqRecupererListeUtilisateurs()),
    ajouterImageProjet: (idProjet, base64_img) =>
      dispatch(actions.reqAjouterImageProjet(idProjet, base64_img)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilProjet);

import React from 'react';
import { connect } from 'react-redux';

import Bouton from '../../../../../UI/Bouton/Bouton';

import * as actions from '../../../../../../services/actions';
import AttenteRequete from '../../../../../../hoc/AttenteRequete/AttenteRequete';

import * as requestType from '../../../../../../ressources/glossaires/glossaire_requetes';
import { RiPushpinLine } from 'react-icons/ri';
import { checkMot } from '../../../../../../ressources/functionJS/checkMot';
import S from './AjouterProjetFavori.module.scss';
const AjouterProjetFavori = (props) => (
  <AttenteRequete
    request_type={requestType.rajouter_un_projet_en_favori}
    str={checkMot('ajout_du_projet_favori_en_cour')}
  >
    <Bouton
      ajoutClass={S.style}
      cliquer={() => props.reqAjouter(parseInt(props.project_id))}
    >
      {/* <AiOutlineStar size={14} color={'#eeb214'}/> */}
      <RiPushpinLine size={14} color={'#eeb214'} />
    </Bouton>
  </AttenteRequete>
);
const mapDispatchToProps = (dispatch) => {
  return {
    reqAjouter: (project_id) =>
      dispatch(actions.reqAjouterProjetFavori(project_id)),
  };
};
export default connect(null, mapDispatchToProps)(AjouterProjetFavori);

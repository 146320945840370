import React, { Component } from 'react';
import { connect } from 'react-redux';

import TableauSensorType from './TableauSensorType/TableauSensorType';
import ProfilSensorType from './ProfilSensorType/ProfilSensorType';
import AjouterSensorType from './Ajouter/AjouterSensorType';
import AccesRestreins from '../../../hoc/AccesRestreins/AccesRestreins';

import * as privilege from '../../../ressources/glossaires/glossaire_privileges';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import * as actions from '../../../services/actions';

import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import { RiAddCircleLine } from 'react-icons/ri';
import { btadd } from './SensorType.module.scss';

class SensorType extends Component {
  state = {
    InfoSelect: {},
    showCreer: false,
  };
  selectioneLigne = (ligne) => {
    this.setState({ ...this.state, InfoSelect: ligne });
  };

  operation() {
    this.setState({
      showCreer: true,
    });
  }

  render() {
    return (
      <div className={s.row}>
        <div className={s.col_sm_6}>
          <h4>{checkMot('liste_des_sensors_types')}</h4>
          <AccesRestreins
            listNumPrivilege={[
              privilege.creer_des_sensor_types,
              privilege.modifier_des_sensor_types,
              privilege.supprimer_des_sensor_types,
              privilege.recuperer_list_sensor_type,
            ]}
          >
            <TableauSensorType selectioneLigne={this.selectioneLigne} />
          </AccesRestreins>
          <AccesRestreins numPrivilege={privilege.creer_des_sensor_types}>
            <button onClick={() => this.operation()} className={btadd}>
              <RiAddCircleLine size={24} />
              {checkMot('ajouter_sensor_types')}
            </button>
          </AccesRestreins>
        </div>
        <div className={s.col_sm_6}>
          {this.state.showCreer ? (
            <span style={{ display: 'block' }}>
              <AjouterSensorType
                reqAjouter={this.props.reqAjouter}
                annuler={() =>
                  this.setState({ ...this.state, showCreer: false })
                }
              />
            </span>
          ) : (
            <ProfilSensorType
              InfoSelect={this.state.InfoSelect}
              reqModifier={this.props.reqModifier}
              reqSupprimer={this.props.reqSupprimer}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reqAjouter: (sensorTypeName, sensor_type_display) =>
      dispatch(
        actions.reqAjouterSensorType(sensorTypeName, sensor_type_display)
      ),
    reqModifier: (idSensorType, sensor_type_name, sensor_type_display) =>
      dispatch(
        actions.reqModifierSensorType(
          idSensorType,
          sensor_type_name,
          sensor_type_display
        )
      ),
    reqSupprimer: (sensorTypeName) =>
      dispatch(actions.reqSupprimerSensorType(sensorTypeName)),
  };
};

export default connect(null, mapDispatchToProps)(SensorType);

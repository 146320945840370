import exportFromJSON from 'export-from-json';
import React, { Component } from 'react';

import Bouton from '../../UI/Bouton/Bouton';
import FormulaireSelectionEmailgroup from '../Ajouter/FormulaireSelectionEmailgroup/FormulaireSelectionEmailgroup';
import FormulaireSelectionImportFilter from '../Ajouter/FormulaireSelectionImportFilter/FormulaireSelectionImportFilter';
import FormulaireSelectionProjet from '../Ajouter/FormulaireSelectionProjet/FormulaireSelectionProjet';
import FormulaireSelectionSensorType from '../Ajouter/FormulaireSelectionSensorType/FormulaireSelectionSensorType';
import ModifierComposante from './TableauComposante/ModifierComposante/ModifierComposante';
import TableauComposante from './TableauComposante/TableauComposante';

import AccesRestreins from '../../../hoc/AccesRestreins/AccesRestreins';
import AttenteRequete from '../../../hoc/AttenteRequete/AttenteRequete';
import Collapse from '../../../hoc/Collapse/Collapse';

import { AiOutlineCopy } from 'react-icons/ai';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import {
  MdClose,
  MdDone,
  MdModeEdit,
  MdVerticalAlignBottom,
} from 'react-icons/md';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import { confirmeOnClick } from '../../../ressources/functionJS/confirmeOnClick';
import { stringOrEmptyString } from '../../../ressources/functionJS/stringOrEmptyString';
import * as privilege from '../../../ressources/glossaires/glossaire_privileges';
import * as requestType from '../../../ressources/glossaires/glossaire_requetes';
import { glossaire_time_zone } from '../../../ressources/glossaires/glossaire_time_zones';
import * as type_btn from '../../../ressources/glossaires/glossaire_type_de_bouton';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import {
  bts,
  capteurActive,
  composanteCapteur,
  ConteneurInfo,
  hedes,
  newStyle,
  sty,
  taglabel,
  text_general,
  text_general_b,
} from './ProfilCapteur.module.scss';

class ProfilCapteur extends Component {
  state = {
    info_text_sensor_id: this.props.infoCapteur.sensor_id,
    info_text_project_id: this.props.infoCapteur.project_id,
    info_text_type_id: this.props.infoCapteur.type_id,
    info_text_sensor_name: this.props.infoCapteur.sensor_name,
    info_text_sensors_group: this.props.infoCapteur.sensors_group,
    info_text_remarks: this.props.infoCapteur.remarks,
    info_text_format_date: this.props.infoCapteur.format_date,
    info_text_path_ascii_file: this.props.infoCapteur.path_ascii_file,
    info_text_position: this.props.infoCapteur.position,
    info_text_import_filter_id: this.props.infoCapteur.import_filter_id,
    info_text_emailgroup_id: this.props.infoCapteur.emailgroup_id,
    info_text_active: this.props.infoCapteur.active,
    info_text_sensor_projection: this.props.infoCapteur.sensor_projection,
    info_text_coordinate_x: this.props.infoCapteur.coordinate_x,
    info_text_coordinate_y: this.props.infoCapteur.coordinate_y,
    info_text_coordinate_z: this.props.infoCapteur.coordinate_z,
    info_text_time_zone: this.props.infoCapteur.time_zone,
    info_text_watchdog: this.props.infoCapteur.watchdog,
    info_text_criticite_id: this.props.infoCapteur.criticite_id,
    info_text_component_list: this.props.infoCapteur.component_list,
    afficherDupliqueProfil: false,
    afficherModifier: false,
  };

  gererEtatDeCibleName = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };
  gererComposantes = (component_list) => {
    this.setState({ ...this.state, info_text_component_list: component_list });
  };
  gererTypeId = (typeId) => {
    this.setState({ ...this.state, info_text_type_id: typeId });
  };
  gererEmailgroupId = (emailgroupId) => {
    this.setState({ ...this.state, info_text_emailgroup_id: emailgroupId });
  };
  gererImportFilterId = (importFilterId) => {
    this.setState({
      ...this.state,
      info_text_import_filter_id: importFilterId,
    });
  };
  gererProjetId = (projetId) => {
    this.setState({ ...this.state, info_text_project_id: projetId });
  };
  annulerModifierProfil = () => {
    this.setState({ afficherModifier: false });
  };
  annulerDupliqueProfil = () => {
    this.setState({ afficherDupliqueProfil: false });
  };
  afficherModifierProfil = () => {
    this.setState({ afficherModifier: true });
  };
  afficherDupliqueProfil = () => {
    this.setState({ afficherDupliqueProfil: true });
  };
  exporterConfiguration = () => {
    //exporte sous fichier CSV les capteurs sélectionné
    const data = this.props.infoCapteur;
    const fileName =
      this.props.infoCapteur.project_id +
      '-' +
      this.props.infoCapteur.sensor_name;
    const exportType = 'json';
    exportFromJSON({ data, fileName, exportType });
  };
  enregistrerModifierProfil = () => {
    const formulaireModifierCapteur = {
      sensor_id: parseInt(this.state.info_text_sensor_id),
      project_id: parseInt(this.state.info_text_project_id),
      type_id: parseInt(this.state.info_text_type_id),
      sensor_name: stringOrEmptyString(this.state.info_text_sensor_name),
      sensors_group: stringOrEmptyString(this.state.info_text_sensors_group),
      remarks: stringOrEmptyString(this.state.info_text_remarks),
      format_date: stringOrEmptyString(this.state.info_text_format_date),
      path_ascii_file: stringOrEmptyString(
        this.state.info_text_path_ascii_file
      ),
      position: stringOrEmptyString(this.state.info_text_position + ''), // +"" -> si le back renvoi un intéger
      import_filter_id: parseInt(this.state.info_text_import_filter_id),
      emailgroup_id: parseInt(this.state.info_text_emailgroup_id),
      active: parseInt(this.state.info_text_active),
      sensor_projection: parseInt(this.state.info_text_sensor_projection),
      coordinate_x: parseFloat(this.state.info_text_coordinate_x),
      coordinate_y: parseFloat(this.state.info_text_coordinate_y),
      coordinate_z: parseFloat(this.state.info_text_coordinate_z),
      time_zone: this.state.info_text_time_zone,
      watchdog: parseInt(this.state.info_text_watchdog),
      criticite_id: parseInt(this.state.info_text_criticite_id),
      component_list: this.state.info_text_component_list,
    };
    this.props.reqModifier(formulaireModifierCapteur);
  };

  enregistrerDupliqueProfil = () => {
    const formulaireDupliqueCapteur = {
      project_id: parseInt(this.state.info_text_project_id),
      type_id: parseInt(this.state.info_text_type_id),
      sensor_name: stringOrEmptyString(
        'Capteur duplique ' + this.state.info_text_sensor_name
      ),
      sensors_group: stringOrEmptyString(this.state.info_text_sensors_group),
      remarks: stringOrEmptyString(this.state.info_text_remarks),
      format_date: stringOrEmptyString(this.state.info_text_format_date),
      path_ascii_file: stringOrEmptyString(
        this.state.info_text_path_ascii_file
      ),
      position: stringOrEmptyString(this.state.info_text_position + ''), // +"" -> si le back renvoi un intéger
      import_filter_id: parseInt(this.state.info_text_import_filter_id),
      emailgroup_id: parseInt(this.state.info_text_emailgroup_id),
      active: parseInt(this.state.info_text_active),
      sensor_projection: parseInt(this.state.info_text_sensor_projection),
      coordinate_x: parseFloat(this.state.info_text_coordinate_x),
      coordinate_y: parseFloat(this.state.info_text_coordinate_y),
      coordinate_z: parseFloat(this.state.info_text_coordinate_z),
      time_zone: this.state.info_text_time_zone,
      watchdog: parseInt(this.state.info_text_watchdog),
      criticite_id: parseInt(this.state.info_text_criticite_id),
      component_list: this.state.info_text_component_list,
    };
    this.props.ajouter(formulaireDupliqueCapteur);
  };

  componentDidUpdate(prevProps) {
    if (this.props.infoCapteur !== prevProps.infoCapteur) {
      this.setState({
        info_text_sensor_id: this.props.infoCapteur.sensor_id,
        info_text_project_id: this.props.infoCapteur.project_id,
        info_text_type_id: this.props.infoCapteur.type_id,
        info_text_sensor_name: this.props.infoCapteur.sensor_name,
        info_text_sensors_group: this.props.infoCapteur.sensors_group,
        info_text_remarks: this.props.infoCapteur.remarks,
        info_text_format_date: this.props.infoCapteur.format_date,
        info_text_path_ascii_file: this.props.infoCapteur.path_ascii_file,
        info_text_position: this.props.infoCapteur.position,
        info_text_import_filter_id: this.props.infoCapteur.import_filter_id,
        info_text_emailgroup_id: this.props.infoCapteur.emailgroup_id,
        info_text_active: this.props.infoCapteur.active,
        info_text_sensor_projection: this.props.infoCapteur.sensor_projection,
        info_text_coordinate_x: this.props.infoCapteur.coordinate_x,
        info_text_coordinate_y: this.props.infoCapteur.coordinate_y,
        info_text_coordinate_z: this.props.infoCapteur.coordinate_z,
        info_text_time_zone: this.props.infoCapteur.time_zone,
        info_text_watchdog: this.props.infoCapteur.watchdog,
        info_text_criticite_id: this.props.infoCapteur.criticite_id,
        info_text_component_list: this.props.infoCapteur.component_list,

        afficherModifier: false,
      });
    }
  }

  render() {
    const formulaireModifierCapteur = (
      <>
        <div className={s.row}>
          <div className={[s.col_12, s.text_left].join(' ')}>
            <Collapse ExpandText={checkMot('modifier_info_general')}>
              <div className={s.row}>
                <div className={s.col_12}>
                  <FormulaireSelectionProjet
                    gererProjetId={this.gererProjetId}
                    preconfigurerProjetId={this.props.infoCapteur.project_id}
                  />
                </div>
                <div className={s.col_12}>
                  <FormulaireSelectionSensorType
                    gererSensorTypeId={this.gererTypeId}
                    preconfigurerSensorTypeId={this.props.infoCapteur.type_id}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('nom')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_sensor_name"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.sensor_name}
                    value={this.state.info_text_sensor_name}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('groupe_de_capteurs')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_sensors_group"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.sensors_group}
                    value={this.state.info_text_sensors_group}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('remarques')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_remarks"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.remarks}
                    value={this.state.info_text_remarks}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('format_date')}
                  </label>

                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_format_date"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.format_date}
                    value={this.state.info_text_format_date}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('chemin_fichier_ascii')}
                  </label>

                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_path_ascii_file"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.path_ascii_file}
                    value={this.state.info_text_path_ascii_file}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('position')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_position"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.position}
                    value={this.state.info_text_position}
                  />
                </div>
              </div>
            </Collapse>
            <Collapse ExpandText={checkMot('modifier_info_avance')}>
              <div className={s.row}>
                <div className={s.col_12}>
                  <FormulaireSelectionImportFilter
                    gererImportFilterId={this.gererImportFilterId}
                    preconfigurerImportFilterId={
                      this.props.infoCapteur.import_filter_id
                    }
                  />
                </div>
                <div className={s.col_12}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('id_du_groupe')}
                  </label>
                  <FormulaireSelectionEmailgroup
                    gererEmailgroupId={this.gererEmailgroupId}
                    preconfigurerEmailgroupId={
                      this.props.infoCapteur.emailgroup_id
                    }
                  />
                </div>
                <div className={s.col_12}>
                  <div className={capteurActive}>
                    <label className={[taglabel].join(' ')}>
                      {checkMot('activite_au_capteur')}
                    </label>
                    <select
                      value={this.state.info_text_active}
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      name="info_text_active"
                      className={[s.custom_select, s.mr_sm_2].join(' ')}
                    >
                      <option selected={this.state.info_text_active} value={1}>
                        {checkMot('active')}
                      </option>
                      <option selected={!this.state.info_text_active} value={0}>
                        {checkMot('desactive')}
                      </option>
                    </select>
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('repere_du_capteur')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_sensor_projection"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.sensor_projection}
                    value={this.state.info_text_sensor_projection}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('coordonnee_x')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_coordinate_x"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.coordinate_x}
                    value={this.state.info_text_coordinate_x}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('coordonnee_y')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_coordinate_y"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.coordinate_y}
                    value={this.state.info_text_coordinate_y}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('coordonnee_z')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_coordinate_z"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.coordinate_z}
                    value={this.state.info_text_coordinate_z}
                  />
                </div>
                <div className={s.col_12}>
                  <div className={capteurActive}>
                    <label className={[taglabel].join(' ')}>
                      {checkMot('time_zone')}
                    </label>
                    <select
                      value={this.state.info_text_time_zone}
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      name="info_text_time_zone"
                      className={[s.custom_select, s.mr_sm_2].join(' ')}
                    >
                      {glossaire_time_zone.map((timezone) => (
                        <option
                          selected={this.state.info_text_time_zone}
                          value={timezone}
                        >
                          {timezone}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('watchdog')} {checkMot('(en minutes)')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_watchdog"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.watchdog}
                    value={this.state.info_text_watchdog}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('criticite_id')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_criticite_id"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.criticite_id}
                    value={this.state.info_text_criticite_id}
                  />
                </div>
              </div>
            </Collapse>

            <div className={composanteCapteur}>
              <Collapse ExpandText={checkMot('tableau_de_composant')}>
                <ModifierComposante
                  sensor_id={this.props.infoCapteur.sensor_id}
                  component_list={this.props.infoCapteur.component_list}
                  gererComposantes={this.gererComposantes}
                />
              </Collapse>
            </div>
          </div>
        </div>
        <div className={s.row}>
          <div className={s.col_md_6}>
            <Bouton
              cliquer={this.annulerModifierProfil}
              ajoutClass={bts}
              type={type_btn.danger}
            >
              <MdClose /> {checkMot('annuler')}
            </Bouton>
          </div>
          <div className={[s.col_md_6, s.text_left].join('')}>
            <AttenteRequete
              request_type={requestType.modification_d_un_capteur}
              str={checkMot('modification_en_cours')}
            >
              <Bouton
                cliquer={this.enregistrerModifierProfil}
                ajoutClass={bts}
                type={type_btn.succes}
              >
                <MdDone /> {checkMot('enregistre')}
              </Bouton>
            </AttenteRequete>
          </div>
        </div>
      </>
    );
    const formulaireDupliqueCapteur = (
      <>
        <div className={s.row}>
          <div className={[s.col_12, s.text_left].join(' ')}>
            <Collapse ExpandText={checkMot('modifier_info_general')}>
              <div className={s.row}>
                <div className={s.col_12}>
                  <FormulaireSelectionProjet
                    gererProjetId={this.gererProjetId}
                    preconfigurerProjetId={this.props.infoCapteur.project_id}
                  />
                </div>
                <div className={s.col_12}>
                  <FormulaireSelectionSensorType
                    gererSensorTypeId={this.gererTypeId}
                    preconfigurerSensorTypeId={this.props.infoCapteur.type_id}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('nom')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_sensor_name"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.sensor_name}
                    value={this.state.info_text_sensor_name}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('groupe_de_capteurs')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_sensors_group"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.sensors_group}
                    value={this.state.info_text_sensors_group}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('remarques')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_remarks"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.remarks}
                    value={this.state.info_text_remarks}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('format_date')}
                  </label>

                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_format_date"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.format_date}
                    value={this.state.info_text_format_date}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('chemin_fichier_ascii')}
                  </label>

                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_path_ascii_file"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.path_ascii_file}
                    value={this.state.info_text_path_ascii_file}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('position')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_position"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.position}
                    value={this.state.info_text_position}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('watchdog')}
                    {checkMot('(en minutes)')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="watchdog"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.position}
                    value={this.state.info_text_position}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('criticite_id')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="criticite_id"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.position}
                    value={this.state.info_text_position}
                  />
                </div>
              </div>
            </Collapse>
            <Collapse ExpandText={checkMot('modifier_info_avance')}>
              <div className={s.row}>
                <div className={s.col_12}>
                  <FormulaireSelectionImportFilter
                    gererImportFilterId={this.gererImportFilterId}
                    preconfigurerImportFilterId={
                      this.props.infoCapteur.import_filter_id
                    }
                  />
                </div>
                <div className={s.col_12}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('id_du_groupe')}
                  </label>
                  <FormulaireSelectionEmailgroup
                    gererEmailgroupId={this.gererEmailgroupId}
                    preconfigurerEmailgroupId={
                      this.props.infoCapteur.emailgroup_id
                    }
                  />
                </div>
                <div className={s.col_12}>
                  <div className={capteurActive}>
                    <label className={[taglabel].join(' ')}>
                      {checkMot('activite_au_capteur')}
                    </label>
                    <select
                      value={this.state.info_text_active}
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      name="info_text_active"
                      className={[s.custom_select, s.mr_sm_2].join(' ')}
                    >
                      <option selected={this.state.info_text_active} value={1}>
                        {checkMot('active')}
                      </option>
                      <option selected={!this.state.info_text_active} value={0}>
                        {checkMot('desactive')}
                      </option>
                    </select>
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('repere_du_capteur')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_sensor_projection"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.sensor_projection}
                    value={this.state.info_text_sensor_projection}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('coordonnee_x')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_coordinate_x"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.coordinate_x}
                    value={this.state.info_text_coordinate_x}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('coordonnee_y')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_coordinate_y"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.coordinate_y}
                    value={this.state.info_text_coordinate_y}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('coordonnee_z')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_coordinate_z"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.coordinate_z}
                    value={this.state.info_text_coordinate_z}
                  />
                </div>
                <div className={s.col_12}>
                  <div className={capteurActive}>
                    <label className={[taglabel].join(' ')}>
                      {checkMot('time_zone')}
                    </label>
                    <input
                      list="timezones"
                      value={this.state.time_zone}
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      name="time_zone"
                      className={text_general}
                    />

                    <datalist id="timezones">
                      <option disabled={true} selected={true}>
                        Selecione um fuso horário
                      </option>
                      {glossaire_time_zone.map((timezone) => (
                        <option
                          value={timezone}
                          selected={this.state.time_zone === timezone}
                        >
                          {timezone}
                        </option>
                      ))}
                    </datalist>
                    {/* <select value={this.state.info_text_time_zone}
                                            onChange={(event) => this.gererEtatDeCibleName(event)}
                                            name="info_text_time_zone"
                                            className={[s.custom_select, s.mr_sm_2].join(" ")}>
                                        {
                                            glossaire_time_zone.map(timezone =>
                                                <option selected={this.state.info_text_time_zone}
                                                        value={timezone}>{timezone}</option>
                                            )
                                        }
                                    </select> */}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('watchdog')} {checkMot('(en minutes)')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_watchdog"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.watchdog}
                    value={this.state.info_text_watchdog}
                  />
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {checkMot('criticite_id')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="info_text_criticite_id"
                    className={text_general_b}
                    placeholder={this.props.infoCapteur.criticite_id}
                    value={this.state.info_text_criticite_id}
                  />
                </div>
              </div>
            </Collapse>

            <div className={composanteCapteur}>
              <Collapse ExpandText={checkMot('tableau_de_composant')}>
                <ModifierComposante
                  // sensor_id={this.props.infoCapteur.sensor_id}
                  component_list={this.props.infoCapteur.component_list}
                  gererComposantes={this.gererComposantes}
                />
              </Collapse>
            </div>
          </div>
        </div>
        <div className={s.row}>
          <div className={s.col_md_6}>
            <Bouton
              cliquer={this.annulerDupliqueProfil}
              ajoutClass={bts}
              type={type_btn.danger}
            >
              <MdClose /> {checkMot('annuler')}
            </Bouton>
          </div>
          <div className={[s.col_md_6, s.text_left].join('')}>
            <AttenteRequete
              request_type={requestType.modification_d_un_capteur}
              str={checkMot('modification_en_cours')}
            >
              <Bouton
                cliquer={this.enregistrerDupliqueProfil}
                ajoutClass={bts}
                type={type_btn.succes}
              >
                <MdDone /> {checkMot('creer')}
              </Bouton>
            </AttenteRequete>
          </div>
        </div>
      </>
    );
    const profil = (
      <>
        <div className={s.row}>
          <div className={[s.col_xl_12, s.text_left].join(' ')}>
            <Collapse ExpandText={checkMot('info_general')}>
              <div className={s.row}>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('projet_id')}
                  </label>
                  <div className={[text_general].join(' ')}>
                    {this.props.infoCapteur.project_id}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('id_type')}
                  </label>
                  <div className={text_general}>
                    {this.props.infoCapteur.type_id}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('nom')}
                  </label>
                  <div className={text_general}>
                    {this.props.infoCapteur.sensor_name}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('groupe_de_capteurs')}
                  </label>
                  <div className={text_general}>
                    {this.props.infoCapteur.sensors_group}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('format_date')}
                  </label>
                  <div className={text_general}>
                    {this.props.infoCapteur.format_date}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('remarques')}
                  </label>
                  <div className={text_general}>
                    {this.props.infoCapteur.remarks}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('chemin_fichier_ascii')}
                  </label>
                  <div className={text_general}>
                    {this.props.infoCapteur.path_ascii_file}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('position')}
                  </label>
                  <div className={text_general}>
                    {this.props.infoCapteur.position}
                  </div>
                </div>
              </div>
            </Collapse>
            <Collapse ExpandText={checkMot('info_avance')}>
              <div className={s.row}>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('filtre_importation_id')}
                  </label>
                  <div className={text_general}>
                    {this.props.infoCapteur.import_filter_id}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('id_du_groupe')}
                  </label>
                  <div className={text_general}>
                    {this.props.infoCapteur.emailgroup_id}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('activite_au_capteur')}
                  </label>
                  <div className={text_general}>
                    {this.props.infoCapteur.active
                      ? checkMot('active')
                      : checkMot('desactive')}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('repere_du_capteur')}
                  </label>
                  <div className={text_general}>
                    {this.props.infoCapteur.sensor_projection}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('coordonnee_x')}
                  </label>
                  <div className={text_general}>
                    {this.props.infoCapteur.coordinate_x}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('coordonnee_y')}
                  </label>
                  <div className={text_general}>
                    {this.props.infoCapteur.coordinate_y}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('coordonnee_z')}
                  </label>
                  <div className={text_general}>
                    {this.props.infoCapteur.coordinate_z}
                  </div>
                </div>
                <div className={s.col_12}>
                  <div className={capteurActive}>
                    <label className={[taglabel].join(' ')}>
                      {checkMot('time_zone')}
                    </label>
                    <select
                      value={this.state.info_text_time_zone}
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      name="info_text_time_zone"
                      className={[s.custom_select, s.mr_sm_2].join(' ')}
                    >
                      {glossaire_time_zone.map((timezone) => (
                        <option
                          selected={this.state.info_text_time_zone}
                          value={timezone}
                        >
                          {timezone}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('watchdog')} {checkMot('(en minutes)')}
                  </label>
                  <div className={text_general}>
                    {this.props.infoCapteur.watchdog}
                  </div>
                </div>
                <div className={s.col_6}>
                  <label className={[taglabel].join(' ')}>
                    {' '}
                    {checkMot('criticite_id')}
                  </label>
                  <div className={text_general}>
                    {this.props.infoCapteur.criticite_id}
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>

        <div className={composanteCapteur}>
          <Collapse ExpandText={checkMot('tableau_de_composantes')}>
            <TableauComposante
              component_list={this.props.infoCapteur.component_list}
            />
          </Collapse>
        </div>

        <div className={sty}>
          <Bouton cliquer={this.afficherDupliqueProfil} ajoutClass={bts}>
            <AiOutlineCopy size={15} /> {checkMot('duplique')}
          </Bouton>
          <Bouton cliquer={this.exporterConfiguration} ajoutClass={bts}>
            <MdVerticalAlignBottom />
            {checkMot('exporter')}
          </Bouton>
          <AccesRestreins numPrivilege={privilege.modifier_des_capteurs}>
            <Bouton cliquer={this.afficherModifierProfil} ajoutClass={bts}>
              <MdModeEdit />
              {checkMot('modifier')}
            </Bouton>
          </AccesRestreins>
          <AccesRestreins numPrivilege={privilege.supprimer_des_capteurs}>
            <AttenteRequete
              request_type={requestType.supprimer_un_capteur}
              str={checkMot('suppression_en_cour')}
            >
              <Bouton
                cliquer={() =>
                  confirmeOnClick(
                    checkMot('voulez_vous_vraiment_supprimer_ce_capteur'),
                    () =>
                      this.props.reqSupprimer(this.props.infoCapteur.sensor_id)
                  )
                }
                ajoutClass={bts}
              >
                <MdClose />
                {checkMot('supprimer')}
              </Bouton>
            </AttenteRequete>
          </AccesRestreins>
        </div>
      </>
    );

    if (this.state.afficherModifier === true) {
      return (
        <div className={ConteneurInfo}>
          <div className={[s.card_header, hedes].join(' ')}>
            <BsFillInfoCircleFill size={14} /> {checkMot('capteur_id')} :{' '}
            {this.props.infoCapteur.sensor_id}
          </div>
          <div className={[s.container, newStyle].join(' ')}>
            {formulaireModifierCapteur}
          </div>
        </div>
      );
    }
    if (this.state.afficherDupliqueProfil === true) {
      return (
        <div className={ConteneurInfo}>
          <div className={[s.card_header, hedes].join(' ')}>
            <BsFillInfoCircleFill size={14} /> {checkMot('capteur_id')} :{' '}
            {' Capteur duplique'}
          </div>
          <div className={s.container}>{formulaireDupliqueCapteur}</div>
        </div>
      );
    } else {
      return (
        <div className={ConteneurInfo}>
          <div className={[s.card_header, hedes].join(' ')}>
            <BsFillInfoCircleFill size={14} /> {checkMot('capteur_id')} :{' '}
            {this.props.infoCapteur.sensor_id}
          </div>
          <div className={[s.container, newStyle].join(' ')}>{profil}</div>
        </div>
      );
    }
  }
}

export default ProfilCapteur;

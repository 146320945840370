import React, { Component } from 'react';

import AttenteRequete from '../../../hoc/AttenteRequete/AttenteRequete';
import AddCapteurComposante from '../../Capteur/Ajouter/AddCapteurComposante';
import Bouton from '../../UI/Bouton/Bouton';
import FormulaireSelectionEmailgroup from './FormulaireSelectionEmailgroup/FormulaireSelectionEmailgroup';
import FormulaireSelectionImportFilter from './FormulaireSelectionImportFilter/FormulaireSelectionImportFilter';
import FormulaireSelectionProjet from './FormulaireSelectionProjet/FormulaireSelectionProjet';
import FormulaireSelectionSensorType from './FormulaireSelectionSensorType/FormulaireSelectionSensorType';

import { alertePerteDonneesFormulaire } from '../../../ressources/functionJS/alertePerteDonneesFormulaire';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import { stringOrEmptyString } from '../../../ressources/functionJS/stringOrEmptyString';
import * as requestType from '../../../ressources/glossaires/glossaire_requetes';

import { MdClose, MdDone } from 'react-icons/md';
import { glossaire_time_zone } from '../../../ressources/glossaires/glossaire_time_zones';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import {
  text_general,
  text_profil,
} from '../ProfilCapteur/ProfilCapteur.module.scss';
import * as S from './AjouterCapteur.module.scss';
import {
  ConteneurInfo,
  Formulaire,
  newSlil,
} from './AjouterCapteur.module.scss';
const valeurParDefaut = {
  project_id: null,
  type_id: null,
  sensor_name: '',
  sensors_group: '',
  remarks: '',
  format_date: '',
  path_ascii_file: '',
  position: null,
  import_filter_id: null,
  emailgroup_id: null,
  active: 1,
  sensor_projection: 1,
  coordinate_x: null,
  coordinate_y: null,
  coordinate_z: null,
  time_zone: 'Europe/Paris',
  watchdog: null,
  criticite_id: 0,
  component_list: [],
};

class Ajouter extends Component {
  constructor(props) {
    super(props);
    this.state = { ...valeurParDefaut };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    alertePerteDonneesFormulaire(
      this.state,
      valeurParDefaut,
      'Voulez vous vraiment actualiser la page ? les configurations que vous avez entrées ne seront pas sauvegardé. '
    );
  }

  gererEtatDeCibleName = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };
  gererComposantes = (component_list) => {
    this.setState({ ...this.state, component_list: component_list });
  };
  gererTypeId = (typeId) => {
    this.setState({ ...this.state, type_id: typeId });
  };
  gererEmailgroupId = (emailgroupId) => {
    this.setState({ ...this.state, emailgroup_id: emailgroupId });
  };
  gererImportFilterId = (importFilterId) => {
    this.setState({ ...this.state, import_filter_id: importFilterId });
  };
  gererProjetId = (projetId) => {
    this.setState({ ...this.state, project_id: projetId });
  };

  gererEnvoi = () => {
    const formulaireCreerCapteur = {
      project_id: parseInt(this.state.project_id),
      type_id: parseInt(this.state.type_id),
      sensor_name: stringOrEmptyString(this.state.sensor_name),
      sensors_group: stringOrEmptyString(this.state.sensors_group),
      remarks: stringOrEmptyString(this.state.remarks),
      format_date: stringOrEmptyString(this.state.format_date),
      path_ascii_file: stringOrEmptyString(this.state.path_ascii_file),
      position: stringOrEmptyString(this.state.position),
      import_filter_id: parseInt(this.state.import_filter_id),
      emailgroup_id: parseInt(this.state.emailgroup_id),
      active: parseInt(this.state.active),
      sensor_projection: parseInt(this.state.sensor_projection),
      coordinate_x: parseFloat(this.state.coordinate_x),
      coordinate_y: parseFloat(this.state.coordinate_y),
      coordinate_z: parseFloat(this.state.coordinate_z),
      time_zone: this.state.time_zone,
      watchdog: parseInt(this.state.watchdog),
      criticite_id: parseInt(this.state.criticite_id),
      component_list: this.state.component_list,
    };
    this.props.ajouter(formulaireCreerCapteur);
  };

  render() {
    return (
      <>
        <div className={[ConteneurInfo].join(' ')}>
          <div
            className={[s.container, s.text_left, Formulaire, newSlil].join(
              ' '
            )}
          >
            <div className={s.row}>
              <div className={[s.col_md_8, s.text_left].join(' ')}>
                <div className={text_profil}>
                  {/*Formulaire pour sélectionner un projet*/}
                  <FormulaireSelectionProjet
                    gererProjetId={this.gererProjetId}
                  />
                  {/*Formulaire pour sélectionner un type de capteur*/}
                  <FormulaireSelectionSensorType
                    gererSensorTypeId={this.gererTypeId}
                  />
                  {/*Formulaire pour le nom du capteur*/}
                  <label className={[S.taglabel, s.card_header].join(' ')}>
                    {checkMot('nom')}{' '}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="sensor_name"
                    className={text_general}
                    value={this.state.sensor_name}
                  />
                  {/*Formulaire pour le groupe de capteur*/}
                  <label className={[S.taglabel, s.card_header].join(' ')}>
                    {checkMot('groupe_de_capteurs')}{' '}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="sensors_group"
                    className={text_general}
                    value={this.state.sensors_group}
                  />
                  {/*Formulaire pour les remarques sur le capteur*/}
                  <label className={[S.taglabel, s.card_header].join(' ')}>
                    {checkMot('remarques')}{' '}
                  </label>
                  <textarea
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="remarks"
                    className={text_general}
                    value={this.state.remarks}
                  />
                  {/*Formulaire de sélection du format de date*/}
                  <label className={[S.taglabel, s.card_header].join(' ')}>
                    {checkMot('format_date')}{' '}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="format_date"
                    className={text_general}
                    value={this.state.format_date}
                  />
                  {/*Formulaire du chemin du fichier ascii du capteur*/}
                  <label className={[S.taglabel, s.card_header].join(' ')}>
                    {checkMot('chemin_fichier_ascii')}{' '}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="path_ascii_file"
                    className={text_general}
                    value={this.state.path_ascii_file}
                  />
                  {/*Formulaire de la position du capteur*/}
                  <label className={[S.taglabel, s.card_header].join(' ')}>
                    {checkMot('position')}
                  </label>
                  <textarea
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="position"
                    className={text_general}
                    value={this.state.position}
                  />
                  {/*Formulaire pour de l'id du filtre d'importation*/}
                  <FormulaireSelectionImportFilter
                    gererImportFilterId={this.gererImportFilterId}
                  />
                  {/*Formulaire pour de l'id du groupe d'email de capteur*/}
                  <FormulaireSelectionEmailgroup
                    gererEmailgroupId={this.gererEmailgroupId}
                  />
                  {/*Formulaire de active/desactive capteur*/}
                  <label className={[S.taglabel, s.card_header].join(' ')}>
                    {checkMot('activite_au_capteur')}
                  </label>
                  <select
                    value={this.state.active}
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="active"
                    className={text_general}
                  >
                    <option selected={this.state.active} value={1}>
                      active
                    </option>
                    <option selected={!this.state.active} value={0}>
                      desactive
                    </option>
                  </select>

                  <label className={[S.taglabel, s.card_header].join(' ')}>
                    {checkMot('repere_du_capteur')}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="sensor_projection"
                    className={text_general}
                    value={this.state.sensor_projection}
                  />
                  <label className={[S.taglabel, s.card_header].join(' ')}>
                    {checkMot('coordonnee_x')}
                  </label>
                  <textarea
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="coordinate_x"
                    className={text_general}
                    value={this.state.coordinate_x}
                  />
                  <label className={[S.taglabel, s.card_header].join(' ')}>
                    {checkMot('coordonnee_y')}
                  </label>
                  <textarea
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="coordinate_y"
                    className={text_general}
                    value={this.state.coordinate_y}
                  />
                  <label className={[S.taglabel, s.card_header].join(' ')}>
                    {checkMot('coordonnee_z')}
                  </label>
                  <textarea
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="coordinate_z"
                    className={text_general}
                    value={this.state.coordinate_z}
                  />

                  <label className={[S.taglabel, s.card_header].join(' ')}>
                    {checkMot('time_zone')}
                  </label>

                  <input
                    list="timezones"
                    value={this.state.time_zone}
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="time_zone"
                    className={text_general}
                  />

                  <datalist id="timezones">
                    <option disabled={true} selected={true}>
                      Selecione um fuso horário
                    </option>
                    {glossaire_time_zone.map((timezone) => (
                      <option
                        value={timezone}
                        selected={this.state.time_zone === timezone}
                      >
                        {timezone}
                      </option>
                    ))}
                  </datalist>

                  {/* <select value={this.state.time_zone} defaultValue={this.state.time_zone} onChange={(event) => this.gererEtatDeCibleName(event)} name="time_zone" className={text_general}>
                                        {
                                            glossaire_time_zone.map(timezone=>
                                                <option selected={this.state.time_zone} value={timezone}>{timezone}</option>
                                            )
                                        }
                                    </select> */}

                  {/*Formulaire du chemin du fichier ascii du capteur*/}
                  <label className={[S.taglabel, s.card_header].join(' ')}>
                    {checkMot('watchdog')} {checkMot('(en minutes)')}{' '}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="watchdog"
                    className={text_general}
                    value={this.state.watchdog}
                  />
                  {/*Formulaire du chemin du fichier ascii du capteur*/}
                  <label className={[S.taglabel, s.card_header].join(' ')}>
                    {checkMot('criticite_id')}{' '}
                  </label>
                  <input
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="criticite_id"
                    className={text_general}
                    value={this.state.criticite_id}
                  />
                </div>
              </div>
            </div>
            {/*Bouton ajouter une composante */}
            <AddCapteurComposante gererComposantes={this.gererComposantes} />
            <div className={s.row}>
              {/*Bouton annuler*/}
              <div className={s.col_6}>
                <Bouton cliquer={(event) => this.props.annuler(event)}>
                  <MdClose />
                  {checkMot('annuler')}
                </Bouton>
              </div>
              <div className={s.col_6}>
                {/*Requête créer un capteur*/}
                <AttenteRequete
                  request_type={requestType.creation_d_un_capteur}
                  str={checkMot('creation_en_cour')}
                >
                  <Bouton cliquer={this.gererEnvoi}>
                    <MdDone />
                    {checkMot('creer')}
                  </Bouton>
                </AttenteRequete>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Ajouter;

import React, { Component } from 'react';
import * as s from '../../../../../ressources/StyleGlobal/StyleGlobal';
import * as S from './FormulaireAxe.module.scss';
import { checkMot } from '../../../../../ressources/functionJS/checkMot';

class FormulaireAxe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.preConfigAxe.title || '',
      type: props.preConfigAxe.type || 'linear',
      range: [
        props.preConfigAxe.range?.[0] ?? null, // si la valeur est undefined, remplacer par null
        props.preConfigAxe.range?.[1] ?? null,
      ],
    };
  }

  componentDidMount() {
    if (this.props.preConfigAxe && this.props.preConfigAxe.range) {
      this.props.gererConfigAxe(this.props.axisKey, {
        ...this.props.preConfigAxe,
        range: [...this.props.preConfigAxe.range],
      });
    }
  }

  // Met à jour à la fois le state et les props
  gererMinAxe = (event) => {
    const value = parseFloat(event.target.value);
    // Peut être défini à null s’il est vide
    const newMin = isNaN(value) ? null : value;

    this.setState(
      (prevState) => ({
        range: [newMin, prevState.range[1]],
      }),
      () => {
        this.props.gererConfigAxe(this.props.axisKey, {
          ...this.props.preConfigAxe,
          range: [newMin, this.props.preConfigAxe.range[1]],
        });
      }
    );
  };

  gererMaxAxe = (event) => {
    const value = parseFloat(event.target.value);
    const newMax = isNaN(value) ? null : value;

    this.setState(
      (prevState) => ({
        range: [prevState.range[0], newMax],
      }),
      () => {
        this.props.gererConfigAxe(this.props.axisKey, {
          ...this.props.preConfigAxe,
          range: [this.props.preConfigAxe.range[0], newMax],
        });
      }
    );
  };

  gererEtatDeCibleName = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    this.props.gererConfigAxe(this.props.axisKey, {
      ...this.props.preConfigAxe,
      [name]: value,
    });
  };

  render() {
    let formulairePlageDeLAxe;
    switch (this.props.preConfigAxe.type) {
      case 'date':
        // ...
        break;

      case 'linear':
        formulairePlageDeLAxe = (
          <>
            <div className={[S.style, s.col_12].join(' ')}>
              {checkMot('plage_de')} {this.props.axisName}
            </div>
            <div className={s.row}>
              <div className={s.col_6}>
                <input
                  type="number"
                  className={[s.form_control, S.input].join(' ')}
                  // Si range[0] n’existe pas, affiche le placeholder
                  placeholder={
                    this.state.range[0] == null
                      ? 'echelle_de_l_axe_gauche_min'
                      : undefined
                  }
                  disabled={this.props.disabled === true}
                  // Convertir null/undefined en "" pour autoriser l’affichage du placeholder
                  value={this.state.range[0] ?? ''}
                  onChange={this.gererMinAxe}
                />
              </div>
              <div className={s.col_6}>
                <input
                  type="number"
                  className={[s.form_control, S.input].join(' ')}
                  placeholder={
                    this.state.range[1] == null
                      ? 'echelle_de_l_axe_gauche_max'
                      : undefined
                  }
                  disabled={this.props.disabled === true}
                  value={this.state.range[1] ?? ''}
                  onChange={this.gererMaxAxe}
                />
              </div>
            </div>
          </>
        );
        break;

      default:
        formulairePlageDeLAxe = (
          <div className={[S.style, s.text_left].join(' ')}>
            {checkMot('selectionner_un_type_de_courbe')}
          </div>
        );
    }

    return (
      <>
        <div className={[S.borderr, s.row].join(' ')}>
          <div className={s.col_12}>{this.props.axisName}</div>
          <div className={s.col_12}>
            <div className={[S.styleTitle].join(' ')}>{checkMot('titre')}</div>
            <input
              type="text"
              className={[s.form_control].join(' ')}
              placeholder="title"
              name="title"
              value={this.state.title}
              disabled={this.props.disabled === true}
              onChange={this.gererEtatDeCibleName}
            />
          </div>
          <div className={s.col_12}>{formulairePlageDeLAxe}</div>
          {this.props.children}
        </div>
      </>
    );
  }
}

export default FormulaireAxe;

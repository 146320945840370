import React, { Component } from 'react';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';

import { connect } from 'react-redux';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import * as S from './FormulaireSelectionProjetsArborecense.module.scss';
import TextField from '@mui/material/TextField';
import { MdSubdirectoryArrowRight } from 'react-icons/md';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';

import { VscCloseAll, VscError, VscUnverified } from 'react-icons/vsc';
class FormulaireSelectionProjetsArborecense extends Component {
  constructor(props) {
    super(props);

    // Initialisation des variables pour la gestion des timeouts
    this._mounted = false;
    this._timeouts = [];
    this._searchTimeout = null;
    this._cachedTrees = {};
    this._cachedFilteredIds = {}; // Cache pour les IDs filtrés par terme de recherche
    this._lastValidTree = null; // Stocker le dernier arbre valide
  }
  // Initialisation de la classe FormulaireSelectionProjetsArborecense et définition de l'état initial

  state = {
    listeIdProjet: [],
    listeNameProjet: '',
    ProjectPerent: false,
    enlevertous: false,
    expandedItems: {},
    pathTree: false,
    isClearing: false, // Nouvel état pour suivre l'opération d'effacement
  };

  _cachedTree = null;
  _forceRebuild = true;

  // Méthode componentDidMount : s'exécute après le montage du composant dans le DOM
  // Utilisée ici pour initialiser les projets pré-configurés (si disponibles)

  componentDidMount() {
    this._mounted = true;
    if (this.props.preconfigurerProjectsListId !== undefined) {
      this.setState({
        ...this.state,
        listeIdProjet: this.props.preconfigurerProjectsListId,
      });
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    // Nettoyer tous les timeouts en attente
    this._timeouts.forEach((timeoutId) => clearTimeout(timeoutId));
  }

  // Méthode componentDidUpdate : s'exécute après la mise à jour du composant
  // Utilisée pour gérer les changements d'état et les sélections de projets

  componentDidUpdate(prevProps, prevState) {
    if (this.state !== prevState) {
      this.props.gererProjetsList(this.state.listeIdProjet);
    }

    // Si la liste des projets change, vider le cache et forcer la reconstruction
    if (prevProps.listeProjets !== this.props.listeProjets) {
      this._cachedTrees = {}; // Vider complètement le cache
      this._forceRebuild = true;
    }
  }
  // gererListeIdProjet : Gère l'ajout ou la suppression de projets de la liste
  // en fonction des actions de l'utilisateur

  gererListeIdProjet = (event) => {
    /**
     * ajoute la valeur de l'event sélectionné dans la liste déroulante
     */
    if (
      event.target.value !== 'unselectable' &&
      this.state.listeIdProjet.indexOf(parseInt(event.target.value)) < 0
    ) {
      // restreint la sélection par défaut et la redondance
      let newListeIdProjet = [...this.state.listeIdProjet];

      newListeIdProjet.push(parseInt(event.target.value));
      this.setState({ ...this.state, listeIdProjet: newListeIdProjet });
      // this.setState({enlevertous: true});
    } else {
      let newListeIdProjet = [...this.state.listeIdProjet];
      const index = this.state.listeIdProjet.indexOf(
        parseInt(event.target.value)
      );
      if (index > -1) {
        newListeIdProjet.splice(index, 1);
      }
      this.setState({ ...this.state, listeIdProjet: newListeIdProjet });
    }
  };
  // enleverProjet : Supprime un projet spécifique de la liste des projets sélectionnés

  enleverProjet = (projectId) => {
    /** via l'id du projet enlève le projet de la liste des projets sélectionnés
     * @projectId: ID du projet à supprimer
     * */
    let newListeIdProjet = this.state.listeIdProjet.filter(
      (id) => id !== projectId
    );
    this.setState({ ...this.state, listeIdProjet: newListeIdProjet });
  };
  // removerTodosProjetosSelecionados : Efface tous les projets sélectionnés de la liste

  removerTodosProjetosSelecionados = () => {
    // Si déjà en cours d'effacement, ne rien faire
    if (this.state.isClearing) return;

    // Indiquer que l'effacement est en cours
    this.setState({ isClearing: true }, () => {
      // Utiliser setTimeout pour laisser le rendu se faire avant la mise à jour principale
      setTimeout(() => {
        this.setState({
          listeIdProjet: [],
          enlevertous: false,
          expandedItems: {},
          pathTree:
            this.state.listeNameProjet &&
            this.state.listeNameProjet.trim() !== '',
          isClearing: false, // Réinitialiser l'indicateur d'effacement
        });
      }, 50);
    });
  };
  // developeEnfent : Gère l'expansion ou la réduction des items de projet dans l'interface utilisateur

  developeEnfent = (projectId) => {
    this.setState((prevState) => ({
      expandedItems: {
        ...prevState.expandedItems,
        [projectId]: !prevState.expandedItems[projectId],
      },
    }));
  };
  // construirArvoreProjetos : Construit un arbre de projets à partir de la liste des projets
  // en utilisant une structure parent-enfant
  PathC() {
    this.setState({ pathTree: true });
  }
  construirArvoreProjetos() {
    // Vérification de sécurité pour éviter les problèmes
    if (
      !this.props.listeProjets ||
      !Array.isArray(this.props.listeProjets) ||
      this.props.listeProjets.length === 0
    ) {
      return [];
    }

    const searchTerm = this.state.listeNameProjet.trim().toLowerCase();

    // Si nous avons déjà un cache pour ce terme de recherche et qu'aucun rebuild n'est forcé
    if (this._cachedTrees[searchTerm] && !this._forceRebuild) {
      return this._cachedTrees[searchTerm];
    }

    // Reset le flag de rebuild
    this._forceRebuild = false;

    let mapaProjetos = new Map();
    let arvoreProjetos = [];

    // Si aucun filtre n'est appliqué, utiliser tous les projets
    if (!searchTerm) {
      // Construction de la Map avec tous les projets
      this.props.listeProjets.forEach((projeto) => {
        mapaProjetos.set(projeto.project_id, { ...projeto, children: [] });
      });

      // Organisation hiérarchique des projets
      this.props.listeProjets.forEach((projeto) => {
        if (projeto.parent_id && mapaProjetos.has(projeto.parent_id)) {
          mapaProjetos
            .get(projeto.parent_id)
            .children.push(mapaProjetos.get(projeto.project_id));
        } else {
          arvoreProjetos.push(mapaProjetos.get(projeto.project_id));
        }
      });

      // Sauvegarder dans le cache
      if (this._isValidTree(arvoreProjetos)) {
        // Vérifier la structure du premier projet avec des enfants
        const projectWithChildren = arvoreProjetos.find(
          (p) => p.children && p.children.length > 0
        );
        if (projectWithChildren) {
          console.log(
            "Structure d'un projet avec enfants:",
            projectWithChildren
          );
          console.log('Premier enfant:', projectWithChildren.children[0]);
        }

        this._lastValidTree = arvoreProjetos;
        this._cachedTrees[searchTerm] = arvoreProjetos;
        return arvoreProjetos;
      } else if (this._lastValidTree) {
        // Si le résultat n'est pas valide, utiliser le dernier arbre valide
        console.warn(
          'Arbre invalide généré, utilisation de la version précédente'
        );
        return this._lastValidTree;
      }

      return arvoreProjetos; // Retour par défaut
    } else {
      // Code pour la recherche filtrée

      // Vérifier si nous avons déjà calculé les IDs pour ce terme de recherche
      if (this._cachedFilteredIds[searchTerm] && !this._forceRebuild) {
        const idsToShow = this._cachedFilteredIds[searchTerm];

        // Construction de la Map avec tous les projets à afficher
        this.props.listeProjets.forEach((projeto) => {
          if (idsToShow.has(projeto.project_id)) {
            mapaProjetos.set(projeto.project_id, { ...projeto, children: [] });
          }
        });

        // Organisation hiérarchique
        this.props.listeProjets.forEach((projeto) => {
          if (idsToShow.has(projeto.project_id)) {
            if (projeto.parent_id && mapaProjetos.has(projeto.parent_id)) {
              mapaProjetos
                .get(projeto.parent_id)
                .children.push(mapaProjetos.get(projeto.project_id));
            } else {
              arvoreProjetos.push(mapaProjetos.get(projeto.project_id));
            }
          }
        });

        // Sauvegarder dans le cache et retourner
        this._cachedTrees[searchTerm] = arvoreProjetos;
        return arvoreProjetos;
      }

      // Étape 1: Trouver tous les projets qui correspondent au terme de recherche
      const projetosFiltrados = this.props.listeProjets.filter(
        (projeto) =>
          projeto.project_name.toLowerCase().includes(searchTerm) ||
          projeto.project_id.toString().includes(searchTerm)
      );

      // Étape 2: Collecter tous les IDs de projets à afficher
      // (incluant les parents des projets filtrés pour maintenir la hiérarchie)
      const idsToShow = new Set();

      // Fonction récursive pour ajouter un projet et tous ses parents
      const addProjectAndParents = (projectId, visitedIds = new Set()) => {
        if (!projectId) return;

        // Vérifier si ce projet a déjà été visité pour éviter les boucles infinies
        if (visitedIds.has(projectId)) {
          console.warn(
            `Boucle détectée dans la hiérarchie des projets: ${projectId}`
          );
          return;
        }

        // Marquer ce projet comme visité
        visitedIds.add(projectId);

        // Trouver le projet dans la liste complète
        const project = this.props.listeProjets.find(
          (p) => p.project_id === projectId
        );

        if (project) {
          idsToShow.add(project.project_id);

          // Ajouter son parent s'il existe
          if (project.parent_id) {
            // Passer l'ensemble des IDs visités pour maintenir l'historique
            addProjectAndParents(project.parent_id, visitedIds);
          }
        }
      };

      // Ajouter chaque projet filtré et ses parents
      projetosFiltrados.forEach((projeto) => {
        // Créer un nouvel ensemble vide pour chaque projet filtré
        addProjectAndParents(projeto.project_id, new Set());
      });

      // Étape 3: Construire l'arbre avec uniquement les projets filtrés et leurs parents

      // Construction de la Map avec tous les projets à afficher
      this.props.listeProjets.forEach((projeto) => {
        if (idsToShow.has(projeto.project_id)) {
          mapaProjetos.set(projeto.project_id, { ...projeto, children: [] });
        }
      });

      // Organisation hiérarchique
      this.props.listeProjets.forEach((projeto) => {
        if (idsToShow.has(projeto.project_id)) {
          if (projeto.parent_id && mapaProjetos.has(projeto.parent_id)) {
            mapaProjetos
              .get(projeto.parent_id)
              .children.push(mapaProjetos.get(projeto.project_id));
          } else {
            arvoreProjetos.push(mapaProjetos.get(projeto.project_id));
          }
        }
      });

      // Sauvegarder dans le cache
      if (this._isValidTree(arvoreProjetos)) {
        this._lastValidTree = arvoreProjetos;
        this._cachedTrees[searchTerm] = arvoreProjetos;
        this._cachedFilteredIds[searchTerm] = idsToShow;
        return arvoreProjetos;
      } else if (this._lastValidTree) {
        // Si le résultat n'est pas valide, utiliser le dernier arbre valide
        console.warn(
          'Arbre invalide généré, utilisation de la version précédente'
        );
        return this._lastValidTree;
      }

      return arvoreProjetos; // Retour par défaut
    }
  }
  // Ajouter cette méthode pour vérifier si l'arbre est valide
  _isValidTree(tree) {
    return Array.isArray(tree) && tree.length > 0;
  }
  // atualizarSelecaoRecursivamente : Met à jour récursivement la sélection des projets et de leurs enfants

  atualizarSelecaoRecursivamente(projeto, selecionado, novaListaIdProjet) {
    if (selecionado) {
      novaListaIdProjet.add(projeto.project_id);
    } else {
      novaListaIdProjet.delete(projeto.project_id);
    }

    // Si le projet a des enfants, les traiter récursivement
    if (projeto.children && Array.isArray(projeto.children)) {
      projeto.children.forEach((filho) => {
        this.atualizarSelecaoRecursivamente(
          filho,
          selecionado,
          novaListaIdProjet
        );
      });
    }
  }

  // toggleProjetoSelecao : Bascule la sélection d'un projet spécifique
  toggleProjetoSelecao(projeto) {
    // Développer automatiquement le parent lorsqu'il est sélectionné
    if (
      projeto.children &&
      projeto.children.length > 0 &&
      !this.state.expandedItems[projeto.project_id]
    ) {
      this.developeEnfent(projeto.project_id);
    }

    this.setState((prevState) => {
      const novaListaIdProjet = new Set(prevState.listeIdProjet);
      const selecionado = !novaListaIdProjet.has(projeto.project_id);

      // Liste des IDs de projets à mettre à jour (commencer par ce projet)
      const projetoIdsToUpdate = [projeto.project_id];

      // Trouver tous les descendants du projet dans la liste plate
      if (selecionado) {
        // Si on sélectionne, chercher tous les descendants récursivement
        const findAllChildren = (parentId) => {
          this.props.listeProjets.forEach((p) => {
            if (p.parent_id === parentId) {
              projetoIdsToUpdate.push(p.project_id);
              findAllChildren(p.project_id); // Continuer avec les enfants de cet enfant
            }
          });
        };

        // Trouver tous les enfants du projet sélectionné
        findAllChildren(projeto.project_id);
      } else {
        // Si on désélectionne, même logique
        const findAllChildren = (parentId) => {
          this.props.listeProjets.forEach((p) => {
            if (p.parent_id === parentId) {
              projetoIdsToUpdate.push(p.project_id);
              findAllChildren(p.project_id);
            }
          });
        };

        findAllChildren(projeto.project_id);
      }

      // Appliquer la sélection ou désélection à tous les projets identifiés
      projetoIdsToUpdate.forEach((id) => {
        if (selecionado) {
          novaListaIdProjet.add(id);
        } else {
          novaListaIdProjet.delete(id);
        }
      });

      return {
        ...prevState,
        listeIdProjet: Array.from(novaListaIdProjet),
        enlevertous: novaListaIdProjet.size > 0,
      };
    });
  }
  // renderArvoreProjetos : Rendu récursif des projets et de leurs enfants sous forme d'arbre
  getChemin(project_id) {
    for (let project of this.props.listeProjets) {
      if (project.project_id === project_id) {
        if (project.parent_id === null) {
          return <span className={S.path}>{project.project_name}</span>;
        } else {
          return [
            this.getChemin(project.parent_id),
            '  /  ',
            <span className={S.path}>{project.project_name}</span>,
          ];
        }
      }
    }
  }

  renderArvoreProjetos(projetonew) {
    const isExpanded = this.state.expandedItems[projetonew.project_id];

    return (
      <div key={projetonew.project_id}>
        <table>
          <tbody>
            <tr>
              <td style={{ padding: '0' }}>
                {projetonew.children && projetonew.children.length > 0 && (
                  <button
                    className={S.plusParent}
                    onClick={() => this.developeEnfent(projetonew.project_id)}
                  >
                    {isExpanded ? (
                      <FiChevronDown size={20} />
                    ) : (
                      <FiChevronRight size={20} />
                    )}
                  </button>
                )}

                <Switch
                  size="small"
                  className={S.checkSlt}
                  type="checkbox"
                  checked={this.state.listeIdProjet.includes(
                    projetonew.project_id
                  )}
                  onChange={() => this.toggleProjetoSelecao(projetonew)}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    this.handleClickDroit(projetonew);
                  }}
                />
              </td>
              <td>{projetonew.project_id}</td>
              <td>{projetonew.project_name}</td>
            </tr>
          </tbody>
        </table>

        {projetonew.parent_id !== null && (
          <div
            style={{ display: this.state.pathTree ? 'block' : 'none' }}
            className={S.pathTow}
          >
            {' '}
            {this.getChemin(projetonew.project_id)}
          </div>
        )}
        {projetonew.children && projetonew.children.length > 0 && (
          <div
            style={{
              marginLeft: '30px',
              marginTop: '-20px',
              marginBottom: '15px',
            }}
          >
            {isExpanded &&
              projetonew.children.map((child) => (
                <div key={child.project_id} style={{ marginBottom: '-15px' }}>
                  <MdSubdirectoryArrowRight
                    size={20}
                    style={{ marginBottom: '-60px', marginLeft: '-40px' }}
                  />
                  {this.renderArvoreProjetos(child)}
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }

  handleClickDroit = (projeto) => {
    this.setState((prevState) => {
      const novaListaIdProjet = new Set(prevState.listeIdProjet);

      // Altera a seleção com base no estado atual
      if (novaListaIdProjet.has(projeto.project_id)) {
        novaListaIdProjet.delete(projeto.project_id);
      } else {
        novaListaIdProjet.add(projeto.project_id);
      }

      return {
        ...prevState,
        listeIdProjet: Array.from(novaListaIdProjet),
      };
    });
  };
  // render : Méthode de rendu du composant, affiche l'interface de sélection des projets

  render() {
    let information_projet_liste = [];
    if (
      this.props.listeProjets !== undefined &&
      this.state.listeIdProjet.length > 0
    ) {
      for (let i = 0; i < this.props.listeProjets.length; i++) {
        for (let j = 0; j < this.state.listeIdProjet.length; j++) {
          if (
            this.state.listeIdProjet[j] ===
            this.props.listeProjets[i].project_id
          ) {
            information_projet_liste.push(this.props.listeProjets[i]);
          }
        }
      }
    }
    let newButton =
      information_projet_liste.length > 0 || this.state.enlevertous ? (
        <Button
          variant="text"
          onClick={this.removerTodosProjetosSelecionados}
          disabled={this.state.isClearing} // Désactiver pendant l'effacement
        >
          <VscCloseAll size={20} />
          {checkMot(' Désélectionne cette liste : ')}
        </Button>
      ) : null; // ou outro componente/elemento como fallback
    const arvoreProjetos = this.construirArvoreProjetos();
    return (
      <>
        <div className={[s.table_responsive, S.barHo].join(' ')}>
          {checkMot('filtre_par')}
          <TextField
            className={S.search}
            id="outlined-basic"
            variant="outlined"
            fullWidth
            label="recherche par nom ou id"
            placeholder="Rechercher par nom ou ID"
            value={this.state.listeNameProjet || ''} // Ajout du contrôle de valeur
            onChange={(event) => {
              const newValue = event.target.value || '';
              this.setState({
                listeNameProjet: newValue,
                pathTree: newValue.trim() !== '', // Afficher les chemins seulement si quelque chose est recherché
              });
            }}
          />

          <table className={[s.table, S.margin].join(' ')}>
            <thead>
              <tr>
                <th scope="col">
                  {checkMot('liste_des_projets')}{' '}
                  <span
                    className={S.infoI}
                    title={checkMot(
                      'clique_droit_pour_selectionner_uniquement_un_parent'
                    )}
                  >
                    <VscUnverified size={30} />
                  </span>
                </th>
                <th scope="col">
                  {checkMot('liste_des_projets_selectionner')}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={S.stylecol1}>
                  {arvoreProjetos.map((projetonew) =>
                    this.renderArvoreProjetos(projetonew)
                  )}
                </td>
                <td className={S.fixed}>
                  <span className={S.space}>{newButton}</span>
                  {Object.keys(information_projet_liste).map(
                    (indListeProjets) => {
                      return (
                        <div
                          className={S.labell}
                          key={indListeProjets}
                          onClick={() =>
                            this.enleverProjet(
                              information_projet_liste[indListeProjets]
                                .project_id
                            )
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          <span className={S.iconn}>
                            <VscError size={15} />
                          </span>{' '}
                          {information_projet_liste[indListeProjets]
                            .project_id +
                            ', ' +
                            information_projet_liste[indListeProjets]
                              .project_name}
                        </div>
                      );
                    }
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listeProjets: state.authentifie.projects_list,
  };
};

export default connect(mapStateToProps)(FormulaireSelectionProjetsArborecense);

export const dico_langues_phrases = {
  dico_langues: {
    traduction_titre_graphe_automatique: {
      fr: (nom_capteur) =>
        'Évolution du capteur ' + nom_capteur + ' en fonction du temps',
      'PT-PT': (nom_capteur) =>
        'Evolução do sensor ' + nom_capteur + ' em função do tempo',
      'PT-BR': (nom_capteur) =>
        'Evolução do sensor ' + nom_capteur + ' em função do tempo',
    },
  },
};

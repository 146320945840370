import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { connect } from 'react-redux';
import Bouton from '../../../composant/UI/Bouton/Bouton';
import * as actions from '../../../services/actions';
import S from './Aide.module.scss';

import { Link } from 'react-router-dom';
import * as url from '../../../ressources/glossaires/glossaire_liens_pages';
// import AccesRestreins from "../../../hoc/AccesRestreins/AccesRestreins";
// import * as privilege from "../../../ressources/glossaires/glossaire_privileges";
// import * as requestType from "../../../ressources/glossaires/glossaire_requetes";
// import AttenteRequete from "../../../hoc/AttenteRequete/AttenteRequete";
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import {
  IoIosHelpCircleOutline,
  IoMdInformationCircleOutline,
} from 'react-icons/io';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import * as type_btn from '../../../ressources/glossaires/glossaire_type_de_bouton';
const EnTeteProfil = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Bouton
        rond={true}
        cliquer={handleClick}
        ajoutClass={S.normal}
        type={type_btn.Wait}
      >
        <AiOutlineQuestionCircle size={14} />
      </Bouton>

      <Menu
        id="TopBar"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={
          props.interfaceAdmin ? S.MenuProfil_admin : S.MenuProfil_client
        }
      >
        <div className={S.divItem}>
          <Link to={url.Formation}>
            <Bouton ajoutClass={S.Item}>
              <MenuItem onClick={handleClose}>
                <div className={S.bt}>
                  <IoIosHelpCircleOutline size={25} />
                </div>
                {checkMot('aide')}
              </MenuItem>
            </Bouton>
          </Link>
        </div>

        <Link to={url.Info}>
          <Bouton ajoutClass={S.Item}>
            <MenuItem onClick={handleClose}>
              <div className={S.bt}>
                <IoMdInformationCircleOutline size={25} />
              </div>
              {checkMot('info')}
            </MenuItem>
          </Bouton>
        </Link>
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    interfaceAdmin: state.authentifie.interfaceAdmin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deconnexion: () => dispatch(actions.seDeconnecter()),
    commuterInterfaceClient: () =>
      dispatch(actions.commutateurInterfaceClient()),
    commuterInterfaceAdmin: () => dispatch(actions.commutateurInterfaceAdmin()),
    enregistrementProjectIdUrl: (project_id) =>
      dispatch(actions.enregistrerProjectIdUrl(project_id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EnTeteProfil);

import React, { Component } from 'react';
import * as indexConteneurInfo from '../../ressources/glossaires/glossaire_conteneur_info';

import S from './ConteneurInformation.module.scss';
import { checkMot } from '../../ressources/functionJS/checkMot';

class ConteneurInformation extends Component {
  render() {
    let affichage;
    if (this.props.selectionInfo === indexConteneurInfo.profil) {
      affichage = this.props.profil;
    } else if (this.props.selectionInfo === indexConteneurInfo.ajouterForm) {
      affichage = this.props.ajouterForm;
    } else if (this.props.selectionInfo === indexConteneurInfo.importation) {
      affichage = this.props.importation;
    } else {
      affichage = (
        <span className={S.stile}>{checkMot('selectionne_sur_une_ligne')}</span>
      );
    }
    return <div className={S.ConteneurInfo}>{affichage}</div>;
  }
}

export default ConteneurInformation;

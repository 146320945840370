import React from 'react';
import * as s from '../../../../../ressources/StyleGlobal/StyleGlobal';
import * as S from './PopupObjet.module.scss';
import * as type_btn from '../../../../../ressources/glossaires/glossaire_type_de_bouton';
import Bouton from '../../../../UI/Bouton/Bouton';
import { dimensionsIconCapteur } from '../../AffichageVue';
const PopupObjet = (props) => {
  let classeDisparait;
  if (!props.afficherPopup) {
    classeDisparait = S.disparait;
  }
  let leafletPopupDirection = S.leafletPopupTop;
  let leafletPopupTipDirection = S.leafletPopupTipTop;
  let leafletPopupTipContainerDirection = S.leafletPopupTipContainerTop;
  switch (props.stylePopup.directionPopup) {
    case 'haut':
      leafletPopupDirection = S.leafletPopupTop;
      leafletPopupTipDirection = S.leafletPopupTipTop;
      leafletPopupTipContainerDirection = S.leafletPopupTipContainerTop;
      break;
    case 'bas':
      leafletPopupDirection = S.leafletPopupBottom;
      leafletPopupTipDirection = S.leafletPopupTipBottom;
      leafletPopupTipContainerDirection = S.leafletPopupTipContainerBottom;
      break;
  }
  let mainDivStyle = {
    width: props.largeurPopup + 'px',
    left: -props.largeurPopup / 2 + dimensionsIconCapteur / 2 + 'px',
  };
  if (props.mainDivStyle !== undefined) {
    mainDivStyle = { ...mainDivStyle, ...props.mainDivStyle };
  }

  let headerDivStyle = {};
  if (props.headerDivStyle !== undefined) {
    headerDivStyle = { ...headerDivStyle, ...props.headerDivStyle };
  }

  let wrapperDivStyle = {};
  if (props.wrapperDivStyle !== undefined) {
    wrapperDivStyle = { ...wrapperDivStyle, ...props.wrapperDivStyle };
  }

  let contentDivStyle = {};
  if (props.contentDivStyle !== undefined) {
    contentDivStyle = { ...contentDivStyle, ...props.contentDivStyle };
  }
  let tipContainerDirectionDivStyle = {};
  if (props.tipContainerDirectionDivStyle !== undefined) {
    tipContainerDirectionDivStyle = {
      ...tipContainerDirectionDivStyle,
      ...props.tipContainerDirectionDivStyle,
    };
  }

  let tipDirectionDivStyle = {};
  if (props.tipDirectionDivStyle !== undefined) {
    tipDirectionDivStyle = {
      ...tipDirectionDivStyle,
      ...props.tipDirectionDivStyle,
    };
  }

  return (
    <div
      className={[S.leafletPopup, leafletPopupDirection, classeDisparait].join(
        ' '
      )}
      style={mainDivStyle}
    >
      <div
        className={[s.card_header, S.titre].join(' ')}
        style={headerDivStyle}
      >
        {props.headerPopupText}
      </div>
      <Bouton
        ajoutClass={[S.leafletPopupCloseButton].join(' ')}
        type={type_btn.popupferme}
        rond={true}
        cliquer={props.fermerPopup}
      />
      {/* <a className={[].join(" ")} onClick={}>×</a> */}
      <div
        style={wrapperDivStyle}
        className={[S.leafletPopupContentWrapper, classeDisparait].join(' ')}
      >
        <div
          style={contentDivStyle}
          className={[S.leafletPopupContent, classeDisparait].join(' ')}
        >
          {props.children}
        </div>
      </div>
      <div
        style={tipContainerDirectionDivStyle}
        className={[
          S.leafletPopupTipContainer,
          leafletPopupTipContainerDirection,
          classeDisparait,
        ].join(' ')}
      >
        <div
          style={tipDirectionDivStyle}
          className={[
            S.leafletPopupTip,
            leafletPopupTipDirection,
            classeDisparait,
          ].join(' ')}
        />
      </div>
    </div>
  );
};
export default PopupObjet;

import { getLanguage, getTranslation } from './translationService';

// Ajoutez une référence pour stocker la fonction de mise à jour
let updateListeners = [];

/**
 * S'abonner aux changements de langue
 * @param {Function} callback - Fonction à appeler lors d'un changement de langue
 * @returns {Function} - Fonction pour se désabonner
 */
export const subscribeToLanguageChanges = (callback) => {
  updateListeners.push(callback);
  return () => {
    updateListeners = updateListeners.filter(
      (listener) => listener !== callback
    );
  };
};

// Écoutez l'événement de changement de langue global
if (typeof window !== 'undefined') {
  window.addEventListener('languageChange', () => {
    // Notifiez tous les abonnés du changement
    updateListeners.forEach((listener) => listener());
  });
}

/**
 * Fonction qui retourne la traduction d'un mot selon la langue actuelle
 * @param {string} str - Clé de traduction à rechercher
 * @returns {string} - Traduction dans la langue actuelle ou la clé si non trouvée
 */
export const checkMot = (str) => {
  // Récupérer la langue actuelle depuis translationService
  const currentLanguage = getLanguage();

  try {
    // Utiliser getTranslation pour obtenir la traduction
    return getTranslation(str, currentLanguage);
  } catch (error) {
    console.warn(
      '/!\\',
      str,
      " n'existe pas dans le dictionnaire de langues. /!\\"
    );
    return str;
  }
};

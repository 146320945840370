import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../services/actions';

import AccesRestreins from '../../../../hoc/AccesRestreins/AccesRestreins';
import AttenteRequete from '../../../../hoc/AttenteRequete/AttenteRequete';

import { checkMot } from '../../../../ressources/functionJS/checkMot';
import { confirmeOnClick } from '../../../../ressources/functionJS/confirmeOnClick';
import * as privilege from '../../../../ressources/glossaires/glossaire_privileges';
import * as requestType from '../../../../ressources/glossaires/glossaire_requetes';

import { BiEditAlt, RiDeleteBin2Line } from 'react-icons/all';
import { style } from '../../../Vue/ProfilVue/ProfilVue.module.scss';
import { btF, btM, profil } from './ProfilGabarit.module.scss';

import { BsFillInfoCircleFill } from 'react-icons/bs';
import FormulaireGabarit from './FormulaireGabarit/FormulaireGabarit';

class ProfilGabarit extends Component {
  state = {
    showModal: false,
    afficheFormulaireModifier: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.InfoSelect !== prevProps.InfoSelect) {
      this.setState({
        template_name: this.props.InfoSelect.template_name,
        afficheFormulaireModifier: false,
      });
    }
  }

  gererTemplateName = (event) => {
    this.setState({ ...this.state, template_name: event.target.value });
  };
  handleOpenModal = () => {
    this.setState({ showModal: true });
  };
  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    //affichage par défaut du profil si aucun gabarit n'a été sélectionné
    let profilGabarit = checkMot('selectionne_un_gabarit');
    if (this.props.InfoSelect !== null) {
      let affichageBouton;
      if (this.state.afficheFormulaireModifier) {
        //formulaire de modification
        affichageBouton = (
          <>
            <button
              className={btM}
              onClick={() =>
                this.setState({
                  ...this.state,
                  afficheFormulaireModifier: false,
                })
              }
            >
              <BiEditAlt size={24} />
              {checkMot('annuler')}
            </button>
          </>
        );
      } else {
        //formulaire de profil
        affichageBouton = (
          <>
            <AccesRestreins numPrivilege={privilege.modifier_des_gabarits}>
              <button
                className={btM}
                onClick={() =>
                  this.setState({
                    ...this.state,
                    afficheFormulaireModifier: true,
                  })
                }
              >
                <BiEditAlt size={24} />
                {checkMot('modifier_gabarit')}
              </button>
            </AccesRestreins>
            <AccesRestreins numPrivilege={privilege.supprimer_des_gabarits}>
              <AttenteRequete
                request_type={requestType.supprimer_un_gabarit}
                str={checkMot('suppression_en_cour')}
              >
                {/*Bouton supprimer un gabarit */}
                <button
                  className={btF}
                  onClick={() =>
                    confirmeOnClick(
                      checkMot('voulez_vous_vraiment_supprimer_ce_gabarit'),
                      () =>
                        this.props.reqSupprimer(
                          parseInt(this.props.InfoSelect.template_id)
                        )
                    )
                  }
                >
                  <RiDeleteBin2Line size={24} />
                  {checkMot('supprimer_gabarit')}
                </button>
              </AttenteRequete>
            </AccesRestreins>
          </>
        );
      }
      profilGabarit = (
        <div className={profil}>
          {/* logo "i"*/}
          <span className={style}>
            <BsFillInfoCircleFill size={24} />
          </span>
          <span className={style}>
            {/*l'id de la vue*/}
            {checkMot('template_id')} : {this.props.InfoSelect.template_id}
          </span>
          <FormulaireGabarit
            preconfigurerGabarit={this.props.InfoSelect}
            affichageProfil={!this.state.afficheFormulaireModifier}
          />
          {/*Affiche les boutons de contrôles de profil ou modification*/}
          {affichageBouton}
        </div>
      );
    }
    return profilGabarit;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reqSupprimer: (idGabarit) =>
      dispatch(actions.reqSupprimerGabarit(idGabarit)),
  };
};

export default connect(null, mapDispatchToProps)(ProfilGabarit);

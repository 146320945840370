import React, { Component } from 'react';
import S from './Connexion.module.scss';
import * as s from '../../ressources/StyleGlobal/StyleGlobal';
import Bouton from '../UI/Bouton/Bouton';

import { Link } from 'react-router-dom';
import * as actions from '../../services/actions';
import { connect } from 'react-redux';
import FondGris from '../UI/FondGris/FondGris';
import FondBlanc from '../UI/FondBlanc/FondBlanc';
import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import AttenteRequete from '../../hoc/AttenteRequete/AttenteRequete';
import * as url from '../../ressources/glossaires/glossaire_liens_pages';
import * as privilege from '../../ressources/glossaires/glossaire_privileges';
import logoEntry from '../../ressources/Logo/argos_png_logo_white.png';
import CommutateurLangue from '../CommutateurLangue/CommutateurLangue';
import { checkMot } from '../../ressources/functionJS/checkMot';
class Connexion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login_name: '',
      motDePasse: '',
      afficheReinitialisationBool: false,
    };
  }
  componentDidMount() {
    //Demande d'information de connexion
    this.props.reqInformationDeConnexion();
    //Affichage par défaut du menu admin en fonction de l'url
    if (
      this.props.listePrivilege.indexOf(privilege.acces_a_l_interface_admin) >=
      0
    ) {
      const urlDeLaPage = window.location.href.split('/')[3];
      switch ('/' + urlDeLaPage) {
        case url.projet:
          this.props.commutateurInterfaceAdmin();
          break;
        case url.filtre:
          this.props.commutateurInterfaceAdmin();
          break;
        case url.alarme:
          this.props.commutateurInterfaceAdmin();
          break;
        case url.capteur:
          this.props.commutateurInterfaceAdmin();
          break;
        case url.vue:
          this.props.commutateurInterfaceAdmin();
          break;
        case url.utilisateur:
          this.props.commutateurInterfaceAdmin();
          break;
        case url.maintenance:
          this.props.commutateurInterfaceAdmin();
          break;
        case url.historique:
          this.props.commutateurInterfaceAdmin();
          break;
        case url.graphe:
          this.props.commutateurInterfaceAdmin();
          break;
        default:
          break;
      }
    }
  }
  gererEmail = (event) => this.setState({ login_name: event.target.value });
  gererMotDePasse = (event) =>
    this.setState({ motDePasse: event.target.value });
  commutateurReinitialisation = () =>
    this.setState({
      afficheReinitialisationBool: !this.state.afficheReinitialisationBool,
    });
  render() {
    let emailClass;
    let email;
    let classeConnexion;
    // let motDePasse = null;
    if (
      this.props.login_name !== undefined &&
      this.props.login_name !== null &&
      this.props.login_name !== ''
    ) {
      emailClass = [s.form_control_plaintext, S.IdUtlisateur].join(' ');
      email = this.props.login_name;
    } else {
      emailClass = [s.form_control, S.IdUtlisateur].join(' ');
      email = this.state.login_name;
    }
    if (this.props.premiereConnexion) {
      classeConnexion = S.Connexion;
    } else {
      classeConnexion = S.PageAccueilConnexion;
    }
    const fomulaireDeConnexion = (
      <>
        <div className={S.divCommutateurLangue}>
          <CommutateurLangue typeAffichage={'bouton'} />
        </div>
        <div className={S.Text}>
          <img alt="logo" src={logoEntry} className={S.logoSty} />
        </div>
        <form
          className={S.FormulaireConnexion}
          onSubmit={(event) => event.preventDefault()}
        >
          <div className={[s.form_group, s.row].join(' ')}>
            <label
              htmlFor="staticEmail"
              className={[s.col, s.col_form_label].join(' ')}
            >
              {checkMot('email')}
            </label>
            <div className={[s.col_sm_12].join(' ')}>
              <input
                type="text"
                className={emailClass}
                id="staticEmail"
                readOnly={
                  this.props.login_name !== undefined &&
                  this.props.login_name !== null &&
                  this.props.login_name !== ''
                }
                placeholder="emailExemple"
                value={email}
                onChange={this.gererEmail}
              />
            </div>
          </div>
          <div className={[s.form_group, s.row].join(' ')}>
            <label
              htmlFor="inputPassword"
              className={[s.col, s.col_form_label].join(' ')}
            >
              {checkMot('mot_de_passe')}
            </label>
            <div className={[s.col_sm_12].join(' ')}>
              <input
                type="password"
                className={[s.form_control, S.MotDePasse].join(' ')}
                id="inputPassword"
                placeholder="motDePasse"
                value={this.state.motDePasse}
                onChange={this.gererMotDePasse}
              />
            </div>
          </div>
          <div className={S.Bouton}>
            <AttenteRequete
              request_type={requestType.information_de_connexion}
              str={checkMot('re_connexion_en_cour')}
            >
              <AttenteRequete
                request_type={requestType.connexion}
                str={checkMot('connexion_en_cour')}
              >
                <Bouton
                  cliquer={() =>
                    this.props.seConnecter(email, this.state.motDePasse)
                  }
                >
                  {checkMot('connexion')}
                </Bouton>
              </AttenteRequete>
            </AttenteRequete>
          </div>
        </form>

        <div className={S.LienReinitialisation}>
          <Link to={''} onClick={this.commutateurReinitialisation}>
            {checkMot('demande_de_reinitialise_de_mdp')}
          </Link>
        </div>
      </>
    );
    const formulaireDeReinitialisation = (
      <>
        <div className={S.Text}>
          <img alt="logo" src={logoEntry} className={S.logoSty} />
        </div>
        <div className={S.FormulaireReinitialisation}>
          <h4 className={S.TextTitre}>
            {checkMot('demande_de_reinitialise_de_mdp')}
          </h4>
          <form onSubmit={(event) => event.preventDefault()}>
            <div className={[s.form_group, s.row].join(' ')}>
              <label
                htmlFor="resetEmail"
                className={[s.col_sm_2, s.col_form_label].join(' ')}
              >
                {checkMot('email')}:
              </label>

              <input
                type="text"
                className={[s.form_control, S.IdUtlisateur, emailClass].join(
                  ' '
                )}
                id="resetEmail"
                readOnly={
                  this.props.login_name !== undefined &&
                  this.props.login_name !== null &&
                  this.props.login_name !== ''
                }
                placeholder="emailExemple"
                value={email}
                onChange={this.gererEmail}
              />
            </div>
            <div className={S.Bouton}>
              <AttenteRequete
                request_type={
                  requestType.demande_de_reinitialisation_du_mot_de_passe
                }
                str={checkMot('demande_en_cour')}
              >
                <Bouton
                  className={[s.btn, s.btn_primary].join(' ')}
                  cliquer={() => this.props.demandeReinitialisationMDP(email)}
                >
                  {checkMot('envoyer')}
                </Bouton>
              </AttenteRequete>
            </div>
          </form>

          <Bouton
            ajoutClass={S.CommutateurReinitialisation}
            cliquer={this.commutateurReinitialisation}
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-arrow-bar-left"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5zM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5z"
              />
            </svg>
          </Bouton>
        </div>
      </>
    );

    const formulaireDeReinitialisationValide = (
      <>
        <div className={S.Text}>
          <img alt="logo" src={logoEntry} className={S.logoSty} />
        </div>
        <div className={S.FormulaireReinitialisation}>
          <h4 className={S.TextTitre}>
            {checkMot('un_lien_de_reinitialisation_vous_envoye_adresse')}{' '}
            {email}.
          </h4>
          {/*todo pensez a vérifier les mail de vos spams */}
          {/*todo après 30 secondes de liens non reçu à l'adresse mail*/}
          <Bouton
            ajoutClass={S.CommutateurReinitialisation}
            cliquer={this.commutateurReinitialisation}
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-arrow-bar-left"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5zM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5z"
              />
            </svg>
          </Bouton>
          {/*    todo ajouter un lien de reenvoie dans le cas où ils n'ont pas reçus de mail */}
        </div>
      </>
    );
    return !this.props.estAuth ? (
      <>
        {this.props.premiereConnexion ? <FondGris /> : <FondBlanc />}
        <div className={classeConnexion}>
          {this.state.afficheReinitialisationBool ? (
            this.props.demandeReinitialisationMDPBool ? (
              formulaireDeReinitialisationValide
            ) : (
              formulaireDeReinitialisation
            )
          ) : (
            <AttenteRequete
              request_type={requestType.information_de_connexion}
              str=""
            >
              {fomulaireDeConnexion}
            </AttenteRequete>
          )}
        </div>
      </>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    estAuth: state.authentifie.identifier,
    premiereConnexion: state.authentifie.reConnexion,
    login_name: state.authentifie.login_name,
    demandeReinitialisationMDPBool:
      state.authentifie.demandeReinitialisationMDPReussie,
    listePrivilege: state.authentifie.privileges_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    seConnecter: (email, motDePasse) =>
      dispatch(actions.authentification(email, motDePasse)),
    demandeReinitialisationMDP: (email) =>
      dispatch(actions.demandeReinitialisationMDP(email)),
    commutateurInterfaceAdmin: () =>
      dispatch(actions.commutateurInterfaceAdmin()),
    reqInformationDeConnexion: () =>
      actions.demandeInformationDeConnexion(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Connexion);

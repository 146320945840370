import { jsDictionaryEquals } from './jsDictionaryEquals';
import { messageAvantFermeturePage } from './messageAvantFermeturePage';

export const alertePerteDonneesFormulaire = (
  updatingState,
  valeurParDefaut,
  messageAlerte
) => {
  if (!jsDictionaryEquals(updatingState, valeurParDefaut)) {
    window.onbeforeunload = (e) => messageAvantFermeturePage(e, messageAlerte);
  }
};

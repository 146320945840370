import { checkMot } from './checkMot';

export const confirmeOnClick = (confirmeMessage, fonctionOnclick) => {
  let isBoss = window.confirm(confirmeMessage);
  if (isBoss) {
    fonctionOnclick();
  } else {
    alert(checkMot('action_non_effectuee'));
  }
};

export const searchKeyInTree = (element, matchingTitle) => {
  //cherche la sous arborescence où l'id du projet parent est à la racine
  if (element.key === matchingTitle) {
    return element;
  } else if (element.nodes != null) {
    var i;
    var result = null;
    for (i = 0; result === null && i < element.nodes.length; i++) {
      result = searchKeyInTree(element.nodes[i], matchingTitle);
    }
    return result;
  }
  return null;
};

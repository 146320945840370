import React, { Component } from 'react';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import { connect } from 'react-redux';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import * as S from './FormulaireSelectionProjetsArborecenseUtilisateur.module.scss';
import TextField from '@mui/material/TextField';
import { MdSubdirectoryArrowRight } from 'react-icons/md';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';

import { VscCloseAll, VscError } from 'react-icons/vsc';
class FormulaireSelectionProjetsArborecenseUtilisateur extends Component {
  // Initialisation de la classe FormulaireSelectionProjetsArborecense et définition de l'état initial

  state = {
    listeIdProjet: [],
    listeNameProjet: '',
    ProjectPerent: false,
    enlevertous: false,
    expandedItems: {},
    pathTree: false,
    isClearing: false, // Nouvel état pour suivre l'opération d'effacement
  };

  // Ajoutez cette propriété de classe en haut du composant (après le state)
  _cachedProjetosMapa = null;
  _lastSearchTerm = '';
  _lastArvoreProjetos = [];

  // Méthode componentDidMount : s'exécute après le montage du composant dans le DOM
  // Utilisée ici pour initialiser les projets pré-configurés (si disponibles)

  componentDidMount() {
    if (this.props.preconfigurerProjectsListId !== undefined) {
      this.setState({
        ...this.state,
        listeIdProjet: this.props.preconfigurerProjectsListId,
      });
    }
  }
  // Méthode componentDidUpdate : s'exécute après la mise à jour du composant
  // Utilisée pour gérer les changements d'état et les sélections de projets

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state !== prevState) {
      this.props.gererProjetsList(this.state.listeIdProjet);
    }
  }
  // gererListeIdProjet : Gère l'ajout ou la suppression de projets de la liste
  // en fonction des actions de l'utilisateur

  gererListeIdProjet = (event) => {
    /**
     * ajoute la valeur de l'event sélectionné dans la liste déroulante
     */
    if (
      event.target.value !== 'unselectable' &&
      this.state.listeIdProjet.indexOf(parseInt(event.target.value)) < 0
    ) {
      // restreint la sélection par défaut et la redondance
      let newListeIdProjet = [...this.state.listeIdProjet];

      newListeIdProjet.push(parseInt(event.target.value));
      this.setState({ ...this.state, listeIdProjet: newListeIdProjet });
      // this.setState({enlevertous: true});
    } else {
      let newListeIdProjet = [...this.state.listeIdProjet];
      const index = this.state.listeIdProjet.indexOf(
        parseInt(event.target.value)
      );
      if (index > -1) {
        newListeIdProjet.splice(index, 1);
      }
      this.setState({ ...this.state, listeIdProjet: newListeIdProjet });
    }
  };
  // enleverProjet : Supprime un projet spécifique de la liste des projets sélectionnés

  enleverProjet = (projectId) => {
    /** via l'id du projet enlève le projet de la liste des projets sélectionnés
     * @projectId: ID du projet à supprimer
     * */
    // Utilise filter pour créer une nouvelle liste sans le projet avec l'ID spécifié
    let newListeIdProjet = this.state.listeIdProjet.filter(
      (id) => id !== projectId
    );
    //mise à jour de l'état de la liste d'ids à envoyer et de la liste d'affichage lors de la suppression d'un projet
    this.setState({ ...this.state, listeIdProjet: newListeIdProjet });
  };
  // Modifiez la méthode removerTodosProjetosSelecionados pour éviter les clics multiples
  removerTodosProjetosSelecionados = () => {
    // Si déjà en cours d'effacement, ne rien faire
    if (this.state.isClearing) return;

    // Indiquer que l'effacement est en cours
    this.setState({ isClearing: true }, () => {
      // Utiliser setTimeout pour laisser le rendu se faire avant la mise à jour principale
      setTimeout(() => {
        this.setState({
          listeIdProjet: [],
          enlevertous: false,
          expandedItems: {},
          pathTree:
            this.state.listeNameProjet &&
            this.state.listeNameProjet.trim() !== '',
          isClearing: false, // Réinitialiser l'indicateur d'effacement
        });
      }, 50);
    });
  };
  // developeEnfent : Gère l'expansion ou la réduction des items de projet dans l'interface utilisateur

  developeEnfent = (projectId) => {
    this.setState((prevState) => ({
      expandedItems: {
        ...prevState.expandedItems,
        [projectId]: !prevState.expandedItems[projectId],
      },
    }));
  };
  // construirArvoreProjetos : Construit un arbre de projets à partir de la liste des projets
  // en utilisant une structure parent-enfant
  PathC() {
    this.setState({ pathTree: true });
  }
  construirArvoreProjetos() {
    // Vérifications de sécurité
    if (!this.props.listeProjets || !Array.isArray(this.props.listeProjets)) {
      return [];
    }

    const searchTerm = (this.state.listeNameProjet || '').trim().toLowerCase();

    // Retourner le cache si la requête est identique
    if (
      searchTerm === this._lastSearchTerm &&
      this._lastArvoreProjetos.length > 0
    ) {
      return this._lastArvoreProjetos;
    }

    try {
      // Construire la carte de projets une seule fois et la réutiliser
      if (!this._cachedProjetosMapa) {
        this._cachedProjetosMapa = new Map();
        this.props.listeProjets.forEach((projeto) => {
          // Créer une copie sans les enfants pour éviter les références circulaires
          const projetoSansEnfants = { ...projeto };
          projetoSansEnfants.children = [];
          this._cachedProjetosMapa.set(projeto.project_id, projetoSansEnfants);
        });
      }

      // Aucun filtre - retourner tous les projets
      if (!searchTerm) {
        const mapaProjetos = new Map(this._cachedProjetosMapa);
        const arvoreProjetos = [];

        // Reconstruire les liens parents-enfants
        this.props.listeProjets.forEach((projeto) => {
          if (projeto.parent_id && mapaProjetos.has(projeto.parent_id)) {
            const parent = mapaProjetos.get(projeto.parent_id);
            const child = mapaProjetos.get(projeto.project_id);
            if (parent && child) {
              // Vérifier que parent.children existe et est un tableau
              if (!Array.isArray(parent.children)) {
                parent.children = [];
              }
              parent.children.push(child);
            }
          } else {
            const rootProject = mapaProjetos.get(projeto.project_id);
            if (
              rootProject &&
              !arvoreProjetos.some(
                (p) => p.project_id === rootProject.project_id
              )
            ) {
              arvoreProjetos.push(rootProject);
            }
          }
        });

        // Mettre en cache pour les futures requêtes
        this._lastSearchTerm = searchTerm;
        this._lastArvoreProjetos = arvoreProjetos;

        return arvoreProjetos;
      }

      // Recherche - implémenter une approche non-bloquante
      const idsToInclude = new Set();
      const termes = searchTerm.split(' ').filter(Boolean);

      // Fonction pour vérifier si un projet correspond à la recherche
      const matchProject = (projeto) => {
        // Si la recherche est numérique, faire une comparaison exacte avec l'ID
        if (/^\d+$/.test(searchTerm)) {
          return projeto.project_id.toString() === searchTerm;
        }

        // Sinon, rechercher dans le nom et l'ID
        return termes.every(
          (term) =>
            projeto.project_name.toLowerCase().includes(term) ||
            projeto.project_id.toString().includes(term)
        );
      };

      // Première passe - trouver les projets correspondants
      const matchingProjects = this.props.listeProjets.filter(matchProject);

      if (matchingProjects.length === 0) {
        this._lastSearchTerm = searchTerm;
        this._lastArvoreProjetos = [];
        return [];
      }

      // Marquer tous les projets correspondants pour inclusion
      matchingProjects.forEach((projeto) =>
        idsToInclude.add(projeto.project_id)
      );

      // Fonction pour ajouter les parents
      const addParents = (projectId) => {
        const project = this.props.listeProjets.find(
          (p) => p.project_id === projectId
        );
        if (project && project.parent_id) {
          idsToInclude.add(project.parent_id);
          addParents(project.parent_id);
        }
      };

      // Ajouter les parents de tous les projets correspondants
      matchingProjects.forEach((projeto) => {
        if (projeto.parent_id) addParents(projeto.parent_id);
      });

      // Fonction pour ajouter les enfants
      const addChildren = (projectId) => {
        const children = this.props.listeProjets.filter(
          (p) => p.parent_id === projectId
        );
        children.forEach((child) => {
          idsToInclude.add(child.project_id);
          addChildren(child.project_id);
        });
      };

      // Ajouter les enfants de tous les projets correspondants
      matchingProjects.forEach((projeto) => addChildren(projeto.project_id));

      // Construire l'arbre filtré
      const mapaProjetos = new Map();
      const arvoreProjetos = [];

      // Créer des copies pour tous les projets inclus
      const projetsToInclude = this.props.listeProjets.filter((p) =>
        idsToInclude.has(p.project_id)
      );

      projetsToInclude.forEach((projeto) => {
        mapaProjetos.set(projeto.project_id, { ...projeto, children: [] });
      });

      // Construire l'arborescence
      projetsToInclude.forEach((projeto) => {
        if (projeto.parent_id && mapaProjetos.has(projeto.parent_id)) {
          mapaProjetos
            .get(projeto.parent_id)
            .children.push(mapaProjetos.get(projeto.project_id));
        } else {
          arvoreProjetos.push(mapaProjetos.get(projeto.project_id));
        }
      });

      // Sauvegarder le résultat en cache
      this._lastSearchTerm = searchTerm;
      this._lastArvoreProjetos = arvoreProjetos;

      return arvoreProjetos;
    } catch (error) {
      console.error(
        "Erreur lors de la construction de l'arbre des projets:",
        error
      );
      return [];
    }
  }
  // atualizarSelecaoRecursivamente : Met à jour récursivement la sélection des projets et de leurs enfants

  atualizarSelecaoRecursivamente(projeto, selecionado, novaListaIdProjet) {
    // Vérifier si le projet existe avant de continuer
    if (!projeto) return;

    try {
      // Modifier la sélection du projet actuel
      if (selecionado) {
        novaListaIdProjet.add(projeto.project_id);
      } else {
        novaListaIdProjet.delete(projeto.project_id);
      }

      // Traiter les enfants uniquement s'ils existent
      if (projeto.children && Array.isArray(projeto.children)) {
        // Utiliser un compteur pour limiter la profondeur de récursion
        const maxDepth = 100; // Limite de sécurité
        let currentDepth = 0;

        // Fonction récursive interne avec limite de profondeur
        const processChildren = (children, depth) => {
          if (depth > maxDepth) return; // Arrêter si la profondeur maximale est atteinte
          children.forEach((child) => {
            if (selecionado) {
              novaListaIdProjet.add(child.project_id);
            } else {
              novaListaIdProjet.delete(child.project_id);
            }
            // Appel récursif pour traiter les enfants des enfants
            if (child.children && Array.isArray(child.children)) {
              processChildren(child.children, depth + 1);
            }
          });
        };

        // Démarrer le traitement des enfants avec la profondeur initiale
        processChildren(projeto.children, currentDepth);
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la sélection:', error);
    }
  }

  // toggleProjetoSelecao : Bascule la sélection d'un projet spécifique

  toggleProjetoSelecao = (projeto) => {
    // Convertir en fonction fléchée pour éviter les problèmes de liaison this
    // Éviter les clics multiples rapides
    if (this.processingToggle) return;
    this.processingToggle = true;

    try {
      // Copier l'état actuel pour le traitement
      const currentListeIdProjet = [...this.state.listeIdProjet]; // Utiliser un tableau plutôt qu'un Set pour plus de fiabilité
      const projetoId = projeto.project_id;
      const index = currentListeIdProjet.indexOf(projetoId);
      const selecionado = index === -1; // Simplifier la logique

      // Mise à jour immédiate (sans récursion)
      if (selecionado) {
        currentListeIdProjet.push(projetoId);
      } else {
        currentListeIdProjet.splice(index, 1);
      }

      // Premier setState pour feedback immédiat
      this.setState(
        {
          listeIdProjet: currentListeIdProjet,
          enlevertous: currentListeIdProjet.length > 0,
        },
        () => {
          // Utiliser un worker séparé pour le traitement lourd
          setTimeout(() => {
            this.processChildrenSelection(projeto, selecionado);
          }, 0);
        }
      );
    } catch (error) {
      console.error('Erreur lors de la sélection du projet:', error);
      this.processingToggle = false; // Réinitialiser en cas d'erreur
    }
  };

  // Nouvelle méthode pour traiter les enfants de manière non-bloquante
  processChildrenSelection = (projeto, selecionado) => {
    try {
      // Traiter les enfants par lots pour éviter de bloquer l'interface
      const processChildren = (children, isSelected) => {
        if (!children || !Array.isArray(children) || children.length === 0)
          return [];

        const batchSize = 10; // Traiter 10 éléments à la fois
        const updatedIds = [...this.state.listeIdProjet];

        // Traiter les enfants par lots
        for (let i = 0; i < children.length; i += batchSize) {
          const currentBatch = children.slice(
            i,
            Math.min(i + batchSize, children.length)
          );

          currentBatch.forEach((child) => {
            const childId = child.project_id;
            const childIndex = updatedIds.indexOf(childId);

            if (isSelected && childIndex === -1) {
              updatedIds.push(childId);
            } else if (!isSelected && childIndex !== -1) {
              updatedIds.splice(childIndex, 1);
            }

            // Traitement récursif des enfants (s'il y en a)
            if (child.children && child.children.length > 0) {
              setTimeout(() => {
                processChildren(child.children, isSelected);
              }, 0);
            }
          });

          // Mettre à jour l'état après chaque lot
          this.setState({
            listeIdProjet: updatedIds,
            enlevertous: updatedIds.length > 0,
          });
        }
      };

      // Démarrer le traitement si des enfants existent
      if (projeto.children && projeto.children.length > 0) {
        processChildren(projeto.children, selecionado);
      }
    } catch (error) {
      console.error('Erreur lors du traitement des enfants:', error);
    } finally {
      // S'assurer que le drapeau est réinitialisé
      setTimeout(() => {
        this.processingToggle = false;
      }, 50);
    }
  };

  // renderArvoreProjetos : Rendu récursif des projets et de leurs enfants sous forme d'arbre
  getChemin(project_id) {
    for (let project of this.props.listeProjets) {
      if (project.project_id === project_id) {
        if (project.parent_id === null) {
          return <span className={S.path}>{project.project_name}</span>;
        } else {
          return [
            this.getChemin(project.parent_id),
            '  /  ',
            <span className={S.path}>{project.project_name}</span>,
          ];
        }
      }
    }
  }

  renderArvoreProjetos(projetonew) {
    const isExpanded = this.state.expandedItems[projetonew.project_id];

    return (
      <div key={projetonew.project_id}>
        <table className={S.padd}>
          <tbody>
            <tr>
              <td>
                {projetonew.children && projetonew.children.length > 0 && (
                  <button
                    className={S.plusParent}
                    onClick={() => this.developeEnfent(projetonew.project_id)}
                  >
                    {isExpanded ? (
                      <FiChevronDown size={20} />
                    ) : (
                      <FiChevronRight size={20} />
                    )}
                  </button>
                )}

                <Switch
                  size="small"
                  className={S.checkSlt}
                  type="checkbox"
                  checked={this.state.listeIdProjet.includes(
                    projetonew.project_id
                  )}
                  onChange={() => this.toggleProjetoSelecao(projetonew)}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    this.handleClickDroit(projetonew, e);
                  }}
                />
              </td>
              <td className={S.idColumn}>{projetonew.project_id}</td>
              <td className={S.nameColumn}>{projetonew.project_name}</td>
            </tr>
          </tbody>
        </table>

        {projetonew.parent_id !== null && (
          <div
            style={{ display: this.state.pathTree ? 'block' : 'none' }}
            className={S.pathTow}
          >
            {' '}
            {this.getChemin(projetonew.project_id)}
          </div>
        )}
        {projetonew.children && projetonew.children.length > 0 && (
          <div
            style={{
              marginLeft: '30px',
              marginTop: '-20px',
              marginBottom: '15px',
            }}
          >
            {isExpanded &&
              projetonew.children.map((child) => (
                <div key={child.project_id} style={{ marginBottom: '-15px' }}>
                  <MdSubdirectoryArrowRight
                    size={20}
                    style={{ marginBottom: '-60px', marginLeft: '-40px' }}
                  />
                  {this.renderArvoreProjetos(child)}
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }

  handleClickDroit = (projeto, event) => {
    // Éviter la propagation de l'événement
    event.preventDefault();

    // Éviter les clics multiples rapides
    if (this.processingClick) return;
    this.processingClick = true;

    // Utiliser requestAnimationFrame pour optimiser la mise à jour
    requestAnimationFrame(() => {
      this.setState(
        (prevState) => {
          const novaListaIdProjet = new Set(prevState.listeIdProjet);

          // Altera a seleção com base no estado atual
          if (novaListaIdProjet.has(projeto.project_id)) {
            novaListaIdProjet.delete(projeto.project_id);
          } else {
            novaListaIdProjet.add(projeto.project_id);
          }

          return {
            listeIdProjet: Array.from(novaListaIdProjet),
            enlevertous: novaListaIdProjet.size > 0,
          };
        },
        () => {
          // Permettre de nouvelles interactions après la mise à jour
          setTimeout(() => {
            this.processingClick = false;
          }, 50);
        }
      );
    });
  };
  // Optimiser la méthode render pour éviter les calculs inutiles à chaque rendu
  render() {
    // Utiliser une méthode memoïsée pour calculer la liste des projets sélectionnés
    const information_projet_liste = this.getSelectedProjects();

    const newButton =
      information_projet_liste.length > 0 || this.state.enlevertous ? (
        <Button
          variant="text"
          onClick={this.removerTodosProjetosSelecionados}
          disabled={this.state.isClearing}
        >
          <VscCloseAll size={20} />
          {checkMot(' Désélectionne cette liste : ')}
        </Button>
      ) : null;

    // Ne construire l'arbre des projets que si nécessaire
    const arvoreProjetos = this.construirArvoreProjetos();

    // Retourner le JSX pour le rendu
    return (
      <>
        <div className={S.ProjSelecMarg}>
          <div className={S.barreUn}>
            <div className={S.inpuTopFilder}>
              <TextField
                className={S.search}
                id="outlined-basic"
                variant="outlined"
                fullWidth
                label={checkMot('recherche par nom ou id')}
                placeholder="Rechercher par nom ou ID"
                value={this.state.listeNameProjet || ''}
                onChange={this.handleSearchChange}
                onKeyDown={(e) => {
                  // Prévenir le comportement par défaut qui pourrait causer des blocages
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div className={S.barreBtn}>
              <span className={S.space}>{newButton}</span>
              {information_projet_liste.length > 0 ? (
                <div className={S.projectList}>
                  {information_projet_liste.map((project, index) => (
                    <div
                      className={S.labell}
                      key={index}
                      onClick={() => this.enleverProjet(project.project_id)}
                      style={{ cursor: 'pointer' }}
                      title={checkMot('Cliquez pour supprimer ce projet')}
                    >
                      <span className={S.iconn}>
                        <VscError size={15} />
                      </span>{' '}
                      <span>{project.project_id}</span>,{' '}
                      <span>{project.project_name}</span>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={S.emptyMessage}>
                  {checkMot('Aucun projet sélectionné')}
                </div>
              )}
            </div>
          </div>
          <div
            className={S.tabl}
            style={{ maxHeight: '300px', overflow: 'auto' }}
          >
            {arvoreProjetos.map((projeto) =>
              this.renderArvoreProjetos(projeto)
            )}
          </div>
        </div>
      </>
    );
  }

  // Ajouter cette méthode pour optimiser le calcul des projets sélectionnés
  getSelectedProjects() {
    if (
      !this.props.listeProjets ||
      !Array.isArray(this.props.listeProjets) ||
      this.state.listeIdProjet.length === 0
    ) {
      return [];
    }

    // Créer un Set pour des recherches plus rapides
    const selectedIds = new Set(this.state.listeIdProjet);

    // Filtrer en une seule passe
    return this.props.listeProjets.filter((project) =>
      selectedIds.has(project.project_id)
    );
  }

  // Ajouter cette méthode à la classe
  handleSearchChange = (event) => {
    // Éviter les problèmes de async
    if (this.searchProcessing) return;
    this.searchProcessing = true;

    try {
      const newValue = event.target.value || '';

      // Mise à jour immédiate et légère (sans reconstruction de l'arbre)
      requestAnimationFrame(() => {
        this.setState({ listeNameProjet: newValue }, () => {
          // Libérer le verrou pour permettre la prochaine mise à jour
          this.searchProcessing = false;

          // Planifier une reconstruction différée de l'arbre et de l'affichage des chemins
          clearTimeout(this._searchTimer);
          this._searchTimer = setTimeout(() => {
            // Cette opération est plus lourde et ne doit pas être faite à chaque frappe
            this.setState({ pathTree: newValue.trim() !== '' });
          }, 500); // Délai plus long pour les recherches complexes
        });
      });
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la recherche:', error);
      // Réinitialiser en cas d'erreur
      this.searchProcessing = false;
      this.setState({
        listeNameProjet: '',
        pathTree: false,
      });
    }
  };
}

/**
 * Connecte le composant à l'état Redux
 * @param {Object} state - L'état global de l'application
 * @returns {Object} Les propriétés mappées depuis l'état
 */
const mapStateToProps = (state) => {
  return {
    listeProjets: state.authentifie.projects_list,
    langue: state.authentifie.langue,
  };
};

/**
 * Connecte les actions Redux au composant
 * @param {Function} dispatch - La fonction dispatch de Redux
 * @returns {Object} Les actions disponibles pour le composant
 */
const mapDispatchToProps = (dispatch) => {
  return {
    // Actions à ajouter si nécessaire
  };
};

// Utilisation de la méthode connect avec mémoisation pour éviter les rendus inutiles
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormulaireSelectionProjetsArborecenseUtilisateur);

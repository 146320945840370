import * as requestType from '../ressources/glossaires/glossaire_requetes';
import {
  ProjectFailureRateResponse,
  SensorFailureRateResponse,
  SensorTypeReponse,
} from '../types/types';
import { _post } from './axios';

export const getFailureRatePerProject = (
  startInterval: number,
  endInterval: number,
  typesIdList: number[],
  criticityIdList: number[],
  filteredProjects: number[]
): Promise<ProjectFailureRateResponse> => {
  return _post({
    request_type: requestType.recuperer_objet_projets_taux_de_pannes,
    start_interval: startInterval,
    end_interval: endInterval,
    types_id_list: typesIdList,
    criticity_id_list: criticityIdList,
    projects_id: filteredProjects,
  });
};

export const getSensorFailureRatePerProject = (
  startInterval: number,
  endInterval: number,
  typesIdList: number[],
  criticityIdList: number[],
  filteredProjects: number[]
): Promise<SensorFailureRateResponse> => {
  return _post({
    request_type: requestType.liste_des_capteurs_defaillants_par_projet,
    start_interval: startInterval,
    end_interval: endInterval,
    types_id_list: typesIdList,
    criticity_id_list: criticityIdList,
    projects_id: filteredProjects,
  });
};

export const getSensorTypesList = (): Promise<SensorTypeReponse> => {
  return _post({
    request_type: requestType.recuperation_de_la_liste_des_sensor_type,
  });
};

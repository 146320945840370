import React, { useState } from 'react';

import S from './ProjetsTauxCriticite.module.scss';

import TableauProjetsTauxCriticite from './TableauProjetsTauxCriticite/TableauProjetsTauxCriticite';

interface ProjetsTauxCriticiteProps {
  start_interval?: number;
  end_interval?: number;
  criticity_id_list: number[];
  types_id_list: number[];
  toolsbar: React.ReactNode;
  sensorList: any[];
}

const ProjetsTauxCriticite: React.FC<ProjetsTauxCriticiteProps> = (props) => {
  const [montrerInfo, setMontrerInfo] = useState(false);

  const ligneSelectione = (info: any) => {
    setMontrerInfo(true);
  };

  const creerVue = () => {
    setMontrerInfo(true);
  };

  //initialisation des varable de style
  let styleConteneurCentralGauche = [S.ConteneurCentralGauche];
  let styleConteneurCentralDroite = [S.ConteneurCentralDroite];

  if (montrerInfo) {
    //style pour un conteneur info ouvert
    styleConteneurCentralDroite.push(S.ConteneurCentralDroiteOuvert);
    // styleConteneurCentralDroite.push(S.ConteneurCentralGaucheOuvert);
  } else {
    //style pour un conteneur info fermé
    styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFermer);
    styleConteneurCentralGauche.push(S.ConteneurCentraGaucheFermer);
  }

  if (montrerInfo) {
    //conteneur info ouvert & plein écran
    //on met la largeur à 100% du conteneur info
    styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFull);
    //on réduit à zéro la largeur du conteneur central
    styleConteneurCentralGauche.push(S.ConteneurCentraGaucheVoid);
  } else if (montrerInfo) {
    //conteneur info ouvert & !plein écran
    styleConteneurCentralDroite.push(S.ConteneurCentralDroiteOuvert);
  } else {
    //conteneur info fermé
    styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFermer);
    styleConteneurCentralGauche.push(S.ConteneurCentraGaucheFermer);
  }

  return (
    <>
      <div className={styleConteneurCentralGauche.join(' ')}>
        <TableauProjetsTauxCriticite
          projetSelectione={ligneSelectione}
          creer={creerVue}
          start_interval={props.start_interval}
          end_interval={props.end_interval}
          criticity_id_list={props.criticity_id_list}
          types_id_list={props.types_id_list}
          sensorList={props.sensorList}
        >
          {props.toolsbar}
        </TableauProjetsTauxCriticite>
      </div>
    </>
  );
};

export default ProjetsTauxCriticite;

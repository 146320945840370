import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import S from './App.module.scss';
import MiseEnPage from './conteneur/MiseEnPage/MiseEnPage';
import ReinitialisationMDP from './conteneur/ReinitialisationMDP/ReinitialisationMDP';
import { LanguageProvider } from './ressources/functionJS/translationService';

function App() {
  useEffect(() => {
    const handleLanguageChange = (e) => {
      if (e.detail?.forceRefresh) {
        // Sauvegarde la position de défilement
        sessionStorage.setItem('scrollPosition', window.scrollY.toString());

        // Force le rafraîchissement
        window.location.reload();
      }
    };

    window.addEventListener('languageChange', handleLanguageChange);

    // Restaure la position de défilement après un rafraîchissement
    const savedPos = sessionStorage.getItem('scrollPosition');
    if (savedPos) {
      window.scrollTo(0, parseInt(savedPos, 10));
      sessionStorage.removeItem('scrollPosition');
    }

    return () => {
      window.removeEventListener('languageChange', handleLanguageChange);
    };
  }, []);

  return (
    <LanguageProvider>
      <div className={S.Theme}>
        <Route path="/" component={() => <MiseEnPage />} />
        <Route
          path="/reinitialisation"
          component={() => <ReinitialisationMDP />}
        />
      </div>
    </LanguageProvider>
  );
}

export default App;

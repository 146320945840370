import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import { ProgressBarLine } from 'react-progressbar-line';

import { checkMot } from '../../../ressources/functionJS/checkMot';

class MdpMetre extends Component {
  createPasswordLabel = (result) => {
    // this.props.getMDPForce(result.score);
    switch (result.score) {
      case 0:
        return checkMot('faible');
      case 1:
        return checkMot('faible');
      case 2:
        return checkMot('raisonnable');
      case 3:
        return checkMot('bon');
      case 4:
        return checkMot('fort');
      default:
        return checkMot('faible');
    }
  };
  render() {
    const password = this.props.password;
    const testedResult = this.props.valeurForceMDP;
    return (
      <>
        <div className={s.row}>
          <div className={s.col_sm_7}>
            {/* <progress  value={testedResult.score} max="4"/> */}
            <ProgressBarLine
              value={testedResult.score}
              min={0}
              max={4}
              strokeWidth={5}
              trailWidth={5}
              text={password && <>{this.createPasswordLabel(testedResult)}</>}
              styles={{
                path: {
                  stroke: '#f5cb5a',
                },
                trail: {
                  stroke: '#f1f1f1',
                },
                text: {
                  fill: '#a6163f',
                  textAlign: 'center',
                  fontSize: '14px',
                },
              }}
            />
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dicoLangues: state.dico.dicoLangues,
    langueSite: state.dico.langueSite,
  };
};

export default connect(mapStateToProps)(MdpMetre);

import React, { Component, Fragment } from 'react';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import BootstrapTable from 'react-bootstrap-table-next';

import FormulaireYScale from './FormulaireYScale/FormulaireYScale';
import FormulaireSelectionDateUnix from '../../../Ajouter/SousComposantTable/SelectionDateForUnixTimestamp/FormulaireSelectionDateUnix';
import Bouton from '../../../../UI/Bouton/Bouton';

import { stringOrEmptyString } from '../../../../../ressources/functionJS/stringOrEmptyString';
import { unixTimestampToDate } from '../../../../../ressources/functionJS/unixTimestampToDate';
import { FloatChampInput } from '../../../../../ressources/functionJS/FloatChampInput';
import { confirmeOnClick } from '../../../../../ressources/functionJS/confirmeOnClick';
import { checkMot } from '../../../../../ressources/functionJS/checkMot';

import { MdAddBox } from 'react-icons/md';
import * as S from './ModifierComposante.module.scss';

class ModifierComposante extends Component {
  componentDidMount() {
    //Duplique la liste des composantes
    let newComponent_list = new Array(this.props.component_list.length);
    for (
      let indComponentList = 0;
      indComponentList < this.props.component_list.length;
      indComponentList++
    ) {
      newComponent_list[indComponentList] = {
        ...this.props.component_list[indComponentList],
        bonton_supprimer: (
          <Bouton
            cliquer={() =>
              confirmeOnClick(
                checkMot('voulez_vous_vraiment_supprimer_cette_composante'),
                () =>
                  this.supprimerComposante(
                    this.props.component_list[indComponentList].component_id
                  )
              )
            }
          >
            {checkMot('supprimer')}
          </Bouton>
        ),
        bonton_dupliquer: (
          <Bouton
            cliquer={() =>
              this.dupliquerComposante(
                this.props.component_list[indComponentList]
              )
            }
          >
            {checkMot('dupliquer')}
          </Bouton>
        ),
      };
    }
    this.setState({ component_list: newComponent_list });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.actualiseComposantesListe(prevState);
  }

  state = {
    columns: [
      {
        dataField: 'bonton_dupliquer',
        text: checkMot('dupliquer'),
        editable: false,
      },
      {
        dataField: 'bonton_supprimer',
        text: checkMot('supprimer'),
        editable: false,
      },
      {
        dataField: 'component_id',
        text: checkMot('id_composante'),
        sort: true,
        hidden: true,
      },
      {
        dataField: 'position_in_sensor',
        text: checkMot('position_in_sensor'),
        sort: true,
      },
      {
        dataField: 'forumula',
        text: checkMot('forumula'),
        headerStyle: { padding: '150px' },
        sort: true,
        editor: { type: Type.TEXTAREA },
        editCellStyle: {
          height: '100px',
        },
      },
      { dataField: 'prefix', text: checkMot('prefix'), sort: true },
      { dataField: 'engineering_unit', text: checkMot('unite'), sort: true },
      {
        dataField: 'precision_decimal_notation',
        text: checkMot('resolution_decimales'),
        sort: true,
        formatter: (cell) => {
          return FloatChampInput(cell);
        },
      },
      {
        dataField: 'start_formula',
        text: checkMot('start_formula'),
        sort: true,
        editorRenderer: (editorProps, value) => (
          <FormulaireSelectionDateUnix
            {...editorProps}
            defaultValue={value}
            value={value}
          />
        ),
        formatter: (cell) => {
          return unixTimestampToDate(cell);
        },
      },
      {
        dataField: 'end_formula',
        text: checkMot('end_formula'),
        sort: true,
        editorRenderer: (editorProps, value) => (
          <FormulaireSelectionDateUnix
            {...editorProps}
            defaultValue={value}
            value={value}
          />
        ),
        formatter: (cell) => {
          return unixTimestampToDate(cell);
        },
      },
      {
        dataField: 'calibration_factors',
        text: checkMot('calibration_factors'),
        sort: true,
      },
      {
        dataField: 'y_scale',
        text: checkMot('y_scale'),
        sort: true,
        editorRenderer: (editorProps, value) => (
          <FormulaireYScale
            {...editorProps}
            defaultValue={value}
            value={value}
          />
        ),
        formatter: (cell) => {
          if (Array.isArray(cell)) {
            return (
              '[' +
              (cell[0] === null ? 'null' : cell[0]) +
              ', ' +
              (cell[1] === null ? 'null' : cell[1]) +
              ']'
            );
          } else {
            return '[aucun]';
          }
        },
      },
      { dataField: 'time_period', text: checkMot('time_period'), sort: true },
      { dataField: 'measures_num', text: checkMot('measures_num'), sort: true },
      {
        dataField: 'serial_number',
        text: checkMot('serial_number'),
        sort: true,
      },
      { dataField: 'coordinate_x', text: checkMot('coordinate_x'), sort: true },
      { dataField: 'coordinate_y', text: checkMot('coordinate_y'), sort: true },
      { dataField: 'coordinate_z', text: checkMot('coordinate_z'), sort: true },
      {
        dataField: 'alarmlevel_high4',
        text: checkMot('alarmlevel_high4_black'),
        sort: true,
      },
      {
        dataField: 'alarmlevel_high3',
        text: checkMot('alarmlevel_high3_red'),
        sort: true,
      },
      {
        dataField: 'alarmlevel_high2',
        text: checkMot('alarmlevel_high2_amber'),
        sort: true,
      },
      {
        dataField: 'alarmlevel_high1',
        text: checkMot('alarmlevel_high1_blue'),
        sort: true,
      },
      {
        dataField: 'alarmlevel_low1',
        text: checkMot('alarmlevel_low1_blue'),
        sort: true,
      },
      {
        dataField: 'alarmlevel_low2',
        text: checkMot('alarmlevel_low2_amber'),
        sort: true,
      },
      {
        dataField: 'alarmlevel_low3',
        text: checkMot('alarmlevel_low3_red'),
        sort: true,
      },
      {
        dataField: 'alarmlevel_low4',
        text: checkMot('alarmlevel_low4_black'),
        sort: true,
      },
      { dataField: 'time_refresh', text: checkMot('time_refresh'), sort: true },
    ],
    incre: 0,
    component_list: [],
  };
  actualiseComposantesListe = (prevState) => {
    /**
     * Met à jour la liste des composantes du capteur
     */
    if (
      JSON.stringify(prevState.component_list) !==
      JSON.stringify(this.state.component_list)
    ) {
      //Duplique la liste des composantes
      let newComponent_list = new Array(this.state.component_list.length);
      for (
        let indComponentList = 0;
        indComponentList < this.state.component_list.length;
        indComponentList++
      ) {
        newComponent_list[indComponentList] = {
          component_id:
            this.state.component_list[indComponentList].component_id,
          sensor_id: parseInt(this.props.sensor_id),
          position_in_sensor: parseInt(
            this.state.component_list[indComponentList].position_in_sensor
          ),
          serial_number: stringOrEmptyString(
            this.state.component_list[indComponentList].serial_number
          ),
          coordinate_x: parseInt(
            this.state.component_list[indComponentList].coordinate_x
          ),
          coordinate_y: parseInt(
            this.state.component_list[indComponentList].coordinate_y
          ),
          coordinate_z: parseInt(
            this.state.component_list[indComponentList].coordinate_z
          ),
          forumula: stringOrEmptyString(
            this.state.component_list[indComponentList].forumula
          ),
          prefix: stringOrEmptyString(
            this.state.component_list[indComponentList].prefix
          ),
          engineering_unit: stringOrEmptyString(
            this.state.component_list[indComponentList].engineering_unit
          ),
          precision_decimal_notation: parseFloat(
            this.state.component_list[indComponentList]
              .precision_decimal_notation
          ),
          alarmlevel_high4: parseFloat(
            this.state.component_list[indComponentList].alarmlevel_high4
          ),
          alarmlevel_high3: parseFloat(
            this.state.component_list[indComponentList].alarmlevel_high3
          ),
          alarmlevel_high2: parseFloat(
            this.state.component_list[indComponentList].alarmlevel_high2
          ),
          alarmlevel_high1: parseFloat(
            this.state.component_list[indComponentList].alarmlevel_high1
          ),
          alarmlevel_low1: parseFloat(
            this.state.component_list[indComponentList].alarmlevel_low1
          ),
          alarmlevel_low2: parseFloat(
            this.state.component_list[indComponentList].alarmlevel_low2
          ),
          alarmlevel_low3: parseFloat(
            this.state.component_list[indComponentList].alarmlevel_low3
          ),
          alarmlevel_low4: parseFloat(
            this.state.component_list[indComponentList].alarmlevel_low4
          ),
          start_formula: parseInt(
            this.state.component_list[indComponentList].start_formula
          ),
          end_formula: parseInt(
            this.state.component_list[indComponentList].end_formula
          ),
          calibration_factors: stringOrEmptyString(
            this.state.component_list[indComponentList].calibration_factors
          ),
          y_scale: this.state.component_list[indComponentList].y_scale,
          time_period: parseInt(
            this.state.component_list[indComponentList].time_period
          ),
          measures_num: parseInt(
            this.state.component_list[indComponentList].measures_num
          ),
          time_refresh: parseFloat(
            this.state.component_list[indComponentList].time_refresh
          ),
        };
      }
      //retire l'id nouvelle composante de la liste de composant et les boutons supprimer
      for (
        let indComponent = 0;
        indComponent < newComponent_list.length;
        indComponent++
      ) {
        if (isNaN(newComponent_list[indComponent]['component_id'])) {
          newComponent_list[indComponent]['component_id'] = null;
        } else {
          newComponent_list[indComponent].component_id = parseInt(
            newComponent_list[indComponent].component_id
          );
        }
        delete newComponent_list[indComponent]['bonton_supprimer'];
      }
      this.props.gererComposantes(newComponent_list);
    }
  };
  dupliquerComposante = (composants_donnees) => {
    const nouvelleIdDefaut = String(
      checkMot('Nouveau composant n°') + this.state.incre
    );
    const newListe = [
      ...this.state.component_list,
      {
        ...composants_donnees,
        component_id: nouvelleIdDefaut,

        bonton_supprimer: (
          <Bouton cliquer={() => this.supprimerComposante(nouvelleIdDefaut)}>
            {checkMot('supprimer')}
          </Bouton>
        ),
        bonton_dupliquer: checkMot('non_duplicable'),
      },
    ];
    this.setState({
      component_list: newListe,
      incre: this.state.incre + 1,
    });
  };
  AjoutLigne = () => {
    const nouvelleIdDefaut = String('Nouveau composant n°' + this.state.incre);
    const newListe = [
      ...this.state.component_list,
      {
        component_id: nouvelleIdDefaut,
        sensor_id: parseInt(this.props.sensor_id),
        position_in_sensor: null,
        serial_number: null,
        coordinate_x: null,
        coordinate_y: null,
        coordinate_z: null,
        forumula: null,
        prefix: null,
        engineering_unit: null,
        precision_decimal_notation: null,
        alarmlevel_high4: null,
        alarmlevel_high3: null,
        alarmlevel_high2: null,
        alarmlevel_high1: null,
        alarmlevel_low1: null,
        alarmlevel_low2: null,
        alarmlevel_low3: null,
        alarmlevel_low4: null,
        start_formula: null,
        end_formula: null,
        calibration_factors: null,
        y_scale: [null, null],
        time_period: null,
        measures_num: 100,
        time_refresh: null,
        bonton_supprimer: (
          <Bouton cliquer={() => this.supprimerComposante(nouvelleIdDefaut)}>
            {checkMot('supprimer')}
          </Bouton>
        ),
        bonton_dupliquer: checkMot('non_duplicable'),
      },
    ];
    this.setState({
      component_list: newListe,
      incre: this.state.incre + 1,
    });
  };
  supprimerComposante = (composanteId) => {
    let newComponent_list = [...this.state.component_list];
    for (
      let indComposante = 0;
      indComposante < this.state.component_list.length;
      indComposante++
    ) {
      if (
        composanteId === this.state.component_list[indComposante].component_id
      ) {
        newComponent_list.splice(indComposante, 1);
      }
    }
    this.setState({ ...this.state, component_list: newComponent_list });
  };

  render() {
    const cellEdit = cellEditFactory({
      mode: 'click',
      blurToSave: true,
      afterSaveCell: () => {
        this.actualiseComposantesListe(this.props.component_list);
      },
    });
    return (
      <Fragment>
        <BootstrapTable
          striped
          hover
          keyField={'component_id'}
          data={this.state.component_list}
          columns={this.state.columns}
          classes={S.TableauBootstrap}
          cellEdit={cellEdit}
        />
        <button onClick={this.AjoutLigne} className={S.btn_stylo}>
          <MdAddBox />
          {checkMot('ajouter_une_composante')}
        </button>
      </Fragment>
    );
  }
}

export default ModifierComposante;

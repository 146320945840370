import { Position } from 'geojson';
import { GeoJSON } from 'leaflet';

import * as S from '../../composant/Vue/AffichageVue/AffichageVue.module.scss';

export const setUrlPathFromDomain = (
  pathForDev: string,
  pathForProd: string,
  defaultPath: string = pathForDev
): string => {
  if (document.domain === 'localhost') {
    return pathForDev;
  } else if (
    document.domain === 'argos-v19.itmsol.fr' ||
    document.domain === 'argos-dev.itmsol.fr' ||
    document.domain === 'argos-dev-test.itmsol.fr'
  ) {
    return pathForProd;
  } else {
    return defaultPath;
  }
};

export function debounce<T extends (...args: any[]) => any>(
  fn: T,
  ms: number
): (...args: Parameters<T>) => void {
  let timer: ReturnType<typeof setTimeout> | undefined;

  return (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
      fn(...args);
    }, ms);
  };
}

export const createGeoJSONCircle = (
  center: number[],
  radiusInKm: number,
  points: number
) => {
  if (!points) points = 64;

  var coords = {
    latitude: center[1],
    longitude: center[0],
  };

  var km = radiusInKm;

  var ret = [];
  var distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
  var distanceY = km / 110.574;

  var theta, x, y;
  for (var i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);

  return {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [ret],
    },
  };
};
export const adjustValueToProvidedDistanceUnit = (
  valeur: number,
  unite: string
) => {
  unite = unite.trim().toLowerCase();
  switch (unite) {
    case 'km':
      return valeur;
    case 'm':
      return valeur / 1000;
    case 'dm':
      return valeur / 10000;
    case 'cm':
      return valeur / 100000;
    default:
      console.error('rayonUnity non prit en charge : ', unite);
      return valeur;
  }
};

export function projectLineString(lineString: {
  coordinates: Position[];
  properties: any;
}) {
  // Vérifie que les coordonnées sont valides
  if (
    !lineString ||
    !lineString.coordinates ||
    !Array.isArray(lineString.coordinates)
  ) {
    return null;
  }

  const isFiniteNumber = (value: any) => !isNaN(value) && isFinite(value);

  if (
    !lineString.coordinates.every(
      (coords) => Array.isArray(coords) && coords.every(isFiniteNumber)
    )
  ) {
    return null;
  }
  /*
    // Définit les systèmes de coordonnées source et de destination
    proj4.defs(sourceCRS, proj4.defs('EPSG:4326'));
    proj4.defs(destinationCRS, '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +units=m +no_defs');
    // Projette les coordonnées de la ligne GeoJSON
    const coordinates = lineString.coordinates.map(coords => proj4(sourceCRS, destinationCRS, coords));
    // Crée une nouvelle feature GeoJSON projetée
    const feature = {
        type: 'Feature',
        properties: lineString.properties,
        geometry: {
            type: 'LineString',
            coordinates: coordinates,
        },
    };
    */
  // Crée une nouvelle feature GeoJSON projetée
  const feature: GeoJSON.Feature = {
    type: 'Feature',
    properties: lineString.properties,
    geometry: {
      type: 'LineString',
      coordinates: lineString.coordinates,
    },
  };

  // Retourne une instance GeoJSON
  return new GeoJSON(feature);
}

const sensorColorArray = [
  { color: 'green', classColor: S.SensorLevel_green },
  { color: 'blue', classColor: S.SensorLevel_blue },
  { color: 'orange', classColor: S.SensorLevel_orange },
  { color: 'red', classColor: S.SensorLevel_red },
  { color: 'black', classColor: S.SensorLevel_black },
];

export const getSensorColor = (index: any) => {
  if (typeof index === 'number') {
    return sensorColorArray[index]
      ? sensorColorArray[index]
      : { color: 'grey', classColor: S.SensorLevel_grey };
  }
  return { color: 'grey', classColor: S.SensorLevel_grey };
};

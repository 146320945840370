import React, { Component } from 'react';
import { connect } from 'react-redux';

import ChangerDeMDP from './ChangerDeMDP/ChangerDeMDP';

import Bouton from '../UI/Bouton/Bouton';
import * as actions from '../../services/actions';
import { stringArray } from '../../ressources/functionJS/stringArray';
import * as type_btn from '../../ressources/glossaires/glossaire_type_de_bouton';
import { checkMot } from '../../ressources/functionJS/checkMot';

import { MdDone, MdClose, MdModeEdit } from 'react-icons/md';
import S from './Profil.module.scss';
import * as s from '../../ressources/StyleGlobal/StyleGlobal';

class Profil extends Component {
  state = {
    idUtilisateur: this.props.user_id,
    prenom: this.props.lastname,
    nom: this.props.name,
    privileges_list: this.props.privileges_list,
    email: this.props.email,
    indexTelephone: this.props.phone_index,
    numeroTelephone: this.props.phone_number,
    entreprise: this.props.company_name,
    imageProfil: this.props.profil_image,
    ancienMotDePasse: '',
    nouveauMotDePasse: '',
    confirmeNouveauMotDePasse: '',
    afficheFormulaireDeModificationBool: false,
    afficheFormulaireDeModificationMDPBool: false,
  };
  gererPrenom = (event) => this.setState({ prenom: event.target.value });
  gererNom = (event) => this.setState({ nom: event.target.value });
  gererEmail = (event) => this.setState({ email: event.target.value });
  gererEntreprise = (event) =>
    this.setState({ entreprise: event.target.value });
  gererNumeroTelephone = (event) =>
    this.setState({ numeroTelephone: event.target.value });
  gererIndexTelephone = (event) =>
    this.setState({ indexTelephone: event.target.value });

  envoieModifierFormulaire = () => {
    let formulaireDeModificationUtilisateur = {
      user_id: this.props.user_id,
      login_name: this.state.email,
      name: this.state.nom,
      lastname: this.state.prenom,
      email: this.state.email,
      phone_number: this.state.indexTelephone,
      phone_index: this.state.numeroTelephone,
      company_name: this.state.entreprise,
      active: 1,
      privileges_list: this.props.privileges_list,
      projects_list_id: Object.keys(this.props.projects_list).map(
        (indProjet) => this.props.projects_list[indProjet].project_id
      ),
    };
    this.props.modifierProfil(formulaireDeModificationUtilisateur);
  };

  modifierFormulaire = () =>
    this.setState({ afficheFormulaireDeModificationBool: true });
  modifierFormulaireMDP = () =>
    this.setState({ afficheFormulaireDeModificationMDPBool: true });
  annulerModification = () =>
    this.setState({ afficheFormulaireDeModificationBool: false });
  annulerModificationMDP = () =>
    this.setState({ afficheFormulaireDeModificationMDPBool: false });

  render() {
    let classeProfil = S.Profil;

    const profil = (
      <>
        <div className={[S.ppro, s.card].join(' ')}>
          <div className={[s.row, s.card_body].join(' ')}>
            <div className={s.col}>
              <div className={[s.col_md_auto, S.label_profil].join(' ')}>
                {checkMot('nom_de_famille')}
              </div>
              <div className={s.col_md_auto}>
                <input
                  className={S.text_general_a}
                  value={this.props.lastname}
                  readOnly={true}
                />
              </div>

              <div className={[s.col_md_auto, S.label_profil].join(' ')}>
                {checkMot('prenom')}
              </div>
              <div className={s.col_md_auto}>
                <input
                  className={S.text_general_a}
                  value={this.props.name}
                  readOnly={true}
                />
              </div>

              <div className={[s.col_md_auto, S.label_profil].join(' ')}>
                {checkMot('privilege_list')}
              </div>
              <div className={s.col_md_auto}>
                <input
                  className={S.text_general_a}
                  value={
                    this.props.privileges_list !== undefined
                      ? this.props.privileges_list.join(', ')
                      : ''
                  }
                  readOnly={true}
                />
              </div>

              <div className={[s.col_md_auto, S.label_profil].join(' ')}>
                {checkMot('email')}
              </div>
              <div className={s.col_md_auto}>
                <input
                  className={S.text_general_a}
                  value={this.props.email}
                  readOnly={true}
                />
              </div>
            </div>
            <div className={s.col}>
              <div className={[s.col_md_auto, S.label_profil].join(' ')}>
                {checkMot('phone_index')}:
              </div>
              <div className={s.col_md_auto}>
                <input
                  className={[S.text_general_a].join(' ')}
                  value={this.props.phone_index}
                  readOnly={true}
                />
              </div>

              <div className={[s.col_md_auto, S.label_profil].join(' ')}>
                {checkMot('numero_de_telephone')}:
              </div>
              <div className={s.col_md_auto}>
                <input
                  className={[S.text_general_a].join(' ')}
                  value={this.props.phone_number}
                  readOnly={true}
                />
              </div>

              <div className={[s.col_md_auto, S.label_profil].join(' ')}>
                {checkMot('company_name')}
              </div>
              <div className={[s.col_md_auto, S.label_profil].join(' ')}>
                <input
                  className={S.text_general_a}
                  value={this.props.company_name}
                  readOnly={true}
                />
              </div>
            </div>
            <div className={s.col_12}>
              <div className={s.row}>
                <div className={[s.col_md_auto, S.btnModifierB1].join(' ')}>
                  <Bouton cliquer={this.modifierFormulaire}>
                    <MdModeEdit />
                    {checkMot('modifier')}
                  </Bouton>
                </div>
                <div className={[s.col_md_auto, S.btnModifierB2].join(' ')}>
                  <Bouton cliquer={this.modifierFormulaireMDP}>
                    <MdModeEdit /> {checkMot('changer_mot_de_passe')}
                  </Bouton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
    /**
     * Formulaires
     **/
    const formulaireDeModification = (
      <>
        <div className={[S.ppro, s.card].join(' ')}>
          <div className={[s.row, s.card_body].join(' ')}>
            <div className={s.col}>
              <div className={[s.col_md_auto, S.label_profil].join(' ')}>
                {checkMot('nom')}
              </div>
              <div className={s.col_md_auto}>
                <input
                  className={S.text_general}
                  value={this.state.prenom}
                  onChange={this.gererPrenom}
                  placeholder={this.props.lastname}
                />
              </div>

              <div className={[s.col_md_auto, S.label_profil].join(' ')}>
                {checkMot('prenom')}
              </div>
              <div className={s.col_md_auto}>
                <input
                  className={S.text_general}
                  value={this.state.nom}
                  onChange={this.gererNom}
                  placeholder={this.props.name}
                />
              </div>

              <div className={[s.col_md_auto, S.label_profil].join(' ')}>
                {checkMot('privilege_list')}
              </div>
              <div className={s.col_md_auto}>
                <input
                  className={S.text_general}
                  value={stringArray(this.props.privileges_list)}
                  readOnly={true}
                />
              </div>

              <div className={[s.col_md_auto, S.label_profil].join(' ')}>
                {checkMot('email')}
              </div>
              <div className={s.col_md_auto}>
                <input
                  className={S.text_general}
                  value={this.state.email}
                  onChange={this.gererEmail}
                  placeholder={this.props.email}
                  readOnly={true}
                />
              </div>
            </div>
            <div className={s.col}>
              <div className={[s.col_md_auto, S.label_profil].join(' ')}>
                {checkMot('phone_index')}
              </div>
              <div className={s.col_md_auto}>
                <input
                  className={S.text_general}
                  value={this.state.indexTelephone}
                  onChange={this.gererIndexTelephone}
                  placeholder={this.props.phone_index}
                />
              </div>

              <div className={[s.col_md_auto, S.label_profil].join(' ')}>
                {checkMot('numero_de_telephone')}
              </div>
              <div className={s.col_md_auto}>
                <input
                  className={S.text_general}
                  value={this.state.numeroTelephone}
                  onChange={this.gererNumeroTelephone}
                  placeholder={this.props.phone_number}
                />
              </div>

              <div className={[s.col_md_auto, S.label_profil].join(' ')}>
                {checkMot('company_name')}
              </div>
              <div className={s.col_md_auto}>
                <input
                  className={S.text_general}
                  value={this.state.entreprise}
                  onChange={this.gererEntreprise}
                  placeholder={this.props.company_name}
                />
              </div>
            </div>
            <div className={s.col_12}>
              <div className={s.row}>
                <div className={[s.col_md_auto, S.btnModifierB1A].join(' ')}>
                  <Bouton
                    cliquer={this.annulerModification}
                    type={type_btn.danger}
                  >
                    <MdClose /> {checkMot('annuler')}
                  </Bouton>
                </div>
                <div className={[s.col_md_auto, S.btnModifierB1B].join(' ')}>
                  <Bouton
                    cliquer={this.envoieModifierFormulaire}
                    type={type_btn.succes}
                  >
                    <MdDone /> {checkMot('envoyer')}
                  </Bouton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
    const formulaireDeModificationMDP = (
      <ChangerDeMDP
        validationChangementMotDePasse={
          this.props.validationChangementMotDePasse
        }
        changerDeMDP={this.props.changerDeMDP}
        idUtilisateur={this.props.user_id}
        annulerModificationMDP={this.annulerModificationMDP}
      />
    );
    return (
      <div className={[classeProfil].join(' ')}>
        {this.state.afficheFormulaireDeModificationMDPBool ? (
          formulaireDeModificationMDP
        ) : (
          <>
            <div className={[S.profilH1, s.card_header].join(' ')}>
              {checkMot('profil')}
            </div>
            {this.state.afficheFormulaireDeModificationBool
              ? formulaireDeModification
              : profil}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.authentifie.user_id,
    lastname: state.authentifie.lastname,
    name: state.authentifie.name,
    privileges_list: state.authentifie.privileges_list,
    email: state.authentifie.email,
    phone_index: state.authentifie.phone_index,
    phone_number: state.authentifie.phone_number,
    company_name: state.authentifie.company_name,
    profil_image: state.authentifie.profil_image,
    projects_list: state.authentifie.projects_list,
    validationChangementMotDePasse:
      state.authentifie.validationChangementMotDePasse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    modifierProfil: (formulaireDeModificationUtilisateur) =>
      dispatch(
        actions.reqModifierUtilisateur(formulaireDeModificationUtilisateur)
      ),
    changerDeMDP: (mdpActuel, nouveauMDP, confirmationNouveauMDP) =>
      dispatch(
        actions.reqChangerMDP(mdpActuel, nouveauMDP, confirmationNouveauMDP)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profil);

import * as actionTypes from './actionTypes';

export const fermetureMenuGauche = () => {
  return { type: actionTypes.FERMETURE_MENU_GAUCHE };
};
export const ouvertureMenuGauche = () => {
  return { type: actionTypes.OUVERTURE_MENU_GAUCHE };
};
export const fermetureEnTete = () => {
  return { type: actionTypes.FERMETURE_EN_TETE };
};
export const ouvertureEnTete = () => {
  return { type: actionTypes.OUVERTURE_EN_TETE };
};

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { checkMot } from '../../ressources/functionJS/checkMot';
import FormDuration from '../UI/FormDuration/FormDuration';
import FormulaireSelectionMultipleTypeCapteur from '../UI/FormulaireSelectionMultipleTypeCapteur/FormulaireSelectionMultipleTypeCapteur';
import MultipleSelect from '../UI/MultipleSelect/MultipleSelect';
import ArborescenceConteneur from './ArborescenceConteneur/ArborescenceConteneur';
import S from './Maintenance.module.scss';
import ProjetsTauxCriticite from './ProjetsTauxCriticite/ProjetsTauxCriticite';
import { Project } from '../../types/types';
import {
  getFailureRatePerProject,
  getSensorFailureRatePerProject,
} from '../../services/api';
import Bouton from '../UI/Bouton/Bouton';

import * as type_btn from '../../ressources/glossaires/glossaire_type_de_bouton';
import { VscRefresh } from 'react-icons/vsc';

const parProjet = 'parProjet';
const parCapteur = 'parCapteur';

interface MaintenanceProps {
  filtre_projects_list_id: number[];
  project_id_url: number | undefined | null;
  projects_list: any[];
}

const Maintenance = (props: MaintenanceProps) => {
  const [ongletSelectionne, setOngletSelectionne] = useState<string>(parProjet);
  const [types_id_list, setTypes_id_list] = useState<number[]>([]);
  const [criticity_id_list, setCriticity_id_list] = useState<any[]>(['null']);
  const [time_period, setTime_period] = useState<number>(24 * 60 * 60);
  const [start_interval, setStart_interval] = useState<number>(
    new Date().getTime() - 24 * 60 * 60 * 1000
  );
  const [end_interval, setEnd_interval] = useState<number>(
    new Date().getTime()
  );
  const [duration, setDuration] = useState<number>(1);
  const [timeUnit, setTimeUnit] = useState<string>('jours');
  const [projectList, setProjectList] = useState<Project[] | undefined>(
    undefined
  );
  const [sensorList, setSensorList] = useState<any | undefined>(undefined);

  useEffect(() => {
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    let endOfDay = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    let unixTimestamp = Math.floor(endOfDay.getTime() / 1000);
    let start_interval = unixTimestamp - parseInt(time_period.toString());
    setStart_interval(start_interval);
    setEnd_interval(unixTimestamp);
  }, [time_period]);

  const getData = () => {
    try {
      if (ongletSelectionne === parProjet) {
        const getFailureRate = async () => {
          const response = await getFailureRatePerProject(
            start_interval!,
            end_interval!,
            types_id_list.map((value) => parseInt(value.toString())),
            criticity_id_list.map((value) => parseInt(value.toString())),
            props.filtre_projects_list_id
          );
          setProjectList(response.projects_list);
        };
        getFailureRate();
      } else {
        const getSensorFailureRate = async () => {
          const response = await getSensorFailureRatePerProject(
            start_interval,
            end_interval,
            types_id_list.map((value) => parseInt(value.toString())),
            criticity_id_list.map((value) => parseInt(value.toString())),
            props.filtre_projects_list_id
          );
          setSensorList(response.sensors_list);
        };
        getSensorFailureRate();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, [ongletSelectionne]);

  const gererCriticities_id = (criticity_id_list: number[]) => {
    setCriticity_id_list(criticity_id_list);
  };
  const gererSensor_types_id = (types_id_list: number[]) => {
    setTypes_id_list(types_id_list);
  };
  const gererTimePeriod = (
    timePeriod: number,
    timeUnit: string,
    timeValue: number
  ) => {
    const startInterval = end_interval! - parseInt(timePeriod.toString());
    setTime_period(parseInt(timePeriod.toString()));
    setTimeUnit(timeUnit);
    setDuration(timeValue);
    setStart_interval(startInterval);
  };
  const handleProjetCapteurDefaillant = () => {
    setOngletSelectionne(parCapteur);
  };

  let moduleOnglet;
  let toolsbar = [
    <FormDuration
      setTime={gererTimePeriod}
      duration={duration}
      timeUnit={timeUnit}
      key={'FormDuration'}
    />,
    <MultipleSelect
      preconfigurerListe={criticity_id_list}
      label={checkMot('criticite')}
      namesList={[
        { label: '5', value: 5 },
        { label: '4', value: 4 },
        { label: '3', value: 3 },
        { label: '2', value: 2 },
        { label: '1', value: 1 },
        { label: 'null', value: null },
      ]}
      labelKey={'label'}
      valueKey={'value'}
      sx={{ m: 0, minWidth: 80, maxWidth: 120, marginLeft: 1 }}
      gererListeSelectionne={gererCriticities_id}
      key={'MultipleSelectCriticity'}
    />,
    <FormulaireSelectionMultipleTypeCapteur
      gererSensor_types_id={gererSensor_types_id}
      key={'FormulaireSelectionMultipleTypeCapteur'}
    />,
    <Bouton
      key={'refresh-projets-list-maintenance-criticity'}
      overText={checkMot('refresh')}
      type={type_btn.outil}
      rond={true}
      cliquer={() => getData()}
    >
      <VscRefresh size={15} />
    </Bouton>,
  ];
  switch (ongletSelectionne) {
    case parProjet:
      moduleOnglet = projectList && (
        <ArborescenceConteneur
          toolsbar={toolsbar}
          start_interval={start_interval}
          end_interval={end_interval}
          criticity_id_list={criticity_id_list}
          types_id_list={types_id_list}
          filtre_projects_list_id={props.filtre_projects_list_id}
          projectList={projectList}
          handleProjetCapteurDefaillant={handleProjetCapteurDefaillant}
          afficherBarreDeRecherche={false}
        />
      );
      break;
    case parCapteur:
      moduleOnglet = (
        <ProjetsTauxCriticite
          toolsbar={toolsbar}
          start_interval={start_interval}
          end_interval={end_interval}
          criticity_id_list={criticity_id_list}
          types_id_list={types_id_list}
          sensorList={sensorList}
        />
      );
      break;
    default:
      moduleOnglet = (
        <div style={{ position: 'absolute', top: '50px', left: '15px' }}>
          {checkMot('selectionner_un_onglet')}
        </div>
      );
      break;
  }

  return (
    <>
      <div className={[S.ConteneurVue].join(' ')}>
        <div className={S.barConteneur}>
          <div className={S.barOnglet}>
            <div
              title={checkMot('par_projet')}
              className={[
                S.titreOnglet,
                ongletSelectionne === parProjet ? S.titreOngletSelectionne : '',
              ].join(' ')}
              onClick={() => setOngletSelectionne(parProjet)}
            >
              {' '}
              {checkMot('par_projet')}{' '}
            </div>
            <div
              title={checkMot('par_capteur')}
              className={[
                S.titreOnglet,
                ongletSelectionne === parCapteur
                  ? S.titreOngletSelectionne
                  : '',
              ].join(' ')}
              onClick={() => setOngletSelectionne(parCapteur)}
            >
              {' '}
              {checkMot('par_capteur')}{' '}
            </div>
          </div>
        </div>
        {moduleOnglet}
      </div>
    </>
  );
};

const mapStateToProps = (state: {
  authentifie: { filtre_projects_list_id: number[] };
  interfaceClient: { project_id_url: number | undefined | null };
  maintenance: { projects_list: any };
}) => {
  return {
    filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
    project_id_url: state.interfaceClient.project_id_url,
    projects_list: state.maintenance.projects_list,
  };
};

export default connect(mapStateToProps)(Maintenance);

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { jsDictionaryEquals } from '../../../../../../../../ressources/functionJS/jsDictionaryEquals';
import * as S from './TableauAlarmesRapport.module.scss';
import * as actions from '../../../../../../../../services/actions';
import Collapse from '../../../../../../../../hoc/Collapse/Collapse';
import { checkMot } from '../../../../../../../../ressources/functionJS/checkMot';
import Tableau from '../../../../../../../UI/tableau/Tableau';
import Bouton from '../../../../../../../UI/Bouton/Bouton';
import * as type_btn from '../../../../../../../../ressources/glossaires/glossaire_type_de_bouton';
import { VscSearch } from 'react-icons/vsc';
import { textFilter } from 'react-bootstrap-table2-filter';
import * as glossaire_type_export from '../../../../../../../../ressources/glossaires/glossaire_type_export';
import { AiOutlineLineChart } from 'react-icons/ai';

class TableauAlarmesRapport extends Component {
  // Définition initiale de l'état du composant
  state = {
    columns: [
      {
        dataField: 'cle_unique',
        text: [checkMot('cle_unique')],
        sort: true,
        hidden: true,
      },
      {
        dataField: 'sensor_id',
        text: [checkMot('id')],
        sort: true,
      },
      {
        dataField: 'position_in_sensor',
        text: [checkMot('position_in_sensor')],
        sort: true,
      },
    ],
    columns_config: [],
    columns_config_selection: [],
    sensors_list_config: [],
    listeComposanteSelection: [],
    ...this.props.preconfigureObjet,
  };

  // Méthodes du cycle de vie du composant
  componentDidMount() {
    this.initializeState();
    this.loadSensors();
  }

  componentDidUpdate(prevProps, prevState) {
    this.handlePropsChange(prevProps);
    this.handleStateChange(prevState);
    this.handleProjectFilterChange(prevProps);
    this.handleProfileDisplayChange(prevProps);
    this.handleSensorsListChange(prevState);
  }

  // Méthodes refactorisées pour l'initialisation et le chargement
  // Initialise l'état du composant en fonction des props
  initializeState = () => {
    if (
      this.props !== undefined &&
      this.props.preconfigureObjet === undefined
    ) {
      this.setState({
        ...this.state,
      });
    } else if (
      this.props !== undefined &&
      this.props.preconfigureObjet !== undefined
    ) {
      this.initializeFromProps();
    }
  };

  // Initialise l'état à partir des props préconfigurées
  initializeFromProps = () => {
    const liste_capteur_composante = this.createSensorComponentList();
    this.setState({
      ...this.state,
      ...this.props.preconfigureObjet,
      sensors_list_config: liste_capteur_composante,
    });
  };

  // Charge la liste des capteurs si des projets sont sélectionnés
  loadSensors = () => {
    if (this.props.filtre_projects_list_id.length > 0) {
      this.props.recupererListeCapteurs(this.props.filtre_projects_list_id);
    }
  };

  // Gestionnaires de changement de props et d'état
  // Gère les changements dans les props de préconfiguration
  handlePropsChange = (prevProps) => {
    if (
      this.props !== undefined &&
      prevProps.preconfigureObjet !== this.props.preconfigureObjet
    ) {
      if (this.props.preconfigureObjet === undefined) {
        this.setState({
          ...this.state,
        });
      } else if (
        !jsDictionaryEquals(
          prevProps.preconfigureObjet,
          this.props.preconfigureObjet
        )
      ) {
        const liste_capteur_composante = this.createSensorComponentList();
        this.setState({
          ...this.state,
          ...this.props.preconfigureObjet,
          sensors_list_config: liste_capteur_composante,
        });
      }
    }
  };

  // Met à jour le composant parent lorsque l'état change
  handleStateChange = (prevState) => {
    if (!jsDictionaryEquals(prevState, this.state)) {
      this.updateParentComponent();
    }
  };

  // Recharge la liste des capteurs quand le filtre de projet change
  handleProjectFilterChange = (prevProps) => {
    if (
      this.props.filtre_projects_list_id !== prevProps.filtre_projects_list_id
    ) {
      this.props.recupererListeCapteurs(this.props.filtre_projects_list_id);
    }
  };

  // Met à jour la configuration des colonnes quand l'affichage de profil change
  handleProfileDisplayChange = (prevProps) => {
    if (this.props.affichageProfil !== prevProps.affichageProfil) {
      this.updateColumnsConfig();
    }
  };

  // Met à jour la configuration des colonnes de sélection quand la liste des capteurs change
  handleSensorsListChange = (prevState) => {
    if (
      !jsDictionaryEquals(
        prevState.sensors_list_config,
        this.state.sensors_list_config
      )
    ) {
      this.updateColumnsConfigSelection();
    }
  };

  // Méthodes auxiliaires
  // Crée la liste des composants de capteurs à partir des props préconfigurées
  createSensorComponentList = () => {
    if (
      !this.props.preconfigureObjet ||
      !this.props.preconfigureObjet.sensors_list
    ) {
      return [];
    }

    return this.props.preconfigureObjet.sensors_list.map((composante) => {
      const cle_unique =
        composante.sensor_id + '_' + composante.position_in_sensor;
      return {
        ...composante,
        cle_unique,
        bouton_supprimer: (
          <Bouton cliquer={() => this.supprimerComposante(cle_unique)}>
            sup
          </Bouton>
        ),
      };
    });
  };

  // Met à jour le composant parent avec les données actuelles
  updateParentComponent = () => {
    const new_sensors_list = this.state.sensors_list_config.map((capteur) => ({
      sensor_id: capteur.sensor_id,
      position_in_sensor: capteur.position_in_sensor,
    }));

    const remonte_info = {};
    for (let i of Object.keys(this.props.preconfigureObjet)) {
      remonte_info[i] = this.state[i];
    }

    this.props.gererJson({
      ...remonte_info,
      sensors_list: new_sensors_list,
    });
  };

  // Méthodes pour mettre à jour les configurations de colonnes
  // Met à jour la configuration des colonnes principales
  updateColumnsConfig = () => {
    this.setState({
      ...this.state,
      columns_config: this.getColumnsConfig(),
    });
  };

  // Met à jour la configuration des colonnes de sélection
  updateColumnsConfigSelection = () => {
    this.setState({
      ...this.state,
      columns_config_selection: this.getColumnsConfigSelection(),
    });
  };

  // Définitions des configurations de colonnes
  // Retourne la configuration des colonnes principales
  getColumnsConfig = () => [
    {
      dataField: 'index',
      text: [
        checkMot('index'),
        <Bouton
          type={type_btn.lupeSearch}
          cliquer={() => this.afficherFiltre('index', checkMot('index'))}
        >
          <VscSearch size={10} />
        </Bouton>,
      ],
      sort: true,
      formatter: (cell, row, rowIndex) => this.renderIndexColumn(rowIndex),
    },
    {
      dataField: 'cle_unique',
      text: [
        checkMot('cle_unique'),
        <Bouton
          type={type_btn.lupeSearch}
          cliquer={() =>
            this.afficherFiltre('cle_unique', checkMot('cle_unique'))
          }
        >
          <VscSearch size={10} />
        </Bouton>,
      ],
      sort: true,
      hidden: true,
    },
    {
      dataField: 'sensor_id',
      text: [
        checkMot('id'),
        <Bouton
          type={type_btn.lupeSearch}
          cliquer={() => this.afficherFiltre('sensor_id', checkMot('id'))}
        >
          <VscSearch size={10} />
        </Bouton>,
      ],
      sort: true,
    },
    {
      dataField: 'sensor_name',
      text: checkMot('sensor_name'),
      sort: true,
      formatter: this.formatSensorName,
    },
    {
      dataField: 'position_in_sensor',
      text: [
        checkMot('position_in_sensor'),
        <Bouton
          type={type_btn.lupeSearch}
          cliquer={() =>
            this.afficherFiltre(
              'position_in_sensor',
              checkMot('position_in_sensor')
            )
          }
        >
          <VscSearch size={10} />
        </Bouton>,
      ],
      sort: true,
    },
    {
      dataField: 'prefix',
      text: checkMot('prefix'),
      sort: true,
      formatter: this.formatPrefix,
    },
    {
      dataField: 'bouton_supprimer',
      text: [checkMot('bouton_supprimer')],
      sort: true,
      hidden: this.props.affichageProfil,
    },
  ];

  // Retourne la configuration des colonnes de sélection
  getColumnsConfigSelection = () => [
    {
      dataField: 'sensor_id',
      text: [
        checkMot('id'),
        <Bouton
          type={type_btn.lupeSearch}
          cliquer={() => this.afficherFiltreConfig('sensor_id', checkMot('id'))}
        >
          <VscSearch size={10} />
        </Bouton>,
      ],
      sort: true,
    },
    {
      dataField: 'sensor_name',
      text: [
        checkMot('sensor_name'),
        <Bouton
          type={type_btn.lupeSearch}
          cliquer={() =>
            this.afficherFiltreConfig('sensor_name', checkMot('sensor_name'))
          }
        >
          <VscSearch size={10} />
        </Bouton>,
      ],
      sort: true,
    },
    {
      dataField: 'composantes',
      text: checkMot('composantes'),
      sort: true,
    },
  ];

  // Fonctions de rendu des colonnes
  // Rendu de la colonne d'index avec boutons de déplacement
  renderIndexColumn = (rowIndex) => (
    <span>
      {rowIndex}
      <Bouton
        desactive={rowIndex === 0}
        cliquer={() => this.moveItem(rowIndex, rowIndex - 1)}
      >
        ^
      </Bouton>
      <Bouton
        desactive={rowIndex === this.state.sensors_list_config.length - 1}
        cliquer={() => this.moveItem(rowIndex, rowIndex + 1)}
      >
        v
      </Bouton>
    </span>
  );

  // Formate le nom du capteur pour l'affichage
  formatSensorName = (cell, row) => {
    for (let i = 0; i < this.props.listeCapteurs.length; i++) {
      if (this.props.listeCapteurs[i].sensor_id === row.sensor_id) {
        return this.props.listeCapteurs[i].sensor_name;
      }
    }
    return cell;
  };

  // Formate le préfixe du capteur pour l'affichage
  formatPrefix = (cell, row) => {
    for (let i = 0; i < this.props.listeCapteurs.length; i++) {
      if (this.props.listeCapteurs[i].sensor_id === row.sensor_id) {
        for (
          let j = 0;
          j < this.props.listeCapteurs[i].component_list.length;
          j++
        ) {
          if (
            this.props.listeCapteurs[i].component_list[j].position_in_sensor ===
            row.position_in_sensor
          ) {
            return this.props.listeCapteurs[i].component_list[j].prefix;
          }
        }
      }
    }
    return cell;
  };

  // Manipulation des éléments du tableau
  // Déplace un élément dans la liste des capteurs configurés
  moveItem = (fromIndex, toIndex) => {
    if (toIndex < 0 || toIndex >= this.state.sensors_list_config.length) {
      return;
    }

    const new_sensors_list_config = [...this.state.sensors_list_config];
    const stock = { ...new_sensors_list_config[fromIndex] };
    new_sensors_list_config[fromIndex] = new_sensors_list_config[toIndex];
    new_sensors_list_config[toIndex] = stock;

    this.setState({
      sensors_list_config: new_sensors_list_config,
    });
  };

  // Gestion des filtres
  // Affiche un filtre pour une colonne donnée dans la configuration principale
  afficherFiltre = (dataField, placeholder) => {
    const newCol = this.state.columns_config.map((col) => {
      if (col.dataField === dataField) {
        return {
          ...col,
          filter: textFilter({
            placeholder: placeholder,
          }),
        };
      }
      return col;
    });

    this.setState({
      columns_config: [...newCol],
    });
  };

  // Affiche un filtre pour une colonne donnée dans la configuration de sélection
  afficherFiltreConfig = (dataField, placeholder) => {
    const newCol = this.state.columns_config_selection.map((col) => {
      if (col.dataField === dataField) {
        return {
          ...col,
          filter: textFilter({
            placeholder: placeholder,
          }),
        };
      }
      return col;
    });

    this.setState({
      columns_config_selection: [...newCol],
    });
  };

  // Gestion de l'état
  // Met à jour l'état en fonction du changement dans un champ de formulaire
  gererEtatDeCibleName = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // Suppression d'un composant de la liste
  supprimerComposante = (cle_unique) => {
    const newListe_capteur_composante = this.state.sensors_list_config.filter(
      (composante) => composante.cle_unique !== cle_unique
    );

    this.setState({
      sensors_list_config: newListe_capteur_composante,
      columns_config_selection: this.getColumnsConfigSelection(),
    });
  };

  // Fonctions de sélection
  // Sélectionne ou désélectionne tous les éléments
  selectAllItems = (isChecked) => {
    // Met à jour toutes les lignes et leurs composants internes
    const updatedData = this.getComposanteSelection().map((item) => ({
      ...item,
      selected: isChecked,
    }));

    // Met à jour sensors_list_config pour refléter la sélection dans les lignes
    let updatedSensorsList = [];

    if (isChecked) {
      // Ajoute tous les composants à la liste des sélectionnés
      updatedData.forEach((item) => {
        if (item.component_list) {
          item.component_list.forEach((component, idx) => {
            const cleUnique = `${item.sensor_id}_${component.position_in_sensor}`;
            updatedSensorsList.push(
              this.createSensorConfig(
                cleUnique,
                item.sensor_id,
                component.position_in_sensor
              )
            );
          });
        }
      });
    }

    this.setState({
      listeComposanteSelection: updatedData,
      sensors_list_config: updatedSensorsList,
    });
  };

  // Sélectionne ou désélectionne un élément spécifique
  selectItem = (row, isChecked) => {
    // Met à jour seulement la ligne actuelle et ses composants
    const updatedData = this.getComposanteSelection().map((item) =>
      item.sensor_id === row.sensor_id ? { ...item, selected: isChecked } : item
    );

    // Met à jour sensors_list_config pour refléter la sélection dans cette ligne
    let updatedSensorsList = [...this.state.sensors_list_config];
    const currentItem = this.props.listeCapteurs.find(
      (item) => item.sensor_id === row.sensor_id
    );

    if (isChecked && currentItem) {
      // Ajoute les composants de cette ligne à la liste des sélectionnés
      currentItem.component_list.forEach((component) => {
        const cleUnique = `${currentItem.sensor_id}_${component.position_in_sensor}`;
        // Ajoute seulement si l'élément n'existe pas déjà dans la liste
        if (
          !updatedSensorsList.some((sensor) => sensor.cle_unique === cleUnique)
        ) {
          updatedSensorsList.push(
            this.createSensorConfig(
              cleUnique,
              currentItem.sensor_id,
              component.position_in_sensor
            )
          );
        }
      });
    } else {
      // Supprime les composants de cette ligne de la liste des sélectionnés
      updatedSensorsList = updatedSensorsList.filter(
        (sensor) => !sensor.cle_unique.startsWith(`${row.sensor_id}_`)
      );
    }

    this.setState({
      listeComposanteSelection: updatedData,
      sensors_list_config: updatedSensorsList,
    });
  };

  // Crée la configuration d'un capteur pour l'ajouter à la liste
  createSensorConfig = (cleUnique, sensorId, positionInSensor) => ({
    cle_unique: cleUnique,
    sensor_id: sensorId,
    position_in_sensor: positionInSensor,
    bouton_supprimer: (
      <Bouton cliquer={() => this.supprimerComposante(cleUnique)}>sup</Bouton>
    ),
  });

  // Préparation des données pour le composant
  // Obtient la liste des composants avec leur état de sélection
  getComposanteSelection = () => {
    return this.props.listeCapteurs.map((capteur) => {
      const rowSelected =
        this.state.listeComposanteSelection &&
        this.state.listeComposanteSelection.find(
          (item) => item.sensor_id === capteur.sensor_id
        )?.selected;

      let liste_checkbox = [];

      if (capteur.component_list) {
        for (let j = 0; j < capteur.component_list.length; j++) {
          const component = capteur.component_list[j];
          const cleUnique = `${capteur.sensor_id}_${component.position_in_sensor}`;
          const isInSensorsList = this.state.sensors_list_config.some(
            (sensor) => sensor.cle_unique === cleUnique
          );

          liste_checkbox.push(
            this.createComponentButton(
              capteur,
              component,
              isInSensorsList || rowSelected
            )
          );
        }
      }

      return {
        ...capteur,
        composantes: liste_checkbox,
        selected: rowSelected || false,
      };
    });
  };

  // Crée un bouton de composant avec son état (actif/désactivé)
  createComponentButton = (capteur, component, isSelected) => {
    const lienGrapheAuto = this.createGrapheAutoLink(capteur, component);
    const cleUnique = `${capteur.sensor_id}_${component.position_in_sensor}`;
    const buttonLabel = `${component.position_in_sensor}:${component.prefix}`;

    if (isSelected) {
      return [
        <Bouton desactive="desable">{buttonLabel}</Bouton>,
        lienGrapheAuto,
      ];
    } else {
      return [
        <Bouton
          cliquer={() => this.addComponentToSelection(capteur, component)}
        >
          {buttonLabel}
        </Bouton>,
        lienGrapheAuto,
      ];
    }
  };

  // Crée un lien vers le graphique automatique pour un composant
  createGrapheAutoLink = (capteur, component) => (
    <a
      href=""
      onClick={(event) => {
        event.preventDefault();
        window.open(
          `/export_impression_ecran-${glossaire_type_export.graphe_automatique}-${capteur.sensor_id}_${component.position_in_sensor}`,
          '_blank',
          `toolbar=no,scrollbars=yes,dependent=yes,top=0,left=0,outerWidth=${
            window.outerWidth / 4
          },outerHeight=${window.outerHeight / 4}`
        );
      }}
    >
      <AiOutlineLineChart />
    </a>
  );

  // Ajoute un composant à la sélection
  addComponentToSelection = (capteur, component) => {
    const cleUnique = `${capteur.sensor_id}_${component.position_in_sensor}`;
    const new_sensors_list_config = [...this.state.sensors_list_config];

    new_sensors_list_config.push(
      this.createSensorConfig(
        cleUnique,
        capteur.sensor_id,
        component.position_in_sensor
      )
    );

    this.setState({ sensors_list_config: new_sensors_list_config });
  };

  // Rendu principal du composant
  render() {
    const listeComposanteSelection = this.getComposanteSelection();

    return (
      <Collapse
        ExpandText={
          <div className={S.contenuEnTete}>
            {this.props.contenuEnTete}
            <span className={S.titre}>{this.state.text}</span>
            <Tableau
              cleLigne={'cle_unique'}
              donnees={this.state.sensors_list_config}
              colonnes={this.state.columns}
              hide_general_search={true}
            />
          </div>
        }
        ExpandTextOuvert={
          <div className={S.contenuEnTete}>
            {this.props.contenuEnTete}
            <span className={S.titre}>{this.state.text}</span>
          </div>
        }
      >
        <div className={S.ConteneurHorizontal}>
          <div className={S.ConteneurCentralGauche}>
            <Tableau
              cleLigne={'cle_unique'}
              donnees={this.state.sensors_list_config}
              colonnes={
                this.state.columns_config.length > 0
                  ? this.state.columns_config
                  : this.getColumnsConfig()
              }
              hide_general_search={true}
            />
          </div>
          <div className={S.ConteneurCentralDroite}>
            <Tableau
              cleLigne={'sensor_id'}
              donnees={listeComposanteSelection}
              colonnes={[
                {
                  dataField: 'select_all',
                  text: (
                    <input
                      type="checkbox"
                      onChange={(e) => this.selectAllItems(e.target.checked)}
                    />
                  ),
                  formatter: (cell, row) => (
                    <input
                      type="checkbox"
                      checked={row.selected || false}
                      onChange={(e) => this.selectItem(row, e.target.checked)}
                    />
                  ),
                  headerStyle: { width: '50px' },
                },
                ...(this.state.columns_config_selection.length > 0
                  ? this.state.columns_config_selection
                  : this.getColumnsConfigSelection()),
              ]}
              hide_general_search={true}
            />
          </div>
        </div>
        {this.props.children}
      </Collapse>
    );
  }
}

// Mappages Redux
// Connecte les états de Redux au composant
const mapStateToProps = (state) => ({
  filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
  listeCapteurs: state.capteur.capteursListe,
});

// Connecte les actions Redux au composant
const mapDispatchToProps = (dispatch) => ({
  recupererListeCapteurs: (listeIdProjets) =>
    dispatch(actions.reqRecupererListeCapteurs(listeIdProjets)),
  reqCreerRapportGabarit: (formulaireRapportGabarit) =>
    dispatch(actions.reqCreerRapportGabarit(formulaireRapportGabarit)),
  reqModifierRapportGabarit: (formulaireRapportGabarit) =>
    dispatch(actions.reqModifierRapportGabarit(formulaireRapportGabarit)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableauAlarmesRapport);

import React, { Component } from 'react';
import { connect } from 'react-redux';

import AttenteRequete from '../../../../hoc/AttenteRequete/AttenteRequete';
import Bouton from '../../../UI/Bouton/Bouton';
import ImporterImage from '../../../UI/ImporterImage/ImporterImage';

import { checkMot } from '../../../../ressources/functionJS/checkMot';
import * as requestType from '../../../../ressources/glossaires/glossaire_requetes';
import * as actions from '../../../../services/actions';

class AjouterImageTypeCapteur extends Component {
  state = {
    imageImporter: null,
  };
  gererImageImporter = (imageImporter) => {
    this.setState({ imageImporter: imageImporter });
  };
  render() {
    let boutonEnvoi;
    const textBouton = checkMot('enregistrer_l_image');
    if (this.state.imageImporter !== null) {
      boutonEnvoi = (
        <Bouton
          cliquer={() =>
            this.props.reqAjouterImageTypeDeCapteur(
              this.props.sensor_type_id,
              this.state.imageImporter.base64
            )
          }
        >
          {textBouton}
        </Bouton>
      );
    } else {
      boutonEnvoi = <Bouton desactive={true}>{textBouton}</Bouton>;
    }
    return (
      <>
        <div>
          <ImporterImage
            extensionAccepter={'.svg'}
            gererImageImporter={this.gererImageImporter}
          />
          <AttenteRequete
            request_type={requestType.ajouter_une_image_de_type_de_capteur}
          >
            {boutonEnvoi}
          </AttenteRequete>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reqAjouterImageTypeDeCapteur: (idTypeDeCapteur, base64_img) =>
      dispatch(
        actions.reqAjouterImageTypeDeCapteur(idTypeDeCapteur, base64_img)
      ),
  };
};

export default connect(null, mapDispatchToProps)(AjouterImageTypeCapteur);

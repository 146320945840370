import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import FormulaireSelectionDateHeureUnix from '../../ProfilGraphe/FormulaireGraphe/FormulaireSelectionDateHeureUnix/FormulaireSelectionDateHeureUnix';
import FormulaireSelectUtsDate from '../../ProfilGraphe/FormulaireGraphe/FormulaireSelectUtsDate/FormulaireSelectUtsDate';

import Bouton from '../../../UI/Bouton/Bouton';
import * as type_btn from '../../../../ressources/glossaires/glossaire_type_de_bouton';
import { integerOrNull } from '../../../../ressources/functionJS/integerOrNull';
import { checkMot } from '../../../../ressources/functionJS/checkMot';
import * as actions from '../../../../services/actions';
import * as S from '../../ProfilGraphe/FormulaireGraphe/FormulaireGraphe.module.scss';
import * as D from './ChangementIntervalTempsGrapheAutomatique.module.scss';
import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';

class ChangementIntervalTempsGrapheAutomatique extends Component {
  state = {
    time_interval: [null, null],
    time_period: null,
    measures_num: null,
    select_time: [],
    inset: '15% 10% 1% 30%',
    width: '700px',
    height: '500px',
  };
  gererEtatDeCibleName = (event) =>
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  gererDebut_time_interval = (unix) =>
    this.setState({
      ...this.state,
      time_interval: [unix, this.state.time_interval[1]],
    });
  gererFin_time_interval = (unix) =>
    this.setState({
      ...this.state,
      time_interval: [this.state.time_interval[0], unix],
    });
  gererConfigSelectUtsDate = (newConfigSelectUtsDate) => {
    this.setState({ ...this.state, select_time: [...newConfigSelectUtsDate] });
  };

  componentDidMount() {
    if (
      window.screen.availWidth / 2 >= window.innerWidth ||
      window.screen.availHeight / 2 >= window.outerHeight
    ) {
      this.setState({
        inset: '0 0 0 0',
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.afficherPopup}
          style={{
            overlay: {
              position: 'absolute',
              zIndex: '1000',
              overflowX: 'hidden',
              overflowY: 'hidden',
              //    padding: "1px",
              // paddingTop: "50px",
              //    backgroundColor: "white",
              border: 'black 1px solid',
              //    borderRadius: "10px",
              backgroundColor: 'transparent',

              transition: 'width 2s, height 4s',
            },
            content: {
              padding: '0px',
              overflowX: 'auto',

              width: this.state.width,
              height: this.state.height,
              inset: this.state.inset,

              boxShadow: '-5px 0px 5px rgba(0, 0, 0, 0.226)',
            },
          }}
          className={D.ModalAdv}
          overlayClassName={D.OverlayAdv}
          onRequestClose={this.props.fermerPopupModifierIntervalle}
        >
          <div className={[s.card_header, s.text_right].join(' ')}>
            <Bouton
              type={type_btn.fermer}
              rond={true}
              cliquer={this.props.fermerPopupModifierIntervalle}
            />
          </div>
          <div className={[s.container, s.text_left, S.Formulaire].join(' ')}>
            {/*Formulaire pour l'interval de récupération des données*/}
            <div className={s.form_group}>
              {/**DEBUT time_interval**/}
              <div className={[s.form_row, s.align_items_center].join(' ')}>
                <span className={s.col_12}>
                  {checkMot('Interval de récupération de données [début, fin]')}
                </span>
                <div className={s.col_6}>
                  <FormulaireSelectionDateHeureUnix
                    value={this.state.time_interval[0]}
                    gereUnixDate={this.gererDebut_time_interval}
                    disabled={this.props.affichageProfil === true}
                  />
                </div>
                <div className={s.col_6}>
                  <FormulaireSelectionDateHeureUnix
                    value={this.state.time_interval[1]}
                    gereUnixDate={this.gererFin_time_interval}
                    disabled={this.props.affichageProfil === true}
                  />
                </div>
              </div>
            </div>
            {/**FIN time_interval**/}

            <div className={s.form_group}>
              {/**DEBUT time_period**/}
              <div className={s.form_row}>
                <div className={s.col_12}>
                  <span>
                    {checkMot(
                      'recuperation_des_donnees_sur_le_dernier_interval_de_temps_jusqu_a_present'
                    )}
                  </span>
                  <input
                    type="text"
                    pattern="[0-9a-zA-Z._%+-]+"
                    className={[s.form_control, s.mb_2].join(' ')}
                    placeholder="time_period"
                    name="time_period"
                    value={this.state.time_period}
                    disabled={this.props.affichageProfil === true}
                    required
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                  />
                </div>
              </div>
            </div>
            {/**FIN time_period**/}
            <div className={s.form_group}>
              {/**DEBUT measures_num**/}
              <div className={s.form_row}>
                <div className={s.col_12}>
                  <span>
                    {checkMot(
                      'recuperation_des_n_derniere_donnees_jusqu_a_present'
                    )}
                  </span>
                  <input
                    type="text"
                    pattern="[0-9]+"
                    className={[s.form_control, s.mb_2].join(' ')}
                    placeholder="measures_num"
                    name="measures_num"
                    value={this.state.measures_num}
                    disabled={this.props.affichageProfil === true}
                    required
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                  />
                </div>
              </div>
            </div>
            {/**FIN measures_num**/}
            <div className={s.form_group}>
              {/**DEBUT select_time**/}
              <div className={s.form_row}>
                <div className={s.col_12}>
                  <span>
                    {checkMot(
                      'recuperation_des_donnees_des_dates_selectionnees'
                    )}
                  </span>
                  <FormulaireSelectUtsDate
                    preConfigSelectUtsDate={this.state.select_time}
                    gererConfigSelectUtsDate={this.gererConfigSelectUtsDate}
                    disabled={this.props.affichageProfil === true}
                  />
                </div>
              </div>
            </div>
            {/**FIN select_time**/}

            <Bouton
              cliquer={() =>
                this.props.reqRecupererDonneesGrapheAutomatiqueXT(
                  integerOrNull(this.props.sensor_id),
                  integerOrNull(this.props.position_in_sensor),
                  integerOrNull(this.state.time_period),
                  integerOrNull(this.state.measures_num),
                  this.state.select_time,
                  this.state.time_interval
                )
              }
            >
              {checkMot('changer_d_echantillon')}
            </Bouton>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reqRecupererDonneesGrapheAutomatiqueXT: (
      sensor_id,
      position_in_sensor,
      time_period,
      measures_num,
      select_time,
      time_interval
    ) =>
      dispatch(
        actions.reqRecupererDonneesGrapheAutomatiqueXT(
          sensor_id,
          position_in_sensor,
          time_period,
          measures_num,
          select_time,
          time_interval
        )
      ),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ChangementIntervalTempsGrapheAutomatique);

import * as actionTypes from './actionTypes';

export const supprimer = (alerteKey) => {
  return { type: actionTypes.SUPPRIMER_ALERTE, alerteKey: alerteKey };
};
export const ajouterAlerte = (typeAlerte, titre, corps) => {
  /**
   * Ajoute une alerte à la liste d'alertes du reducteur alerte.js
   * @param typeAlerte - est pour selectionner le type d'alerte, Attention la chaîne de caractère doit correspondre à la clé du dictionnaire de type d'alerte dans Accueil.js
   * @param titre - est pour ajouter un titre à l'alerte, Attention la chaîne de caractère doit correspondre à la clé du "dictionnaire de langue" dans dictionnaire.js
   * @param corps - est pour ajouter un corps à l'alerte,
   *  corps peut-être:
   *      - une chaîne de caractère, Attention la chaîne de caractère doit correspondre à la clé du dictionnaire de langue dans dictionnaire.js (exemple : todo:mettre un exemple"")
   *      - une liste de taille 2 [String,{}]
   *          - où la première valeur est une chaîne de caractère, Attention la chaîne de caractère doit correspondre à la clé du "dictionnaire de langue" dans dictionnaire.js (exemple : "ApiSuccesCorpsConnexion")
   *          - où la deuxième valeur peut-être une chaîne de caractère, un entier, un flottant, ... (qui sera directement affiché sans traduction, exemples : "Marcel", 5, 1.2, true, ...)
   *          - où la deuxième valeur est un dictionnaire, Attention les clés du dictionnaire doivent correspondre aux clés de la fonction du "dictionnaire de langue" (la fonction associé à la chaîne de caractère précédente, example: corps[0]="ApiEchecErreursEnBoucle") du dictionnaire.js (exemple : { numAvertissment: 0, idErreur: 100006})
   * @return {dictionary} - est pour l'action du reducteur alerte.js
   */
  return {
    type: actionTypes.AJOUTER_ALERTE,
    typeAlerte: typeAlerte,
    titre: titre,
    corps: corps,
  };
};

import axios from 'axios';
import * as actions from './index';
import * as actionTypes from './actionTypes';
import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import { store } from '../../index';

export const reqAjouterUtilisateur = (formulaireCreerUtilisateur) => {
  return (dispatch) => {
    requetePost(dispatch, {
      request_type: requestType.creation_d_un_utilisateur,
      ...formulaireCreerUtilisateur,
    });
  };
};
export const reqModifierUtilisateur = (formulaireDeModificationUtilisateur) => {
  return (dispatch) => {
    requetePost(dispatch, {
      request_type: requestType.modification_d_un_utilisateur,
      ...formulaireDeModificationUtilisateur,
    });
  };
};
export const reqSupprimerUtilisateur = (idUtilisateur) => {
  return (dispatch) => {
    requetePost(dispatch, {
      request_type: requestType.supprimer_un_utilisateur,
      user_id: idUtilisateur,
    });
  };
};
export const reqRecupererListeUtilisateurs = () => {
  return (dispatch) => {
    requetePost(dispatch, {
      request_type: requestType.recuperation_de_la_liste_des_utilisateurs,
    });
  };
};
export const reqRecupererUnUtilisateur = (user_id) => {
  return (dispatch) => {
    requetePost(dispatch, {
      request_type: requestType.recuperation_d_un_utilisateur,
      user_id: user_id,
    });
  };
};
const requetePost = (dispatch, requeteAPI) => {
  /**
   * gère l'envoi du type de requête et du retour de l'api
   * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
   * @param requeteAPI - permet de rendre la requête à l'API
   * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
   */
  dispatch(actions.debutAttente(requeteAPI.request_type));
  axios
    .post('', requeteAPI, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      let titreAlerteSucces = 'Requête api succès';
      let corpsAlerteSucces = 'Requête api succès';
      let titreAlerteEchec = 'Requête api échec';
      let corpsAlerteEchec = 'Requête api échec';
      let succesRequete = {
        alerte() {},
        actionReducteur() {},
        fonctionSupplementaire() {},
      };
      let succesRequeteAvecErreur = {
        alerte() {},
        actionReducteur() {},
        fonctionSupplementaire() {},
      };
      let echecRequete = {
        alerte() {},
        actionReducteur() {},
        fonctionSupplementaire() {},
      };

      switch (requeteAPI.request_type) {
        case requestType.creation_d_un_utilisateur:
          titreAlerteSucces = 'ApiSuccesTitreCreerUtilisateur';
          corpsAlerteSucces = 'ApiSuccesCorpsCreerUtilisateur';
          titreAlerteEchec = 'ApiEchecTitreCreerUtilisateur';
          corpsAlerteEchec = 'ApiEchecCorpsCreerUtilisateur';
          if (
            res.data.user_id > 0 &&
            res.data.user_id !== null &&
            res.data.user_id !== undefined
          ) {
            succesRequete.alerte = () =>
              dispatch(
                actions.ajouterAlerte('success', titreAlerteSucces, [
                  corpsAlerteSucces,
                  res.data.user_id,
                ])
              );
            succesRequete.fonctionSupplementaire = () =>
              dispatch(actions.reqRecupererListeUtilisateurs());
          }
          if (res.data.errors.length > 0) {
            succesRequeteAvecErreur.alerte = () =>
              dispatch(
                actions.ajouterAlerte('warning', titreAlerteSucces, [
                  corpsAlerteSucces,
                  res.data.errors.length,
                ])
              );
            succesRequeteAvecErreur.fonctionSupplementaire = () => {
              affichageRetourErreurs(
                dispatch,
                'warning',
                titreAlerteSucces,
                res
              );
              dispatch(actions.reqRecupererListeUtilisateurs());
            };
          }
          if (res.data.state === false) {
            echecRequete.alerte = () =>
              dispatch(
                actions.ajouterAlerte('danger', titreAlerteEchec, [
                  corpsAlerteEchec,
                  res.data.errors.length,
                ])
              );
            echecRequete.fonctionSupplementaire = () =>
              affichageRetourErreurs(dispatch, 'danger', titreAlerteEchec, res);
          }
          break;
        case requestType.modification_d_un_utilisateur:
          titreAlerteSucces = 'ApiSuccesTitre_modification_d_un_utilisateur';
          corpsAlerteSucces = 'ApiSuccesCorps_modification_d_un_utilisateur';
          titreAlerteEchec = 'ApiEchecTitre_modification_d_un_utilisateur';
          corpsAlerteEchec = 'ApiEchecCorps_modification_d_un_utilisateur';

          succesRequete.alerte = () =>
            dispatch(
              actions.ajouterAlerte('success', titreAlerteSucces, [
                corpsAlerteSucces,
                res.data.user_id,
              ])
            );
          succesRequete.fonctionSupplementaire = () =>
            dispatch(actions.reqRecupererListeUtilisateurs());
          if (res.data.errors.length > 0) {
            succesRequeteAvecErreur.alerte = () =>
              dispatch(
                actions.ajouterAlerte('warning', titreAlerteSucces, [
                  corpsAlerteSucces,
                  res.data.errors.length,
                ])
              );
            succesRequeteAvecErreur.fonctionSupplementaire = () => {
              affichageRetourErreurs(
                dispatch,
                'warning',
                titreAlerteSucces,
                res
              );
              dispatch(actions.reqRecupererListeUtilisateurs());
            };
          }
          if (res.data.state === false) {
            echecRequete.alerte = () =>
              dispatch(
                actions.ajouterAlerte('danger', titreAlerteEchec, [
                  corpsAlerteEchec,
                  res.data.errors.length,
                ])
              );
            echecRequete.fonctionSupplementaire = () =>
              affichageRetourErreurs(dispatch, 'danger', titreAlerteEchec, res);
          }
          break;
        case requestType.recuperation_d_un_utilisateur:
          titreAlerteEchec = '';
          corpsAlerteEchec = '';

          const state = store.getState();
          if (state.authentifie.user_id === null) {
            // || state.authentifie.user_id===res.data.user_id){
            titreAlerteSucces =
              'ApiSuccesTitre_recuperation_de_l_utilisateur_connecte';
            corpsAlerteSucces =
              'ApiSuccesCorps_recuperation_de_l_utilisateur_connecte';

            succesRequete.actionReducteur = () =>
              dispatch(actions.enregistrerUtilisateur(res.data));
            succesRequete.alerte = () =>
              dispatch(
                actions.ajouterAlerte('success', titreAlerteSucces, [
                  corpsAlerteSucces,
                  res.data.lastname,
                ])
              );
          } else {
            if (res.data.errors.length > 0) {
              succesRequeteAvecErreur.alerte = () =>
                dispatch(
                  actions.ajouterAlerte('warning', titreAlerteSucces, [
                    corpsAlerteSucces,
                    res.data.errors.length,
                  ])
                );
            }
            if (res.data.state === false) {
              echecRequete.alerte = () =>
                dispatch(
                  actions.ajouterAlerte('danger', titreAlerteEchec, [
                    corpsAlerteEchec,
                    res.data.errors.length,
                  ])
                );
            }
          }
          break;
        case requestType.supprimer_un_utilisateur:
          titreAlerteSucces = 'ApiSuccesTitre_supprimer_un_utilisateur';
          corpsAlerteSucces = 'ApiSuccesCorps_supprimer_un_utilisateur';
          titreAlerteEchec = 'ApiEchecTitre_supprimer_un_utilisateur';
          corpsAlerteEchec = 'ApiEchecCorps_supprimer_un_utilisateur';

          succesRequete.alerte = () =>
            dispatch(
              actions.ajouterAlerte('success', titreAlerteSucces, [
                corpsAlerteSucces,
                requeteAPI.user_id,
              ])
            );
          succesRequete.fonctionSupplementaire = () =>
            dispatch(actions.reqRecupererListeUtilisateurs());

          if (res.data.errors.length > 0) {
            succesRequeteAvecErreur.alerte = () =>
              dispatch(
                actions.ajouterAlerte('warning', titreAlerteSucces, [
                  corpsAlerteSucces,
                  res.data.errors.length,
                ])
              );
            succesRequeteAvecErreur.fonctionSupplementaire = () => {
              affichageRetourErreurs(
                dispatch,
                'warning',
                titreAlerteSucces,
                res
              );
              dispatch(actions.reqRecupererListeUtilisateurs());
            };
          }
          if (res.data.state === false) {
            echecRequete.alerte = () =>
              dispatch(
                actions.ajouterAlerte('danger', titreAlerteEchec, [
                  corpsAlerteEchec,
                  res.data.errors.length,
                ])
              );
            echecRequete.fonctionSupplementaire = () =>
              affichageRetourErreurs(dispatch, 'danger', titreAlerteEchec, res);
          }
          break;
        case requestType.recuperation_de_la_liste_des_utilisateurs:
          titreAlerteSucces =
            'ApiSuccesTitre_recuperation_de_la_liste_des_utilisateurs';
          corpsAlerteSucces =
            'ApiSuccesCorps_recuperation_de_la_liste_des_utilisateurs';
          titreAlerteEchec =
            'ApiEchecTitre_recuperation_de_la_liste_des_utilisateurs';
          corpsAlerteEchec =
            'ApiEchecCorps_recuperation_de_la_liste_des_utilisateurs';
          // succesRequete.alerte=()=> dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.user_id]))
          succesRequete.actionReducteur = () =>
            dispatch(enregistrerListeUtilisateurs(res.data.users_list));
          if (res.data.errors.length > 0) {
            succesRequeteAvecErreur.alerte = () =>
              dispatch(
                actions.ajouterAlerte('warning', titreAlerteSucces, [
                  corpsAlerteSucces,
                  res.data.errors.length,
                ])
              );
            succesRequeteAvecErreur.actionReducteur = () =>
              dispatch(
                enregistrerListeUtilisateurs(dispatch, res.data.users_list)
              );
            succesRequeteAvecErreur.fonctionSupplementaire = () => {
              affichageRetourErreurs(
                dispatch,
                'warning',
                titreAlerteSucces,
                res
              );
            };
          }
          if (res.data.state === false) {
            echecRequete.alerte = () =>
              dispatch(
                actions.ajouterAlerte('danger', titreAlerteEchec, [
                  corpsAlerteEchec,
                  res.data.errors.length,
                ])
              );
            echecRequete.fonctionSupplementaire = () => {
              affichageRetourErreurs(dispatch, 'danger', titreAlerteEchec, res);
            };
          }
          break;
        default:
          break;
      }
      if (res.data.state) {
        if (res.data.errors.length === 0) {
          succesRequete.actionReducteur();
          succesRequete.alerte();
          succesRequete.fonctionSupplementaire();
        } else {
          succesRequeteAvecErreur.actionReducteur();
          succesRequeteAvecErreur.alerte();
          succesRequeteAvecErreur.fonctionSupplementaire();
        }
      } else {
        echecRequete.actionReducteur();
        echecRequete.alerte();
        echecRequete.fonctionSupplementaire();
      }
    })
    .catch((err) => {
      console.error('Error API', requeteAPI.request_type, ':', err);
      // dispatch(actions.ajouterAlerte("danger", "ApiErreurRequeteTitre", ["ApiErreurRequeteCorps", {request_type: requeteAPI.request_type, messageErreur: err.toString()}]));
    })
    .finally(() => dispatch(actions.finAttente(requeteAPI.request_type)));
};
const affichageRetourErreurs = (dispatch, typeAlerte, titre, res) => {
  if (res.data.errors !== undefined) {
    for (let erreurKey = 0; erreurKey < res.data.errors.length; erreurKey++) {
      dispatch(
        actions.ajouterAlerte(typeAlerte, titre, [
          'ApiEchecErreursEnBoucle',
          { numAvertissement: erreurKey, idErreur: res.data.errors[erreurKey] },
        ])
      );
    }
  } else {
    dispatch(
      actions.ajouterAlerte(
        typeAlerte,
        'Erreur retour api',
        'errors est undefine, pour une requête de connexion'
      )
    );
  }
};
const enregistrerListeUtilisateurs = (users_liste) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_UTILISATEURS,
    utilisateursListe: users_liste,
  };
};

export const FloatChampInput = (cell) => {
  if (cell === null || cell === undefined) {
    return null;
  }
  if (typeof cell === 'string') {
    // Enregistre le zéro si la chaîne est vide ou ne contient que des espaces
    if (cell.trim() === '') {
      return 0;
    }
    // Remplacer les virgules par des points
    cell = cell.replace(',', '.');

    // Ajouter un zéro devant le point décimal si la chaîne commence par un point
    if (cell.startsWith('.')) {
      cell = '0' + cell;
      return parseFloat(cell);
    }

    // Ajouter un zéro après le point décimal si la chaîne se termine par un point ou commence par "0."
    if (cell.endsWith('.') || cell.startsWith('0.')) {
      cell = cell + '0';
      return parseFloat(cell);
    }
    // Vérifier si la chaîne est une notation décimale précise
    const regex = /^-?\d+(\.\d+)?$/;
    if (!regex.test(cell)) {
      return null;
    }
  }
  // Convertir la chaîne en nombre décimal
  return parseFloat(cell);
};

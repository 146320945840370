import React, { Component } from 'react';
import { connect } from 'react-redux';
import S from './FiltreParProjet.module.scss';
import * as s from '../../ressources/StyleGlobal/StyleGlobal';
import FormulaireSelectionProjetsArborecense from '../UI/FormulaireSelectionProjetsArborecense/FormulaireSelectionProjetsArborecense';
import * as actions from '../../services/actions';
import { checkMot } from '../../ressources/functionJS/checkMot';
import { jsDictionaryEquals } from '../../ressources/functionJS/jsDictionaryEquals';
// import ArborescenceProjets from "../../composant/Navigation/RubriquesNavigationClient/ArborescenceProjets/ArborescenceProjets";
class FiltreParProjet extends Component {
  state = {
    projects_list_id: [...this.props.filtre_projects_list_id],
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !jsDictionaryEquals(
        this.state.projects_list_id,
        this.props.filtre_projects_list_id
      )
    ) {
      // met à jours la liste de projet sélectionné de redux
      this.props.gererFiltreParProjet(this.state.projects_list_id);
    }
  }

  gererProjetsList = (projects_list_id) => {
    this.setState({ ...this.state, projects_list_id: projects_list_id });
  };
  render() {
    return (
      // <div className={S.Profil}>
      //     <div className={s.row}>
      //         <div className={s.col_sm_12}>{checkMot("filtre_par")}
      //         <FormulaireSelectionProjets gererProjetsList={this.gererProjetsList} preconfigurerProjectsListId={this.state.projects_list_id} />
      //         </div>
      //     </div>
      // </div>

      <div className={S.Profil}>
        <div className={s.row}>
          <div className={s.col_sm_12}>
            <FormulaireSelectionProjetsArborecense
              gererProjetsList={this.gererProjetsList}
              preconfigurerProjectsListId={this.state.projects_list_id}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //import la liste des ids déjà sélectionné
    filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //import l'action qui permet la mis à jours de la donnée liste des id de projet sélectionné
    gererFiltreParProjet: (listeProjetsId) =>
      dispatch(actions.gererFiltreParProjet(listeProjetsId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltreParProjet);

import { connect } from 'react-redux';
const AccesRestreins = (props) => {
  /**
     * Permet d'afficher les composants children si l'utilisateur a le privilège suffisant pour le numPrivilege associé
     * exemple d'utilisation :
         <AccesRestreins numPrivilege={props.privilegeCreer}>
             <Bouton cliquer={props.creer}>
                {props.iconeCreer}
             </Bouton>
         </AccesRestreins>
    */
  if (props.listePrivilege !== undefined && props.listePrivilege.length > 0) {
    if (props.numPrivilege !== undefined) {
      return props.listePrivilege.indexOf(props.numPrivilege) >= 0
        ? props.children
        : null;
    } else if (props.listNumPrivilege !== undefined) {
      let ALePrivilege = false;
      for (
        let indListNumPrivilege = 0;
        indListNumPrivilege < props.listNumPrivilege.length;
        indListNumPrivilege++
      ) {
        if (
          props.listePrivilege.indexOf(
            props.listNumPrivilege[indListNumPrivilege]
          ) >= 0
        ) {
          ALePrivilege = true;
          break;
        }
      }
      if (ALePrivilege) {
        return props.children;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const mapStateToProps = (state) => {
  return {
    listePrivilege: state.authentifie.privileges_list,
  };
};

export default connect(mapStateToProps)(AccesRestreins);

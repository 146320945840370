import React, { Component } from 'react';

import AccesRestreins from '../../../../hoc/AccesRestreins/AccesRestreins';
import AttenteRequete from '../../../../hoc/AttenteRequete/AttenteRequete';
import AffichageImage from '../../../UI/AffichageImage/AffichageImage';
import AjouterImageTypeCapteur from '../AjouterImageTypeCapteur/AjouterImageTypeCapteur';

import { checkMot } from '../../../../ressources/functionJS/checkMot';
import { confirmeOnClick } from '../../../../ressources/functionJS/confirmeOnClick';
import * as privilege from '../../../../ressources/glossaires/glossaire_privileges';
import * as requestType from '../../../../ressources/glossaires/glossaire_requetes';

import { BiEditAlt, RiDeleteBin2Line, RiMailSendFill } from 'react-icons/all';
import { BiErrorCircle } from 'react-icons/bi';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import * as glossaire_affichage_type_capteur from '../../../../ressources/glossaires/glossaire_affichage_type_capteur';
import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';
import {
  btF,
  btM,
  divs,
  img,
  Input,
  InputReadOnly,
  profil,
  span,
} from './ProfilSensorType.module.scss';

class ProfilSensorType extends Component {
  state = {
    showModal: false,
    info_sensorType_name: this.props.InfoSelect.sensor_type_name,
    info_sensorType_display: this.props.InfoSelect.sensor_type_display,
    afficheFormulaireModifier: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.InfoSelect !== prevProps.InfoSelect) {
      this.setState({
        info_sensorType_name: this.props.InfoSelect.sensor_type_name,
        info_sensorType_display: this.props.InfoSelect.sensor_type_display,
        afficheFormulaireModifier: false,
      });
    }
  }

  gererSensorTypeName = (event) => {
    this.setState({ ...this.state, info_sensorType_name: event.target.value });
  };
  gererSensorTypeDisplay = (event) => {
    this.setState({
      ...this.state,
      info_sensorType_display: event.target.value,
    });
  };
  handleOpenModal = () => {
    this.setState({ showModal: true });
  };
  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    //affichage par défaut du profil si aucun type de capteur n'a été sélectionné
    let profilSensorType = checkMot('selectionne_une_sensor_type');
    if (this.props.InfoSelect !== null) {
      let inputClass;
      let affichageBouton;
      if (this.state.afficheFormulaireModifier) {
        //formulaire de modification
        inputClass = Input;
        affichageBouton = (
          <>
            <button
              className={btM}
              onClick={() =>
                this.setState({
                  ...this.state,
                  afficheFormulaireModifier: false,
                })
              }
            >
              <BiEditAlt size={24} />
              {checkMot('annuler')}
            </button>
            <AttenteRequete
              request_type={requestType.modification_d_un_sensor_type}
              str={checkMot('modification_en_cour')}
            >
              {/*bouton d'envoi de la requête de modification de type de capteur*/}
              <button
                className={btF}
                onClick={() =>
                  this.props.reqModifier(
                    parseInt(this.props.InfoSelect.sensor_type_id),
                    this.state.info_sensorType_name,
                    this.state.info_sensorType_display
                  )
                }
              >
                <RiMailSendFill size={24} />
                {checkMot('envoi_modification')}
              </button>
            </AttenteRequete>
          </>
        );
      } else {
        //formulaire de profil
        inputClass = InputReadOnly;
        affichageBouton = (
          <>
            <AccesRestreins numPrivilege={privilege.modifier_des_sensor_types}>
              {/*Bouton pour afficher le formulaire de modification*/}
              <button
                className={btM}
                onClick={() =>
                  this.setState({
                    ...this.state,
                    afficheFormulaireModifier: true,
                  })
                }
              >
                <BiEditAlt size={24} />
                {checkMot('modifier_sensor_type')}
              </button>
            </AccesRestreins>
            <AccesRestreins numPrivilege={privilege.supprimer_des_sensor_types}>
              <AttenteRequete
                request_type={requestType.suppression_d_un_sensor_type}
                str={checkMot('suppression_en_cour')}
              >
                {/*Bouton supprimer un type de capteur */}
                <button
                  className={btF}
                  onClick={() =>
                    confirmeOnClick(
                      checkMot(
                        'voulez_vous_vraiment_supprimer_ce_type_de_capteur'
                      ),
                      () =>
                        this.props.reqSupprimer(
                          parseInt(this.props.InfoSelect.sensor_type_id)
                        )
                    )
                  }
                >
                  <RiDeleteBin2Line size={24} />
                  {checkMot('supprimer_sensor_type')}
                </button>
              </AttenteRequete>
            </AccesRestreins>
            <br />
            <AccesRestreins numPrivilege={privilege.modifier_des_sensor_types}>
              {/*Modal pour la modification de l'image de type de capteur*/}
              {this.state.showModal ? (
                <div style={{ border: 'solid black 1px' }}>
                  <span onClick={this.handleCloseModal}>
                    <IoIosCloseCircleOutline size={40} />
                  </span>
                  <AjouterImageTypeCapteur
                    sensor_type_id={this.props.InfoSelect.sensor_type_id}
                  />
                </div>
              ) : (
                <button className={btM} onClick={this.handleOpenModal}>
                  <BiEditAlt size={24} />
                  {checkMot('modifier_image_de_type_capteur')}
                </button>
              )}
            </AccesRestreins>
          </>
        );
      }
      profilSensorType = (
        <div className={profil}>
          {/*Affiche l'image du type de capteur*/}
          {this.props.InfoSelect.sensor_type_path_image !== null ? (
            <div className={divs}>
              <AffichageImage
                ajoutClass={img}
                src={this.props.InfoSelect.sensor_type_path_image}
                alt={'sensor_type_path_image'}
              />
            </div>
          ) : (
            <div className={divs}>
              <BiErrorCircle size={50} /> aucune image de type de capteur{' '}
            </div>
          )}
          {/*Affiche l'id du type de capteur*/}
          <span className={span}>{checkMot('sensori_d')}</span>
          <br />
          <input
            className={InputReadOnly}
            value={this.props.InfoSelect.sensor_type_id}
            readOnly={true}
          />
          <br />
          {/*Affiche le nom du type de capteur*/}
          <span className={span}>{checkMot('sensor_type_name')}</span>
          <br />
          <input
            className={inputClass}
            onChange={(event) => this.gererSensorTypeName(event)}
            placeholder={this.props.InfoSelect.sensor_type_name}
            value={this.state.info_sensorType_name}
            readOnly={!this.state.afficheFormulaireModifier}
          />
          <br />
          {/*Affiche le nom du type affichage de capteur*/}
          <span className={span}>{checkMot('sensor_type_display')}</span>
          <br />
          <select
            onChange={(event) => this.gererSensorTypeDisplay(event)}
            defaultValue={this.props.InfoSelect.sensor_type_display}
            value={this.state.info_sensorType_display}
            disabled={!this.state.afficheFormulaireModifier}
            className={[s.custom_select, inputClass].join(' ')}
          >
            {Object.keys(glossaire_affichage_type_capteur).map(
              (cleTypeAffichage) => {
                return (
                  <option
                    value={glossaire_affichage_type_capteur[cleTypeAffichage]}
                  >
                    {checkMot(
                      glossaire_affichage_type_capteur[cleTypeAffichage]
                    )}
                  </option>
                );
              }
            )}
          </select>
          <br />
          {/*Affiche les boutons de contrôles de profil ou modification*/}
          {affichageBouton}
        </div>
      );
    }
    return profilSensorType;
  }
}

export default ProfilSensorType;

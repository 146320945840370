import React, { Component } from 'react';
import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';

import SelectPrivilege from '../../../UI/formulaire/SelectPrivilege/SelectPrivilege';
import ListePrivilegesDetaillees from '../../../UI/ListePrivilegesDetaillees/ListePrivilegesDetaillees';
import { connect } from 'react-redux';
import * as S from './FormulairePersonalisationPrivileges.module.scss';
import { checkMot } from '../../../../ressources/functionJS/checkMot';
function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

class FormulairePersonalisationPrivileges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personnaliserPrivilegeBool: false,
      listePrivilegesIdCategorie: [],
      privilegePersonnalise: [],
    };
  }
  componentDidMount() {
    //préconfigure le champ des privilèges pour la modification
    if (this.props.preconfigurerPrivilege !== undefined) {
      this.setState({
        listePrivilegesIdCategorie: this.props.preconfigurerPrivilege,
      });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state !== prevState) {
      if (this.state.personnaliserPrivilegeBool) {
        this.props.gererPrivilegesList(this.state.privilegePersonnalise);
      } else {
        this.props.gererPrivilegesList(this.state.listePrivilegesIdCategorie);
      }
    }
  }

  gererPrivilegesCategorie = (listePrivilegesIdCategorie) => {
    this.setState({
      ...this.state,
      listePrivilegesIdCategorie: listePrivilegesIdCategorie,
    });
  };
  gererPrivilegePersonnalise = (listePrivilegesPersonnalise) => {
    this.setState({
      ...this.state,
      privilegePersonnalise: listePrivilegesPersonnalise,
    });
  };

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };
  render() {
    const AffichageListePrivilegesDetaillees = (
      <ListePrivilegesDetaillees
        defaultChecked={this.state.listePrivilegesIdCategorie}
        remontePrivilegePersonnalise={this.gererPrivilegePersonnalise}
      />
    );
    return (
      <>
        <label className={S.text}>{checkMot('privilege_utilisateur')}</label>
        <div className={[S.text2].join(' ')}>
          {/*Affichage du label en fonction de la sélection des privilèges de l'utilisateur*/}
          <label className={s.mr_sm_2} htmlFor="inlineFormCustomSelect">
            {this.props.listePrivilegesParCategorie !== null &&
            this.props.listePrivilegesParCategorie !== undefined
              ? Object.keys(this.props.listePrivilegesParCategorie).map(
                  (cleCat) => {
                    return !this.state.personnaliserPrivilegeBool ? (
                      arrayEquals(
                        this.props.listePrivilegesParCategorie[cleCat],
                        this.state.listePrivilegesIdCategorie
                      ) ? (
                        <span key={'affichage' + cleCat}>
                          : {checkMot(cleCat)}
                        </span>
                      ) : null
                    ) : null;
                  }
                )
              : null}
          </label>
          <div className={[s.text_left].join(' ')}>
            <SelectPrivilege
              gererPrivilege={this.gererPrivilegesCategorie}
              preconfigurerPrivilege={this.props.preconfigurerPrivilege}
              desactive={this.state.personnaliserPrivilegeBool}
              dicoLangues={this.props.dicoLangues}
              langueSite={this.props.langueSite}
              idPrivilegeUtilisateur={this.props.idPrivilegeUtilisateur}
              listePrivilegesParCategorie={
                this.props.listePrivilegesParCategorie
              }
            />
          </div>
          {/*todo: Affichage de la liste des privilèges avec description pour la sélection personnalisé de privilège*/}
          <div className={[s.col_auto].join(' ')}>
            {checkMot('defaut')}
            <input
              type={'checkbox'}
              checked={this.state.personnaliserPrivilegeBool}
              onChange={this.handleChange}
              name="personnaliserPrivilegeBool"
              key="personnaliserPrivilegeBool"
            />
            {checkMot('personnalise')}
          </div>
          <div className={[s.col_auto].join(' ')}>
            {this.state.personnaliserPrivilegeBool
              ? AffichageListePrivilegesDetaillees
              : null}
          </div>
        </div>
        {/**Privilège**/}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dicoLangues: state.dico.dicoLangues,
    langueSite: state.dico.langueSite,
    idPrivilegeUtilisateur: state.authentifie.idPrivilegeUtilisateur,
    listePrivilegesParCategorie:
      state.privilegeUtilisateur.ListePrivilegesParCategorie,
  };
};
export default connect(mapStateToProps)(FormulairePersonalisationPrivileges);

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { textFilter } from 'react-bootstrap-table2-filter';
import Tableau from '../../UI/tableau/Tableau';
import Bouton from '../../UI/Bouton/Bouton';

import * as privilege from '../../../ressources/glossaires/glossaire_privileges';
import * as requestType from '../../../ressources/glossaires/glossaire_requetes';
import * as type_btn from '../../../ressources/glossaires/glossaire_type_de_bouton';
import { checkMot } from '../../../ressources/functionJS/checkMot';

import AttenteRequete from '../../../hoc/AttenteRequete/AttenteRequete';
import * as actions from '../../../services/actions';

import { BsFolderPlus } from 'react-icons/bs';
import { VscRefresh } from 'react-icons/vsc';
import { VscSearch } from 'react-icons/vsc';
import * as S from './TableauProjet.module.scss';

class TableauProjet extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    // this.props.recupererListeUtilisateurs(); //todo mettre une condition pour un intervalle de temps ou une action de la part de l'utilisateur || peut-être à cause de redux qui réatualise le composant alors que le state est pareil (à vérifier dans le reducteur de utilisateur)
    //todo: mettre la table à jour en fonction de la requête de mis à jour
    //todo: comparer avec l'ancienne version pour voir quel ligne a changé
    //todo: réfléchir à la modification de groupe qui peut impliquer plein de notifs d'alertes
    // todo reception de la liste et actualisation -> rappeler la liste et comparer pour afficher les différences
    if (
      JSON.stringify(prevProps.listeProjets) !==
      JSON.stringify(this.props.listeProjets)
    ) {
      this.setState({ ...this.state, listeProjets: this.props.listeProjets });
    }
  }
  afficherFiltre = (dataField, placeholder) => {
    const newCol = this.state.columns.map((col) => {
      if (col.dataField === dataField) {
        return {
          ...col,
          filter: textFilter({
            placeholder: placeholder,
          }),
        };
      } else {
        return col;
      }
    });
    this.setState({
      ...this.state,
      columns: [...newCol],
    });
  };
  state = {
    columns: [
      {
        dataField: 'project_id',
        text: [
          checkMot('id'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() => this.afficherFiltre('project_id', checkMot('id'))}
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'project_name',
        text: [
          checkMot('nom'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() => this.afficherFiltre('project_name', checkMot('nom'))}
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'groupe',
        text: [
          checkMot('groupe'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() => this.afficherFiltre('groupe', checkMot('groupe'))}
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'description',
        text: [
          checkMot('description_de_projet'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre(
                'description',
                checkMot('description_de_projet')
              )
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'parent_id',
        text: [
          checkMot('id_du_projet_parent'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('parent_id', checkMot('id_du_projet_parent'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'project_image',
        text: [
          checkMot('image_de_projet'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('project_image', checkMot('image_de_projet'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'active',
        text: [
          checkMot('etat'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() => this.afficherFiltre('active', checkMot('etat'))}
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'geo_barr',
        text: [
          checkMot('geo_barriere'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('geo_barr', checkMot('geo_barriere'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        formatter: (cell) => {
          if (cell === '[]' || cell === '' || cell === null) {
            return checkMot('non_existant');
          } else {
            return checkMot('existant');
          }
        },
      },
    ],
    selectRow: {
      mode: 'radio',
      clickToSelect: true,
      hideSelectAll: true,
      hideSelectColumn: true,
      style: () => {
        return {
          background: '#eeb214',
          color: 'black',
        };
      },
      onSelect: (row) => {
        // todo: pour chaque sélection faire la requête pour récupérer la liste des utilisateurs d'un projet
        return this.props.projetSelectione(row);
      },
    },
    iconeCreer: <BsFolderPlus size={15} />,
  };
  render() {
    return (
      <>
        <Tableau
          privilegeCreer={privilege.creer_des_projets}
          creer={this.props.creer}
          iconeCreer={this.state.iconeCreer}
          cleLigne={'project_id'}
          donnees={this.props.listeProjets}
          colonnes={this.state.columns}
          selectionnerLignes={this.state.selectRow}
        >
          <AttenteRequete
            request_type={requestType.recuperation_de_la_liste_des_projets}
            str={checkMot('maj_projets_en_cour')}
          >
            <Bouton
              hoverText={checkMot('refresh')}
              ajoutClass={S.btn}
              type={type_btn.outil}
              rond={true}
              cliquer={() => {
                this.props.recupererListeProjets();
              }}
            >
              <VscRefresh size={15} />
            </Bouton>
          </AttenteRequete>
        </Tableau>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listeProjets: state.authentifie.projects_list,
    user_id: state.authentifie.user_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    recupererListeProjets: () => dispatch(actions.reqRecupererListeProjets()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableauProjet);

import React, { useEffect, useState } from 'react';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import { getSensorTypesList } from '../../../services/api';
import { SensorType } from '../../../types/types';

interface FormulaireSelectionMultipleTypeCapteurProps {
  gererSensor_types_id: (selectedIds: number[]) => void;
}

const FormulaireSelectionMultipleTypeCapteur: React.FC<
  FormulaireSelectionMultipleTypeCapteurProps
> = (props) => {
  const [defaultSensorType, setDefaultSensorType] = useState<number[]>([]);
  const [listSensorTypes, setListSensorTypes] = useState<SensorType[]>([]);

  useEffect(() => {
    const initSelectList = async () => {
      const response = await getSensorTypesList();
      setListSensorTypes(response.sensor_types_list);
    };
    initSelectList();
  }, []);

  // récupérer liste des capteurs avec this.props.listeCapteurs
  // créer un formulaire pour ajouter un capteur à l'objet vue sous forme de liste déroulante via objet datalist
  return (
    <MultipleSelect
      preconfigurerListe={defaultSensorType}
      label={checkMot('sensor_type')}
      namesList={listSensorTypes}
      labelKey={'sensor_type_name'}
      valueKey={'sensor_type_id'}
      sx={{ m: 0, minWidth: 220, maxWidth: 500, marginLeft: 1 }}
      gererListeSelectionne={props.gererSensor_types_id}
    />
  );
};

export default FormulaireSelectionMultipleTypeCapteur;

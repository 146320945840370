import React, { Component } from 'react';
import { textFilter } from 'react-bootstrap-table2-filter';
import { connect } from 'react-redux';

import Bouton from '../../../UI/Bouton/Bouton';
import Tableau from '../../../UI/tableau/Tableau';

import { checkMot } from '../../../../ressources/functionJS/checkMot';
import * as type_btn from '../../../../ressources/glossaires/glossaire_type_de_bouton';

import * as S from './TableauProjetsTauxCriticite.module.scss';

import { VscNewFile, VscSearch } from 'react-icons/vsc';
import { convertiUtsToDateHour } from '../../../InterfaceClient/Tabs/Alarmes/TableauAlarmesClient/TableauAlarmesClient';
import {
  TIME_UNITS,
  uniteAffichageDuree,
} from '../../../UI/FormulaireSaisieDurée/FormulaireSaisieDuree';

class TableauProjetsTauxCriticite extends Component {
  state = {
    columns: [
      {
        dataField: 'detection_timestamp_sensor_id',
        text: [
          checkMot('detection_timestamp_sensor_id'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre(
                'detection_timestamp_sensor_id',
                checkMot('detection_timestamp_sensor_id')
              )
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        formatter: (cell) => {
          return convertiUtsToDateHour(cell);
        },
        hidden: true,
      },
      {
        dataField: 'detection_timestamp',
        text: [
          checkMot('detection_timestamp'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre(
                'detection_timestamp',
                checkMot('detection_timestamp')
              )
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        formatter: (cell) => {
          return convertiUtsToDateHour(cell);
        },
      },
      {
        dataField: 'timestamp_last_value',
        text: [
          checkMot('timestamp_last_value'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre(
                'timestamp_last_value',
                checkMot('timestamp_last_value')
              )
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        formatter: (cell) => {
          return convertiUtsToDateHour(cell);
        },
      },
      {
        dataField: 'delay',
        text: [
          checkMot('delay'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() => this.afficherFiltre('delay', checkMot('delay'))}
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        formatter: (cell) => {
          const selectedUnit = uniteAffichageDuree(cell);
          const valeur = cell / TIME_UNITS[selectedUnit];
          return valeur.toFixed(2) + ' ' + checkMot(selectedUnit);
        },
      },
      {
        dataField: 'criticity_level',
        text: [
          checkMot('criticity_level'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre(
                'criticity_level',
                checkMot('criticity_level')
              )
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'project_name',
        text: [
          checkMot('project_name'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('project_name', checkMot('project_name'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      // {
      //
      //     dataField: 'sensor_id', text: [checkMot("sensor_id"),
      //         <Bouton type={type_btn.lupeSearch}
      //                 cliquer={() => this.afficherFiltre("sensor_id", checkMot("sensor_id"))}><VscSearch size={10}/>
      //         </Bouton>
      //     ], sort: true,
      // },
      {
        dataField: 'sensor_name',
        text: [
          checkMot('sensor_name'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('sensor_name', checkMot('sensor_name'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'sensor_type',
        text: [
          checkMot('sensor_type'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('sensor_type', checkMot('sensor_type'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'sensor_group',
        text: [
          checkMot('sensor_group'),
          <Bouton
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('sensor_group', checkMot('sensor_group'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
    ],
    selectRow: {
      // paramètre d'affichage des lignes du tableau
      mode: 'radio',
      clickToSelect: true,
      hideSelectAll: true,
      hideSelectColumn: true,
      style: () => {
        //style après sélection
        return {
          background: '#eeb214',
          color: 'black',
        };
      },
      // onSelect: (row) => {//fonction de récupération des données de la ligne après sélection de la ligne
      //     return this.props.projetSelectione(row);
      // }
    },
    iconeCreer: <VscNewFile size={15} />,
  };

  afficherFiltre = (dataField, placeholder) => {
    const newCol = this.state.columns.map((col) => {
      if (col.dataField === dataField) {
        return {
          ...col,
          filter: textFilter({
            placeholder: placeholder,
          }),
        };
      } else {
        return col;
      }
    });
    this.setState({
      ...this.state,
      columns: [...newCol],
    });
  };

  render() {
    let liste_tableaux_de_bord_favoris = [];
    if (this.props.sensorList?.length > 0) {
      for (let objetProject of this.props.sensorList) {
        //récupère la liste des alarmes_xy (utiliser la requête api) en fonction de la liste de projets sélectionné dans le filtre
        if (this.props.filtre_projects_list_id.length > 0) {
          // envoie la requête de recupération de alarme si la liste de projets n'est pas vide

          liste_tableaux_de_bord_favoris.push({
            ...objetProject,
            detection_timestamp_sensor_id:
              objetProject['detection_timestamp'] +
              '_' +
              objetProject['sensor_id'],
          });
        }
      }
    }

    return (
      <>
        <Tableau
          cleLigne={'detection_timestamp_sensor_id'}
          creer={this.props.creer}
          iconeCreer={this.state.iconeCreer}
          // donnees={this.props.sensors_list_maintenance}
          donnees={liste_tableaux_de_bord_favoris}
          colonnes={this.state.columns}
          selectionnerLignes={this.state.selectRow}
        >
          <span className={S.refreshForm}>{this.props.children}</span>
        </Tableau>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listeRapportsGabarits: state.rapport.templates_reports_list,
    filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
    project_id_url: state.interfaceClient.project_id_url,
    projects_list: state.authentifie.projects_list,
  };
};

export default connect(mapStateToProps)(TableauProjetsTauxCriticite);

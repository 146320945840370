import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../services/actions';

import ConteneurInformation from '../ConteneurInformation/ConteneurInformation';
import AjouterUtilisateur from './Ajouter/AjouterUtilisateur';
import TableauUtilisateur from './TableauUtilisateur/TableauUtilisateur';
import ProfilUtilisateur from '../Utilisateur/ProfilUtilisateur/ProfilUtilisateur';
import { checkMot } from '../../ressources/functionJS/checkMot';
import Bouton from '../UI/Bouton/Bouton';

import * as indexConteneurInfo from '../../ressources/glossaires/glossaire_conteneur_info';
import * as type_btn from '../../ressources/glossaires/glossaire_type_de_bouton';
import { BiArrowFromLeft, BiArrowFromRight } from 'react-icons/bi';
import S from './Utilisateur.module.scss';

class Utilisateur extends Component {
  state = {
    parametresSelectione: {},
    montrerInfo: false,
    indexConteneurInfo: null,
    montrerInfoFull: false,
  };
  UtilisateurSelectione = (info) => {
    this.setState({
      ...this.state,
      parametresSelectione: info,
      indexConteneurInfo: indexConteneurInfo.profil,
      montrerInfo: true,
    });
  };
  //fonction switch conteneur info
  commutateurInfoCreer = () =>
    this.setState((prevState) => {
      if (prevState.montrerInfo) {
        //ancien état : conteneur info ouvert ; prochain état fermé -> on désactive full info
        return {
          ...this.state,
          montrerInfo: !prevState.montrerInfo,
          montrerInfoFull: false,
        };
      } else {
        return { ...this.state, montrerInfo: !prevState.montrerInfo };
      }
    });
  //fonction switch conteneur info full screen
  commutateurInfoFull = () =>
    this.setState((prevState) => {
      return { ...this.state, montrerInfoFull: !prevState.montrerInfoFull };
    });
  commutateurAnnuler = () => {
    this.props.ajouterAlerte('warning', 'Annuler', 'Utilisateur non créé');
    this.setState({
      ...this.state,
      montrerInfo: false,
      indexConteneurInfo: null,
    });
  };
  creerUtilisateur = () => {
    this.setState({
      ...this.state,
      montrerInfo: true,
      indexConteneurInfo: indexConteneurInfo.ajouterForm,
    });
  };

  render() {
    let styleConteneurCentralGauche = [S.ConteneurCentralGauche];
    let styleConteneurCentralDroite = [S.ConteneurCentralDroite];
    let commutateurClasses = [S.CommutateurInfo];

    if (this.state.montrerInfo && this.state.montrerInfoFull) {
      //conteneur info ouvert & plein écran
      //on met la largeur à 100% du conteneur info
      styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFull);
      //on réduit à zéro la largeur du conteneur central
      styleConteneurCentralGauche.push(S.ConteneurCentraGaucheVoid);
    } else if (this.state.montrerInfo && !this.state.montrerInfoFull) {
      //conteneur info ouvert & !plein écran
      styleConteneurCentralDroite.push(S.ConteneurCentralDroiteOuvert);
    } else {
      //conteneur info fermé
      styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFermer);
      styleConteneurCentralGauche.push(S.ConteneurCentraGaucheFermer);
    }
    return (
      <>
        <div className={[S.ConteneurUtilisateur].join(' ')}>
          <div className={styleConteneurCentralGauche.join(' ')}>
            <TableauUtilisateur
              selectioneLigne={this.UtilisateurSelectione}
              creer={this.creerUtilisateur}
            />
            <div className={S.titleModule}> {checkMot('utilisateurs')} </div>
          </div>
          {/* bouton plein ferme conteneur  info */}
          <div className={styleConteneurCentralDroite.join(' ')}>
            {/* bouton plein ferme conteneur  info */}
            <Bouton
              ajoutClass={[commutateurClasses, S.expandTO].join(' ')}
              cliquer={this.commutateurInfoCreer}
              rond={true}
              type={type_btn.danger}
            >
              <BiArrowFromLeft size={15} />
            </Bouton>
            {/* bouton plein écran */}
            <Bouton
              ajoutClass={S.expand}
              cliquer={this.commutateurInfoFull}
              rond={true}
              type={type_btn.danger}
            >
              <BiArrowFromRight size={15} />
            </Bouton>
            <ConteneurInformation
              selectionInfo={this.state.indexConteneurInfo}
              ajouterForm={
                <AjouterUtilisateur
                  ajouterAlerte={this.props.ajouterAlerte}
                  annuler={this.commutateurAnnuler}
                  ajouter={this.props.reqAjouter}
                />
              }
              profil={
                <ProfilUtilisateur
                  infoUser={this.state.parametresSelectione}
                  reqModifier={this.props.reqModifier}
                  reqSupprimer={this.props.reqSupprimer}
                  listeProjets={this.props.listeProjets}
                  langueSite={this.props.langueSite}
                  listePrivilegesParCategorie={
                    this.props.listePrivilegesParCategorie
                  }
                  dicoPrivileges={this.props.dicoPrivileges}
                />
              }
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listeProjets: state.authentifie.projects_list,
    listePrivilegesParCategorie:
      state.privilegeUtilisateur.ListePrivilegesParCategorie,
    langueSite: state.dico.langueSite,
    dicoPrivileges: state.dico.dico_privileges,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ajouterAlerte: (typeAlerte, titre, corps) =>
      dispatch(actions.ajouterAlerte(typeAlerte, titre, corps)),
    reqAjouter: (formulaireCreerUtilisateur) =>
      dispatch(actions.reqAjouterUtilisateur(formulaireCreerUtilisateur)),
    reqModifier: (formulaireDeModificationUtilisateur) =>
      dispatch(
        actions.reqModifierUtilisateur(formulaireDeModificationUtilisateur)
      ),
    reqSupprimer: (idUtilisateur) =>
      dispatch(actions.reqSupprimerUtilisateur(idUtilisateur)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Utilisateur);

import personnaliser from './personnaliser.module.scss';

let globalStyle = personnaliser;

export const accordion = globalStyle['accordion'];
export const active = globalStyle['active'];
export const alert = globalStyle['alert'];
export const alert_danger = globalStyle['alert-danger'];
export const alert_dark = globalStyle['alert-dark'];
export const alert_dismissible = globalStyle['alert-dismissible'];
export const alert_heading = globalStyle['alert-heading'];
export const alert_info = globalStyle['alert-info'];
export const alert_light = globalStyle['alert-light'];
export const alert_link = globalStyle['alert-link'];
export const alert_primary = globalStyle['alert-primary'];
export const alert_secondary = globalStyle['alert-secondary'];
export const alert_success = globalStyle['alert-success'];
export const alert_warning = globalStyle['alert-warning'];
export const align_baseline = globalStyle['align-baseline'];
export const align_bottom = globalStyle['align-bottom'];
export const align_content_around = globalStyle['align-content-around'];
export const align_content_between = globalStyle['align-content-between'];
export const align_content_center = globalStyle['align-content-center'];
export const align_content_end = globalStyle['align-content-end'];
export const align_content_lg_around = globalStyle['align-content-lg-around'];
export const align_content_lg_between = globalStyle['align-content-lg-between'];
export const align_content_lg_center = globalStyle['align-content-lg-center'];
export const align_content_lg_end = globalStyle['align-content-lg-end'];
export const align_content_lg_start = globalStyle['align-content-lg-start'];
export const align_content_lg_stretch = globalStyle['align-content-lg-stretch'];
export const align_content_md_around = globalStyle['align-content-md-around'];
export const align_content_md_between = globalStyle['align-content-md-between'];
export const align_content_md_center = globalStyle['align-content-md-center'];
export const align_content_md_end = globalStyle['align-content-md-end'];
export const align_content_md_start = globalStyle['align-content-md-start'];
export const align_content_md_stretch = globalStyle['align-content-md-stretch'];
export const align_content_sm_around = globalStyle['align-content-sm-around'];
export const align_content_sm_between = globalStyle['align-content-sm-between'];
export const align_content_sm_center = globalStyle['align-content-sm-center'];
export const align_content_sm_end = globalStyle['align-content-sm-end'];
export const align_content_sm_start = globalStyle['align-content-sm-start'];
export const align_content_sm_stretch = globalStyle['align-content-sm-stretch'];
export const align_content_start = globalStyle['align-content-start'];
export const align_content_stretch = globalStyle['align-content-stretch'];
export const align_content_xl_around = globalStyle['align-content-xl-around'];
export const align_content_xl_between = globalStyle['align-content-xl-between'];
export const align_content_xl_center = globalStyle['align-content-xl-center'];
export const align_content_xl_end = globalStyle['align-content-xl-end'];
export const align_content_xl_start = globalStyle['align-content-xl-start'];
export const align_content_xl_stretch = globalStyle['align-content-xl-stretch'];
export const align_items_baseline = globalStyle['align-items-baseline'];
export const align_items_center = globalStyle['align-items-center'];
export const align_items_end = globalStyle['align-items-end'];
export const align_items_lg_baseline = globalStyle['align-items-lg-baseline'];
export const align_items_lg_center = globalStyle['align-items-lg-center'];
export const align_items_lg_end = globalStyle['align-items-lg-end'];
export const align_items_lg_start = globalStyle['align-items-lg-start'];
export const align_items_lg_stretch = globalStyle['align-items-lg-stretch'];
export const align_items_md_baseline = globalStyle['align-items-md-baseline'];
export const align_items_md_center = globalStyle['align-items-md-center'];
export const align_items_md_end = globalStyle['align-items-md-end'];
export const align_items_md_start = globalStyle['align-items-md-start'];
export const align_items_md_stretch = globalStyle['align-items-md-stretch'];
export const align_items_sm_baseline = globalStyle['align-items-sm-baseline'];
export const align_items_sm_center = globalStyle['align-items-sm-center'];
export const align_items_sm_end = globalStyle['align-items-sm-end'];
export const align_items_sm_start = globalStyle['align-items-sm-start'];
export const align_items_sm_stretch = globalStyle['align-items-sm-stretch'];
export const align_items_start = globalStyle['align-items-start'];
export const align_items_stretch = globalStyle['align-items-stretch'];
export const align_items_xl_baseline = globalStyle['align-items-xl-baseline'];
export const align_items_xl_center = globalStyle['align-items-xl-center'];
export const align_items_xl_end = globalStyle['align-items-xl-end'];
export const align_items_xl_start = globalStyle['align-items-xl-start'];
export const align_items_xl_stretch = globalStyle['align-items-xl-stretch'];
export const align_middle = globalStyle['align-middle'];
export const align_self_auto = globalStyle['align-self-auto'];
export const align_self_baseline = globalStyle['align-self-baseline'];
export const align_self_center = globalStyle['align-self-center'];
export const align_self_end = globalStyle['align-self-end'];
export const align_self_lg_auto = globalStyle['align-self-lg-auto'];
export const align_self_lg_baseline = globalStyle['align-self-lg-baseline'];
export const align_self_lg_center = globalStyle['align-self-lg-center'];
export const align_self_lg_end = globalStyle['align-self-lg-end'];
export const align_self_lg_start = globalStyle['align-self-lg-start'];
export const align_self_lg_stretch = globalStyle['align-self-lg-stretch'];
export const align_self_md_auto = globalStyle['align-self-md-auto'];
export const align_self_md_baseline = globalStyle['align-self-md-baseline'];
export const align_self_md_center = globalStyle['align-self-md-center'];
export const align_self_md_end = globalStyle['align-self-md-end'];
export const align_self_md_start = globalStyle['align-self-md-start'];
export const align_self_md_stretch = globalStyle['align-self-md-stretch'];
export const align_self_sm_auto = globalStyle['align-self-sm-auto'];
export const align_self_sm_baseline = globalStyle['align-self-sm-baseline'];
export const align_self_sm_center = globalStyle['align-self-sm-center'];
export const align_self_sm_end = globalStyle['align-self-sm-end'];
export const align_self_sm_start = globalStyle['align-self-sm-start'];
export const align_self_sm_stretch = globalStyle['align-self-sm-stretch'];
export const align_self_start = globalStyle['align-self-start'];
export const align_self_stretch = globalStyle['align-self-stretch'];
export const align_self_xl_auto = globalStyle['align-self-xl-auto'];
export const align_self_xl_baseline = globalStyle['align-self-xl-baseline'];
export const align_self_xl_center = globalStyle['align-self-xl-center'];
export const align_self_xl_end = globalStyle['align-self-xl-end'];
export const align_self_xl_start = globalStyle['align-self-xl-start'];
export const align_self_xl_stretch = globalStyle['align-self-xl-stretch'];
export const align_text_bottom = globalStyle['align-text-bottom'];
export const align_text_top = globalStyle['align-text-top'];
export const align_top = globalStyle['align-top'];
export const arrow = globalStyle['arrow'];
export const badge = globalStyle['badge'];
export const badge_danger = globalStyle['badge-danger'];
export const badge_dark = globalStyle['badge-dark'];
export const badge_info = globalStyle['badge-info'];
export const badge_light = globalStyle['badge-light'];
export const badge_pill = globalStyle['badge-pill'];
export const badge_primary = globalStyle['badge-primary'];
export const badge_secondary = globalStyle['badge-secondary'];
export const badge_success = globalStyle['badge-success'];
export const badge_warning = globalStyle['badge-warning'];
export const bg_danger = globalStyle['bg-danger'];
export const bg_dark = globalStyle['bg-dark'];
export const bg_info = globalStyle['bg-info'];
export const bg_light = globalStyle['bg-light'];
export const bg_primary = globalStyle['bg-primary'];
export const bg_secondary = globalStyle['bg-secondary'];
export const bg_success = globalStyle['bg-success'];
export const bg_transparent = globalStyle['bg-transparent'];
export const bg_warning = globalStyle['bg-warning'];
export const bg_white = globalStyle['bg-white'];
export const blockquote = globalStyle['blockquote'];
export const blockquote_footer = globalStyle['blockquote-footer'];
export const border = globalStyle['border'];
export const border_0 = globalStyle['border-0'];
export const border_bottom = globalStyle['border-bottom'];
export const border_bottom_0 = globalStyle['border-bottom-0'];
export const border_danger = globalStyle['border-danger'];
export const border_dark = globalStyle['border-dark'];
export const border_info = globalStyle['border-info'];
export const border_left = globalStyle['border-left'];
export const border_left_0 = globalStyle['border-left-0'];
export const border_light = globalStyle['border-light'];
export const border_primary = globalStyle['border-primary'];
export const border_right = globalStyle['border-right'];
export const border_right_0 = globalStyle['border-right-0'];
export const border_secondary = globalStyle['border-secondary'];
export const border_success = globalStyle['border-success'];
export const border_top = globalStyle['border-top'];
export const border_top_0 = globalStyle['border-top-0'];
export const border_warning = globalStyle['border-warning'];
export const border_white = globalStyle['border-white'];
export const breadcrumb = globalStyle['breadcrumb'];
export const breadcrumb_item = globalStyle['breadcrumb-item'];
export const bs_popover_auto = globalStyle['bs-popover-auto'];
export const bs_popover_bottom = globalStyle['bs-popover-bottom'];
export const bs_popover_left = globalStyle['bs-popover-left'];
export const bs_popover_right = globalStyle['bs-popover-right'];
export const bs_popover_top = globalStyle['bs-popover-top'];
export const bs_tooltip_auto = globalStyle['bs-tooltip-auto'];
export const bs_tooltip_bottom = globalStyle['bs-tooltip-bottom'];
export const bs_tooltip_left = globalStyle['bs-tooltip-left'];
export const bs_tooltip_right = globalStyle['bs-tooltip-right'];
export const bs_tooltip_top = globalStyle['bs-tooltip-top'];
export const btn = globalStyle['btn'];
export const btn_block = globalStyle['btn-block'];
export const btn_danger = globalStyle['btn-danger'];
export const btn_dark = globalStyle['btn-dark'];
export const btn_group = globalStyle['btn-group'];
export const btn_group_lg = globalStyle['btn-group-lg'];
export const btn_group_sm = globalStyle['btn-group-sm'];
export const btn_group_toggle = globalStyle['btn-group-toggle'];
export const btn_group_vertical = globalStyle['btn-group-vertical'];
export const btn_info = globalStyle['btn-info'];
export const btn_lg = globalStyle['btn-lg'];
export const btn_light = globalStyle['btn-light'];
export const btn_link = globalStyle['btn-link'];
export const btn_outline_danger = globalStyle['btn-outline-danger'];
export const btn_outline_dark = globalStyle['btn-outline-dark'];
export const btn_outline_info = globalStyle['btn-outline-info'];
export const btn_outline_light = globalStyle['btn-outline-light'];
export const btn_outline_primary = globalStyle['btn-outline-primary'];
export const btn_outline_secondary = globalStyle['btn-outline-secondary'];
export const btn_outline_success = globalStyle['btn-outline-success'];
export const btn_outline_warning = globalStyle['btn-outline-warning'];
export const btn_primary = globalStyle['btn-primary'];
export const btn_secondary = globalStyle['btn-secondary'];
export const btn_sm = globalStyle['btn-sm'];
export const btn_success = globalStyle['btn-success'];
export const btn_toolbar = globalStyle['btn-toolbar'];
export const btn_warning = globalStyle['btn-warning'];
export const card = globalStyle['card'];
export const card_body = globalStyle['card-body'];
export const card_columns = globalStyle['card-columns'];
export const card_deck = globalStyle['card-deck'];
export const card_footer = globalStyle['card-footer'];
export const card_group = globalStyle['card-group'];
export const card_header = globalStyle['card-header'];
export const card_header_pills = globalStyle['card-header-pills'];
export const card_header_tabs = globalStyle['card-header-tabs'];
export const card_img = globalStyle['card-img'];
export const card_img_bottom = globalStyle['card-img-bottom'];
export const card_img_overlay = globalStyle['card-img-overlay'];
export const card_img_top = globalStyle['card-img-top'];
export const card_link = globalStyle['card-link'];
export const card_subtitle = globalStyle['card-subtitle'];
export const card_text = globalStyle['card-text'];
export const card_title = globalStyle['card-title'];
export const carousel = globalStyle['carousel'];
export const carousel_caption = globalStyle['carousel-caption'];
export const carousel_control_next = globalStyle['carousel-control-next'];
export const carousel_control_next_icon =
  globalStyle['carousel-control-next-icon'];
export const carousel_control_prev = globalStyle['carousel-control-prev'];
export const carousel_control_prev_icon =
  globalStyle['carousel-control-prev-icon'];
export const carousel_fade = globalStyle['carousel-fade'];
export const carousel_indicators = globalStyle['carousel-indicators'];
export const carousel_inner = globalStyle['carousel-inner'];
export const carousel_item = globalStyle['carousel-item'];
export const carousel_item_left = globalStyle['carousel-item-left'];
export const carousel_item_next = globalStyle['carousel-item-next'];
export const carousel_item_prev = globalStyle['carousel-item-prev'];
export const carousel_item_right = globalStyle['carousel-item-right'];
export const clearfix = globalStyle['clearfix'];
export const close = globalStyle['close'];
export const col = globalStyle['col'];
export const col_1 = globalStyle['col-1'];
export const col_10 = globalStyle['col-10'];
export const col_11 = globalStyle['col-11'];
export const col_12 = globalStyle['col-12'];
export const col_2 = globalStyle['col-2'];
export const col_3 = globalStyle['col-3'];
export const col_4 = globalStyle['col-4'];
export const col_5 = globalStyle['col-5'];
export const col_6 = globalStyle['col-6'];
export const col_7 = globalStyle['col-7'];
export const col_8 = globalStyle['col-8'];
export const col_9 = globalStyle['col-9'];
export const col_auto = globalStyle['col-auto'];
export const col_form_label = globalStyle['col-form-label'];
export const col_form_label_lg = globalStyle['col-form-label-lg'];
export const col_form_label_sm = globalStyle['col-form-label-sm'];
export const col_lg = globalStyle['col-lg'];
export const col_lg_1 = globalStyle['col-lg-1'];
export const col_lg_10 = globalStyle['col-lg-10'];
export const col_lg_11 = globalStyle['col-lg-11'];
export const col_lg_12 = globalStyle['col-lg-12'];
export const col_lg_2 = globalStyle['col-lg-2'];
export const col_lg_3 = globalStyle['col-lg-3'];
export const col_lg_4 = globalStyle['col-lg-4'];
export const col_lg_5 = globalStyle['col-lg-5'];
export const col_lg_6 = globalStyle['col-lg-6'];
export const col_lg_7 = globalStyle['col-lg-7'];
export const col_lg_8 = globalStyle['col-lg-8'];
export const col_lg_9 = globalStyle['col-lg-9'];
export const col_lg_auto = globalStyle['col-lg-auto'];
export const col_md = globalStyle['col-md'];
export const col_md_1 = globalStyle['col-md-1'];
export const col_md_10 = globalStyle['col-md-10'];
export const col_md_11 = globalStyle['col-md-11'];
export const col_md_12 = globalStyle['col-md-12'];
export const col_md_2 = globalStyle['col-md-2'];
export const col_md_3 = globalStyle['col-md-3'];
export const col_md_4 = globalStyle['col-md-4'];
export const col_md_5 = globalStyle['col-md-5'];
export const col_md_6 = globalStyle['col-md-6'];
export const col_md_7 = globalStyle['col-md-7'];
export const col_md_8 = globalStyle['col-md-8'];
export const col_md_9 = globalStyle['col-md-9'];
export const col_md_auto = globalStyle['col-md-auto'];
export const col_sm = globalStyle['col-sm'];
export const col_sm_1 = globalStyle['col-sm-1'];
export const col_sm_10 = globalStyle['col-sm-10'];
export const col_sm_11 = globalStyle['col-sm-11'];
export const col_sm_12 = globalStyle['col-sm-12'];
export const col_sm_2 = globalStyle['col-sm-2'];
export const col_sm_3 = globalStyle['col-sm-3'];
export const col_sm_4 = globalStyle['col-sm-4'];
export const col_sm_5 = globalStyle['col-sm-5'];
export const col_sm_6 = globalStyle['col-sm-6'];
export const col_sm_7 = globalStyle['col-sm-7'];
export const col_sm_8 = globalStyle['col-sm-8'];
export const col_sm_9 = globalStyle['col-sm-9'];
export const col_sm_auto = globalStyle['col-sm-auto'];
export const col_xl = globalStyle['col-xl'];
export const col_xl_1 = globalStyle['col-xl-1'];
export const col_xl_10 = globalStyle['col-xl-10'];
export const col_xl_11 = globalStyle['col-xl-11'];
export const col_xl_12 = globalStyle['col-xl-12'];
export const col_xl_2 = globalStyle['col-xl-2'];
export const col_xl_3 = globalStyle['col-xl-3'];
export const col_xl_4 = globalStyle['col-xl-4'];
export const col_xl_5 = globalStyle['col-xl-5'];
export const col_xl_6 = globalStyle['col-xl-6'];
export const col_xl_7 = globalStyle['col-xl-7'];
export const col_xl_8 = globalStyle['col-xl-8'];
export const col_xl_9 = globalStyle['col-xl-9'];
export const col_xl_auto = globalStyle['col-xl-auto'];
export const collapse = globalStyle['collapse'];
export const collapsing = globalStyle['collapsing'];
export const com = globalStyle['com'];
export const container = globalStyle['container'];
export const container_fluid = globalStyle['container-fluid'];
export const container_lg = globalStyle['container-lg'];
export const container_md = globalStyle['container-md'];
export const container_sm = globalStyle['container-sm'];
export const container_xl = globalStyle['container-xl'];
export const css = globalStyle['css'];
export const custom_checkbox = globalStyle['custom-checkbox'];
export const custom_control = globalStyle['custom-control'];
export const custom_control_inline = globalStyle['custom-control-inline'];
export const custom_control_input = globalStyle['custom-control-input'];
export const custom_control_label = globalStyle['custom-control-label'];
export const custom_file = globalStyle['custom-file'];
export const custom_file_input = globalStyle['custom-file-input'];
export const custom_file_label = globalStyle['custom-file-label'];
export const custom_radio = globalStyle['custom-radio'];
export const custom_range = globalStyle['custom-range'];
export const custom_select = globalStyle['custom-select'];
export const custom_select_lg = globalStyle['custom-select-lg'];
export const custom_select_sm = globalStyle['custom-select-sm'];
export const custom_switch = globalStyle['custom-switch'];
export const d_block = globalStyle['d-block'];
export const d_flex = globalStyle['d-flex'];
export const d_inline = globalStyle['d-inline'];
export const d_inline_block = globalStyle['d-inline-block'];
export const d_inline_flex = globalStyle['d-inline-flex'];
export const d_lg_block = globalStyle['d-lg-block'];
export const d_lg_flex = globalStyle['d-lg-flex'];
export const d_lg_inline = globalStyle['d-lg-inline'];
export const d_lg_inline_block = globalStyle['d-lg-inline-block'];
export const d_lg_inline_flex = globalStyle['d-lg-inline-flex'];
export const d_lg_none = globalStyle['d-lg-none'];
export const d_lg_table = globalStyle['d-lg-table'];
export const d_lg_table_cell = globalStyle['d-lg-table-cell'];
export const d_lg_table_row = globalStyle['d-lg-table-row'];
export const d_md_block = globalStyle['d-md-block'];
export const d_md_flex = globalStyle['d-md-flex'];
export const d_md_inline = globalStyle['d-md-inline'];
export const d_md_inline_block = globalStyle['d-md-inline-block'];
export const d_md_inline_flex = globalStyle['d-md-inline-flex'];
export const d_md_none = globalStyle['d-md-none'];
export const d_md_table = globalStyle['d-md-table'];
export const d_md_table_cell = globalStyle['d-md-table-cell'];
export const d_md_table_row = globalStyle['d-md-table-row'];
export const d_none = globalStyle['d-none'];
export const d_print_block = globalStyle['d-print-block'];
export const d_print_flex = globalStyle['d-print-flex'];
export const d_print_inline = globalStyle['d-print-inline'];
export const d_print_inline_block = globalStyle['d-print-inline-block'];
export const d_print_inline_flex = globalStyle['d-print-inline-flex'];
export const d_print_none = globalStyle['d-print-none'];
export const d_print_table = globalStyle['d-print-table'];
export const d_print_table_cell = globalStyle['d-print-table-cell'];
export const d_print_table_row = globalStyle['d-print-table-row'];
export const d_sm_block = globalStyle['d-sm-block'];
export const d_sm_flex = globalStyle['d-sm-flex'];
export const d_sm_inline = globalStyle['d-sm-inline'];
export const d_sm_inline_block = globalStyle['d-sm-inline-block'];
export const d_sm_inline_flex = globalStyle['d-sm-inline-flex'];
export const d_sm_none = globalStyle['d-sm-none'];
export const d_sm_table = globalStyle['d-sm-table'];
export const d_sm_table_cell = globalStyle['d-sm-table-cell'];
export const d_sm_table_row = globalStyle['d-sm-table-row'];
export const d_table = globalStyle['d-table'];
export const d_table_cell = globalStyle['d-table-cell'];
export const d_table_row = globalStyle['d-table-row'];
export const d_xl_block = globalStyle['d-xl-block'];
export const d_xl_flex = globalStyle['d-xl-flex'];
export const d_xl_inline = globalStyle['d-xl-inline'];
export const d_xl_inline_block = globalStyle['d-xl-inline-block'];
export const d_xl_inline_flex = globalStyle['d-xl-inline-flex'];
export const d_xl_none = globalStyle['d-xl-none'];
export const d_xl_table = globalStyle['d-xl-table'];
export const d_xl_table_cell = globalStyle['d-xl-table-cell'];
export const d_xl_table_row = globalStyle['d-xl-table-row'];
export const disabled = globalStyle['disabled'];
export const display_1 = globalStyle['display-1'];
export const display_2 = globalStyle['display-2'];
export const display_3 = globalStyle['display-3'];
export const display_4 = globalStyle['display-4'];
export const dropdown = globalStyle['dropdown'];
export const dropdown_divider = globalStyle['dropdown-divider'];
export const dropdown_header = globalStyle['dropdown-header'];
export const dropdown_item = globalStyle['dropdown-item'];
export const dropdown_item_text = globalStyle['dropdown-item-text'];
export const dropdown_menu = globalStyle['dropdown-menu'];
export const dropdown_menu_left = globalStyle['dropdown-menu-left'];
export const dropdown_menu_lg_left = globalStyle['dropdown-menu-lg-left'];
export const dropdown_menu_lg_right = globalStyle['dropdown-menu-lg-right'];
export const dropdown_menu_md_left = globalStyle['dropdown-menu-md-left'];
export const dropdown_menu_md_right = globalStyle['dropdown-menu-md-right'];
export const dropdown_menu_right = globalStyle['dropdown-menu-right'];
export const dropdown_menu_sm_left = globalStyle['dropdown-menu-sm-left'];
export const dropdown_menu_sm_right = globalStyle['dropdown-menu-sm-right'];
export const dropdown_menu_xl_left = globalStyle['dropdown-menu-xl-left'];
export const dropdown_menu_xl_right = globalStyle['dropdown-menu-xl-right'];
export const dropdown_toggle = globalStyle['dropdown-toggle'];
export const dropdown_toggle_split = globalStyle['dropdown-toggle-split'];
export const dropleft = globalStyle['dropleft'];
export const dropright = globalStyle['dropright'];
export const dropup = globalStyle['dropup'];
export const embed_responsive = globalStyle['embed-responsive'];
export const embed_responsive_16by9 = globalStyle['embed-responsive-16by9'];
export const embed_responsive_1by1 = globalStyle['embed-responsive-1by1'];
export const embed_responsive_21by9 = globalStyle['embed-responsive-21by9'];
export const embed_responsive_4by3 = globalStyle['embed-responsive-4by3'];
export const embed_responsive_item = globalStyle['embed-responsive-item'];
export const fade = globalStyle['fade'];
export const figure = globalStyle['figure'];
export const figure_caption = globalStyle['figure-caption'];
export const figure_img = globalStyle['figure-img'];
export const fixed_bottom = globalStyle['fixed-bottom'];
export const fixed_top = globalStyle['fixed-top'];
export const flex_column = globalStyle['flex-column'];
export const flex_column_reverse = globalStyle['flex-column-reverse'];
export const flex_fill = globalStyle['flex-fill'];
export const flex_grow_0 = globalStyle['flex-grow-0'];
export const flex_grow_1 = globalStyle['flex-grow-1'];
export const flex_lg_column = globalStyle['flex-lg-column'];
export const flex_lg_column_reverse = globalStyle['flex-lg-column-reverse'];
export const flex_lg_fill = globalStyle['flex-lg-fill'];
export const flex_lg_grow_0 = globalStyle['flex-lg-grow-0'];
export const flex_lg_grow_1 = globalStyle['flex-lg-grow-1'];
export const flex_lg_nowrap = globalStyle['flex-lg-nowrap'];
export const flex_lg_row = globalStyle['flex-lg-row'];
export const flex_lg_row_reverse = globalStyle['flex-lg-row-reverse'];
export const flex_lg_shrink_0 = globalStyle['flex-lg-shrink-0'];
export const flex_lg_shrink_1 = globalStyle['flex-lg-shrink-1'];
export const flex_lg_wrap = globalStyle['flex-lg-wrap'];
export const flex_lg_wrap_reverse = globalStyle['flex-lg-wrap-reverse'];
export const flex_md_column = globalStyle['flex-md-column'];
export const flex_md_column_reverse = globalStyle['flex-md-column-reverse'];
export const flex_md_fill = globalStyle['flex-md-fill'];
export const flex_md_grow_0 = globalStyle['flex-md-grow-0'];
export const flex_md_grow_1 = globalStyle['flex-md-grow-1'];
export const flex_md_nowrap = globalStyle['flex-md-nowrap'];
export const flex_md_row = globalStyle['flex-md-row'];
export const flex_md_row_reverse = globalStyle['flex-md-row-reverse'];
export const flex_md_shrink_0 = globalStyle['flex-md-shrink-0'];
export const flex_md_shrink_1 = globalStyle['flex-md-shrink-1'];
export const flex_md_wrap = globalStyle['flex-md-wrap'];
export const flex_md_wrap_reverse = globalStyle['flex-md-wrap-reverse'];
export const flex_nowrap = globalStyle['flex-nowrap'];
export const flex_row = globalStyle['flex-row'];
export const flex_row_reverse = globalStyle['flex-row-reverse'];
export const flex_shrink_0 = globalStyle['flex-shrink-0'];
export const flex_shrink_1 = globalStyle['flex-shrink-1'];
export const flex_sm_column = globalStyle['flex-sm-column'];
export const flex_sm_column_reverse = globalStyle['flex-sm-column-reverse'];
export const flex_sm_fill = globalStyle['flex-sm-fill'];
export const flex_sm_grow_0 = globalStyle['flex-sm-grow-0'];
export const flex_sm_grow_1 = globalStyle['flex-sm-grow-1'];
export const flex_sm_nowrap = globalStyle['flex-sm-nowrap'];
export const flex_sm_row = globalStyle['flex-sm-row'];
export const flex_sm_row_reverse = globalStyle['flex-sm-row-reverse'];
export const flex_sm_shrink_0 = globalStyle['flex-sm-shrink-0'];
export const flex_sm_shrink_1 = globalStyle['flex-sm-shrink-1'];
export const flex_sm_wrap = globalStyle['flex-sm-wrap'];
export const flex_sm_wrap_reverse = globalStyle['flex-sm-wrap-reverse'];
export const flex_wrap = globalStyle['flex-wrap'];
export const flex_wrap_reverse = globalStyle['flex-wrap-reverse'];
export const flex_xl_column = globalStyle['flex-xl-column'];
export const flex_xl_column_reverse = globalStyle['flex-xl-column-reverse'];
export const flex_xl_fill = globalStyle['flex-xl-fill'];
export const flex_xl_grow_0 = globalStyle['flex-xl-grow-0'];
export const flex_xl_grow_1 = globalStyle['flex-xl-grow-1'];
export const flex_xl_nowrap = globalStyle['flex-xl-nowrap'];
export const flex_xl_row = globalStyle['flex-xl-row'];
export const flex_xl_row_reverse = globalStyle['flex-xl-row-reverse'];
export const flex_xl_shrink_0 = globalStyle['flex-xl-shrink-0'];
export const flex_xl_shrink_1 = globalStyle['flex-xl-shrink-1'];
export const flex_xl_wrap = globalStyle['flex-xl-wrap'];
export const flex_xl_wrap_reverse = globalStyle['flex-xl-wrap-reverse'];
export const float_left = globalStyle['float-left'];
export const float_lg_left = globalStyle['float-lg-left'];
export const float_lg_none = globalStyle['float-lg-none'];
export const float_lg_right = globalStyle['float-lg-right'];
export const float_md_left = globalStyle['float-md-left'];
export const float_md_none = globalStyle['float-md-none'];
export const float_md_right = globalStyle['float-md-right'];
export const float_none = globalStyle['float-none'];
export const float_right = globalStyle['float-right'];
export const float_sm_left = globalStyle['float-sm-left'];
export const float_sm_none = globalStyle['float-sm-none'];
export const float_sm_right = globalStyle['float-sm-right'];
export const float_xl_left = globalStyle['float-xl-left'];
export const float_xl_none = globalStyle['float-xl-none'];
export const float_xl_right = globalStyle['float-xl-right'];
export const focus = globalStyle['focus'];
export const font_italic = globalStyle['font-italic'];
export const font_weight_bold = globalStyle['font-weight-bold'];
export const font_weight_bolder = globalStyle['font-weight-bolder'];
export const font_weight_light = globalStyle['font-weight-light'];
export const font_weight_lighter = globalStyle['font-weight-lighter'];
export const font_weight_normal = globalStyle['font-weight-normal'];
export const form_check = globalStyle['form-check'];
export const form_check_inline = globalStyle['form-check-inline'];
export const form_check_input = globalStyle['form-check-input'];
export const form_check_label = globalStyle['form-check-label'];
export const form_control = globalStyle['form-control'];
export const form_control_file = globalStyle['form-control-file'];
export const form_control_lg = globalStyle['form-control-lg'];
export const form_control_plaintext = globalStyle['form-control-plaintext'];
export const form_control_range = globalStyle['form-control-range'];
export const form_control_sm = globalStyle['form-control-sm'];
export const form_group = globalStyle['form-group'];
export const form_inline = globalStyle['form-inline'];
export const form_row = globalStyle['form-row'];
export const form_text = globalStyle['form-text'];
export const h_100 = globalStyle['h-100'];
export const h_25 = globalStyle['h-25'];
export const h_50 = globalStyle['h-50'];
export const h_75 = globalStyle['h-75'];
export const h_auto = globalStyle['h-auto'];
export const h1 = globalStyle['h1'];
export const h2 = globalStyle['h2'];
export const h3 = globalStyle['h3'];
export const h4 = globalStyle['h4'];
export const h5 = globalStyle['h5'];
export const h6 = globalStyle['h6'];
export const hide = globalStyle['hide'];
export const img_fluid = globalStyle['img-fluid'];
export const img_thumbnail = globalStyle['img-thumbnail'];
export const initialism = globalStyle['initialism'];
export const input_group = globalStyle['input-group'];
export const input_group_append = globalStyle['input-group-append'];
export const input_group_lg = globalStyle['input-group-lg'];
export const input_group_prepend = globalStyle['input-group-prepend'];
export const input_group_sm = globalStyle['input-group-sm'];
export const input_group_text = globalStyle['input-group-text'];
export const invalid_feedback = globalStyle['invalid-feedback'];
export const invalid_tooltip = globalStyle['invalid-tooltip'];
export const invisible = globalStyle['invisible'];
export const is_invalid = globalStyle['is-invalid'];
export const is_valid = globalStyle['is-valid'];
export const jumbotron = globalStyle['jumbotron'];
export const jumbotron_fluid = globalStyle['jumbotron-fluid'];
export const justify_content_around = globalStyle['justify-content-around'];
export const justify_content_between = globalStyle['justify-content-between'];
export const justify_content_center = globalStyle['justify-content-center'];
export const justify_content_end = globalStyle['justify-content-end'];
export const justify_content_lg_around =
  globalStyle['justify-content-lg-around'];
export const justify_content_lg_between =
  globalStyle['justify-content-lg-between'];
export const justify_content_lg_center =
  globalStyle['justify-content-lg-center'];
export const justify_content_lg_end = globalStyle['justify-content-lg-end'];
export const justify_content_lg_start = globalStyle['justify-content-lg-start'];
export const justify_content_md_around =
  globalStyle['justify-content-md-around'];
export const justify_content_md_between =
  globalStyle['justify-content-md-between'];
export const justify_content_md_center =
  globalStyle['justify-content-md-center'];
export const justify_content_md_end = globalStyle['justify-content-md-end'];
export const justify_content_md_start = globalStyle['justify-content-md-start'];
export const justify_content_sm_around =
  globalStyle['justify-content-sm-around'];
export const justify_content_sm_between =
  globalStyle['justify-content-sm-between'];
export const justify_content_sm_center =
  globalStyle['justify-content-sm-center'];
export const justify_content_sm_end = globalStyle['justify-content-sm-end'];
export const justify_content_sm_start = globalStyle['justify-content-sm-start'];
export const justify_content_start = globalStyle['justify-content-start'];
export const justify_content_xl_around =
  globalStyle['justify-content-xl-around'];
export const justify_content_xl_between =
  globalStyle['justify-content-xl-between'];
export const justify_content_xl_center =
  globalStyle['justify-content-xl-center'];
export const justify_content_xl_end = globalStyle['justify-content-xl-end'];
export const justify_content_xl_start = globalStyle['justify-content-xl-start'];
export const lead = globalStyle['lead'];
export const list_group = globalStyle['list-group'];
export const list_group_flush = globalStyle['list-group-flush'];
export const list_group_horizontal = globalStyle['list-group-horizontal'];
export const list_group_horizontal_lg = globalStyle['list-group-horizontal-lg'];
export const list_group_horizontal_md = globalStyle['list-group-horizontal-md'];
export const list_group_horizontal_sm = globalStyle['list-group-horizontal-sm'];
export const list_group_horizontal_xl = globalStyle['list-group-horizontal-xl'];
export const list_group_item = globalStyle['list-group-item'];
export const list_group_item_action = globalStyle['list-group-item-action'];
export const list_group_item_danger = globalStyle['list-group-item-danger'];
export const list_group_item_dark = globalStyle['list-group-item-dark'];
export const list_group_item_info = globalStyle['list-group-item-info'];
export const list_group_item_light = globalStyle['list-group-item-light'];
export const list_group_item_primary = globalStyle['list-group-item-primary'];
export const list_group_item_secondary =
  globalStyle['list-group-item-secondary'];
export const list_group_item_success = globalStyle['list-group-item-success'];
export const list_group_item_warning = globalStyle['list-group-item-warning'];
export const list_inline = globalStyle['list-inline'];
export const list_inline_item = globalStyle['list-inline-item'];
export const list_unstyled = globalStyle['list-unstyled'];
export const m_0 = globalStyle['m-0'];
export const m_1 = globalStyle['m-1'];
export const m_2 = globalStyle['m-2'];
export const m_3 = globalStyle['m-3'];
export const m_4 = globalStyle['m-4'];
export const m_5 = globalStyle['m-5'];
export const m_auto = globalStyle['m-auto'];
export const m_lg_0 = globalStyle['m-lg-0'];
export const m_lg_1 = globalStyle['m-lg-1'];
export const m_lg_2 = globalStyle['m-lg-2'];
export const m_lg_3 = globalStyle['m-lg-3'];
export const m_lg_4 = globalStyle['m-lg-4'];
export const m_lg_5 = globalStyle['m-lg-5'];
export const m_lg_auto = globalStyle['m-lg-auto'];
export const m_lg_n1 = globalStyle['m-lg-n1'];
export const m_lg_n2 = globalStyle['m-lg-n2'];
export const m_lg_n3 = globalStyle['m-lg-n3'];
export const m_lg_n4 = globalStyle['m-lg-n4'];
export const m_lg_n5 = globalStyle['m-lg-n5'];
export const m_md_0 = globalStyle['m-md-0'];
export const m_md_1 = globalStyle['m-md-1'];
export const m_md_2 = globalStyle['m-md-2'];
export const m_md_3 = globalStyle['m-md-3'];
export const m_md_4 = globalStyle['m-md-4'];
export const m_md_5 = globalStyle['m-md-5'];
export const m_md_auto = globalStyle['m-md-auto'];
export const m_md_n1 = globalStyle['m-md-n1'];
export const m_md_n2 = globalStyle['m-md-n2'];
export const m_md_n3 = globalStyle['m-md-n3'];
export const m_md_n4 = globalStyle['m-md-n4'];
export const m_md_n5 = globalStyle['m-md-n5'];
export const m_n1 = globalStyle['m-n1'];
export const m_n2 = globalStyle['m-n2'];
export const m_n3 = globalStyle['m-n3'];
export const m_n4 = globalStyle['m-n4'];
export const m_n5 = globalStyle['m-n5'];
export const m_sm_0 = globalStyle['m-sm-0'];
export const m_sm_1 = globalStyle['m-sm-1'];
export const m_sm_2 = globalStyle['m-sm-2'];
export const m_sm_3 = globalStyle['m-sm-3'];
export const m_sm_4 = globalStyle['m-sm-4'];
export const m_sm_5 = globalStyle['m-sm-5'];
export const m_sm_auto = globalStyle['m-sm-auto'];
export const m_sm_n1 = globalStyle['m-sm-n1'];
export const m_sm_n2 = globalStyle['m-sm-n2'];
export const m_sm_n3 = globalStyle['m-sm-n3'];
export const m_sm_n4 = globalStyle['m-sm-n4'];
export const m_sm_n5 = globalStyle['m-sm-n5'];
export const m_xl_0 = globalStyle['m-xl-0'];
export const m_xl_1 = globalStyle['m-xl-1'];
export const m_xl_2 = globalStyle['m-xl-2'];
export const m_xl_3 = globalStyle['m-xl-3'];
export const m_xl_4 = globalStyle['m-xl-4'];
export const m_xl_5 = globalStyle['m-xl-5'];
export const m_xl_auto = globalStyle['m-xl-auto'];
export const m_xl_n1 = globalStyle['m-xl-n1'];
export const m_xl_n2 = globalStyle['m-xl-n2'];
export const m_xl_n3 = globalStyle['m-xl-n3'];
export const m_xl_n4 = globalStyle['m-xl-n4'];
export const m_xl_n5 = globalStyle['m-xl-n5'];
export const map = globalStyle['map'];
export const mark = globalStyle['mark'];
export const mb_0 = globalStyle['mb-0'];
export const mb_1 = globalStyle['mb-1'];
export const mb_2 = globalStyle['mb-2'];
export const mb_3 = globalStyle['mb-3'];
export const mb_4 = globalStyle['mb-4'];
export const mb_5 = globalStyle['mb-5'];
export const mb_auto = globalStyle['mb-auto'];
export const mb_lg_0 = globalStyle['mb-lg-0'];
export const mb_lg_1 = globalStyle['mb-lg-1'];
export const mb_lg_2 = globalStyle['mb-lg-2'];
export const mb_lg_3 = globalStyle['mb-lg-3'];
export const mb_lg_4 = globalStyle['mb-lg-4'];
export const mb_lg_5 = globalStyle['mb-lg-5'];
export const mb_lg_auto = globalStyle['mb-lg-auto'];
export const mb_lg_n1 = globalStyle['mb-lg-n1'];
export const mb_lg_n2 = globalStyle['mb-lg-n2'];
export const mb_lg_n3 = globalStyle['mb-lg-n3'];
export const mb_lg_n4 = globalStyle['mb-lg-n4'];
export const mb_lg_n5 = globalStyle['mb-lg-n5'];
export const mb_md_0 = globalStyle['mb-md-0'];
export const mb_md_1 = globalStyle['mb-md-1'];
export const mb_md_2 = globalStyle['mb-md-2'];
export const mb_md_3 = globalStyle['mb-md-3'];
export const mb_md_4 = globalStyle['mb-md-4'];
export const mb_md_5 = globalStyle['mb-md-5'];
export const mb_md_auto = globalStyle['mb-md-auto'];
export const mb_md_n1 = globalStyle['mb-md-n1'];
export const mb_md_n2 = globalStyle['mb-md-n2'];
export const mb_md_n3 = globalStyle['mb-md-n3'];
export const mb_md_n4 = globalStyle['mb-md-n4'];
export const mb_md_n5 = globalStyle['mb-md-n5'];
export const mb_n1 = globalStyle['mb-n1'];
export const mb_n2 = globalStyle['mb-n2'];
export const mb_n3 = globalStyle['mb-n3'];
export const mb_n4 = globalStyle['mb-n4'];
export const mb_n5 = globalStyle['mb-n5'];
export const mb_sm_0 = globalStyle['mb-sm-0'];
export const mb_sm_1 = globalStyle['mb-sm-1'];
export const mb_sm_2 = globalStyle['mb-sm-2'];
export const mb_sm_3 = globalStyle['mb-sm-3'];
export const mb_sm_4 = globalStyle['mb-sm-4'];
export const mb_sm_5 = globalStyle['mb-sm-5'];
export const mb_sm_auto = globalStyle['mb-sm-auto'];
export const mb_sm_n1 = globalStyle['mb-sm-n1'];
export const mb_sm_n2 = globalStyle['mb-sm-n2'];
export const mb_sm_n3 = globalStyle['mb-sm-n3'];
export const mb_sm_n4 = globalStyle['mb-sm-n4'];
export const mb_sm_n5 = globalStyle['mb-sm-n5'];
export const mb_xl_0 = globalStyle['mb-xl-0'];
export const mb_xl_1 = globalStyle['mb-xl-1'];
export const mb_xl_2 = globalStyle['mb-xl-2'];
export const mb_xl_3 = globalStyle['mb-xl-3'];
export const mb_xl_4 = globalStyle['mb-xl-4'];
export const mb_xl_5 = globalStyle['mb-xl-5'];
export const mb_xl_auto = globalStyle['mb-xl-auto'];
export const mb_xl_n1 = globalStyle['mb-xl-n1'];
export const mb_xl_n2 = globalStyle['mb-xl-n2'];
export const mb_xl_n3 = globalStyle['mb-xl-n3'];
export const mb_xl_n4 = globalStyle['mb-xl-n4'];
export const mb_xl_n5 = globalStyle['mb-xl-n5'];
export const media = globalStyle['media'];
export const media_body = globalStyle['media-body'];
export const mh_100 = globalStyle['mh-100'];
export const min_vh_100 = globalStyle['min-vh-100'];
export const min_vw_100 = globalStyle['min-vw-100'];
export const ml_0 = globalStyle['ml-0'];
export const ml_1 = globalStyle['ml-1'];
export const ml_2 = globalStyle['ml-2'];
export const ml_3 = globalStyle['ml-3'];
export const ml_4 = globalStyle['ml-4'];
export const ml_5 = globalStyle['ml-5'];
export const ml_auto = globalStyle['ml-auto'];
export const ml_lg_0 = globalStyle['ml-lg-0'];
export const ml_lg_1 = globalStyle['ml-lg-1'];
export const ml_lg_2 = globalStyle['ml-lg-2'];
export const ml_lg_3 = globalStyle['ml-lg-3'];
export const ml_lg_4 = globalStyle['ml-lg-4'];
export const ml_lg_5 = globalStyle['ml-lg-5'];
export const ml_lg_auto = globalStyle['ml-lg-auto'];
export const ml_lg_n1 = globalStyle['ml-lg-n1'];
export const ml_lg_n2 = globalStyle['ml-lg-n2'];
export const ml_lg_n3 = globalStyle['ml-lg-n3'];
export const ml_lg_n4 = globalStyle['ml-lg-n4'];
export const ml_lg_n5 = globalStyle['ml-lg-n5'];
export const ml_md_0 = globalStyle['ml-md-0'];
export const ml_md_1 = globalStyle['ml-md-1'];
export const ml_md_2 = globalStyle['ml-md-2'];
export const ml_md_3 = globalStyle['ml-md-3'];
export const ml_md_4 = globalStyle['ml-md-4'];
export const ml_md_5 = globalStyle['ml-md-5'];
export const ml_md_auto = globalStyle['ml-md-auto'];
export const ml_md_n1 = globalStyle['ml-md-n1'];
export const ml_md_n2 = globalStyle['ml-md-n2'];
export const ml_md_n3 = globalStyle['ml-md-n3'];
export const ml_md_n4 = globalStyle['ml-md-n4'];
export const ml_md_n5 = globalStyle['ml-md-n5'];
export const ml_n1 = globalStyle['ml-n1'];
export const ml_n2 = globalStyle['ml-n2'];
export const ml_n3 = globalStyle['ml-n3'];
export const ml_n4 = globalStyle['ml-n4'];
export const ml_n5 = globalStyle['ml-n5'];
export const ml_sm_0 = globalStyle['ml-sm-0'];
export const ml_sm_1 = globalStyle['ml-sm-1'];
export const ml_sm_2 = globalStyle['ml-sm-2'];
export const ml_sm_3 = globalStyle['ml-sm-3'];
export const ml_sm_4 = globalStyle['ml-sm-4'];
export const ml_sm_5 = globalStyle['ml-sm-5'];
export const ml_sm_auto = globalStyle['ml-sm-auto'];
export const ml_sm_n1 = globalStyle['ml-sm-n1'];
export const ml_sm_n2 = globalStyle['ml-sm-n2'];
export const ml_sm_n3 = globalStyle['ml-sm-n3'];
export const ml_sm_n4 = globalStyle['ml-sm-n4'];
export const ml_sm_n5 = globalStyle['ml-sm-n5'];
export const ml_xl_0 = globalStyle['ml-xl-0'];
export const ml_xl_1 = globalStyle['ml-xl-1'];
export const ml_xl_2 = globalStyle['ml-xl-2'];
export const ml_xl_3 = globalStyle['ml-xl-3'];
export const ml_xl_4 = globalStyle['ml-xl-4'];
export const ml_xl_5 = globalStyle['ml-xl-5'];
export const ml_xl_auto = globalStyle['ml-xl-auto'];
export const ml_xl_n1 = globalStyle['ml-xl-n1'];
export const ml_xl_n2 = globalStyle['ml-xl-n2'];
export const ml_xl_n3 = globalStyle['ml-xl-n3'];
export const ml_xl_n4 = globalStyle['ml-xl-n4'];
export const ml_xl_n5 = globalStyle['ml-xl-n5'];
export const modal = globalStyle['modal'];
export const modal_backdrop = globalStyle['modal-backdrop'];
export const modal_body = globalStyle['modal-body'];
export const modal_content = globalStyle['modal-content'];
export const modal_dialog = globalStyle['modal-dialog'];
export const modal_dialog_centered = globalStyle['modal-dialog-centered'];
export const modal_dialog_scrollable = globalStyle['modal-dialog-scrollable'];
export const modal_footer = globalStyle['modal-footer'];
export const modal_header = globalStyle['modal-header'];
export const modal_lg = globalStyle['modal-lg'];
export const modal_open = globalStyle['modal-open'];
export const modal_scrollbar_measure = globalStyle['modal-scrollbar-measure'];
export const modal_sm = globalStyle['modal-sm'];
export const modal_static = globalStyle['modal-static'];
export const modal_title = globalStyle['modal-title'];
export const modal_xl = globalStyle['modal-xl'];
export const mr_0 = globalStyle['mr-0'];
export const mr_1 = globalStyle['mr-1'];
export const mr_2 = globalStyle['mr-2'];
export const mr_3 = globalStyle['mr-3'];
export const mr_4 = globalStyle['mr-4'];
export const mr_5 = globalStyle['mr-5'];
export const mr_auto = globalStyle['mr-auto'];
export const mr_lg_0 = globalStyle['mr-lg-0'];
export const mr_lg_1 = globalStyle['mr-lg-1'];
export const mr_lg_2 = globalStyle['mr-lg-2'];
export const mr_lg_3 = globalStyle['mr-lg-3'];
export const mr_lg_4 = globalStyle['mr-lg-4'];
export const mr_lg_5 = globalStyle['mr-lg-5'];
export const mr_lg_auto = globalStyle['mr-lg-auto'];
export const mr_lg_n1 = globalStyle['mr-lg-n1'];
export const mr_lg_n2 = globalStyle['mr-lg-n2'];
export const mr_lg_n3 = globalStyle['mr-lg-n3'];
export const mr_lg_n4 = globalStyle['mr-lg-n4'];
export const mr_lg_n5 = globalStyle['mr-lg-n5'];
export const mr_md_0 = globalStyle['mr-md-0'];
export const mr_md_1 = globalStyle['mr-md-1'];
export const mr_md_2 = globalStyle['mr-md-2'];
export const mr_md_3 = globalStyle['mr-md-3'];
export const mr_md_4 = globalStyle['mr-md-4'];
export const mr_md_5 = globalStyle['mr-md-5'];
export const mr_md_auto = globalStyle['mr-md-auto'];
export const mr_md_n1 = globalStyle['mr-md-n1'];
export const mr_md_n2 = globalStyle['mr-md-n2'];
export const mr_md_n3 = globalStyle['mr-md-n3'];
export const mr_md_n4 = globalStyle['mr-md-n4'];
export const mr_md_n5 = globalStyle['mr-md-n5'];
export const mr_n1 = globalStyle['mr-n1'];
export const mr_n2 = globalStyle['mr-n2'];
export const mr_n3 = globalStyle['mr-n3'];
export const mr_n4 = globalStyle['mr-n4'];
export const mr_n5 = globalStyle['mr-n5'];
export const mr_sm_0 = globalStyle['mr-sm-0'];
export const mr_sm_1 = globalStyle['mr-sm-1'];
export const mr_sm_2 = globalStyle['mr-sm-2'];
export const mr_sm_3 = globalStyle['mr-sm-3'];
export const mr_sm_4 = globalStyle['mr-sm-4'];
export const mr_sm_5 = globalStyle['mr-sm-5'];
export const mr_sm_auto = globalStyle['mr-sm-auto'];
export const mr_sm_n1 = globalStyle['mr-sm-n1'];
export const mr_sm_n2 = globalStyle['mr-sm-n2'];
export const mr_sm_n3 = globalStyle['mr-sm-n3'];
export const mr_sm_n4 = globalStyle['mr-sm-n4'];
export const mr_sm_n5 = globalStyle['mr-sm-n5'];
export const mr_xl_0 = globalStyle['mr-xl-0'];
export const mr_xl_1 = globalStyle['mr-xl-1'];
export const mr_xl_2 = globalStyle['mr-xl-2'];
export const mr_xl_3 = globalStyle['mr-xl-3'];
export const mr_xl_4 = globalStyle['mr-xl-4'];
export const mr_xl_5 = globalStyle['mr-xl-5'];
export const mr_xl_auto = globalStyle['mr-xl-auto'];
export const mr_xl_n1 = globalStyle['mr-xl-n1'];
export const mr_xl_n2 = globalStyle['mr-xl-n2'];
export const mr_xl_n3 = globalStyle['mr-xl-n3'];
export const mr_xl_n4 = globalStyle['mr-xl-n4'];
export const mr_xl_n5 = globalStyle['mr-xl-n5'];
export const mt_0 = globalStyle['mt-0'];
export const mt_1 = globalStyle['mt-1'];
export const mt_2 = globalStyle['mt-2'];
export const mt_3 = globalStyle['mt-3'];
export const mt_4 = globalStyle['mt-4'];
export const mt_5 = globalStyle['mt-5'];
export const mt_auto = globalStyle['mt-auto'];
export const mt_lg_0 = globalStyle['mt-lg-0'];
export const mt_lg_1 = globalStyle['mt-lg-1'];
export const mt_lg_2 = globalStyle['mt-lg-2'];
export const mt_lg_3 = globalStyle['mt-lg-3'];
export const mt_lg_4 = globalStyle['mt-lg-4'];
export const mt_lg_5 = globalStyle['mt-lg-5'];
export const mt_lg_auto = globalStyle['mt-lg-auto'];
export const mt_lg_n1 = globalStyle['mt-lg-n1'];
export const mt_lg_n2 = globalStyle['mt-lg-n2'];
export const mt_lg_n3 = globalStyle['mt-lg-n3'];
export const mt_lg_n4 = globalStyle['mt-lg-n4'];
export const mt_lg_n5 = globalStyle['mt-lg-n5'];
export const mt_md_0 = globalStyle['mt-md-0'];
export const mt_md_1 = globalStyle['mt-md-1'];
export const mt_md_2 = globalStyle['mt-md-2'];
export const mt_md_3 = globalStyle['mt-md-3'];
export const mt_md_4 = globalStyle['mt-md-4'];
export const mt_md_5 = globalStyle['mt-md-5'];
export const mt_md_auto = globalStyle['mt-md-auto'];
export const mt_md_n1 = globalStyle['mt-md-n1'];
export const mt_md_n2 = globalStyle['mt-md-n2'];
export const mt_md_n3 = globalStyle['mt-md-n3'];
export const mt_md_n4 = globalStyle['mt-md-n4'];
export const mt_md_n5 = globalStyle['mt-md-n5'];
export const mt_n1 = globalStyle['mt-n1'];
export const mt_n2 = globalStyle['mt-n2'];
export const mt_n3 = globalStyle['mt-n3'];
export const mt_n4 = globalStyle['mt-n4'];
export const mt_n5 = globalStyle['mt-n5'];
export const mt_sm_0 = globalStyle['mt-sm-0'];
export const mt_sm_1 = globalStyle['mt-sm-1'];
export const mt_sm_2 = globalStyle['mt-sm-2'];
export const mt_sm_3 = globalStyle['mt-sm-3'];
export const mt_sm_4 = globalStyle['mt-sm-4'];
export const mt_sm_5 = globalStyle['mt-sm-5'];
export const mt_sm_auto = globalStyle['mt-sm-auto'];
export const mt_sm_n1 = globalStyle['mt-sm-n1'];
export const mt_sm_n2 = globalStyle['mt-sm-n2'];
export const mt_sm_n3 = globalStyle['mt-sm-n3'];
export const mt_sm_n4 = globalStyle['mt-sm-n4'];
export const mt_sm_n5 = globalStyle['mt-sm-n5'];
export const mt_xl_0 = globalStyle['mt-xl-0'];
export const mt_xl_1 = globalStyle['mt-xl-1'];
export const mt_xl_2 = globalStyle['mt-xl-2'];
export const mt_xl_3 = globalStyle['mt-xl-3'];
export const mt_xl_4 = globalStyle['mt-xl-4'];
export const mt_xl_5 = globalStyle['mt-xl-5'];
export const mt_xl_auto = globalStyle['mt-xl-auto'];
export const mt_xl_n1 = globalStyle['mt-xl-n1'];
export const mt_xl_n2 = globalStyle['mt-xl-n2'];
export const mt_xl_n3 = globalStyle['mt-xl-n3'];
export const mt_xl_n4 = globalStyle['mt-xl-n4'];
export const mt_xl_n5 = globalStyle['mt-xl-n5'];
export const mw_100 = globalStyle['mw-100'];
export const mx_0 = globalStyle['mx-0'];
export const mx_1 = globalStyle['mx-1'];
export const mx_2 = globalStyle['mx-2'];
export const mx_3 = globalStyle['mx-3'];
export const mx_4 = globalStyle['mx-4'];
export const mx_5 = globalStyle['mx-5'];
export const mx_auto = globalStyle['mx-auto'];
export const mx_lg_0 = globalStyle['mx-lg-0'];
export const mx_lg_1 = globalStyle['mx-lg-1'];
export const mx_lg_2 = globalStyle['mx-lg-2'];
export const mx_lg_3 = globalStyle['mx-lg-3'];
export const mx_lg_4 = globalStyle['mx-lg-4'];
export const mx_lg_5 = globalStyle['mx-lg-5'];
export const mx_lg_auto = globalStyle['mx-lg-auto'];
export const mx_lg_n1 = globalStyle['mx-lg-n1'];
export const mx_lg_n2 = globalStyle['mx-lg-n2'];
export const mx_lg_n3 = globalStyle['mx-lg-n3'];
export const mx_lg_n4 = globalStyle['mx-lg-n4'];
export const mx_lg_n5 = globalStyle['mx-lg-n5'];
export const mx_md_0 = globalStyle['mx-md-0'];
export const mx_md_1 = globalStyle['mx-md-1'];
export const mx_md_2 = globalStyle['mx-md-2'];
export const mx_md_3 = globalStyle['mx-md-3'];
export const mx_md_4 = globalStyle['mx-md-4'];
export const mx_md_5 = globalStyle['mx-md-5'];
export const mx_md_auto = globalStyle['mx-md-auto'];
export const mx_md_n1 = globalStyle['mx-md-n1'];
export const mx_md_n2 = globalStyle['mx-md-n2'];
export const mx_md_n3 = globalStyle['mx-md-n3'];
export const mx_md_n4 = globalStyle['mx-md-n4'];
export const mx_md_n5 = globalStyle['mx-md-n5'];
export const mx_n1 = globalStyle['mx-n1'];
export const mx_n2 = globalStyle['mx-n2'];
export const mx_n3 = globalStyle['mx-n3'];
export const mx_n4 = globalStyle['mx-n4'];
export const mx_n5 = globalStyle['mx-n5'];
export const mx_sm_0 = globalStyle['mx-sm-0'];
export const mx_sm_1 = globalStyle['mx-sm-1'];
export const mx_sm_2 = globalStyle['mx-sm-2'];
export const mx_sm_3 = globalStyle['mx-sm-3'];
export const mx_sm_4 = globalStyle['mx-sm-4'];
export const mx_sm_5 = globalStyle['mx-sm-5'];
export const mx_sm_auto = globalStyle['mx-sm-auto'];
export const mx_sm_n1 = globalStyle['mx-sm-n1'];
export const mx_sm_n2 = globalStyle['mx-sm-n2'];
export const mx_sm_n3 = globalStyle['mx-sm-n3'];
export const mx_sm_n4 = globalStyle['mx-sm-n4'];
export const mx_sm_n5 = globalStyle['mx-sm-n5'];
export const mx_xl_0 = globalStyle['mx-xl-0'];
export const mx_xl_1 = globalStyle['mx-xl-1'];
export const mx_xl_2 = globalStyle['mx-xl-2'];
export const mx_xl_3 = globalStyle['mx-xl-3'];
export const mx_xl_4 = globalStyle['mx-xl-4'];
export const mx_xl_5 = globalStyle['mx-xl-5'];
export const mx_xl_auto = globalStyle['mx-xl-auto'];
export const mx_xl_n1 = globalStyle['mx-xl-n1'];
export const mx_xl_n2 = globalStyle['mx-xl-n2'];
export const mx_xl_n3 = globalStyle['mx-xl-n3'];
export const mx_xl_n4 = globalStyle['mx-xl-n4'];
export const mx_xl_n5 = globalStyle['mx-xl-n5'];
export const my_0 = globalStyle['my-0'];
export const my_1 = globalStyle['my-1'];
export const my_2 = globalStyle['my-2'];
export const my_3 = globalStyle['my-3'];
export const my_4 = globalStyle['my-4'];
export const my_5 = globalStyle['my-5'];
export const my_auto = globalStyle['my-auto'];
export const my_lg_0 = globalStyle['my-lg-0'];
export const my_lg_1 = globalStyle['my-lg-1'];
export const my_lg_2 = globalStyle['my-lg-2'];
export const my_lg_3 = globalStyle['my-lg-3'];
export const my_lg_4 = globalStyle['my-lg-4'];
export const my_lg_5 = globalStyle['my-lg-5'];
export const my_lg_auto = globalStyle['my-lg-auto'];
export const my_lg_n1 = globalStyle['my-lg-n1'];
export const my_lg_n2 = globalStyle['my-lg-n2'];
export const my_lg_n3 = globalStyle['my-lg-n3'];
export const my_lg_n4 = globalStyle['my-lg-n4'];
export const my_lg_n5 = globalStyle['my-lg-n5'];
export const my_md_0 = globalStyle['my-md-0'];
export const my_md_1 = globalStyle['my-md-1'];
export const my_md_2 = globalStyle['my-md-2'];
export const my_md_3 = globalStyle['my-md-3'];
export const my_md_4 = globalStyle['my-md-4'];
export const my_md_5 = globalStyle['my-md-5'];
export const my_md_auto = globalStyle['my-md-auto'];
export const my_md_n1 = globalStyle['my-md-n1'];
export const my_md_n2 = globalStyle['my-md-n2'];
export const my_md_n3 = globalStyle['my-md-n3'];
export const my_md_n4 = globalStyle['my-md-n4'];
export const my_md_n5 = globalStyle['my-md-n5'];
export const my_n1 = globalStyle['my-n1'];
export const my_n2 = globalStyle['my-n2'];
export const my_n3 = globalStyle['my-n3'];
export const my_n4 = globalStyle['my-n4'];
export const my_n5 = globalStyle['my-n5'];
export const my_sm_0 = globalStyle['my-sm-0'];
export const my_sm_1 = globalStyle['my-sm-1'];
export const my_sm_2 = globalStyle['my-sm-2'];
export const my_sm_3 = globalStyle['my-sm-3'];
export const my_sm_4 = globalStyle['my-sm-4'];
export const my_sm_5 = globalStyle['my-sm-5'];
export const my_sm_auto = globalStyle['my-sm-auto'];
export const my_sm_n1 = globalStyle['my-sm-n1'];
export const my_sm_n2 = globalStyle['my-sm-n2'];
export const my_sm_n3 = globalStyle['my-sm-n3'];
export const my_sm_n4 = globalStyle['my-sm-n4'];
export const my_sm_n5 = globalStyle['my-sm-n5'];
export const my_xl_0 = globalStyle['my-xl-0'];
export const my_xl_1 = globalStyle['my-xl-1'];
export const my_xl_2 = globalStyle['my-xl-2'];
export const my_xl_3 = globalStyle['my-xl-3'];
export const my_xl_4 = globalStyle['my-xl-4'];
export const my_xl_5 = globalStyle['my-xl-5'];
export const my_xl_auto = globalStyle['my-xl-auto'];
export const my_xl_n1 = globalStyle['my-xl-n1'];
export const my_xl_n2 = globalStyle['my-xl-n2'];
export const my_xl_n3 = globalStyle['my-xl-n3'];
export const my_xl_n4 = globalStyle['my-xl-n4'];
export const my_xl_n5 = globalStyle['my-xl-n5'];
export const nav = globalStyle['nav'];
export const nav_fill = globalStyle['nav-fill'];
export const nav_item = globalStyle['nav-item'];
export const nav_justified = globalStyle['nav-justified'];
export const nav_link = globalStyle['nav-link'];
export const nav_pills = globalStyle['nav-pills'];
export const nav_tabs = globalStyle['nav-tabs'];
export const navbar = globalStyle['navbar'];
export const navbar_brand = globalStyle['navbar-brand'];
export const navbar_collapse = globalStyle['navbar-collapse'];
export const navbar_dark = globalStyle['navbar-dark'];
export const navbar_expand = globalStyle['navbar-expand'];
export const navbar_expand_lg = globalStyle['navbar-expand-lg'];
export const navbar_expand_md = globalStyle['navbar-expand-md'];
export const navbar_expand_sm = globalStyle['navbar-expand-sm'];
export const navbar_expand_xl = globalStyle['navbar-expand-xl'];
export const navbar_light = globalStyle['navbar-light'];
export const navbar_nav = globalStyle['navbar-nav'];
export const navbar_text = globalStyle['navbar-text'];
export const navbar_toggler = globalStyle['navbar-toggler'];
export const navbar_toggler_icon = globalStyle['navbar-toggler-icon'];
export const no_gutters = globalStyle['no-gutters'];
export const offset_1 = globalStyle['offset-1'];
export const offset_10 = globalStyle['offset-10'];
export const offset_11 = globalStyle['offset-11'];
export const offset_2 = globalStyle['offset-2'];
export const offset_3 = globalStyle['offset-3'];
export const offset_4 = globalStyle['offset-4'];
export const offset_5 = globalStyle['offset-5'];
export const offset_6 = globalStyle['offset-6'];
export const offset_7 = globalStyle['offset-7'];
export const offset_8 = globalStyle['offset-8'];
export const offset_9 = globalStyle['offset-9'];
export const offset_lg_0 = globalStyle['offset-lg-0'];
export const offset_lg_1 = globalStyle['offset-lg-1'];
export const offset_lg_10 = globalStyle['offset-lg-10'];
export const offset_lg_11 = globalStyle['offset-lg-11'];
export const offset_lg_2 = globalStyle['offset-lg-2'];
export const offset_lg_3 = globalStyle['offset-lg-3'];
export const offset_lg_4 = globalStyle['offset-lg-4'];
export const offset_lg_5 = globalStyle['offset-lg-5'];
export const offset_lg_6 = globalStyle['offset-lg-6'];
export const offset_lg_7 = globalStyle['offset-lg-7'];
export const offset_lg_8 = globalStyle['offset-lg-8'];
export const offset_lg_9 = globalStyle['offset-lg-9'];
export const offset_md_0 = globalStyle['offset-md-0'];
export const offset_md_1 = globalStyle['offset-md-1'];
export const offset_md_10 = globalStyle['offset-md-10'];
export const offset_md_11 = globalStyle['offset-md-11'];
export const offset_md_2 = globalStyle['offset-md-2'];
export const offset_md_3 = globalStyle['offset-md-3'];
export const offset_md_4 = globalStyle['offset-md-4'];
export const offset_md_5 = globalStyle['offset-md-5'];
export const offset_md_6 = globalStyle['offset-md-6'];
export const offset_md_7 = globalStyle['offset-md-7'];
export const offset_md_8 = globalStyle['offset-md-8'];
export const offset_md_9 = globalStyle['offset-md-9'];
export const offset_sm_0 = globalStyle['offset-sm-0'];
export const offset_sm_1 = globalStyle['offset-sm-1'];
export const offset_sm_10 = globalStyle['offset-sm-10'];
export const offset_sm_11 = globalStyle['offset-sm-11'];
export const offset_sm_2 = globalStyle['offset-sm-2'];
export const offset_sm_3 = globalStyle['offset-sm-3'];
export const offset_sm_4 = globalStyle['offset-sm-4'];
export const offset_sm_5 = globalStyle['offset-sm-5'];
export const offset_sm_6 = globalStyle['offset-sm-6'];
export const offset_sm_7 = globalStyle['offset-sm-7'];
export const offset_sm_8 = globalStyle['offset-sm-8'];
export const offset_sm_9 = globalStyle['offset-sm-9'];
export const offset_xl_0 = globalStyle['offset-xl-0'];
export const offset_xl_1 = globalStyle['offset-xl-1'];
export const offset_xl_10 = globalStyle['offset-xl-10'];
export const offset_xl_11 = globalStyle['offset-xl-11'];
export const offset_xl_2 = globalStyle['offset-xl-2'];
export const offset_xl_3 = globalStyle['offset-xl-3'];
export const offset_xl_4 = globalStyle['offset-xl-4'];
export const offset_xl_5 = globalStyle['offset-xl-5'];
export const offset_xl_6 = globalStyle['offset-xl-6'];
export const offset_xl_7 = globalStyle['offset-xl-7'];
export const offset_xl_8 = globalStyle['offset-xl-8'];
export const offset_xl_9 = globalStyle['offset-xl-9'];
export const order_0 = globalStyle['order-0'];
export const order_1 = globalStyle['order-1'];
export const order_10 = globalStyle['order-10'];
export const order_11 = globalStyle['order-11'];
export const order_12 = globalStyle['order-12'];
export const order_2 = globalStyle['order-2'];
export const order_3 = globalStyle['order-3'];
export const order_4 = globalStyle['order-4'];
export const order_5 = globalStyle['order-5'];
export const order_6 = globalStyle['order-6'];
export const order_7 = globalStyle['order-7'];
export const order_8 = globalStyle['order-8'];
export const order_9 = globalStyle['order-9'];
export const order_first = globalStyle['order-first'];
export const order_last = globalStyle['order-last'];
export const order_lg_0 = globalStyle['order-lg-0'];
export const order_lg_1 = globalStyle['order-lg-1'];
export const order_lg_10 = globalStyle['order-lg-10'];
export const order_lg_11 = globalStyle['order-lg-11'];
export const order_lg_12 = globalStyle['order-lg-12'];
export const order_lg_2 = globalStyle['order-lg-2'];
export const order_lg_3 = globalStyle['order-lg-3'];
export const order_lg_4 = globalStyle['order-lg-4'];
export const order_lg_5 = globalStyle['order-lg-5'];
export const order_lg_6 = globalStyle['order-lg-6'];
export const order_lg_7 = globalStyle['order-lg-7'];
export const order_lg_8 = globalStyle['order-lg-8'];
export const order_lg_9 = globalStyle['order-lg-9'];
export const order_lg_first = globalStyle['order-lg-first'];
export const order_lg_last = globalStyle['order-lg-last'];
export const order_md_0 = globalStyle['order-md-0'];
export const order_md_1 = globalStyle['order-md-1'];
export const order_md_10 = globalStyle['order-md-10'];
export const order_md_11 = globalStyle['order-md-11'];
export const order_md_12 = globalStyle['order-md-12'];
export const order_md_2 = globalStyle['order-md-2'];
export const order_md_3 = globalStyle['order-md-3'];
export const order_md_4 = globalStyle['order-md-4'];
export const order_md_5 = globalStyle['order-md-5'];
export const order_md_6 = globalStyle['order-md-6'];
export const order_md_7 = globalStyle['order-md-7'];
export const order_md_8 = globalStyle['order-md-8'];
export const order_md_9 = globalStyle['order-md-9'];
export const order_md_first = globalStyle['order-md-first'];
export const order_md_last = globalStyle['order-md-last'];
export const order_sm_0 = globalStyle['order-sm-0'];
export const order_sm_1 = globalStyle['order-sm-1'];
export const order_sm_10 = globalStyle['order-sm-10'];
export const order_sm_11 = globalStyle['order-sm-11'];
export const order_sm_12 = globalStyle['order-sm-12'];
export const order_sm_2 = globalStyle['order-sm-2'];
export const order_sm_3 = globalStyle['order-sm-3'];
export const order_sm_4 = globalStyle['order-sm-4'];
export const order_sm_5 = globalStyle['order-sm-5'];
export const order_sm_6 = globalStyle['order-sm-6'];
export const order_sm_7 = globalStyle['order-sm-7'];
export const order_sm_8 = globalStyle['order-sm-8'];
export const order_sm_9 = globalStyle['order-sm-9'];
export const order_sm_first = globalStyle['order-sm-first'];
export const order_sm_last = globalStyle['order-sm-last'];
export const order_xl_0 = globalStyle['order-xl-0'];
export const order_xl_1 = globalStyle['order-xl-1'];
export const order_xl_10 = globalStyle['order-xl-10'];
export const order_xl_11 = globalStyle['order-xl-11'];
export const order_xl_12 = globalStyle['order-xl-12'];
export const order_xl_2 = globalStyle['order-xl-2'];
export const order_xl_3 = globalStyle['order-xl-3'];
export const order_xl_4 = globalStyle['order-xl-4'];
export const order_xl_5 = globalStyle['order-xl-5'];
export const order_xl_6 = globalStyle['order-xl-6'];
export const order_xl_7 = globalStyle['order-xl-7'];
export const order_xl_8 = globalStyle['order-xl-8'];
export const order_xl_9 = globalStyle['order-xl-9'];
export const order_xl_first = globalStyle['order-xl-first'];
export const order_xl_last = globalStyle['order-xl-last'];
export const org = globalStyle['org'];
export const overflow_auto = globalStyle['overflow-auto'];
export const overflow_hidden = globalStyle['overflow-hidden'];
export const p_0 = globalStyle['p-0'];
export const p_1 = globalStyle['p-1'];
export const p_2 = globalStyle['p-2'];
export const p_3 = globalStyle['p-3'];
export const p_4 = globalStyle['p-4'];
export const p_5 = globalStyle['p-5'];
export const p_lg_0 = globalStyle['p-lg-0'];
export const p_lg_1 = globalStyle['p-lg-1'];
export const p_lg_2 = globalStyle['p-lg-2'];
export const p_lg_3 = globalStyle['p-lg-3'];
export const p_lg_4 = globalStyle['p-lg-4'];
export const p_lg_5 = globalStyle['p-lg-5'];
export const p_md_0 = globalStyle['p-md-0'];
export const p_md_1 = globalStyle['p-md-1'];
export const p_md_2 = globalStyle['p-md-2'];
export const p_md_3 = globalStyle['p-md-3'];
export const p_md_4 = globalStyle['p-md-4'];
export const p_md_5 = globalStyle['p-md-5'];
export const p_sm_0 = globalStyle['p-sm-0'];
export const p_sm_1 = globalStyle['p-sm-1'];
export const p_sm_2 = globalStyle['p-sm-2'];
export const p_sm_3 = globalStyle['p-sm-3'];
export const p_sm_4 = globalStyle['p-sm-4'];
export const p_sm_5 = globalStyle['p-sm-5'];
export const p_xl_0 = globalStyle['p-xl-0'];
export const p_xl_1 = globalStyle['p-xl-1'];
export const p_xl_2 = globalStyle['p-xl-2'];
export const p_xl_3 = globalStyle['p-xl-3'];
export const p_xl_4 = globalStyle['p-xl-4'];
export const p_xl_5 = globalStyle['p-xl-5'];
export const page_item = globalStyle['page-item'];
export const page_link = globalStyle['page-link'];
export const pagination = globalStyle['pagination'];
export const pagination_lg = globalStyle['pagination-lg'];
export const pagination_sm = globalStyle['pagination-sm'];
export const pb_0 = globalStyle['pb-0'];
export const pb_1 = globalStyle['pb-1'];
export const pb_2 = globalStyle['pb-2'];
export const pb_3 = globalStyle['pb-3'];
export const pb_4 = globalStyle['pb-4'];
export const pb_5 = globalStyle['pb-5'];
export const pb_lg_0 = globalStyle['pb-lg-0'];
export const pb_lg_1 = globalStyle['pb-lg-1'];
export const pb_lg_2 = globalStyle['pb-lg-2'];
export const pb_lg_3 = globalStyle['pb-lg-3'];
export const pb_lg_4 = globalStyle['pb-lg-4'];
export const pb_lg_5 = globalStyle['pb-lg-5'];
export const pb_md_0 = globalStyle['pb-md-0'];
export const pb_md_1 = globalStyle['pb-md-1'];
export const pb_md_2 = globalStyle['pb-md-2'];
export const pb_md_3 = globalStyle['pb-md-3'];
export const pb_md_4 = globalStyle['pb-md-4'];
export const pb_md_5 = globalStyle['pb-md-5'];
export const pb_sm_0 = globalStyle['pb-sm-0'];
export const pb_sm_1 = globalStyle['pb-sm-1'];
export const pb_sm_2 = globalStyle['pb-sm-2'];
export const pb_sm_3 = globalStyle['pb-sm-3'];
export const pb_sm_4 = globalStyle['pb-sm-4'];
export const pb_sm_5 = globalStyle['pb-sm-5'];
export const pb_xl_0 = globalStyle['pb-xl-0'];
export const pb_xl_1 = globalStyle['pb-xl-1'];
export const pb_xl_2 = globalStyle['pb-xl-2'];
export const pb_xl_3 = globalStyle['pb-xl-3'];
export const pb_xl_4 = globalStyle['pb-xl-4'];
export const pb_xl_5 = globalStyle['pb-xl-5'];
export const pl_0 = globalStyle['pl-0'];
export const pl_1 = globalStyle['pl-1'];
export const pl_2 = globalStyle['pl-2'];
export const pl_3 = globalStyle['pl-3'];
export const pl_4 = globalStyle['pl-4'];
export const pl_5 = globalStyle['pl-5'];
export const pl_lg_0 = globalStyle['pl-lg-0'];
export const pl_lg_1 = globalStyle['pl-lg-1'];
export const pl_lg_2 = globalStyle['pl-lg-2'];
export const pl_lg_3 = globalStyle['pl-lg-3'];
export const pl_lg_4 = globalStyle['pl-lg-4'];
export const pl_lg_5 = globalStyle['pl-lg-5'];
export const pl_md_0 = globalStyle['pl-md-0'];
export const pl_md_1 = globalStyle['pl-md-1'];
export const pl_md_2 = globalStyle['pl-md-2'];
export const pl_md_3 = globalStyle['pl-md-3'];
export const pl_md_4 = globalStyle['pl-md-4'];
export const pl_md_5 = globalStyle['pl-md-5'];
export const pl_sm_0 = globalStyle['pl-sm-0'];
export const pl_sm_1 = globalStyle['pl-sm-1'];
export const pl_sm_2 = globalStyle['pl-sm-2'];
export const pl_sm_3 = globalStyle['pl-sm-3'];
export const pl_sm_4 = globalStyle['pl-sm-4'];
export const pl_sm_5 = globalStyle['pl-sm-5'];
export const pl_xl_0 = globalStyle['pl-xl-0'];
export const pl_xl_1 = globalStyle['pl-xl-1'];
export const pl_xl_2 = globalStyle['pl-xl-2'];
export const pl_xl_3 = globalStyle['pl-xl-3'];
export const pl_xl_4 = globalStyle['pl-xl-4'];
export const pl_xl_5 = globalStyle['pl-xl-5'];
export const pointer_event = globalStyle['pointer-event'];
export const popover = globalStyle['popover'];
export const popover_body = globalStyle['popover-body'];
export const popover_header = globalStyle['popover-header'];
export const position_absolute = globalStyle['position-absolute'];
export const position_fixed = globalStyle['position-fixed'];
export const position_relative = globalStyle['position-relative'];
export const position_static = globalStyle['position-static'];
export const position_sticky = globalStyle['position-sticky'];
export const pr_0 = globalStyle['pr-0'];
export const pr_1 = globalStyle['pr-1'];
export const pr_2 = globalStyle['pr-2'];
export const pr_3 = globalStyle['pr-3'];
export const pr_4 = globalStyle['pr-4'];
export const pr_5 = globalStyle['pr-5'];
export const pr_lg_0 = globalStyle['pr-lg-0'];
export const pr_lg_1 = globalStyle['pr-lg-1'];
export const pr_lg_2 = globalStyle['pr-lg-2'];
export const pr_lg_3 = globalStyle['pr-lg-3'];
export const pr_lg_4 = globalStyle['pr-lg-4'];
export const pr_lg_5 = globalStyle['pr-lg-5'];
export const pr_md_0 = globalStyle['pr-md-0'];
export const pr_md_1 = globalStyle['pr-md-1'];
export const pr_md_2 = globalStyle['pr-md-2'];
export const pr_md_3 = globalStyle['pr-md-3'];
export const pr_md_4 = globalStyle['pr-md-4'];
export const pr_md_5 = globalStyle['pr-md-5'];
export const pr_sm_0 = globalStyle['pr-sm-0'];
export const pr_sm_1 = globalStyle['pr-sm-1'];
export const pr_sm_2 = globalStyle['pr-sm-2'];
export const pr_sm_3 = globalStyle['pr-sm-3'];
export const pr_sm_4 = globalStyle['pr-sm-4'];
export const pr_sm_5 = globalStyle['pr-sm-5'];
export const pr_xl_0 = globalStyle['pr-xl-0'];
export const pr_xl_1 = globalStyle['pr-xl-1'];
export const pr_xl_2 = globalStyle['pr-xl-2'];
export const pr_xl_3 = globalStyle['pr-xl-3'];
export const pr_xl_4 = globalStyle['pr-xl-4'];
export const pr_xl_5 = globalStyle['pr-xl-5'];
export const pre_scrollable = globalStyle['pre-scrollable'];
export const progress = globalStyle['progress'];
export const progress_bar = globalStyle['progress-bar'];
export const progress_bar_animated = globalStyle['progress-bar-animated'];
export const progress_bar_striped = globalStyle['progress-bar-striped'];
export const pt_0 = globalStyle['pt-0'];
export const pt_1 = globalStyle['pt-1'];
export const pt_2 = globalStyle['pt-2'];
export const pt_3 = globalStyle['pt-3'];
export const pt_4 = globalStyle['pt-4'];
export const pt_5 = globalStyle['pt-5'];
export const pt_lg_0 = globalStyle['pt-lg-0'];
export const pt_lg_1 = globalStyle['pt-lg-1'];
export const pt_lg_2 = globalStyle['pt-lg-2'];
export const pt_lg_3 = globalStyle['pt-lg-3'];
export const pt_lg_4 = globalStyle['pt-lg-4'];
export const pt_lg_5 = globalStyle['pt-lg-5'];
export const pt_md_0 = globalStyle['pt-md-0'];
export const pt_md_1 = globalStyle['pt-md-1'];
export const pt_md_2 = globalStyle['pt-md-2'];
export const pt_md_3 = globalStyle['pt-md-3'];
export const pt_md_4 = globalStyle['pt-md-4'];
export const pt_md_5 = globalStyle['pt-md-5'];
export const pt_sm_0 = globalStyle['pt-sm-0'];
export const pt_sm_1 = globalStyle['pt-sm-1'];
export const pt_sm_2 = globalStyle['pt-sm-2'];
export const pt_sm_3 = globalStyle['pt-sm-3'];
export const pt_sm_4 = globalStyle['pt-sm-4'];
export const pt_sm_5 = globalStyle['pt-sm-5'];
export const pt_xl_0 = globalStyle['pt-xl-0'];
export const pt_xl_1 = globalStyle['pt-xl-1'];
export const pt_xl_2 = globalStyle['pt-xl-2'];
export const pt_xl_3 = globalStyle['pt-xl-3'];
export const pt_xl_4 = globalStyle['pt-xl-4'];
export const pt_xl_5 = globalStyle['pt-xl-5'];
export const px_0 = globalStyle['px-0'];
export const px_1 = globalStyle['px-1'];
export const px_2 = globalStyle['px-2'];
export const px_3 = globalStyle['px-3'];
export const px_4 = globalStyle['px-4'];
export const px_5 = globalStyle['px-5'];
export const px_lg_0 = globalStyle['px-lg-0'];
export const px_lg_1 = globalStyle['px-lg-1'];
export const px_lg_2 = globalStyle['px-lg-2'];
export const px_lg_3 = globalStyle['px-lg-3'];
export const px_lg_4 = globalStyle['px-lg-4'];
export const px_lg_5 = globalStyle['px-lg-5'];
export const px_md_0 = globalStyle['px-md-0'];
export const px_md_1 = globalStyle['px-md-1'];
export const px_md_2 = globalStyle['px-md-2'];
export const px_md_3 = globalStyle['px-md-3'];
export const px_md_4 = globalStyle['px-md-4'];
export const px_md_5 = globalStyle['px-md-5'];
export const px_sm_0 = globalStyle['px-sm-0'];
export const px_sm_1 = globalStyle['px-sm-1'];
export const px_sm_2 = globalStyle['px-sm-2'];
export const px_sm_3 = globalStyle['px-sm-3'];
export const px_sm_4 = globalStyle['px-sm-4'];
export const px_sm_5 = globalStyle['px-sm-5'];
export const px_xl_0 = globalStyle['px-xl-0'];
export const px_xl_1 = globalStyle['px-xl-1'];
export const px_xl_2 = globalStyle['px-xl-2'];
export const px_xl_3 = globalStyle['px-xl-3'];
export const px_xl_4 = globalStyle['px-xl-4'];
export const px_xl_5 = globalStyle['px-xl-5'];
export const py_0 = globalStyle['py-0'];
export const py_1 = globalStyle['py-1'];
export const py_2 = globalStyle['py-2'];
export const py_3 = globalStyle['py-3'];
export const py_4 = globalStyle['py-4'];
export const py_5 = globalStyle['py-5'];
export const py_lg_0 = globalStyle['py-lg-0'];
export const py_lg_1 = globalStyle['py-lg-1'];
export const py_lg_2 = globalStyle['py-lg-2'];
export const py_lg_3 = globalStyle['py-lg-3'];
export const py_lg_4 = globalStyle['py-lg-4'];
export const py_lg_5 = globalStyle['py-lg-5'];
export const py_md_0 = globalStyle['py-md-0'];
export const py_md_1 = globalStyle['py-md-1'];
export const py_md_2 = globalStyle['py-md-2'];
export const py_md_3 = globalStyle['py-md-3'];
export const py_md_4 = globalStyle['py-md-4'];
export const py_md_5 = globalStyle['py-md-5'];
export const py_sm_0 = globalStyle['py-sm-0'];
export const py_sm_1 = globalStyle['py-sm-1'];
export const py_sm_2 = globalStyle['py-sm-2'];
export const py_sm_3 = globalStyle['py-sm-3'];
export const py_sm_4 = globalStyle['py-sm-4'];
export const py_sm_5 = globalStyle['py-sm-5'];
export const py_xl_0 = globalStyle['py-xl-0'];
export const py_xl_1 = globalStyle['py-xl-1'];
export const py_xl_2 = globalStyle['py-xl-2'];
export const py_xl_3 = globalStyle['py-xl-3'];
export const py_xl_4 = globalStyle['py-xl-4'];
export const py_xl_5 = globalStyle['py-xl-5'];
export const rounded = globalStyle['rounded'];
export const rounded_0 = globalStyle['rounded-0'];
export const rounded_bottom = globalStyle['rounded-bottom'];
export const rounded_circle = globalStyle['rounded-circle'];
export const rounded_left = globalStyle['rounded-left'];
export const rounded_lg = globalStyle['rounded-lg'];
export const rounded_pill = globalStyle['rounded-pill'];
export const rounded_right = globalStyle['rounded-right'];
export const rounded_sm = globalStyle['rounded-sm'];
export const rounded_top = globalStyle['rounded-top'];
export const row = globalStyle['row'];
export const row_cols_1 = globalStyle['row-cols-1'];
export const row_cols_2 = globalStyle['row-cols-2'];
export const row_cols_3 = globalStyle['row-cols-3'];
export const row_cols_4 = globalStyle['row-cols-4'];
export const row_cols_5 = globalStyle['row-cols-5'];
export const row_cols_6 = globalStyle['row-cols-6'];
export const row_cols_lg_1 = globalStyle['row-cols-lg-1'];
export const row_cols_lg_2 = globalStyle['row-cols-lg-2'];
export const row_cols_lg_3 = globalStyle['row-cols-lg-3'];
export const row_cols_lg_4 = globalStyle['row-cols-lg-4'];
export const row_cols_lg_5 = globalStyle['row-cols-lg-5'];
export const row_cols_lg_6 = globalStyle['row-cols-lg-6'];
export const row_cols_md_1 = globalStyle['row-cols-md-1'];
export const row_cols_md_2 = globalStyle['row-cols-md-2'];
export const row_cols_md_3 = globalStyle['row-cols-md-3'];
export const row_cols_md_4 = globalStyle['row-cols-md-4'];
export const row_cols_md_5 = globalStyle['row-cols-md-5'];
export const row_cols_md_6 = globalStyle['row-cols-md-6'];
export const row_cols_sm_1 = globalStyle['row-cols-sm-1'];
export const row_cols_sm_2 = globalStyle['row-cols-sm-2'];
export const row_cols_sm_3 = globalStyle['row-cols-sm-3'];
export const row_cols_sm_4 = globalStyle['row-cols-sm-4'];
export const row_cols_sm_5 = globalStyle['row-cols-sm-5'];
export const row_cols_sm_6 = globalStyle['row-cols-sm-6'];
export const row_cols_xl_1 = globalStyle['row-cols-xl-1'];
export const row_cols_xl_2 = globalStyle['row-cols-xl-2'];
export const row_cols_xl_3 = globalStyle['row-cols-xl-3'];
export const row_cols_xl_4 = globalStyle['row-cols-xl-4'];
export const row_cols_xl_5 = globalStyle['row-cols-xl-5'];
export const row_cols_xl_6 = globalStyle['row-cols-xl-6'];
export const shadow = globalStyle['shadow'];
export const shadow_lg = globalStyle['shadow-lg'];
export const shadow_none = globalStyle['shadow-none'];
export const shadow_sm = globalStyle['shadow-sm'];
export const show = globalStyle['show'];
export const showing = globalStyle['showing'];
export const small = globalStyle['small'];
export const spinner_border = globalStyle['spinner-border'];
export const spinner_border_sm = globalStyle['spinner-border-sm'];
export const spinner_grow = globalStyle['spinner-grow'];
export const spinner_grow_sm = globalStyle['spinner-grow-sm'];
export const sr_only = globalStyle['sr-only'];
export const sr_only_focusable = globalStyle['sr-only-focusable'];
export const sticky_top = globalStyle['sticky-top'];
export const stretched_link = globalStyle['stretched-link'];
export const tab_content = globalStyle['tab-content'];
export const tab_pane = globalStyle['tab-pane'];
export const table = globalStyle['table'];
export const table_active = globalStyle['table-active'];
export const table_bordered = globalStyle['table-bordered'];
export const table_borderless = globalStyle['table-borderless'];
export const table_danger = globalStyle['table-danger'];
export const table_dark = globalStyle['table-dark'];
export const table_hover = globalStyle['table-hover'];
export const table_info = globalStyle['table-info'];
export const table_light = globalStyle['table-light'];
export const table_primary = globalStyle['table-primary'];
export const table_responsive = globalStyle['table-responsive'];
export const table_responsive_lg = globalStyle['table-responsive-lg'];
export const table_responsive_md = globalStyle['table-responsive-md'];
export const table_responsive_sm = globalStyle['table-responsive-sm'];
export const table_responsive_xl = globalStyle['table-responsive-xl'];
export const table_secondary = globalStyle['table-secondary'];
export const table_sm = globalStyle['table-sm'];
export const table_striped = globalStyle['table-striped'];
export const table_success = globalStyle['table-success'];
export const table_warning = globalStyle['table-warning'];
export const text_black_50 = globalStyle['text-black-50'];
export const text_body = globalStyle['text-body'];
export const text_break = globalStyle['text-break'];
export const text_capitalize = globalStyle['text-capitalize'];
export const text_center = globalStyle['text-center'];
export const text_danger = globalStyle['text-danger'];
export const text_dark = globalStyle['text-dark'];
export const text_decoration_none = globalStyle['text-decoration-none'];
export const text_hide = globalStyle['text-hide'];
export const text_info = globalStyle['text-info'];
export const text_justify = globalStyle['text-justify'];
export const text_left = globalStyle['text-left'];
export const text_lg_center = globalStyle['text-lg-center'];
export const text_lg_left = globalStyle['text-lg-left'];
export const text_lg_right = globalStyle['text-lg-right'];
export const text_light = globalStyle['text-light'];
export const text_lowercase = globalStyle['text-lowercase'];
export const text_md_center = globalStyle['text-md-center'];
export const text_md_left = globalStyle['text-md-left'];
export const text_md_right = globalStyle['text-md-right'];
export const text_monospace = globalStyle['text-monospace'];
export const text_muted = globalStyle['text-muted'];
export const text_nowrap = globalStyle['text-nowrap'];
export const text_primary = globalStyle['text-primary'];
export const text_reset = globalStyle['text-reset'];
export const text_right = globalStyle['text-right'];
export const text_secondary = globalStyle['text-secondary'];
export const text_sm_center = globalStyle['text-sm-center'];
export const text_sm_left = globalStyle['text-sm-left'];
export const text_sm_right = globalStyle['text-sm-right'];
export const text_success = globalStyle['text-success'];
export const text_truncate = globalStyle['text-truncate'];
export const text_uppercase = globalStyle['text-uppercase'];
export const text_warning = globalStyle['text-warning'];
export const text_white = globalStyle['text-white'];
export const text_white_50 = globalStyle['text-white-50'];
export const text_wrap = globalStyle['text-wrap'];
export const text_xl_center = globalStyle['text-xl-center'];
export const text_xl_left = globalStyle['text-xl-left'];
export const text_xl_right = globalStyle['text-xl-right'];
export const thead_dark = globalStyle['thead-dark'];
export const thead_light = globalStyle['thead-light'];
export const toast = globalStyle['toast'];
export const toast_body = globalStyle['toast-body'];
export const toast_header = globalStyle['toast-header'];
export const tooltip = globalStyle['tooltip'];
export const tooltip_inner = globalStyle['tooltip-inner'];
export const user_select_all = globalStyle['user-select-all'];
export const user_select_auto = globalStyle['user-select-auto'];
export const user_select_none = globalStyle['user-select-none'];
export const valid_feedback = globalStyle['valid-feedback'];
export const valid_tooltip = globalStyle['valid-tooltip'];
export const vh_100 = globalStyle['vh-100'];
export const visible = globalStyle['visible'];
export const vw_100 = globalStyle['vw-100'];
export const w_100 = globalStyle['w-100'];
export const w_25 = globalStyle['w-25'];
export const w_50 = globalStyle['w-50'];
export const w_75 = globalStyle['w-75'];
export const w_auto = globalStyle['w-auto'];
export const w3 = globalStyle['w3'];
export const was_validated = globalStyle['was-validated'];

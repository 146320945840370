import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Bouton from '../../../composant/UI/Bouton/Bouton';
import * as actions from '../../../services/actions';
import { connect } from 'react-redux';
import S from './EnTeteProfil.module.scss';

import { Link } from 'react-router-dom';
import * as url from '../../../ressources/glossaires/glossaire_liens_pages';
import AccesRestreins from '../../../hoc/AccesRestreins/AccesRestreins';
import * as privilege from '../../../ressources/glossaires/glossaire_privileges';
import * as requestType from '../../../ressources/glossaires/glossaire_requetes';
import AttenteRequete from '../../../hoc/AttenteRequete/AttenteRequete';
import { FaUserCog } from 'react-icons/fa';

import * as type_btn from '../../../ressources/glossaires/glossaire_type_de_bouton';
import { checkMot } from '../../../ressources/functionJS/checkMot';
const EnTeteProfil = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Bouton rond={true} cliquer={handleClick} type={type_btn.Wait}>
        <FaUserCog size={14} />
      </Bouton>

      <Menu
        id="TopBar"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={
          props.interfaceAdmin ? S.MenuProfil_admin : S.MenuProfil_client
        }
      >
        <div className={S.divItem}>
          <Link to={url.profil}>
            <Bouton ajoutClass={S.Item}>
              <MenuItem onClick={handleClose}>{checkMot('profil')}</MenuItem>
            </Bouton>
          </Link>
        </div>
        <div className={S.divItem}>
          <div className={S.desc}>
            <AccesRestreins numPrivilege={privilege.acces_a_l_interface_admin}>
              {props.interfaceAdmin ? (
                <Link
                  to={url.accueil}
                  onClick={() => {
                    props.commuterInterfaceClient();
                    props.enregistrementProjectIdUrl('');
                  }}
                >
                  <Bouton ajoutClass={S.Item}>
                    <MenuItem>{checkMot('menu_client')}</MenuItem>
                  </Bouton>
                </Link>
              ) : (
                <Link
                  to={url.projet}
                  onClick={() => {
                    props.commuterInterfaceAdmin();
                    props.enregistrementProjectIdUrl('');
                  }}
                >
                  <Bouton ajoutClass={S.Item}>
                    <MenuItem>{checkMot('menu_admin')}</MenuItem>
                  </Bouton>
                </Link>
              )}
            </AccesRestreins>
          </div>
        </div>
        {/*<MenuItem classes={{ root: S.Item}} onClick={handleClose}>*/}
        {/*    <Bouton ajoutClass={S.Item}>{checkMot("aide")}</Bouton>*/}
        {/*</MenuItem>*/}
        <AttenteRequete
          request_type={requestType.deconnexion}
          str={checkMot('deconnexion_en_cours')}
        >
          <Bouton ajoutClass={S.Item} cliquer={props.deconnexion}>
            <MenuItem classes={{ root: S.Item }} onClick={handleClose}>
              {checkMot('deconnexion')}
            </MenuItem>
          </Bouton>
        </AttenteRequete>
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    interfaceAdmin: state.authentifie.interfaceAdmin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deconnexion: () => dispatch(actions.seDeconnecter()),
    commuterInterfaceClient: () =>
      dispatch(actions.commutateurInterfaceClient()),
    commuterInterfaceAdmin: () => dispatch(actions.commutateurInterfaceAdmin()),
    enregistrementProjectIdUrl: (project_id) =>
      dispatch(actions.enregistrerProjectIdUrl(project_id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EnTeteProfil);

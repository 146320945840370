import React, { Component } from 'react';

import Bouton from '../../UI/Bouton/Bouton';
import AccesRestreins from '../../../hoc/AccesRestreins/AccesRestreins';
import AttenteRequete from '../../../hoc/AttenteRequete/AttenteRequete';
import Collapse from '../../../hoc/Collapse/Collapse';

import * as requestType from '../../../ressources/glossaires/glossaire_requetes';
import * as privilege from '../../../ressources/glossaires/glossaire_privileges';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import { confirmeOnClick } from '../../../ressources/functionJS/confirmeOnClick';

import { AiFillEdit, AiFillDelete } from 'react-icons/ai';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import {
  sas,
  hedes,
  ConteneurInfo,
  text_profil_alarme_xy,
  bts,
  ConteneurProfilVue,
  style,
} from './ProfilAlarmeXY.module.scss';
import FormulaireAlarmeXY from './FormulaireAlarmeXY/FormulaireAlarmeXY';

class ProfilAlarmeXY extends Component {
  state = {
    afficherModifierVue: false,
  };

  //fonction afficher le formulaire de modification du profil de l'alarme xy
  afficherModifierProfil = () => {
    this.setState({ afficherModifierVue: true });
  };
  //fonction pour annuler la modification du profil de l'alarme xy
  annulerModificationProfil = () => {
    this.setState({ ...this.state, afficherModifierVue: false });
  };

  render() {
    const formulaireVue = (
      <div className={[s.col_md_12, ConteneurProfilVue].join(' ')}>
        <div className={text_profil_alarme_xy}>
          <FormulaireAlarmeXY
            preconfigurerAlarmeXY={this.props.infoAlarmeXY}
            annuler={this.annulerModificationProfil}
            affichageProfil={!this.state.afficherModifierVue}
          />
          {/*Début barre d'outil pour formulaire Vue*/}
          {this.state.afficherModifierVue ? null : (
            <div className={[s.row, bts].join(' ')}>
              <AccesRestreins numPrivilege={privilege.modifier_des_alarmes}>
                <div className={sas}>
                  {/*Bouton pour passer le formulaire Vue en mode modifiable*/}
                  <Bouton cliquer={this.afficherModifierProfil}>
                    <AiFillEdit /> {checkMot('modifier')}
                  </Bouton>
                </div>
                <div className={sas}>
                  {/*<Bouton cliquer={this.handleOpenModal}> <AiFillPicture/> {checkMot("ajouter_une_image")}</Bouton>*/}
                  {/*<Modal isOpen={this.state.showModal}  className={Modale} overlayClassName={Overlay} onRequestClose={this.handleCloseModal}>*/}
                  {/*    <span onClick={this.handleCloseModal} className={btsFermer}><IoIosCloseCircleOutline size={40}/></span>*/}
                  {/*    <span className={modalCorp}>*/}
                  {/*    </span>*/}
                  {/*</Modal>*/}
                </div>
              </AccesRestreins>
              <div className={sas}>
                <AccesRestreins numPrivilege={privilege.supprimer_des_alarmes}>
                  <AttenteRequete
                    request_type={
                      requestType.supprimer_une_configuration_d_alarme_xy
                    }
                  >
                    <Bouton
                      cliquer={() =>
                        confirmeOnClick(
                          checkMot(
                            'voulez_vous_vraiment_supprimer_cette_alarme_xy'
                          ),
                          () =>
                            this.props.reqSupprimer(
                              this.props.infoAlarmeXY.alarm_xy_id
                            )
                        )
                      }
                    >
                      <AiFillDelete /> {checkMot('supprimer')}
                    </Bouton>
                  </AttenteRequete>
                </AccesRestreins>
              </div>
            </div>
          )}
          {/*Fin barre d'outil pour formulaire Vue*/}
        </div>
      </div>
    );
    return (
      <div className={ConteneurInfo}>
        {/*entête du profil alarme xy*/}
        <div className={[s.card_header, hedes].join(' ')}>
          {/* logo "i"*/}
          <span className={style}>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-info-circle-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
              />
            </svg>
          </span>
          <span className={style}>
            {/*l'id de l'alarme xy*/}
            {checkMot('alarm_xy_id')} : {this.props.infoAlarmeXY.alarm_xy_id}
          </span>
        </div>
        {/*conteneur pour l'affichage général du conteneur info*/}
        <div className={s.container}>
          <Collapse ExpandText={checkMot('info_general')}>
            {formulaireVue}
          </Collapse>
        </div>
      </div>
    );
  }
}

export default ProfilAlarmeXY;
